import { useUser } from '@clerk/clerk-react';

import { isProd } from 'src/utils';

import { TrackContext } from './enums';
import { trackFunction } from './trackFunction';
import { TrackingContextProvider } from './TrackingContextProvider';

export const GlobalTrackingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const { isSignedIn } = useUser();
  return (
    <TrackingContextProvider
      trackFunction={trackFunction}
      trackingProps={{
        environment: process.env.REACT_APP_ENV,
        codeVersion: process.env.VERCEL_GIT_COMMIT_SHA ?? 'local',
        isAuthenticated: isSignedIn,
      }}
      contextName={TrackContext.base}
      debug={!isProd}
      {...props}
    />
  );
};
