import { SvgIcon } from '@mui/material';

export const CardholdersIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 7">
          <g id="Union">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.2233 3.42885C7.70774 2.94441 8.36478 2.67225 9.04988 2.67225C9.73498 2.67225 10.392 2.94441 10.8765 3.42885C11.3609 3.91329 11.6331 4.57033 11.6331 5.25543C11.6331 5.94053 11.3609 6.59757 10.8765 7.08201C10.392 7.56645 9.73498 7.83861 9.04988 7.83861C8.36478 7.83861 7.70774 7.56645 7.2233 7.08201C6.73886 6.59757 6.4667 5.94053 6.4667 5.25543C6.4667 4.57033 6.73886 3.91329 7.2233 3.42885ZM9.04988 4.2756C8.79001 4.2756 8.54079 4.37884 8.35704 4.56259C8.17329 4.74634 8.07005 4.99556 8.07005 5.25543C8.07005 5.5153 8.17329 5.76452 8.35704 5.94827C8.54079 6.13202 8.79001 6.23526 9.04988 6.23526C9.30975 6.23526 9.55897 6.13202 9.74272 5.94827C9.92647 5.76452 10.0297 5.5153 10.0297 5.25543C10.0297 4.99556 9.92647 4.74634 9.74272 4.56259C9.55897 4.37884 9.30975 4.2756 9.04988 4.2756Z"
              fill="inherit"
            />
            <path
              d="M7.46629 9.90709C7.65004 9.72334 7.89926 9.62011 8.15913 9.62011H9.94063C10.2005 9.62011 10.4497 9.72334 10.6335 9.90709C10.8172 10.0908 10.9205 10.3401 10.9205 10.5999V11.4907C10.9205 11.9334 11.2794 12.2924 11.7221 12.2924C12.1649 12.2924 12.5238 11.9334 12.5238 11.4907V10.5999C12.5238 9.91483 12.2517 9.25779 11.7672 8.77335C11.2828 8.28891 10.6257 8.01676 9.94063 8.01676H8.15913C7.47403 8.01676 6.81699 8.28891 6.33255 8.77335C5.84811 9.25779 5.57595 9.91483 5.57595 10.5999V11.4907C5.57595 11.9334 5.93487 12.2924 6.37763 12.2924C6.82038 12.2924 7.1793 11.9334 7.1793 11.4907V10.5999C7.1793 10.3401 7.28253 10.0908 7.46629 9.90709Z"
              fill="inherit"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.231445 3.47393C0.231445 1.55533 1.78678 0 3.70537 0H14.3944C16.313 0 17.8683 1.55533 17.8683 3.47393V11.4907C17.8683 13.4093 16.313 14.9646 14.3944 14.9646H3.70537C1.78678 14.9646 0.231445 13.4093 0.231445 11.4907V3.47393ZM3.70537 1.60335C2.67228 1.60335 1.8348 2.44084 1.8348 3.47393V11.4907C1.8348 12.5238 2.67228 13.3613 3.70537 13.3613H14.3944C15.4275 13.3613 16.265 12.5238 16.265 11.4907V3.47393C16.265 2.44084 15.4275 1.60335 14.3944 1.60335H3.70537Z"
              fill="inherit"
            />
            <path
              d="M5.57595 19.5074C5.57595 19.0647 5.93487 18.7058 6.37763 18.7058H11.7221C12.1649 18.7058 12.5238 19.0647 12.5238 19.5074C12.5238 19.9502 12.1649 20.3091 11.7221 20.3091H6.37763C5.93487 20.3091 5.57595 19.9502 5.57595 19.5074Z"
              fill="inherit"
            />
            <path
              d="M3.70537 16.0335C3.26262 16.0335 2.9037 16.3924 2.9037 16.8352C2.9037 17.2779 3.26262 17.6369 3.70537 17.6369L14.3944 17.6369C14.8371 17.6369 15.1961 17.2779 15.1961 16.8352C15.1961 16.3924 14.8371 16.0335 14.3944 16.0335L3.70537 16.0335Z"
              fill="inherit"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
