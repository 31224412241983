import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { SERVICE_BENJI_CARD_URLS } from 'src/utils';

interface RegisterNewAccountReturn {
  object: 'account_link';
  created: number; // 1700676009
  expires_at: number; //1700676309
  url: string; // "https://connect.stripe.com/setup/c/acct_1OFKXNQ7GKZWJltc/Cw2igHfghyFP"
}

const registerNewAccount = async ({
  email,
  password,
  firstName,
  lastName,
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}) => {
  const { data } = await axios.post<RegisterNewAccountReturn>(
    SERVICE_BENJI_CARD_URLS.USER_REGISTER_NEW_ACCOUNT,
    {
      email,
      password,
      firstName,
      lastName,
    },
  );
  return data;
};

export const useRegisterNewAccount = () => {
  return useMutation({
    mutationFn: (data: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
    }) => registerNewAccount(data),
  });
};
