import { lowerCase } from 'lodash';

import type { CardholderCleaned, CardholderRaw } from 'src/types/cardholders';

export const cleanCardholders = (rawCardholderss: CardholderRaw[]) =>
  rawCardholderss.reduce((acc, cardholder) => {
    const status = lowerCase(cardholder.status);
    return [
      ...acc,
      {
        id: cardholder.id,
        stripeId: cardholder.stripe_cardholder_id,
        billingAddress: cardholder.billing.address,
        createdAt: cardholder.created,
        name: {
          first: cardholder.individual?.first_name,
          last: cardholder.individual?.last_name,
          full: cardholder.name,
        },
        status: status,
        isActive: status === 'active',
        email: cardholder.email,
        phoneNumber: cardholder.phone_number,
        dob:
          cardholder.individual?.dob?.year &&
          cardholder.individual.dob.month &&
          cardholder.individual.dob.day
            ? `${cardholder.individual?.dob?.year ?? ''}-${`${
                cardholder.individual?.dob?.month ?? ''
              }`.padStart(2, '0')}-${`${
                cardholder.individual?.dob?.day ?? ''
              }`.padStart(2, '0')}`
            : '',
        cardholderType: cardholder.metadata.type,
        totalSpendingLimitCents: cardholder.accumulated_spend_limit_cents,
        totalSpendCents: cardholder.accumulated_spend_cents,
        remainingSpendLimitCents:
          cardholder.accumulated_remaining_spend_limit_cents,
        transactionsCount: cardholder.total_transactions,
        cardsCount: cardholder.total_cards,
        group: cardholder.group,
      } as CardholderCleaned,
    ];
  }, [] as CardholderCleaned[]);
