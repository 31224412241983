import styled from '@emotion/styled';
import { CircularProgress, Typography } from '@mui/material';

const LoaderCopyContainer = styled.div``;

const OverlaySpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const AsynchronouslyLoadedSection = ({
  isLoading,
  children,
  loaderCopy,
  className,
}: {
  isLoading: boolean;
  children?: React.ReactNode;
  loaderCopy?: {
    header: string;
    subHeader?: string;
  };
  loaderHeight?: number;
  className?: string;
}) =>
  isLoading ? (
    <OverlaySpinnerContainer className={className}>
      <LoaderCopyContainer>
        {!!loaderCopy?.header && (
          <Typography variant="h5" component="h2">
            {loaderCopy.header}
          </Typography>
        )}
        {!!loaderCopy?.subHeader && (
          <Typography variant="body1" component="p">
            {loaderCopy.subHeader}
          </Typography>
        )}
        <CircularProgress />
      </LoaderCopyContainer>
    </OverlaySpinnerContainer>
  ) : (
    <>{children}</>
  );
