import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';

import { COLOR_PALETTE } from 'src/theme';

type size = 'small' | 'medium' | 'large';

const labelSize = {
  small: 12,
  medium: 12,
  large: 12,
};
const valueSize = {
  small: 15,
  medium: 26,
  large: 32,
};
const gapSize = {
  small: 0,
  medium: 0,
  large: 8,
};

export const DataItemDisplay = ({
  label,
  value,
  className,
  isPrivate,
  size = 'large',
  onClick,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  className?: string;
  isPrivate?: boolean;
  size?: size;
  onClick?: () => void;
}) => {
  return (
    <Box
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${gapSize[size]}px;
      `}
      onClick={onClick}
    >
      <Typography
        className="data-item-display-label"
        css={css`
          display: flex;
          align-items: center;
          align-content: center;
          gap: 4px;
          color: ${COLOR_PALETTE.dataItemDisplayLabel};
          font-size: ${labelSize[size]}px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        `}
      >
        {label}
      </Typography>
      <Typography
        css={css`
          color: ${COLOR_PALETTE.dataItemDisplayValue};
          font-size: ${valueSize[size]}px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          ${size === 'small' && 'min-height: 15px'};
          ${size === 'small' && 'padding-top: 4px'};
        `}
        className={`${
          isPrivate ? 'highlight-block' : ''
        } data-item-display-value`}
      >
        {value}
      </Typography>
    </Box>
  );
};
