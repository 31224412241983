import { SvgIcon } from '@mui/material';

export const UserProfileIcon = ({
  className, // color,
}: {
  className?: string;
}) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <mask id="profileMask" x="0" y="0" width="34" height="34">
            <rect width="34" height="34" fill="white" />
            <path
              transform="scale(0.8) translate(4.25, 4.25)"
              d="M17 0.000976562C7.61214 0.000976562 0 7.61162 0 17.0002C0 26.3888 7.61139 33.9995 17 33.9995C26.3894 33.9995 34 26.3888 34 17.0002C34 7.61162 26.3894 0.000976562 17 0.000976562ZM17 5.08395C20.1064 5.08395 22.6236 7.60192 22.6236 10.7068C22.6236 13.8124 20.1064 16.3297 17 16.3297C13.8951 16.3297 11.3779 13.8124 11.3779 10.7068C11.3779 7.60192 13.8951 5.08395 17 5.08395ZM16.9963 29.555C13.8981 29.555 11.0605 28.4267 8.87187 26.5591C8.33871 26.1043 8.03105 25.4375 8.03105 24.7378C8.03105 21.5889 10.5796 19.0687 13.7293 19.0687H20.2722C23.4226 19.0687 25.9615 21.5889 25.9615 24.7378C25.9615 25.4382 25.6553 26.1036 25.1214 26.5583C22.9335 28.4267 20.0952 29.555 16.9963 29.555Z"
              fill="black"
            />
          </mask>
        </defs>
        <circle
          cx="17"
          cy="17"
          r="12"
          fill={'inherit'}
          mask="url(#profileMask)"
        />
        <circle
          cx="17"
          cy="17"
          r="16"
          fill="none"
          stroke={'inherit'}
          strokeWidth="1"
        />
      </svg>
    </SvgIcon>
  );
};
