import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';

import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import { useAppRoutes } from './useAppRoutes';
import { useAxios } from './useAxios';

import type { Address } from 'src/types';
import type { CardType } from 'src/types/cards';

export interface UserDetails {
  cardHolderDetails?: {
    id: string;
    'billing.address': string;
    email: string;
    name: string;
    phone_number: string;
    status: string;
  };

  userDetails: {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    status: string;
  };
  accountDetails: {
    companyName: string;
    companyAddress: Address;
    companySupportEmail: string;
    companyCardTypes: CardType[];
    features: {
      groups: boolean;
      sendCardsViaEmail: boolean;
    };
  };
}

export const useGetUserDetails = () => {
  const { axios, generateSecureHeaders } = useAxios();

  const { isSignedIn } = useUser();
  const { isAuthenticatedRoute } = useAppRoutes();
  const getUserDetails = async () => {
    const { data } = await axios.get<UserDetails>(
      SERVICE_BENJI_CARD_URLS.USER_GET_DETAILS,
      {
        headers: await generateSecureHeaders(),
      },
    );
    return data;
  };

  return useQuery({
    queryFn: () => getUserDetails(),
    queryKey: queryKeys.account.details(),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!isSignedIn && !!isAuthenticatedRoute,
  });
};
