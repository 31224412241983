import { SvgIcon } from '@mui/material';

import { COLOR_PALETTE } from 'src/theme';

export const SignOutIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Frame 9"
          clipPath="url(#clip0_712_1680)"
          fill="none"
          stroke={COLOR_PALETTE.textOnDark}
        >
          <path
            id="Vector"
            d="M9.16699 7.33366V5.50033C9.16699 5.0141 9.36015 4.54778 9.70396 4.20396C10.0478 3.86015 10.5141 3.66699 11.0003 3.66699H17.417C17.9032 3.66699 18.3695 3.86015 18.7134 4.20396C19.0572 4.54778 19.2503 5.0141 19.2503 5.50033V16.5003C19.2503 16.9866 19.0572 17.4529 18.7134 17.7967C18.3695 18.1405 17.9032 18.3337 17.417 18.3337H11.0003C10.5141 18.3337 10.0478 18.1405 9.70396 17.7967C9.36015 17.4529 9.16699 16.9866 9.16699 16.5003V14.667"
            stroke="inherit"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M13.75 11H2.75L5.5 8.25"
            stroke="inherit"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M5.5 13.75L2.75 11"
            stroke="inherit"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_712_1680">
            <rect width="22" height="22" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
