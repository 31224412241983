import { SvgIcon } from '@mui/material';

export const AddressInputIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
      >
        <g clipPath="url(#clip0_500_14168)">
          <path
            d="M4.25 9.00781H2.75L9.5 2.25781L16.25 9.00781H14.75"
            stroke="#141E27"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.25 9.00781V14.2578C4.25 14.6556 4.40804 15.0372 4.68934 15.3185C4.97064 15.5998 5.35218 15.7578 5.75 15.7578H13.25C13.6478 15.7578 14.0294 15.5998 14.3107 15.3185C14.592 15.0372 14.75 14.6556 14.75 14.2578V9.00781"
            stroke="#141E27"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 9.00781H11V12.0078H8V9.00781Z"
            stroke="#141E27"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_500_14168">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0.5 0.0078125)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
