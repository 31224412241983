import { ClerkProvider } from '@clerk/clerk-react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ErrorBoundary } from '@highlight-run/react';
import { GlobalStyles, ThemeProvider as MuiThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, useNavigate } from 'react-router-dom';

import {
  ToastProvider,
  ModalProvider,
  GlobalTrackingContextProvider,
} from 'src/context';

import { AppRoutes } from './AppRoutes';
import { Highlight, Mixpanel } from './components';
import { PageWrapper } from './components/PageWrapper';
import { COLOR_PALETTE, theme } from './theme';

import './App.css';

const queryClient = new QueryClient();

const REACT_APP_CLERK_PUBLISHABLE_KEY =
  process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
if (!REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

const DEFAULT_SIGNED_IN_REDIRECT_URL = '/secure/dashboard';
const SIGN_IN_PAGE_URL = '/login';

const ClerkProviderWithRouting = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  return (
    <ClerkProvider
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}
      publishableKey={REACT_APP_CLERK_PUBLISHABLE_KEY}
      afterSignOutUrl={SIGN_IN_PAGE_URL}
      signInFallbackRedirectUrl={DEFAULT_SIGNED_IN_REDIRECT_URL}
      signUpFallbackRedirectUrl={DEFAULT_SIGNED_IN_REDIRECT_URL}
      signInForceRedirectUrl={DEFAULT_SIGNED_IN_REDIRECT_URL}
      signUpForceRedirectUrl={DEFAULT_SIGNED_IN_REDIRECT_URL}
      signInUrl={SIGN_IN_PAGE_URL}
      signUpUrl={SIGN_IN_PAGE_URL}
      supportEmail="support@benjicard.com"
      appearance={{
        variables: {
          colorPrimary: COLOR_PALETTE.textOnLight,
          colorDanger: COLOR_PALETTE.error,
          colorSuccess: COLOR_PALETTE.success,
          colorWarning: COLOR_PALETTE.warning,
          colorText: COLOR_PALETTE.textOnLight,
        },
      }}
    >
      {children}
    </ClerkProvider>
  );
};

export const App = () => {
  return (
    <BrowserRouter basename="/">
      <ClerkProviderWithRouting>
        <QueryClientProvider client={queryClient}>
          <MuiThemeProvider theme={theme}>
            <EmotionThemeProvider theme={theme}>
              <GlobalStyles styles={{ body: { fontOpticalSizing: 'auto' } }} />
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <GlobalTrackingContextProvider>
                  <ErrorBoundary showDialog={false}>
                    <ToastProvider>
                      <ModalProvider>
                        <PageWrapper>
                          <AppRoutes />
                        </PageWrapper>
                      </ModalProvider>
                    </ToastProvider>
                  </ErrorBoundary>
                  <Highlight />
                  <Mixpanel />
                </GlobalTrackingContextProvider>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              </LocalizationProvider>
            </EmotionThemeProvider>
          </MuiThemeProvider>
        </QueryClientProvider>
      </ClerkProviderWithRouting>
    </BrowserRouter>
  );
};
