import { css } from '@emotion/react';
import { Clear as ClearIcon } from '@mui/icons-material';
import { Button, InputAdornment, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { COLOR_PALETTE } from 'src/theme';

import type { TextFieldProps } from '@mui/material';

export const CurrencyTextField = (props: TextFieldProps) => {
  const { setValue, formState } = useFormContext();
  return (
    <TextField
      {...props}
      className={`${props.className} highlight-block`}
      sx={{
        input: {
          '&::placeholder': {
            opacity: 1,
            color: COLOR_PALETTE.lightTextOnLight,
          },
        },
      }}
      css={css`
        fieldset {
          border-radius: 8px;
          border-color: ${COLOR_PALETTE.border};
        }
      `}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: (
          <InputAdornment position="end">
            <Button
              disabled={!props.value || formState.isSubmitting}
              size="small"
              css={css`
                padding: 8px;
                min-width: 0;
                color: rgba(0, 0, 0, 0.6);
              `}
              onClick={() => {
                if (props.name) {
                  setValue(props.name, null);
                } else {
                  console.warn('CurrencyTextField: no name prop');
                }
              }}
            >
              <ClearIcon />
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};
