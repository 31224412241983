import { css } from '@emotion/react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from '@mui/material';

import { TrackType, useModal, useTrack } from 'src/context';

export const CONFIRM_DEACTIVATE_CARDHOLDER_MODAL =
  'confirm-deactivate-cardholder-modal';
export const useConfirmDeactivateCardholderModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>>,
) => {
  const { track } = useTrack();

  return useModal({
    modalId: CONFIRM_DEACTIVATE_CARDHOLDER_MODAL,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
    },
    onOpen: () => {
      track({
        label: 'Modal Opened',
        type: TrackType.effect,
        modalName: 'Confirm Deactivate Cardholder',
        isSuccessful: null,
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
};

export const ConfirmDeactivateCardholderModal = ({
  onSubmit,
  isSubmitting,
}: {
  onSubmit: () => void;
  isSubmitting?: boolean;
}) => {
  const { track } = useTrack();
  const { isOpen, closeModal } = useConfirmDeactivateCardholderModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Confirm Deactivate Cardholder',
      });
    },
  });

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="xs"
      onClose={isSubmitting ? handleCloseModal : undefined}
    >
      <DialogTitle>
        Warning
        <IconButton
          disabled={isSubmitting}
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Deactivating this cardholder will deactivate all its remaining active
          cards. Would you like to proceed?
        </Typography>
      </DialogContent>
      <DialogActions
        css={css`
          /* TODO: get color from theme */
          border-top: 1px solid #e0e0e0;
        `}
      >
        <Button
          onClick={handleCloseModal}
          variant="text"
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={isSubmitting} variant="contained">
          Deactivate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
