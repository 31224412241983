import { useUser } from '@clerk/clerk-react';

import { useGetUserDetails, useGetUserPrivileges } from 'src/hooks';

import { TrackContext } from './enums';
import { TrackingContextProvider } from './TrackingContextProvider';

export const AppTrackingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const { user } = useUser();
  const { privileges } = useGetUserPrivileges();
  const { data: { userDetails, accountDetails } = {} } = useGetUserDetails();

  return (
    <TrackingContextProvider
      trackingProps={{
        userId: user?.id,
        userFirstName: userDetails?.first_name,
        userLastName: userDetails?.last_name,
        userName: `${userDetails?.first_name} ${userDetails?.last_name}`,
        userEmail: user?.primaryEmailAddress?.emailAddress,
        companyName: accountDetails?.companyName,
        userPrivileges: privileges,
      }}
      contextName={TrackContext.app}
      {...props}
    />
  );
};
