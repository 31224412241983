import { get } from 'lodash';
import { createContext, useContext, useState } from 'react';

export interface ModalProviderArgs {
  children: React.ReactNode;
}

export interface ModalContextValues {
  getModalStatus: (modalId: string) => boolean;
  openModal: (modalId: string) => void;
  closeModal: (modalId: string) => void;
  numberOfModalsOpen: number;
}

const ModalContext = createContext<ModalContextValues | Record<string, never>>(
  {},
);

export const ModalProvider = ({ children }: ModalProviderArgs): JSX.Element => {
  const [modals, setModals] = useState<Record<string, boolean>>({});
  const handleOpenModal = (modalId: string) => {
    setModals((modals) => ({
      ...modals,
      [modalId]: true,
    }));
  };
  const handleCloseModal = (modalId: string) => {
    setModals((modals) => ({
      ...modals,
      [modalId]: false,
    }));
  };

  return (
    <ModalContext.Provider
      value={{
        getModalStatus: (modalId: string) => get(modals, modalId, false),
        openModal: handleOpenModal,
        closeModal: handleCloseModal,
        numberOfModalsOpen: Object.values(modals).filter(Boolean).length,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = ({
  modalId,
  onClose,
  onOpen,
}: {
  modalId?: string;
  onClose?: () => void;
  onOpen?: () => void;
}) => {
  const { getModalStatus, openModal, closeModal, numberOfModalsOpen } =
    useContext(ModalContext);
  return {
    numberOfModalsOpen,
    getModalStatus: (id?: string) => getModalStatus(id ?? modalId ?? ''),
    isOpen: getModalStatus(modalId ?? ''),
    openModal: () => {
      if (onOpen) {
        onOpen();
      }
      if (modalId) {
        openModal(modalId);
      } else {
        throw new Error('modalId is required');
      }
    },
    closeModal: () => {
      if (onClose) {
        onClose();
      }
      if (modalId) {
        closeModal(modalId);
      } else {
        throw new Error('modalId is required');
      }
    },
  };
};
