import { useUser } from '@clerk/clerk-react';
import { css } from '@emotion/react';
import { Card, Typography, Box, Skeleton } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import { type StripeElementStyle } from '@stripe/stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';

import { TrackType, useTrack } from 'src/context';
import { useAxios } from 'src/hooks/useAxios';
import { COLOR_PALETTE } from 'src/theme';
import { SERVICE_BENJI_CARD_URLS } from 'src/utils';

import visaLogo from './black-visa-logo.svg';
import checkIcon from './check-icon.png';
import copyIcon from './copy.svg';

import { FormFieldSwitch } from '../forms/FormFieldSwitch';
import { Logo } from '../Logo';
import { ScaleInnerComponentToFitWidth } from '../ScaleInnerComponentToFitWidth';

const handleCopyClick = (
  setIsCopySuccess: (isCopySuccess: boolean) => void,
) => {
  setIsCopySuccess(true);
  setTimeout(() => {
    setIsCopySuccess(false);
  }, 1000);
};

const useGetCardDetails = ({
  setIsCopyCardSuccess,
  setIsCopyCCVSuccess,
  setIsCopyExpirationSuccess,
}: {
  setIsCopyCardSuccess: (isCopySuccess: boolean) => void;
  setIsCopyCCVSuccess: (isCopySuccess: boolean) => void;
  setIsCopyExpirationSuccess: (isCopySuccess: boolean) => void;
}) => {
  const { axios, generateSecureHeaders } = useAxios();
  const { isSignedIn } = useUser();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false);

  const getCardDetails = async ({
    cardId,
    token,
  }: {
    cardId: string;
    token?: string;
  }) => {
    setIsLoading(true);
    if (stripe) {
      const nonceResult = await stripe.createEphemeralKeyNonce({
        issuingCard: cardId,
      });
      const elements = stripe.elements();

      const nonce = nonceResult.nonce;
      const ephemeralKeyResult = await axios.post(
        isSignedIn
          ? SERVICE_BENJI_CARD_URLS.CARD_GET_AUTHED_EPHEMERAL_KEY
          : generatePath(
              SERVICE_BENJI_CARD_URLS.CARD_GET_SEND_CARD_EPHEMERAL_KEY,
              {
                token,
              },
            ),
        {
          card_id: cardId,
        },
        isSignedIn ? { headers: await generateSecureHeaders() } : {},
      );
      const ephemeralKeySecret = ephemeralKeyResult.data.ephemeralKeySecret;

      // https://stripe.com/docs/js/appendix/style
      const stripeStyles: StripeElementStyle = {
        base: {
          color: COLOR_PALETTE.cardDisplayText,
          fontSize: '14px',
          lineHeight: '20px',
          fontFamily: 'Helvetica',
          fontWeight: 300,
          fontSmoothing: 'antialiased',
        },
      };

      const cardNumber = elements.create('issuingCardNumberDisplay', {
        issuingCard: cardId,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKeySecret,
        style: stripeStyles,
      });
      cardNumber.mount('#card-number');
      const cardNumberCopy = elements.create('issuingCardCopyButton', {
        toCopy: 'number',
        style: stripeStyles,
      });
      cardNumberCopy.mount('#card-number-copy');
      cardNumberCopy.on('click', () => {
        handleCopyClick(setIsCopyCardSuccess);
      });

      const cardCVC = elements.create('issuingCardCvcDisplay', {
        issuingCard: cardId,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKeySecret,
        style: stripeStyles,
      });
      cardCVC.mount('#card-cvc');
      const cardCVCCopy = elements.create('issuingCardCopyButton', {
        toCopy: 'cvc',
        style: stripeStyles,
      });
      cardCVCCopy.mount('#card-cvc-copy');
      cardCVCCopy.on('click', () => {
        handleCopyClick(setIsCopyCCVSuccess);
      });

      const cardExpiry = elements.create('issuingCardExpiryDisplay', {
        issuingCard: cardId,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKeySecret,
        style: stripeStyles,
      });
      cardExpiry.mount('#card-expiry');
      const cardExpiryCopy = elements.create('issuingCardCopyButton', {
        toCopy: 'expiry',
        style: stripeStyles,
      });
      cardExpiryCopy.mount('#card-expiry-copy');
      cardExpiryCopy.on('click', () => {
        handleCopyClick(setIsCopyExpirationSuccess);
      });
    } else {
      console.error(
        'Stripe.js has not loaded yet. Unable to load card details',
      );
    }
    setIsLoading(false);
    return;
  };

  return {
    getCardDetails,
    isLoading,
  };
};

export const CreditCardPreview = ({
  cardholder,
  cardId,
  lastFour,
  expiry,
  showByDefault = false,
  className,
  token,
}: {
  cardholder?: string;
  cardId?: string;
  lastFour?: string;
  expiry?: string;
  showByDefault?: boolean;
  className?: string;
  token?: string;
}) => {
  const { track } = useTrack();
  const [areDetailsShown, setAreDetailsShown] = useState(false);
  const [isCopyNameSuccess, setIsCopyNameSuccess] = useState(false);
  const [isCopyCardSuccess, setIsCopyCardSuccess] = useState(false);
  const [isCopyCCVSuccess, setIsCopyCCVSuccess] = useState(false);
  const [isCopyExpirationSuccess, setIsCopyExpirationSuccess] = useState(false);
  const { isLoading, getCardDetails } = useGetCardDetails({
    setIsCopyCardSuccess,
    setIsCopyCCVSuccess,
    setIsCopyExpirationSuccess,
  });
  const handleHideShow = useCallback(
    async (newSreDetailsShownFromProps?: boolean) => {
      const newAreDetailsShown =
        newSreDetailsShownFromProps ?? !areDetailsShown;
      if (cardId) {
        if (!showByDefault) {
          track({
            label: 'Toggle card number visibility',
            type: TrackType.action,
            actionType: 'toggle',
            isVisible: !areDetailsShown,
          });
        }
        setAreDetailsShown(newAreDetailsShown);
        if (newAreDetailsShown && !isLoading) {
          console.log({
            areDetailsShown,
            cardId,
            getCardDetails,
            isLoading,
            showByDefault,
            token,
            track,
          });
          await getCardDetails({ cardId, token });
        }
      } else {
        console.error('No card ID provided');
      }
    },
    [
      areDetailsShown,
      cardId,
      getCardDetails,
      isLoading,
      showByDefault,
      token,
      track,
    ],
  );

  useEffect(() => {
    if (showByDefault && !areDetailsShown) {
      // TODO: figure out why this is being called twice on the view-card page
      handleHideShow();
    }
  }, [areDetailsShown, handleHideShow, showByDefault, isLoading]);

  const toggleIsDisabled = !cardId || showByDefault;

  return (
    <ScaleInnerComponentToFitWidth
      minWidthBeforeScaling={320}
      className={className}
    >
      <Card
        css={css`
          display: flex;
          flex-direction: column;
          background: #7d7e7d;
          background: linear-gradient(113deg, #f3f3f3 8.83%, #fff 90.53%);
          border-radius: 12px;
          border: 1px solid ${COLOR_PALETTE.cardBorder};
          max-width: 320px;
          margin: 0 auto;
          width: 100%;
          min-height: 180px;
        `}
      >
        <Box
          css={css`
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            height: 100%;
            flex-grow: 1;
            padding: 12px;
          `}
        >
          <Box
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            `}
          >
            <Logo
              css={css`
                height: 30px;
                margin: 0;
              `}
              variant="dark"
            />
            {showByDefault ? null : (
              <FormFieldSwitch<{
                cardIsActive: boolean;
              }>
                name="cardIsActive"
                label={areDetailsShown ? 'Hide' : 'Show'}
                defaultValue={areDetailsShown}
                checked={areDetailsShown}
                disabled={toggleIsDisabled}
                onChange={(checked) => {
                  handleHideShow(checked);
                }}
                css={css`
                  .MuiSwitch-track {
                    ${toggleIsDisabled
                      ? `
                        background-color: ${COLOR_PALETTE.switchBackgroundDisabled}!important;
                        `
                      : `
                        background-color: ${
                          areDetailsShown
                            ? COLOR_PALETTE.switchBackground
                            : COLOR_PALETTE.switchLightState
                        }!important;
                      `}
                  }
                `}
              />
            )}
          </Box>
          <Box
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: 8px 10px;
              width: 100%;
              margin-top: auto;
            `}
          >
            <Box
              css={css`
                flex: 1 1 100%;
              `}
            >
              <Typography
                css={css`
                  display: flex;
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 500;
                  margin-bottom: 4px;
                  position: relative;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                `}
                className="highlight-block"
              >
                {cardholder ? (
                  <>
                    {cardholder}
                    <Box
                      component="button"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(cardholder)
                          .then(() => {
                            handleCopyClick(setIsCopyNameSuccess);
                          })
                          .catch((error) => {
                            console.error('Failed to copy cardholder name', {
                              error,
                            });
                          });
                      }}
                      css={css`
                        align-self: center;
                        height: 20px;
                        width: 20px;
                        border: 0;
                        border-radius: 4px;
                        padding: 0;
                        margin-left: 4px;
                        background-image: ${isCopyNameSuccess
                          ? `url(${checkIcon})`
                          : `url(${copyIcon})`};
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 12px;
                        background-color: transparent;
                        transition: transform 0.2s;
                        ${isCopyNameSuccess
                          ? ''
                          : `
                        &:hover {
                          cursor: pointer;
                          transform: scale(0.9);
                          background-color: ${COLOR_PALETTE.cardPreviewCopyButtonHover}; 
                        }
                        
                      `}
                      `}
                    />
                  </>
                ) : isLoading ? (
                  <Skeleton
                    css={css`
                      background-color: gray;
                      position: absolute;
                      transform: none;
                      top: 4px;
                      left: -4px;
                      width: 220px;
                      height: 16px;
                    `}
                  />
                ) : null}
              </Typography>
            </Box>
            <Box
              css={css`
                flex: 0 0 100%;
              `}
              className="highlight-block"
            >
              {areDetailsShown ? (
                <Box
                  css={css`
                    display: flex;
                    gap: 4px;
                    position: relative;
                    height: 20px;
                    #card-number {
                      width: 136px;
                      height: 20px;
                    }
                    button {
                      align-self: center;
                      height: 20px;
                      width: 20px;
                      border: 0;
                      border-radius: 4px;
                      padding: 0;
                      background-image: ${isCopyCardSuccess
                        ? `url(${checkIcon})`
                        : `url(${copyIcon})`};
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: 12px;
                      background-color: transparent;
                      transition: transform 0.2s;
                      ${isCopyCardSuccess
                        ? ''
                        : `
                        &:hover {
                          cursor: pointer;
                          transform: scale(0.9);
                          background-color: ${COLOR_PALETTE.cardPreviewCopyButtonHover}; 
                        }
                        
                      `}
                    }
                  `}
                >
                  <div id="card-number" />
                  <button id="card-number-copy" />
                  {isLoading && (
                    <Skeleton
                      css={css`
                        background-color: gray;
                        position: absolute;
                        transform: none;
                        border-radius: 6px;
                        top: 3px;
                        left: -1px;
                        width: 140px;
                        height: 14px;
                      `}
                    />
                  )}
                </Box>
              ) : (
                <Box
                  css={css`
                    height: 20px;
                  `}
                >
                  <Typography
                    css={css`
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: 300;
                      font-family: 'Helvetica';
                    `}
                  >
                    <Box
                      component="span"
                      css={css`
                        padding-left: 2px;
                        letter-spacing: 1.9px;
                      `}
                    >
                      **** **** ****
                    </Box>{' '}
                    {lastFour ?? (
                      <Box
                        component="span"
                        css={css`
                          letter-spacing: 1.4px;
                        `}
                      >
                        ****
                      </Box>
                    )}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              css={css`
                height: 20px;
                width: 60px;
              `}
              className="highlight-block"
            >
              {areDetailsShown ? (
                <Box
                  css={css`
                    display: flex;
                    gap: 4px;
                    position: relative;

                    #card-expiry {
                      width: 35px;
                      height: 20px;
                    }
                    button {
                      align-self: center;
                      height: 20px;
                      width: 20px;
                      border: 0;
                      border-radius: 4px;
                      padding: 0;
                      background-image: ${isCopyExpirationSuccess
                        ? `url(${checkIcon})`
                        : `url(${copyIcon})`};
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: 12px;
                      background-color: transparent;
                      transition: transform 0.2s;
                      ${isCopyExpirationSuccess
                        ? ''
                        : `
                        &:hover {
                          cursor: pointer;
                          transform: scale(0.9);
                          background-color: ${COLOR_PALETTE.cardPreviewCopyButtonHover}; 
                        }
                        
                      `}
                    }
                  `}
                >
                  <div id="card-expiry" />
                  <button id="card-expiry-copy" />
                  {isLoading && (
                    <Skeleton
                      css={css`
                        background-color: gray;
                        position: absolute;
                        transform: none;
                        border-radius: 6px;
                        top: 3px;
                        left: -1px;
                        width: calc(100% - 22px);
                        height: 14px;
                      `}
                    />
                  )}
                </Box>
              ) : expiry ? (
                <Typography
                  css={css`
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 300;
                    font-family: 'Helvetica';
                  `}
                >
                  {expiry}
                </Typography>
              ) : (
                <Typography
                  css={css`
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 1.9px;
                    font-family: 'Helvetica';
                  `}
                >
                  **/**
                </Typography>
              )}
            </Box>
            <Box
              css={css`
                height: 20px;
                width: 50px;
              `}
              className="highlight-block"
            >
              {areDetailsShown ? (
                <Box
                  css={css`
                    display: flex;
                    gap: 4px;
                    position: relative;

                    #card-cvc {
                      width: 24px;
                      height: 20px;
                    }
                    button {
                      align-self: center;
                      height: 20px;
                      width: 20px;
                      border: 0;
                      border-radius: 4px;
                      padding: 0;
                      background-image: ${isCopyCCVSuccess
                        ? `url(${checkIcon})`
                        : `url(${copyIcon})`};
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: 12px;
                      background-color: transparent;
                      transition: transform 0.2s;
                      ${isCopyCCVSuccess
                        ? ''
                        : `
                        &:hover {
                          cursor: pointer;
                          transform: scale(0.9);
                          background-color: ${COLOR_PALETTE.cardPreviewCopyButtonHover}; 
                        }
                        
                      `}
                    }
                  `}
                >
                  <div id="card-cvc" />
                  <button id="card-cvc-copy" />
                  {isLoading && (
                    <Skeleton
                      css={css`
                        background-color: gray;
                        position: absolute;
                        transform: none;
                        border-radius: 6px;
                        top: 3px;
                        left: -1px;
                        width: calc(100% - 22px);
                        height: 14px;
                      `}
                    />
                  )}
                </Box>
              ) : (
                <Box
                  css={css`
                    height: 20px;
                    width: 78px;
                  `}
                >
                  <Typography
                    css={css`
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: 300;
                      letter-spacing: 1.9px;
                      font-family: 'Helvetica';
                    `}
                  >
                    ***
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              component="img"
              src={visaLogo}
              css={css`
                height: 20px;
                align-self: flex-end;
                margin-left: auto;
              `}
            />
          </Box>
        </Box>
      </Card>
    </ScaleInnerComponentToFitWidth>
  );
};
