import { css } from '@emotion/react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import type {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

export function FormFieldCheckbox<FormFields extends FieldValues>({
  name,
  label,
  control,
  defaultValue,
  className,
  rules,
  disabled,
}: {
  name: FieldPath<FormFields>;
  label: React.ReactNode;
  control: Control<FormFields>;
  defaultValue: FieldPathValue<FormFields, FieldPath<FormFields>>;
  className?: string;
  rules?: Omit<
    RegisterOptions<FormFields>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  disabled?: boolean;
  type?: string;
  onBlur?: () => void;
}) {
  const { formState } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <FormControl
          disabled={formState.isSubmitting || disabled}
          className={className}
          error={!!get(formState.errors, field.name)}
          css={css`
            flex: 1 1 100%;
          `}
        >
          <FormControlLabel
            label={label}
            css={css`
              align-items: flex-start;
              .MuiCheckbox-root {
                padding: 2px 8px;
              }
            `}
            control={
              <Checkbox
                id={field.name}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                checked={!!field.value}
              />
            }
          />
          {!!get(formState.errors, field.name) && (
            <FormHelperText error={!!get(formState.errors, field.name)}>
              {get(formState.errors, field.name)
                ? (get(formState.errors, field.name)?.message as string)
                : ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
