import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';

import './App.css';

// @ts-expect-error -- root element is defined in index.html
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
