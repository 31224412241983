import { SvgIcon } from '@mui/material';

export const NameInputIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
      >
        <g clipPath="url(#clip0_500_14156)">
          <path
            d="M6.5 5.75781C6.5 6.55346 6.81607 7.31652 7.37868 7.87913C7.94129 8.44174 8.70435 8.75781 9.5 8.75781C10.2956 8.75781 11.0587 8.44174 11.6213 7.87913C12.1839 7.31652 12.5 6.55346 12.5 5.75781C12.5 4.96216 12.1839 4.1991 11.6213 3.63649C11.0587 3.07388 10.2956 2.75781 9.5 2.75781C8.70435 2.75781 7.94129 3.07388 7.37868 3.63649C6.81607 4.1991 6.5 4.96216 6.5 5.75781Z"
            stroke="#141E27"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 16.2578V14.7578C5 13.9622 5.31607 13.1991 5.87868 12.6365C6.44129 12.0739 7.20435 11.7578 8 11.7578H11C11.7956 11.7578 12.5587 12.0739 13.1213 12.6365C13.6839 13.1991 14 13.9622 14 14.7578V16.2578"
            stroke="#141E27"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_500_14156">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0.5 0.507812)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
