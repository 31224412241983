import { useAuth } from '@clerk/clerk-react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Menu as MenuIcon,
  ChevronRightRounded as ChevronRightRoundedIcon,
  BusinessOutlined as BusinessOutlinedIcon,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Drawer as DrawerBase,
  IconButton,
  List,
  ListItem as ListItemBase,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { startCase } from 'lodash';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { TrackType, useTrack } from 'src/context';
import {
  useAppRoutes,
  useGetUserDetails,
  useGetUserPrivileges,
  useGetFeatures,
} from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';

import {
  BalanceTransferIcon,
  CardholdersIcon,
  CardsIcon,
  DisputesIcon,
  HomeIcon,
  SignOutIcon,
  TransactionsIcon,
  UserProfileIcon,
  UsersIcon,
} from './Icon';
import { Logo } from './Logo';
import { PageFooter } from './PageFooter';

import type { SxProps } from '@mui/material';

export const drawerWidth = 252;

const Drawer = styled(DrawerBase)`
  & > .MuiPaper-root {
    border-right: none;
    background-color: ${COLOR_PALETTE.darkBackground};
    padding: 28px 11px;
  }
`;

const ListItem = styled(ListItemBase)`
  .MuiListItemButton-root {
    display: flex;
    width: 220px;
    padding: 12px 10px 12px 18px;
    align-items: center;
    gap: 20px;
    border-radius: 100px;
    &,
    .MuiTypography-root {
      color: ${COLOR_PALETTE.textOnDark};
    }
    .MuiListItemText-root {
      margin: 0;
    }
    .MuiTypography-root {
      font-size: 15px;
      font-weight: 600;
      line-height: 19px;
    }
    .MuiListItemIcon-root {
      min-width: initial;
    }
    & .MuiSvgIcon-root {
      height: 22px;
      width: 22px;
      fill: ${COLOR_PALETTE.textOnDark};
    }
    &.Mui-selected,
    &.Mui-selected:hover {
      background-color: ${COLOR_PALETTE.white};
      &,
      .MuiTypography-root {
        color: ${COLOR_PALETTE.textOnLight};
      }
      & .MuiSvgIcon-root {
        fill: ${COLOR_PALETTE.textOnLight};
      }
    }
    &:hover {
      background-color: ${COLOR_PALETTE.navItemHover};
      &,
      .MuiTypography-root {
        color: ${COLOR_PALETTE.textOnDark};
      }
      & .MuiSvgIcon-root {
        fill: ${COLOR_PALETTE.textOnDark};
      }
    }
  }
`;

const PageMainSection = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-grow: 1;
  overflow: auto;
  width: 100vw;
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      width: calc(100vw - ${drawerWidth}px);
    }
  `}
`;

const PageContents = styled(Box)`
  flex-grow: 1;
  padding: 32px 24px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  & > * {
    width: 100%;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 12px;
    }
  `};
`;

export const PageWrapper = ({ children }: { children: React.ReactNode }) => {
  const { track } = useTrack();
  const { signOut } = useAuth();
  const { privileges, isLoading: isLoadingPrivileges } = useGetUserPrivileges();
  const { data: userDetails } = useGetUserDetails();
  const { navigate, generatePath } = useAppRoutes();
  const { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { features, isLoading: isLoadingFeatures } = useGetFeatures();

  const isPageLoading = isLoadingPrivileges || isLoadingFeatures;

  const handleLogout = async () => {
    track({
      label: 'Click logout',
      type: TrackType.action,
      actionType: 'click',
    });
    signOut();
  };

  const drawer = (
    <>
      <Link
        to="/secure/dashboard"
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <Logo
          css={css`
            max-width: 100px;
            padding-bottom: 0;
          `}
          variant="light"
        />
      </Link>
      <Typography
        component="span"
        variant="body1"
        css={css`
          display: block;
          width: 100%;
          text-align: center;
          color: ${COLOR_PALETTE.navSubHeader};
          margin-top: 9px;
          letter-spacing: 2.4px;
          line-height: normal;
          font-weight: 400;
          font-style: normal;
          font-feature-settings:
            'clig' off,
            'liga' off;
          min-height: 17.5px;
        `}
      >
        {userDetails?.accountDetails.companyName ?? ''}
      </Typography>
      <List
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 48px 11px 0 0;
          width: 100%;
        `}
      >
        {isPageLoading ? null : (
          <>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate('/secure/dashboard');
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'instant',
                  });
                  setMobileOpen(false);
                }}
                selected={generatePath('/secure/dashboard') === pathname}
                css={css`
                  ${generatePath('/secure/dashboard') === pathname
                    ? `
                    span {
                      font-weight: bold;
                    }
                  `
                    : ''}
                `}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Overview" />
              </ListItemButton>
            </ListItem>
            {features?.groups && privileges?.cards && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate('/secure/groups');
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'instant',
                    });
                    setMobileOpen(false);
                  }}
                  selected={generatePath('/secure/groups') === pathname}
                >
                  <ListItemIcon>
                    <BusinessOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Groups" />
                </ListItemButton>
              </ListItem>
            )}
            {privileges?.cards && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate('/secure/cardholders');
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'instant',
                    });
                    setMobileOpen(false);
                  }}
                  selected={generatePath('/secure/cardholders') === pathname}
                >
                  <ListItemIcon>
                    <CardholdersIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cardholders" />
                </ListItemButton>
              </ListItem>
            )}
            {privileges?.cards && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate('/secure/cards');
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'instant',
                    });
                    setMobileOpen(false);
                  }}
                  selected={generatePath('/secure/cards') === pathname}
                >
                  <ListItemIcon>
                    <CardsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cards" />
                </ListItemButton>
              </ListItem>
            )}
            {privileges?.transactions && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate('/secure/transactions');
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'instant',
                    });
                    setMobileOpen(false);
                  }}
                  selected={generatePath('/secure/transactions') === pathname}
                >
                  <ListItemIcon>
                    <TransactionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Transactions" />
                </ListItemButton>
              </ListItem>
            )}
            {privileges?.dispute && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate('/secure/disputes');
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'instant',
                    });
                    setMobileOpen(false);
                  }}
                  selected={generatePath('/secure/disputes') === pathname}
                >
                  <ListItemIcon>
                    <DisputesIcon />
                  </ListItemIcon>
                  <ListItemText primary="Disputes" />
                </ListItemButton>
              </ListItem>
            )}
            {privileges?.balance && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate('/secure/funds-transfers');
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'instant',
                    });
                    setMobileOpen(false);
                  }}
                  selected={
                    generatePath('/secure/funds-transfers') === pathname
                  }
                >
                  <ListItemIcon>
                    <BalanceTransferIcon />
                  </ListItemIcon>
                  <ListItemText primary="Funds Transfers" />
                </ListItemButton>
              </ListItem>
            )}
            {privileges?.subuser_admin && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate('/secure/users');
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'instant',
                    });
                    setMobileOpen(false);
                  }}
                  selected={generatePath('/secure/users') === pathname}
                >
                  <ListItemIcon>
                    <UsersIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItemButton>
              </ListItem>
            )}
          </>
        )}
      </List>
      <List
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 8px 11px 0 0;
          width: 100%;
          margin-top: auto;
        `}
      >
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate('/secure/profile');
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
              });
              setMobileOpen(false);
            }}
            selected={generatePath('/secure/profile') === pathname}
            css={css`
              && {
                background-color: ${COLOR_PALETTE.darkerNavItem};
                border-radius: 12px;
                padding: 9px 12px;
                gap: 0px;
                .MuiTypography-root {
                  margin-left: 12px;
                  font-size: 14px;
                  padding-right: 4px;
                }
              }
              &.Mui-selected {
                background-color: ${COLOR_PALETTE.primary}!important;
                .MuiSvgIcon-root {
                  fill: none;
                  stroke: ${COLOR_PALETTE.textOnLight};
                }
              }
              .MuiSvgIcon-root {
                stroke: ${COLOR_PALETTE.textOnDark};
              }

              &&& svg {
                height: 36px;
                width: 36px;
              }
            `}
          >
            <ListItemIcon>
              <ListItemIcon>
                <UserProfileIcon />
              </ListItemIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                userDetails
                  ? `Hi, ${startCase(
                      userDetails.userDetails.first_name,
                    )} ${startCase(userDetails.userDetails.last_name)}`
                  : 'Profile'
              }
            />
            <ChevronRightRoundedIcon
              css={css`
                height: 16px !important;
                width: 16px !important;
                fill: #888888 !important;
                stroke: #888888 !important;
              `}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              handleLogout();
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
              });
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <SignOutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );

  const ToolBarSection = (props: { className?: string; sxProps?: SxProps }) => (
    <Toolbar
      sx={{
        p: { sm: '0 16px' },
        backgroundColor: COLOR_PALETTE.darkBackground,
        ...props.sxProps,
      }}
      className={props.className}
    >
      <IconButton
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: 'none' }, color: COLOR_PALETTE.textOnDark }}
      >
        <MenuIcon />
      </IconButton>
    </Toolbar>
  );
  const { shouldShowSideNav, shouldUsePageWrapper } = useAppRoutes();

  if (shouldUsePageWrapper) {
    return (
      <Box
        css={(theme) => css`
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          min-height: 100vh;
          background-color: ${theme.palette.background.default};
        `}
      >
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
          css={css`
            color: #000000;
            width: calc(100% - ${shouldShowSideNav ? 0 : drawerWidth}px);
            margin-left: ${shouldShowSideNav ? 0 : drawerWidth}px;
          `}
        >
          {shouldShowSideNav && <ToolBarSection />}
        </AppBar>
        {shouldShowSideNav && (
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
              }}
              css={css`
                & .MuiDrawer-paper {
                  box-sizing: 'border-box';
                  width: ${drawerWidth}px;
                }
              `}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
              }}
              css={css`
                & .MuiDrawer-paper {
                  box-sizing: border-box;
                  width: ${drawerWidth}px;
                }
              `}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        )}
        <PageMainSection>
          <ToolBarSection
            // Toolbar here is used as spacer
            sxProps={{
              display: { xs: 'block', sm: 'none' },
            }}
            css={css`
              opacity: 0;
            `}
          />
          <PageContents>{children}</PageContents>
          <PageFooter />
        </PageMainSection>
      </Box>
    );
  } else {
    return <>{children}</>;
  }
};
