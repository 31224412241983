import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAxios } from 'src/hooks/useAxios';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import type { FormFields } from './NewGroup';
import type { GroupRaw } from 'src/types/groups';

export const useCreateGroup = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();

  const createGroup = async ({ name, status }: FormFields) => {
    const { data } = await axios.post<GroupRaw>(
      SERVICE_BENJI_CARD_URLS.GROUP_CREATE,
      {
        name,
        status,
      },
      {
        headers: await generateSecureHeaders(),
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: FormFields) => createGroup(data),
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.groups._baseKey,
      ]);
    },
  });
};
