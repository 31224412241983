import { css } from '@emotion/react';
import { Box, Button, TextField } from '@mui/material';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { TrackType, useToast, useTrack } from 'src/context';
import { COLOR_PALETTE } from 'src/theme';

import { useUpdateGroupDetails } from './useUpdateGroupDetails';

import type { UnsavedChanges } from './';

interface GroupNameFormFields {
  name: string;
}

export const GroupNameForm = ({
  name,
  groupId,
  setUnsavedChanges,
  disabled,
}: {
  name?: string;
  groupId: string;
  setUnsavedChanges: (props: UnsavedChanges) => void;
  disabled?: boolean;
}) => {
  const { track } = useTrack();
  const { setToast } = useToast();
  const { mutateAsync: updateGroupDetails } = useUpdateGroupDetails();
  const useFormMethods = useForm<GroupNameFormFields>();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useFormMethods;

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const handleSetSuccessMessage = (message: string) => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };

  const nameFormValue = watch('name');
  useEffect(() => {
    setUnsavedChanges({
      name: {
        hasUnsavedChanges: nameFormValue !== name,
        label: 'Group Name',
      },
    });
  }, [nameFormValue, name, setUnsavedChanges]);

  const onSubmit = async ({ name }: GroupNameFormFields) => {
    track({
      label: 'Submit group name',
      type: TrackType.action,
      actionType: 'submit',
    });
    await updateGroupDetails(
      {
        groupId,
        name: name ?? '',
      },
      {
        onSuccess: () => {
          handleSetSuccessMessage('Saved');
          setUnsavedChanges({
            name: {
              hasUnsavedChanges: false,
              label: 'Group Name',
            },
          });
          setToast({
            message: 'Group Name Saved Successfully',
            severity: 'success',
          });
          track({
            label: 'Set group name',
            type: TrackType.effect,
            isSuccessful: true,
          });
        },
        onError: (error) => {
          reset(undefined, { keepValues: true });
          const errorMessage = get(error, 'response.data.message');
          setToast({
            message: errorMessage ?? 'Error Saving Group Name',
            severity: 'error',
          });
          track({
            label: 'Set group name',
            type: TrackType.effect,
            isSuccessful: false,
          });
        },
      },
    );
  };

  return (
    <FormProvider {...useFormMethods}>
      <Box
        onSubmit={handleSubmit(onSubmit, (errors) => {
          track({
            label: 'Submit group name',
            type: TrackType.action,
            actionType: 'submit',
            validationErrors: Object.entries(errors).map(([key, value]) => ({
              field: key,
              message: value.message,
            })),
          });
        })}
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0 8px;
          justify-content: flex-end;
          align-items: baseline;
          flex: 1 1 auto;
        `}
        component="form"
      >
        <Controller
          control={control}
          name="name"
          defaultValue={name ?? ''}
          render={({ field }) => {
            return (
              <TextField
                className="highlight-block"
                sx={{
                  input: {
                    '&::placeholder': {
                      opacity: 1,
                      color: COLOR_PALETTE.lightTextOnLight,
                    },
                  },
                }}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value || ''}
                error={!!errors.name}
                disabled={isSubmitting || disabled}
                type="text"
                variant="outlined"
                placeholder=""
                helperText={errors.name ? (errors.name.message as string) : ''}
                css={css`
                  flex: 1 1 200px;
                  fieldset {
                    border-radius: 8px;
                    border-color: ${COLOR_PALETTE.border};
                  }
                `}
              />
            );
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color={successMessage ? 'success' : 'primary'}
          disabled={disabled || isSubmitting || !!successMessage}
          css={css`
            flex: 0 0 80px;
            padding: 10px 16px;
          `}
        >
          {successMessage ?? 'Save'}
        </Button>
      </Box>
    </FormProvider>
  );
};
