import { SvgIcon } from '@mui/material';

import { COLOR_PALETTE } from 'src/theme';

export const CardIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="credit-card (2) 1">
          <path
            id="Vector"
            d="M79.6875 69.8125H64.0625C62.6199 69.8125 61.4375 68.6301 61.4375 67.1875C61.4375 65.7449 62.6199 64.5625 64.0625 64.5625H79.6875C81.1301 64.5625 82.3125 65.7449 82.3125 67.1875C82.3125 68.6301 81.1301 69.8125 79.6875 69.8125Z"
            fill={COLOR_PALETTE.textOnDark}
            stroke={COLOR_PALETTE.textOnDark}
          />
          <path
            id="Vector_2"
            d="M53.125 70.3125H50C48.2812 70.3125 46.875 68.9062 46.875 67.1875C46.875 65.4688 48.2812 64.0625 50 64.0625H53.125C54.8438 64.0625 56.25 65.4688 56.25 67.1875C56.25 68.9062 54.8438 70.3125 53.125 70.3125Z"
            fill={COLOR_PALETTE.textOnDark}
          />
          <path
            id="Vector_3"
            d="M93.75 40.125H6.25C4.80739 40.125 3.625 38.9426 3.625 37.5C3.625 36.0574 4.80739 34.875 6.25 34.875H93.75C95.1926 34.875 96.375 36.0574 96.375 37.5C96.375 38.9426 95.1926 40.125 93.75 40.125Z"
            fill={COLOR_PALETTE.textOnDark}
            stroke={COLOR_PALETTE.textOnDark}
          />
          <path
            id="Vector_4"
            d="M81.25 84.375H18.75C10.1406 84.375 3.125 77.3594 3.125 68.75V31.25C3.125 22.6406 10.1406 15.625 18.75 15.625H81.25C89.8594 15.625 96.875 22.6406 96.875 31.25V68.75C96.875 77.3594 89.8594 84.375 81.25 84.375ZM18.75 21.875C13.5781 21.875 9.375 26.0781 9.375 31.25V68.75C9.375 73.9219 13.5781 78.125 18.75 78.125H81.25C86.4219 78.125 90.625 73.9219 90.625 68.75V31.25C90.625 26.0781 86.4219 21.875 81.25 21.875H18.75Z"
            fill={COLOR_PALETTE.textOnDark}
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
