import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';

import { COLOR_PALETTE } from 'src/theme';

import type { ReactNode } from 'react';

export const CircleChart = ({
  value,
  max = 100,
  size = 170,
  fontSize = 22,
  strokeWidth = 20,
  activeWidthModifier = 1.5,
  backgroundColor = COLOR_PALETTE.chartBackground,
  fillColor = COLOR_PALETTE.chartFilled,
  label,
  className,
}: {
  value: number;
  max: number;
  size?: number;
  fontSize?: number;
  strokeWidth?: number;
  activeWidthModifier?: number;
  backgroundColor?: string;
  fillColor?: string;
  label?: ReactNode;
  className?: string;
}) => {
  const valueAsPositive = Math.abs(value);
  const isOverMax = valueAsPositive > max;
  const isZero = valueAsPositive === 0 && max === 0;
  const percentage = isOverMax
    ? 100
    : isZero
    ? 0
    : (valueAsPositive / max) * 100;

  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;
  const rotationOffset = 10;

  // Calculate the positions for the small rectangles
  const startAngle = -Math.PI / 2 + (rotationOffset * Math.PI) / 180;
  const endAngle = (2 * Math.PI * percentage) / 100 + startAngle;

  const startX = size / 2 + radius * Math.cos(startAngle);
  const startY = size / 2 + radius * Math.sin(startAngle);

  const endX = size / 2 + radius * Math.cos(endAngle);
  const endY = size / 2 + radius * Math.sin(endAngle);

  const paddingForExpandedStroke =
    (strokeWidth * activeWidthModifier - strokeWidth) / 2;

  // Rectangle dimensions and corner radius
  const rectWidth = strokeWidth * activeWidthModifier;
  const rectHeight = 5;
  const rectRx = 4;
  const rectRy = 4;

  return (
    <Box
      css={css`
        padding: ${paddingForExpandedStroke}px;
        width: ${size + paddingForExpandedStroke * 2}px;
        height: ${size + paddingForExpandedStroke * 2}px;
        position: relative;
      `}
      className={className}
    >
      <Box
        component="svg"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        css={css`
          display: block;
          margin: auto;
          overflow: visible;
        `}
      >
        <Box
          component="circle"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke={backgroundColor}
          strokeWidth={strokeWidth}
          css={css`
            transform-origin: 50% 50%;
          `}
        />
        <Box
          component="circle"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke={fillColor}
          strokeWidth={strokeWidth * activeWidthModifier}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="butt"
          css={css`
            transform: rotate(${-90 + rotationOffset}deg);
            transform-origin: 50% 50%;
          `}
        />
        {/* Rectangle at the start of the stroke */}
        {!!percentage && (
          <Box
            component="rect"
            x={startX - rectWidth / 2}
            y={startY - rectHeight / 2}
            width={rectWidth}
            height={rectHeight}
            rx={rectRx}
            ry={rectRy}
            fill={fillColor}
            css={css`
              transform-origin: ${startX}px ${startY}px;
            `}
            transform={`rotate(${(startAngle * 180) / Math.PI})`}
          />
        )}
        {/* Rectangle at the end of the stroke */}
        {!!percentage && (
          <Box
            component="rect"
            x={endX - rectWidth / 2}
            y={endY - rectHeight / 2}
            width={rectWidth}
            height={rectHeight}
            rx={rectRx}
            ry={rectRy}
            fill={fillColor}
            css={css`
              transform-origin: ${endX}px ${endY}px;
              transform: rotate(${(endAngle * 180) / Math.PI}deg);
            `}
          />
        )}
      </Box>
      <Box
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        {label ? (
          label
        ) : (
          <Typography
            css={css`
              font-size: ${fontSize}px;
              font-weight: 400;
              color: ${COLOR_PALETTE.textOnLight};
            `}
          >
            {percentage === 0
              ? 0
              : percentage < 1
              ? '1'
              : isOverMax
              ? '100'
              : Math.floor(percentage)}
            %
          </Typography>
        )}
      </Box>
    </Box>
  );
};
