import { css } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { TrackType, useToast, useTrack } from 'src/context';

import { CurrencyTextField } from './CurrencyTextField';
import { useUpdateCardSpendControlAmount } from './useUpdateCardSpendControlAmount';

import type { UnsavedChanges } from './';

interface CardSpendControlFormFields {
  cardSpendControlAmountDollars: number | null;
}

export const CardSpendControlForm = ({
  cardSpendControlAmountCents,
  cardId,
  setUnsavedChanges,
  currentSpendCents,
}: {
  cardSpendControlAmountCents?: number | null;
  cardId: string;
  setUnsavedChanges: (props: UnsavedChanges) => void;
  currentSpendCents: number;
}) => {
  const { track } = useTrack();
  const { setToast } = useToast();
  const { mutateAsync: updateCardSpendControl } =
    useUpdateCardSpendControlAmount();
  const useFormMethods = useForm<CardSpendControlFormFields>();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    watch,
  } = useFormMethods;

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const handleSetSuccessMessage = (message: string) => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };

  const cardSpendControlAmountDollarsFormValue = watch(
    'cardSpendControlAmountDollars',
  );
  useEffect(() => {
    setUnsavedChanges({
      cardSpendControlAmountDollars: {
        hasUnsavedChanges:
          (cardSpendControlAmountDollarsFormValue ?? 0) !==
          (cardSpendControlAmountCents ?? 0) / 100,
        label: 'Card All-Time Spend Limit',
      },
    });
  }, [
    cardSpendControlAmountCents,
    cardSpendControlAmountDollarsFormValue,
    setUnsavedChanges,
  ]);

  const onSubmit = async ({
    cardSpendControlAmountDollars,
  }: CardSpendControlFormFields) => {
    track({
      label: 'Submit card spend limit',
      type: TrackType.action,
      actionType: 'submit',
      newSpendLimitCents: cardSpendControlAmountDollars
        ? cardSpendControlAmountDollars * 100
        : null,
    });
    await updateCardSpendControl(
      {
        cardId,
        cardSpendControlAmountDollars,
      },
      {
        onSuccess: () => {
          handleSetSuccessMessage('Saved');
          setUnsavedChanges({
            cardSpendControlAmountDollars: {
              hasUnsavedChanges: false,
              label: 'Card All-Time Spend Limit',
            },
          });
          setToast({
            message: cardSpendControlAmountDollars
              ? 'Card Limit Saved Successfully'
              : 'Card Limit Cleared Successfully',
            severity: 'success',
          });
          track({
            label: 'Set card spend limit',
            type: TrackType.effect,
            isSuccessful: true,
            newSpendLimitCents: cardSpendControlAmountDollars
              ? cardSpendControlAmountDollars * 100
              : null,
          });
        },
        onError: () => {
          setToast({
            message: cardSpendControlAmountDollars
              ? 'Error Saving Card Limit'
              : 'Error Clearing Card Limit',
            severity: 'error',
          });
          track({
            label: 'Set card spend limit',
            type: TrackType.effect,
            isSuccessful: false,
            newSpendLimitCents: cardSpendControlAmountDollars
              ? cardSpendControlAmountDollars * 100
              : null,
          });
        },
      },
    );
  };

  return (
    <FormProvider {...useFormMethods}>
      <Box
        onSubmit={handleSubmit(onSubmit, (errors) => {
          const cardSpendControlAmountDollars = getValues(
            'cardSpendControlAmountDollars',
          );
          track({
            label: 'Submit card spend limit',
            type: TrackType.action,
            actionType: 'submit',
            newSpendLimitCents: cardSpendControlAmountDollars
              ? cardSpendControlAmountDollars * 100
              : null,
            validationErrors: Object.entries(errors).map(([key, value]) => ({
              field: key,
              message: value.message,
            })),
          });
        })}
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0 8px;
          justify-content: flex-end;
          align-items: baseline;
        `}
        component="form"
      >
        <Typography
          css={css`
            flex: 1 1 100%;
            font-size: 12px;
            font-weight: 500;
            padding-bottom: 4px;
          `}
        >
          Card All-Time Spend Limit
        </Typography>
        <Controller
          control={control}
          name="cardSpendControlAmountDollars"
          defaultValue={
            cardSpendControlAmountCents
              ? cardSpendControlAmountCents / 100
              : null
          }
          rules={{
            min: {
              value: Math.abs(currentSpendCents) / 100,
              message:
                Math.abs(currentSpendCents) === 0
                  ? 'Must be greater than $0'
                  : 'Must be greater than card Total Spent',
            },
          }}
          render={({ field }) => {
            return (
              <NumericFormat
                name={field.name}
                onBlur={field.onBlur}
                value={field.value || ''}
                error={!!errors.cardSpendControlAmountDollars}
                customInput={CurrencyTextField}
                type="text"
                allowNegative={false}
                thousandSeparator
                fixedDecimalScale
                decimalScale={2}
                variant="outlined"
                placeholder="None"
                helperText={
                  errors.cardSpendControlAmountDollars
                    ? (errors.cardSpendControlAmountDollars.message as string)
                    : ''
                }
                onValueChange={(values) => {
                  setValue(field.name, values.floatValue ?? null);
                }}
                css={css`
                  flex: 1 1 200px;
                `}
              />
            );
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color={successMessage ? 'success' : 'primary'}
          disabled={isSubmitting || !!successMessage}
          css={css`
            flex: 0 0 80px;
            padding: 10px 16px;
          `}
        >
          {successMessage ?? 'Save'}
        </Button>
      </Box>
    </FormProvider>
  );
};
