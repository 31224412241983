import { css } from '@emotion/react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import { Outlet } from 'react-router-dom';

import { AsynchronouslyLoadedSection } from './AsynchronouslyLoadedSection';

export const PermissionsWrapper = ({
  hasAccess,
  message = 'Sorry, but you do not have access to this page.',
  cta,
  isLoading,
  children,
}: {
  hasAccess: boolean;
  message?: string;
  cta?: {
    onClick?: () => void;
    label: string;
  };
  isLoading?: boolean;
  children?: React.ReactNode;
}) => {
  if (hasAccess) {
    return children ? <>{children}</> : <Outlet />;
  } else {
    return (
      <AsynchronouslyLoadedSection isLoading={!!isLoading}>
        <Card
          css={css`
            margin: 0 auto;
          `}
        >
          <CardContent>
            <Typography color="text.secondary" gutterBottom>
              Invalid Permissions
            </Typography>
            <Divider />
            <Typography
              variant="body1"
              css={css`
                margin-top: 16px;
              `}
            >
              {message}
            </Typography>
          </CardContent>
          {cta && (
            <CardActions>
              <Button
                onClick={cta?.onClick}
                css={css`
                  margin-left: auto;
                `}
              >
                {cta?.label}
              </Button>
            </CardActions>
          )}
        </Card>
      </AsynchronouslyLoadedSection>
    );
  }
};
