import { lowerCase } from 'lodash';

import { formatDateToMST } from 'src/utils';

import type { DisputeCleaned, DisputeRaw } from 'src/types/dispute';

export const cleanDisputes = (rawDisputes: DisputeRaw[]) =>
  rawDisputes.reduce((acc, dispute) => {
    const prevCard = acc[acc.length - 1];
    const prettyCreatedAtDate = formatDateToMST(new Date(dispute.created));
    const status = lowerCase(dispute.status);
    return [
      ...acc,
      {
        id: dispute.id,
        stripeId: dispute.stripe_dispute_id,
        createdAt: dispute.created,
        prettyCreatedAtDate,
        isCreatedAtDateSameAsPrev:
          prevCard?.prettyCreatedAtDate === prettyCreatedAtDate,
        amountCents: dispute.amount,
        merchantName: dispute.transaction.merchant,
        status,
        reason: dispute.evidence.reason,
        card: {
          stripeId: dispute.stripe_card_id,
          lastFour: dispute.card_last4,
          cardholderName: dispute.cardholder_name,
        },
        paymentMethod: `****${dispute.card_last4} ${dispute.cardholder_name}`,
        group: dispute.group,
      } as DisputeCleaned,
    ];
  }, [] as DisputeCleaned[]);
