import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

import { FormFieldSwitch } from 'src/components/forms/FormFieldSwitch';
import { TrackType, useToast, useTrack } from 'src/context';
import { COLOR_PALETTE } from 'src/theme';

import { useUpdateCardStatus } from './useUpdateCardStatus';

interface CardIsActiveFormFields {
  isCardActive: boolean;
}

export const CardIsActiveForm = ({
  isCardActive,
  cardId,
  className,
  disabled,
}: {
  isCardActive?: boolean;
  cardId: string;
  className?: string;
  disabled?: boolean;
}) => {
  const { track } = useTrack();
  const { setToast } = useToast();
  const { mutateAsync: updateCardStatus, isLoading } = useUpdateCardStatus();
  const [isActive, setIsActive] = useState(isCardActive);

  useEffect(() => {
    setIsActive(isCardActive);
  }, [isCardActive]);

  const onSubmit = async ({ isCardActive }: CardIsActiveFormFields) => {
    await updateCardStatus(
      {
        cardId,
        isCardActive,
      },
      {
        onSuccess: () => {
          setToast({
            message: `Card Successfully ${
              isCardActive ? 'Activated' : 'Deactivated'
            }`,
            severity: 'success',
          });
          track({
            label: 'Set card isActive',
            type: TrackType.effect,
            isSuccessful: true,
            isActive: isCardActive,
          });
        },
        onError: () => {
          setIsActive(!isCardActive);
          setToast({
            message: `Error ${
              isCardActive ? 'Activating' : 'Deactivating'
            } Card`,
            severity: 'error',
          });
          track({
            label: 'Set card isActive',
            type: TrackType.effect,
            isSuccessful: false,
            isActive: isCardActive,
          });
        },
      },
    );
  };

  return (
    <FormFieldSwitch<{
      cardIsActive: boolean;
    }>
      name="cardIsActive"
      label={isActive ? 'Active' : 'Inactive'}
      className={className}
      disabled={isLoading || disabled}
      checked={!!isActive}
      defaultValue={!!isActive}
      onChange={(checked) => {
        setIsActive(checked);
        onSubmit({ isCardActive: checked });
        track({
          label: 'Toggle card isActive',
          type: TrackType.action,
          actionType: 'toggle',
          isActive: checked,
        });
      }}
      css={css`
        .MuiFormControlLabel-label {
          color: ${COLOR_PALETTE.inputLabel}!important;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 300;
        }
      `}
    />
  );
};
