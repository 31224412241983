import { SvgIcon } from '@mui/material';

export const EmailInputIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_1135_10068)">
          <path
            d="M2.5 5.83268C2.5 5.39065 2.67559 4.96673 2.98816 4.65417C3.30072 4.34161 3.72464 4.16602 4.16667 4.16602H15.8333C16.2754 4.16602 16.6993 4.34161 17.0118 4.65417C17.3244 4.96673 17.5 5.39065 17.5 5.83268V14.166C17.5 14.608 17.3244 15.032 17.0118 15.3445C16.6993 15.6571 16.2754 15.8327 15.8333 15.8327H4.16667C3.72464 15.8327 3.30072 15.6571 2.98816 15.3445C2.67559 15.032 2.5 14.608 2.5 14.166V5.83268Z"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 5.83398L10 10.834L17.5 5.83398"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1135_10068">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
