import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { SERVICE_BENJI_CARD_URLS } from 'src/utils';

interface NeedsToAgree {
  needsToAgree: true;
}
interface HasAgreed {
  cardholderName: string;
  ephemeralKeySecret: string;
}

const getCardEphemeralKey = async ({
  token,
  cardId,
  nonce,
  hasAgreedToTerms,
}: {
  token: string;
  cardId: string;
  nonce: string;
  hasAgreedToTerms?: boolean;
}) => {
  const { data } = await axios.post<NeedsToAgree | HasAgreed>(
    SERVICE_BENJI_CARD_URLS.CARD_GET_TOKEN_EPHEMERAL_KEY,
    { token, cardId, hasAgreedToTerms, nonce },
  );

  return data;
};

export const useGetCardEphemeralKey = ({
  token,
  cardId,
  nonce,
  hasAgreedToTerms,
}: {
  token?: string;
  cardId?: string;
  nonce?: string;
  hasAgreedToTerms?: boolean;
}) => {
  return useQuery({
    queryKey: ['iframeCard', { token, cardId, nonce, hasAgreedToTerms }],
    queryFn: () =>
      getCardEphemeralKey({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- will always be defined due to enabled check
        token: token!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- will always be defined due to enabled check
        cardId: cardId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- will always be defined due to enabled check
        nonce: nonce!,
        hasAgreedToTerms,
      }),
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!token && !!cardId && !!nonce,
  });
};
