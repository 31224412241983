import { css } from '@emotion/react';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

import { COLOR_PALETTE } from 'src/theme';

export const TableMenu = ({
  menuItems,
  disabled = false,
  onClick,
}: {
  disabled?: boolean;
  onClick?: () => void;
  menuItems: { label: string; onClick: () => void; disabled?: boolean }[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        onClick={(e) => {
          onClick?.();
          setAnchorEl(e.currentTarget);
        }}
        disabled={disabled}
        css={css`
          padding: 4px;
          height: 46px;
          width: 46px;
          border: 1px solid ${COLOR_PALETTE.border};
        `}
        id="table-menu-button"
        aria-controls={isOpen ? 'table-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
      >
        <MoreVertIcon
          css={css`
            color: ${COLOR_PALETTE.textOnLight};
          `}
        />
      </IconButton>
      <Menu
        id="table-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'table-menu-button',
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            disabled={item.disabled}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
