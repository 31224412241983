import { css } from '@emotion/react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { COLOR_PALETTE } from 'src/theme';

import type {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

export function FormFieldRadioCard<FormFields extends FieldValues>({
  name,
  label,
  control,
  defaultValue,
  options,
  className,
  rules,
  disabled,
}: {
  name: FieldPath<FormFields>;
  label: React.ReactNode;
  control: Control<FormFields>;
  defaultValue: FieldPathValue<FormFields, FieldPath<FormFields>>;
  options: { label: React.ReactNode; value: string; disabled?: boolean }[];
  className?: string;
  rules?: Omit<
    RegisterOptions<FormFields>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  disabled?: boolean;
  type?: string;
  onBlur?: () => void;
}) {
  const { formState } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <FormControl
          disabled={formState.isSubmitting || disabled}
          className={className}
          error={!!get(formState.errors, field.name)}
          css={css`
            flex: 1 1 100%;
          `}
        >
          <FormLabel
            css={css`
              align-items: flex-start;
              .MuiCheckbox-root {
                padding: 2px 8px;
              }
            `}
          >
            {label}
          </FormLabel>
          <RadioGroup
            id={field.name}
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            css={css`
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              gap: 20px;
              padding-top: 20px;
            `}
          >
            {options.map(({ label, value, disabled }) => {
              const isSelected = field.value === value;
              return (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={label}
                  disabled={disabled}
                  labelPlacement="start"
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    gap: 8px;
                    margin: 0;
                    flex: 1 1 200px;
                    padding: 12px 12px;
                    border-radius: 8px;
                    border: 1px solid
                      ${isSelected
                        ? COLOR_PALETTE.primary
                        : COLOR_PALETTE.inputBorder};
                    background-color: ${isSelected
                      ? COLOR_PALETTE.primary
                      : COLOR_PALETTE.white};
                    box-shadow: ${isSelected
                      ? '0px 6px 10px 4px rgba(242, 190, 87, 0.26)'
                      : 'initial'};
                    .MuiRadio-root {
                      padding: 0;
                      color: ${COLOR_PALETTE.inputBorder};
                    }
                  `}
                />
              );
            })}
          </RadioGroup>
          {!!get(formState.errors, field.name) && (
            <FormHelperText error={!!get(formState.errors, field.name)}>
              {get(formState.errors, field.name)
                ? (get(formState.errors, field.name)?.message as string)
                : ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
