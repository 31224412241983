import { css } from '@emotion/react';
import { ArrowDropDownOutlined as ArrowDropDownOutlinedIcon } from '@mui/icons-material';
import { Box, FormLabel, InputAdornment, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import type { DateView } from '@mui/x-date-pickers';
// eslint-disable-next-line import/order -- TODO: fix lint bug
import type { HTMLInputTypeAttribute } from 'react';
import type {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

interface FormFieldInputPropsBase<FormFields extends FieldValues> {
  name: FieldPath<FormFields>;
  label?: string;
  placeholder?: string;
  control: Control<FormFields>;
  defaultValue: FieldPathValue<FormFields, FieldPath<FormFields>>;
  className?: string;
  icon?: React.ReactNode;
  rules?: Omit<
    RegisterOptions<FormFields>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  disabled?: boolean;
  type?: Omit<HTMLInputTypeAttribute, 'date'>;
  onBlur?: () => void;
  helperText?: React.ReactNode;
}

interface FormFieldDateInputProps<FormFields extends FieldValues>
  extends Omit<FormFieldInputPropsBase<FormFields>, 'type'> {
  type: 'date';
  views?: DateView[];
  openTo?: DateView;
}

export function FormFieldInput<FormFields extends FieldValues>({
  name,
  label,
  placeholder,
  control,
  defaultValue,
  className,
  rules,
  disabled,
  type = 'text',
  onBlur,
  helperText,
  icon,
  ...rest
}: FormFieldInputPropsBase<FormFields> | FormFieldDateInputProps<FormFields>) {
  const { formState } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{
        ...(type === 'email' && {
          pattern: {
            value: emailPattern,
            message: 'Invalid email address',
          },
        }),
        ...rules,
      }}
      render={({ field }) => (
        <Box
          css={css`
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
          `}
          className={className}
        >
          <FormLabel
            css={css`
              font-size: 12px;
              font-weight: 500;
              width: 100%;
            `}
          >
            {label}
          </FormLabel>
          {type === 'date' ? (
            <DatePicker
              disabled={formState.isSubmitting || disabled}
              className="highlight-block"
              value={field.value || null}
              name={field.name}
              onChange={(newValue) => field.onChange(newValue)}
              views={'views' in rest ? rest.views : undefined}
              openTo={'openTo' in rest ? rest.openTo : undefined}
              css={css`
                flex: 1 1 200px;
                input {
                  color: ${field.value || placeholder
                    ? 'inherit'
                    : 'transparent'};
                }
                .Mui-focused input {
                  color: inherit;
                }
                .MuiInputAdornment-root .MuiButtonBase-root {
                  padding: 0;
                  margin-right: -8px;
                }
              `}
              onAccept={() => {
                field.onBlur();
                onBlur?.();
              }}
              slots={{
                openPickerIcon: ArrowDropDownOutlinedIcon,
              }}
              slotProps={{
                textField: {
                  onBlur: () => {
                    field.onBlur();
                    onBlur?.();
                  },
                  placeholder: placeholder,
                  error: !!get(formState.errors, field.name),
                  helperText: get(formState.errors, field.name)
                    ? (get(formState.errors, field.name)?.message as string)
                    : helperText,
                  InputProps: {
                    startAdornment: icon ? (
                      <InputAdornment
                        position="start"
                        css={css`
                          svg {
                            height: 18px;
                            width: 18px;
                          }
                        `}
                      >
                        {icon}
                      </InputAdornment>
                    ) : undefined,
                  },
                },
              }}
            />
          ) : (
            <TextField
              disabled={formState.isSubmitting || disabled}
              className="highlight-block"
              name={field.name}
              placeholder={placeholder}
              onChange={field.onChange}
              onBlur={() => {
                field.onBlur();
                onBlur?.();
              }}
              value={field.value || ''}
              error={!!get(formState.errors, field.name)}
              type={type as HTMLInputTypeAttribute}
              variant="outlined"
              helperText={
                get(formState.errors, field.name)
                  ? (get(formState.errors, field.name)?.message as string)
                  : helperText
              }
              InputProps={
                icon
                  ? {
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          css={css`
                            svg {
                              height: 18px;
                              width: 18px;
                            }
                          `}
                        >
                          {icon}
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
              css={css`
                flex: 1 1 200px;
                .Mui-focused input {
                  color: inherit;
                }
              `}
              {...rest}
            />
          )}
        </Box>
      )}
    />
  );
}
