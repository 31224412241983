import { useUser } from '@clerk/clerk-react';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';

import {
  useGetUserDetails,
  useGetUserPrivileges,
  usePrevious,
} from 'src/hooks';
import { isProd } from 'src/utils';

export const Mixpanel = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- This variable will always be defined.
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN!, {
    debug: !isProd,
    persistence: 'localStorage',
  });

  const { isSignedIn } = useUser();
  const { privileges, isSuccess: hasLoadedPrivileges } = useGetUserPrivileges();
  const { data: userDetails, isSuccess: hasLoadedUserDetails } =
    useGetUserDetails();

  useEffect(() => {
    if (isSignedIn && userDetails?.userDetails.id) {
      if (!isProd) {
        console.log('Mixpanel identify', userDetails.userDetails.id);
      }
      mixpanel.identify(userDetails.userDetails.id);
    }
  }, [isSignedIn, userDetails?.userDetails.id]);
  useEffect(() => {
    if (!isSignedIn) {
      if (!isProd) {
        console.log('Mixpanel reset');
      }
      mixpanel.reset();
    }
  }, [isSignedIn]);

  const previousUserId = usePrevious(userDetails?.userDetails.id);
  const [hasSetUserData, setHasSetUserData] = useState(false);
  useEffect(() => {
    if (
      isSignedIn &&
      userDetails &&
      hasLoadedPrivileges &&
      hasLoadedUserDetails &&
      (previousUserId !== userDetails.userDetails.id || !hasSetUserData)
    ) {
      const name = `${userDetails.userDetails.first_name} ${userDetails.userDetails.last_name}`;
      if (!isProd) {
        console.log('Mixpanel people.set', {
          // Built in properties
          $first_name: userDetails.userDetails.first_name,
          $last_name: userDetails.userDetails.last_name,
          $name: name,
          $email: userDetails.userDetails.email,
          // Custom properties
          userId: userDetails.userDetails.id,
          companyName: userDetails.accountDetails.companyName,
          privileges,
        });
      }
      mixpanel.people.set({
        // Built in properties
        $first_name: userDetails.userDetails.first_name,
        $last_name: userDetails.userDetails.last_name,
        $name: name,
        $email: userDetails.userDetails.email,
        // Custom properties
        userId: userDetails.userDetails.id,
        companyName: userDetails.accountDetails.companyName,
        privileges,
      });
      setHasSetUserData(true);
    }
  }, [
    hasLoadedPrivileges,
    hasLoadedUserDetails,
    hasSetUserData,
    isSignedIn,
    previousUserId,
    privileges,
    userDetails,
  ]);

  return null;
};
