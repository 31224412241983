import { css } from '@emotion/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
  Input,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { debounce, omit } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import {
  AsynchronouslyLoadedSection,
  FancyCurrencyDisplay,
  SlideLeftTransition,
} from 'src/components';
import { CARDHOLDER_DETAILS_MODAL_ID } from 'src/components/modals/CardholderDetailsModal';
import { TrackType, useTrack } from 'src/context';
import { useModal, useToast } from 'src/context';
import {
  useAppRoutes,
  useGetAuthorizationOrTransaction,
  useGetPaginatedData,
} from 'src/hooks';
import { useAxios } from 'src/hooks/useAxios';
import { cleanTransactions } from 'src/pages/Transactions/utils';
import { Privilege } from 'src/types';
import {
  SERVICE_BENJI_CARD_URLS,
  formatDateToMST,
  queryKeys,
  searchParamsToObject,
} from 'src/utils';

import { CARD_DETAILS_MODAL_ID } from './CardDetailsModal';
import { DISPUTE_DETAILS_MODAL_ID } from './DisputeDetailsModal';
import { TRANSACTION_DETAILS_MODAL_ID } from './TransactionDetailsModal';

import { drawerWidth } from '../PageWrapper';

import type { RoutePath } from 'src/AppRoutes';
import type { TransactionCleaned } from 'src/types/authorizations-transactions';

const CREATE_DISPUTE_MODAL_ID = 'create-dispute-modal';
export const useCreateDisputeModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>>,
) => {
  const { track } = useTrack();
  const { navigate, pathname } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);

  const modal = useModal({
    modalId: CREATE_DISPUTE_MODAL_ID,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      navigate(pathname as RoutePath, {
        queryParams: omit(
          currentSearchParams,
          'selectedCreateDisputeTransactionId',
        ),
        replace: true,
        preventScrollReset: true,
      });
    },
    onOpen: () => {
      track({
        label: 'Clicked open modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Create Dispute',
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
  return {
    ...modal,
    openModal: (id: string) => {
      navigate(pathname as RoutePath, {
        queryParams: {
          ...currentSearchParams,
          selectedCreateDisputeTransactionId: id,
        },
        replace: true,
        preventScrollReset: true,
      });
      modal.openModal();
    },
  };
};
export const CreateDisputeModal = () => {
  const { axios, generateSecureHeaders } = useAxios();
  const { track, incrementMixpanelProfileData } = useTrack();
  const { isOpen, openModal, closeModal } = useCreateDisputeModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Create Dispute',
      });
    },
  });
  const { setToast } = useToast();
  const [searchParams] = useSearchParams();
  const selectedCreateDisputeTransactionId = searchParams.get(
    'selectedCreateDisputeTransactionId',
  );
  useEffect(() => {
    if (!isOpen && selectedCreateDisputeTransactionId) {
      openModal(selectedCreateDisputeTransactionId);
    }
  }, [isOpen, openModal, selectedCreateDisputeTransactionId]);
  useEffect(() => {
    if (isOpen && !selectedCreateDisputeTransactionId) {
      closeModal();
    }
  }, [closeModal, isOpen, selectedCreateDisputeTransactionId]);

  const [transactionSearchInputValue, settransactionSearchInputValue] =
    useState('');
  const [transactionSearchValue, settransactionSearchValue] = useState('');
  const debouncer = useRef(
    debounce((value) => {
      settransactionSearchValue(value);
    }, 1000),
  );
  const handleSettransactionSearchValue = useCallback(
    (value: string) => debouncer.current(value),
    [],
  );
  const { data: transactions, isLoading: isLoadingTransactions } =
    useGetPaginatedData({
      endpointUrl: SERVICE_BENJI_CARD_URLS.TRANSACTIONS_GET_COMPLETED_ALL,
      queryKeyBase: [...queryKeys.transactions._searchBaseKey(), 'original'],
      filters: {
        search: transactionSearchValue,
      },
      dataFormatter: cleanTransactions,
      requiredPrivileges: [Privilege.transactions],
      autoFetchNextPage: false,
      enabled: !!transactionSearchValue,
    });

  const { data: selectedTransaction, isLoading: isLoadingDispute } =
    useGetAuthorizationOrTransaction({
      id: selectedCreateDisputeTransactionId ?? '',
      type: 'transaction',
    });

  const [disputeReason, setDisputeReason] = useState<string>();
  const [explanation, setExplanation] = useState<string>();
  const [productType, setProductType] = useState<string>();
  const [productDescription, setProductDescription] = useState<string>();
  const [expectedDate, setExpectedDate] = useState<string>();
  const [receivedDate, setReceivedDate] = useState<string>();
  const [returnStatus, setReturnStatus] = useState<string>();
  const [returnDescription, setReturnDescription] = useState<string>();
  const [returnDate, setReturnDate] = useState<string>();
  const [cancelDate, setCancelDate] = useState<string>();
  const [cancelReason, setCancelReason] = useState<string>();
  const [cancellationPolicyProvided, setCancellationPolicyProvided] = useState<
    // TODO: fix type when I redo the form.
    boolean | string
  >(false);
  const handleCloseModal = () => {
    setDisputeReason('');
    setExplanation('');
    setProductType('');
    setProductDescription('');
    setExpectedDate('');
    setReceivedDate('');
    setReturnStatus('');
    setReturnDescription('');
    setCancelDate('');
    setCancelReason('');
    setCancellationPolicyProvided(false);
    closeModal();
  };

  const queryClient = useQueryClient();
  const handleSubmitDispute = async () => {
    track({
      label: 'Submit dispute',
      type: TrackType.action,
      actionType: 'submit',
      disputeReason,
      transactionId: selectedTransaction?.id,
      transactionStripeId: selectedTransaction?.stripeId,
      disputeAmountCents: selectedTransaction?.amountCents,
      disputeCreatedAt: new Date().toISOString(),
    });
    let isDisputeReady = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- fix the mutativeness of this code
    const transaction: any = {
      ...selectedTransaction,
      id: selectedTransaction?.stripeId,
    };
    if (disputeReason == 'fraudulent') {
      if (explanation != undefined && explanation.length > 0) {
        transaction.disputeReason = disputeReason;
        transaction.explanation = explanation;
        isDisputeReady = true;
      } else {
        //fill out required field please
      }
    } else if (disputeReason == 'not_received') {
      if (
        productType != undefined &&
        expectedDate != undefined &&
        productDescription != undefined &&
        productDescription.length > 0
      ) {
        transaction.disputeReason = disputeReason;
        transaction.productType = productType;
        transaction.expectedDate = expectedDate;
        transaction.productDescription = productDescription;
        isDisputeReady = true;
      } else {
        //fill out required fields please
      }
    } else if (disputeReason == 'duplicate') {
      if (
        originalTransaction != undefined &&
        originalTransaction.authorizationDate <
          (selectedTransaction?.authorizationDate ?? 0)
      ) {
        transaction.disputeReason = disputeReason;
        transaction.originalTransaction = {
          ...originalTransaction,
          id: originalTransaction?.stripeId,
        };
        isDisputeReady = true;
      } else {
        console.log('please fill out the required fields');
      }
    } else if (disputeReason == 'merchandise_not_as_described') {
      if (
        receivedDate != undefined &&
        returnStatus != undefined &&
        returnDescription != undefined &&
        explanation != undefined &&
        returnDate != undefined
      ) {
        transaction.disputeReason = disputeReason;
        transaction.receivedDate = receivedDate;
        transaction.returnStatus = returnStatus;
        transaction.returnDescription = returnDescription;
        transaction.explanation = explanation;
        transaction.returnDate = returnDate;
        isDisputeReady = true;
      } else {
        console.log('please fill out the required fields');
      }
    } else if (disputeReason == 'service_not_as_described') {
      if (
        receivedDate != undefined &&
        cancelDate != undefined &&
        cancelReason != undefined &&
        explanation != undefined
      ) {
        transaction.disputeReason = disputeReason;
        transaction.receivedDate = receivedDate;
        transaction.cancelDate = cancelDate;
        transaction.cancelReason = cancelReason;
        transaction.explanation = explanation;
        isDisputeReady = true;
      } else {
        console.log('please fill out the required fields');
      }
    } else if (disputeReason == 'canceled') {
      if (
        productType != undefined &&
        expectedDate != undefined &&
        cancelDate != undefined &&
        productDescription != undefined &&
        productDescription.length > 0 &&
        explanation != undefined
      ) {
        transaction.disputeReason = disputeReason;
        transaction.productType = productType;
        transaction.productDescription = productDescription;
        transaction.expectedDate = expectedDate;
        transaction.cancelDate = cancelDate;
        transaction.cancellationPolicyProvided = cancellationPolicyProvided;
        transaction.explanation = explanation;
        isDisputeReady = true;
      } else {
        console.log('please fill out the required fields');
      }
    } else if (disputeReason == 'other') {
      if (
        productType != undefined &&
        productDescription != undefined &&
        (explanation?.length ?? 0) > 0 &&
        explanation != undefined &&
        explanation.length > 0
      ) {
        transaction.disputeReason = disputeReason;
        transaction.productType = productType;
        transaction.productDescription = productDescription;
        transaction.explanation = explanation;
        isDisputeReady = true;
      } else {
        console.log('please fill out the required fields');
      }
    }
    if (isDisputeReady == true) {
      await axios
        .post(
          `${SERVICE_BENJI_CARD_URLS.DISPUTES_CREATE}`,
          { transaction },
          {
            headers: await generateSecureHeaders(),
          },
        )
        .then(() => {
          const transactionsBaseQueryKey = queryKeys.transactions._baseKey;
          queryClient.invalidateQueries(transactionsBaseQueryKey);
          queryClient.invalidateQueries([
            ...queryKeys.paginatedData._baseKey,
            ...transactionsBaseQueryKey,
          ]);
          const disputesBaseQueryKey = queryKeys.disputes._baseKey;
          queryClient.invalidateQueries(disputesBaseQueryKey);
          queryClient.invalidateQueries([
            ...queryKeys.paginatedData._baseKey,
            ...disputesBaseQueryKey,
          ]);
          setToast({
            message: 'Dispute Created',
            severity: 'success',
          });
          track({
            label: 'Disputed transaction',
            type: TrackType.effect,
            isSuccessful: true,
            disputeReason,
            // TODO: add these once the BE returns them
            // transactionId,
            // disputeId,
            // disputeAmountCents,
            // disputeCreatedAt,
            // disputeStatus,
          });
          incrementMixpanelProfileData('createDisputeCount');
          handleCloseModal();
        })
        .catch(() => {
          track({
            label: 'Disputed transaction',
            type: TrackType.effect,
            isSuccessful: false,
            disputeReason,
            // TODO: add these once the BE returns them
            // transactionId,
            // disputeId,
            // disputeAmountCents,
            // disputeCreatedAt,
            // disputeStatus,
          });
          setToast({
            message: 'Error Creating Dispute',
            severity: 'error',
          });
        });
    }
  };

  const [originalTransaction, setOriginalTransaction] =
    useState<TransactionCleaned | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix
  const handleExplanation = (e: any) => {
    setExplanation(e.target.value);
  };

  const { getModalStatus, numberOfModalsOpen } = useModal({});
  // TODO:figure a better way to do this.
  // TODO: get real modal ids
  const modalsPotentiallyNestedUnder = [
    CARDHOLDER_DETAILS_MODAL_ID,
    CARD_DETAILS_MODAL_ID,
    TRANSACTION_DETAILS_MODAL_ID,
    DISPUTE_DETAILS_MODAL_ID,
  ];
  const nestedLevel = modalsPotentiallyNestedUnder
    .map((modalId) => getModalStatus(modalId))
    .filter(Boolean).length;

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      css={(theme) => css`
        ${theme.breakpoints.down('sm')} {
          left: 0 !important;
          top: 112px;
          .MuiModal-backdrop {
            left: 0 !important;
            top: 56px;
          }
          .MuiDialog-container {
            padding-left: 40px !important;
          }
        }
        left: ${drawerWidth}px;
        z-index: ${1300 - numberOfModalsOpen + nestedLevel};
        .MuiModal-backdrop {
          left: ${drawerWidth}px;
          ${nestedLevel > 0 ? 'background: none;' : ''}
        }
        .MuiDialog-container {
          justify-content: flex-end;
          padding-left: 20px;
        }
        .MuiDialog-paper {
          max-height: none;
          height: 100vh;
          padding: 0;
          margin: 0;
          max-width: ${1000 - nestedLevel * 20}px;
          width: 100%;
          border-radius: 0;
        }
      `}
      TransitionComponent={SlideLeftTransition}
    >
      <DialogTitle
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0 8px;
          justify-content: flex-end;
          align-items: baseline;
          /* TODO: get color from theme */
          border-bottom: 1px solid #e0e0e0;
          min-height: 72px;
        `}
      >
        <Typography
          variant="h5"
          css={css`
            flex: 1 1 200px;
          `}
        >
          Create New Dispute
        </Typography>
      </DialogTitle>
      <DialogContent>
        <AsynchronouslyLoadedSection isLoading={isLoadingDispute}>
          <FormControl fullWidth style={{ marginTop: 10 }}>
            <InputLabel id="DisputeReasonLabel">Reason: </InputLabel>
            <Select
              labelId="transactionDateLabel"
              id="transactionDateLabelSelect"
              value={disputeReason ?? ''}
              label="Reason"
              onChange={(e) => setDisputeReason(e.target.value)}
            >
              <MenuItem value={'fraudulent'}>Fraudulent</MenuItem>
              <MenuItem value={'not_received'}>Not Received</MenuItem>
              <MenuItem value={'duplicate'}>Duplicate</MenuItem>
              <MenuItem value={'merchandise_not_as_described'}>
                Merchandise Not As Described
              </MenuItem>
              <MenuItem value={'service_not_as_described'}>
                Service Not As Described
              </MenuItem>
              <MenuItem value={'canceled'}>Canceled</MenuItem>
              <MenuItem value={'other'}>Other</MenuItem>
            </Select>
          </FormControl>

          {disputeReason === 'fraudulent' ? (
            <FormControl>
              <TextField
                className="highlight-block"
                style={{ marginTop: '1vh' }}
                id="outlined-multiline-flexible"
                label="Explanation"
                multiline
                maxRows={4}
                onChange={(e) => handleExplanation(e)}
              />
            </FormControl>
          ) : (
            <></>
          )}
          {disputeReason === 'not_received' ? (
            <Col>
              <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel id="Product Type">Product Type: </InputLabel>
                <Select
                  labelId="transactionDateLabel"
                  id="transactionDateLabelSelect"
                  value={productType ?? ''}
                  label="Product Type"
                  onChange={(e) => setProductType(e.target.value)}
                >
                  <MenuItem value={'merchandise'}>Merchandise</MenuItem>
                  <MenuItem value={'service'}>Service</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <DialogContentText style={{ marginTop: '1vh' }}>
                  Expected Date:
                </DialogContentText>
                <Input
                  onChange={(e) => {
                    setExpectedDate(e.target.value);
                  }}
                  style={{ width: '20vw' }}
                  type={'date'}
                  className="highlight-block"
                ></Input>
              </FormControl>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  className="highlight-block"
                  style={{ marginTop: '1vh' }}
                  id="outlined-multiline-flexible"
                  label="Product Description"
                  multiline
                  maxRows={4}
                  onChange={(e) => setProductDescription(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <TextField
                  className="highlight-block"
                  style={{ marginTop: '1vh' }}
                  id="outlined-multiline-flexible"
                  label="Explanation"
                  multiline
                  maxRows={4}
                  onChange={(e) => setExplanation(e.target.value)}
                />
              </FormControl>
            </Col>
          ) : (
            <></>
          )}

          {disputeReason === 'duplicate' ? (
            <>
              <Autocomplete
                disablePortal
                options={transactions?.results ?? []}
                getOptionKey={({ id }) => id}
                getOptionLabel={() => transactionSearchInputValue}
                onChange={(_e, value) => {
                  setOriginalTransaction(value);
                }}
                onInputChange={(_e, value) => {
                  settransactionSearchInputValue(value);
                  handleSettransactionSearchValue(value);
                }}
                loading={!!transactionSearchValue && isLoadingTransactions}
                slotProps={{
                  paper: {
                    sx: {
                      maxHeight: '300px',
                      '& *': {
                        maxHeight: '300px',
                      },
                    },
                  },
                }}
                // overriding filter options to allow for filtering to happen on BE.
                filterOptions={(x) => x}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <Typography variant="body1">
                      {option.prettyCreatedAtDate},{' '}
                      <FancyCurrencyDisplay
                        amountCents={option.amountCents}
                        css={css`
                          display: inline-block;
                        `}
                      />
                      , {option.merchantName}, {option.paymentMethod}
                    </Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    className="highlight-block"
                    label="Original Transaction"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!!transactionSearchValue && isLoadingTransactions ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              {originalTransaction &&
              originalTransaction.authorizationDate <
                (selectedTransaction?.authorizationDate ?? 0) ? (
                <></>
              ) : (
                <p style={{ color: 'red' }}>
                  Original Transaction must be older than duplicate transaction.
                </p>
              )}
              {!!originalTransaction && (
                <Typography>
                  {originalTransaction.prettyCreatedAtDate},{' '}
                  <FancyCurrencyDisplay
                    amountCents={originalTransaction.amountCents}
                    css={css`
                      display: inline-block;
                    `}
                  />
                  , {originalTransaction.merchantName},{' '}
                  {originalTransaction.paymentMethod}
                </Typography>
              )}
            </>
          ) : (
            <></>
          )}
          {disputeReason === 'merchandise_not_as_described' ? (
            <>
              <FormControl fullWidth>
                <DialogContentText style={{ marginTop: '1vh' }}>
                  Recieved Date:
                </DialogContentText>
                <Input
                  onChange={(e) => {
                    setReceivedDate(e.target.value);
                  }}
                  style={{ width: '20vw' }}
                  type={'date'}
                  className="highlight-block"
                ></Input>
              </FormControl>
              <FormControl fullWidth style={{ marginTop: '1vh' }}>
                <InputLabel id="returnStatusLabel">Return Status: </InputLabel>
                <Select
                  labelId="returnStatusLabel"
                  id="returnStatus"
                  value={returnStatus ?? ''}
                  label="Product Type"
                  onChange={(e) => setReturnStatus(e.target.value)}
                >
                  <MenuItem value="successful">Accepted</MenuItem>
                  <MenuItem value="merchant_rejected">Rejected</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <DialogContentText style={{ marginTop: '1vh' }}>
                  Returned Date:
                </DialogContentText>
                <Input
                  onChange={(e) => {
                    setReturnDate(e.target.value);
                  }}
                  style={{ width: '20vw' }}
                  type={'date'}
                  className="highlight-block"
                ></Input>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  className="highlight-block"
                  style={{ marginTop: '1vh' }}
                  id="outlined-multiline-flexible"
                  label="Return Description"
                  multiline
                  maxRows={4}
                  onChange={(e) => setReturnDescription(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  className="highlight-block"
                  style={{ marginTop: '1vh' }}
                  id="outlined-multiline-flexible"
                  label="Explanation"
                  multiline
                  maxRows={4}
                  onChange={(e) => handleExplanation(e)}
                />
              </FormControl>
            </>
          ) : (
            <></>
          )}
          {disputeReason === 'service_not_as_described' ? (
            <>
              <FormControl fullWidth>
                <DialogContentText style={{ marginTop: '1vh' }}>
                  Recieved Date:
                </DialogContentText>
                <Input
                  onChange={(e) => {
                    setReceivedDate(e.target.value);
                  }}
                  style={{ width: '20vw' }}
                  type={'date'}
                  className="highlight-block"
                ></Input>
              </FormControl>
              <FormControl fullWidth>
                <DialogContentText style={{ marginTop: '1vh' }}>
                  Cancellation Date:
                </DialogContentText>
                <Input
                  onChange={(e) => {
                    setCancelDate(e.target.value);
                  }}
                  style={{ width: '20vw' }}
                  type={'date'}
                  className="highlight-block"
                ></Input>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  className="highlight-block"
                  style={{ marginTop: '1vh' }}
                  id="outlined-multiline-flexible"
                  label="Cancellation reason"
                  multiline
                  maxRows={4}
                  onChange={(e) => setCancelReason(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  className="highlight-block"
                  style={{ marginTop: '1vh' }}
                  id="outlined-multiline-flexible"
                  label="Explanation"
                  multiline
                  maxRows={4}
                  onChange={(e) => handleExplanation(e)}
                />
              </FormControl>
            </>
          ) : (
            <></>
          )}

          {disputeReason === 'canceled' ? (
            <>
              <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel id="Product Type">Product Type: </InputLabel>
                <Select
                  labelId="transactionDateLabel"
                  id="transactionDateLabelSelect"
                  value={productType ?? ''}
                  label="Product Type"
                  onChange={(e) => setProductType(e.target.value)}
                >
                  <MenuItem value={'merchandise'}>Merchandise</MenuItem>
                  <MenuItem value={'service'}>Service</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <DialogContentText style={{ marginTop: '1vh' }}>
                  Expected Date:
                </DialogContentText>
                <Input
                  onChange={(e) => {
                    setExpectedDate(e.target.value);
                  }}
                  style={{ width: '20vw' }}
                  type={'date'}
                  className="highlight-block"
                ></Input>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  className="highlight-block"
                  style={{ marginTop: '1vh' }}
                  id="outlined-multiline-flexible"
                  label="Product Description"
                  multiline
                  maxRows={4}
                  onChange={(e) => setProductDescription(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  className="highlight-block"
                  style={{ marginTop: '1vh' }}
                  id="outlined-multiline-flexible"
                  label="Explanation"
                  multiline
                  maxRows={4}
                  onChange={(e) => setExplanation(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth>
                <DialogContentText style={{ marginTop: '1vh' }}>
                  Canceled Date:
                </DialogContentText>
                <Input
                  onChange={(e) => {
                    setCancelDate(e.target.value);
                  }}
                  style={{ width: '20vw' }}
                  type={'date'}
                  className="highlight-block"
                ></Input>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      setCancellationPolicyProvided(e.target.checked);
                    }}
                  />
                }
                label={'Cancellation Policy Provided?'}
              />
            </>
          ) : (
            <></>
          )}
          {disputeReason === 'other' ? (
            <>
              <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel id="Product Type">Product Type: </InputLabel>
                <Select
                  labelId="transactionDateLabel"
                  id="transactionDateLabelSelect"
                  value={productType ?? ''}
                  label="Product Type"
                  onChange={(e) => setProductType(e.target.value)}
                >
                  <MenuItem value={'merchandise'}>Merchandise</MenuItem>
                  <MenuItem value={'service'}>Service</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  className="highlight-block"
                  style={{ marginTop: '1vh' }}
                  id="outlined-multiline-flexible"
                  label="Product Description"
                  multiline
                  maxRows={4}
                  onChange={(e) => setProductDescription(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <TextField
                  className="highlight-block"
                  style={{ marginTop: '1vh' }}
                  id="outlined-multiline-flexible"
                  label="Explanation"
                  multiline
                  maxRows={4}
                  onChange={(e) => handleExplanation(e)}
                />
              </FormControl>
            </>
          ) : (
            <></>
          )}
          <Row style={{ marginTop: '2vh' }}>
            <DialogContentText>
              Merchant: {selectedTransaction?.merchantName}
            </DialogContentText>
            <DialogContentText>
              Date:{' '}
              {selectedTransaction?.authorizationDate
                ? formatDateToMST(
                    new Date(selectedTransaction?.authorizationDate),
                  )
                : '?'}
            </DialogContentText>
            <DialogContentText>
              Amount:{' '}
              <FancyCurrencyDisplay
                amountCents={selectedTransaction?.amountCents}
                css={css`
                  display: inline-block;
                `}
              />
            </DialogContentText>
            <DialogContentText>
              Payment Method: {selectedTransaction?.paymentMethod}
            </DialogContentText>
          </Row>
          <Box
            css={css`
              display: flex;
              width: 100%;
              justify-content: flex-end;
              padding-top: 16px;
            `}
          >
            <Button variant="contained" onClick={handleSubmitDispute}>
              Submit Dispute
            </Button>
          </Box>
        </AsynchronouslyLoadedSection>
      </DialogContent>
      <DialogActions
        css={css`
          /* TODO: get color from theme */
          border-top: 1px solid #e0e0e0;
        `}
      >
        <Button onClick={handleCloseModal}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
