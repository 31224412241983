import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mapValues } from 'lodash';

import { useAxios } from 'src/hooks/useAxios';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import type { UserPrivileges } from 'src/types';

export const useUpdateOrCreateUser = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();

  const updateCardStatus = async ({
    userId,
    privileges,
    name,
    email,
  }: {
    userId?: string;
    privileges: UserPrivileges;
    name: { first: string; last: string };
    email: string;
  }) => {
    const { data } = userId
      ? await axios.post(
          SERVICE_BENJI_CARD_URLS.USERS_UPDATE_SUB_USER,
          {
            userId,
            // Sometimes the BE returns privileges as null instead of false, this cleans it up before sending it back.
            userPrivileges: mapValues(privileges, (value) => Boolean(value)),
          },
          {
            headers: await generateSecureHeaders(),
          },
        )
      : await axios.post(
          SERVICE_BENJI_CARD_URLS.USERS_CREATE_SUB_USER,
          {
            subUserFirstName: name.first,
            subUserLastName: name.last,
            subUserEmail: email,
            userPrivileges: privileges,
          },
          {
            headers: await generateSecureHeaders(),
          },
        );
    return data;
  };

  return useMutation({
    mutationFn: (data: {
      userId?: string;
      privileges: UserPrivileges;
      name: { first: string; last: string };
      email: string;
    }) => updateCardStatus(data),
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.subUsers._baseKey,
      ]);
      queryClient.invalidateQueries(
        queryKeys.subUsers.details({ id: userId ?? '' }),
      );
    },
  });
};
