import { css } from '@emotion/react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  AsynchronouslyLoadedSection,
  FancyCurrencyDisplay,
  InfoPopover,
} from 'src/components';
import { CircleChart } from 'src/components/CircleChart';
import { DataItemDisplay } from 'src/components/DataItemDisplay';
import {
  useGetUserPrivileges,
  useGetAccountOverview,
  useGetAccountMonthOverview,
  useAppRoutes,
} from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { monthNames, today } from 'src/utils';

import { FundsTransfersOverviewCard } from './BalanceTransfersOverviewCard';
import { CardIcon } from './CardIcon';
import { TransactionsOverviewCard } from './TransactionsOverviewCard';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? '');

export const DashboardPage = () => {
  const { privileges, isLoading: areUserPrivilegesLoading } =
    useGetUserPrivileges();

  const { data: accountOverview, isLoading: isBalanceLoading } =
    useGetAccountOverview();

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const { data: monthOverview, isLoading: isMonthOverviewLoading } =
    useGetAccountMonthOverview({ year, month });

  const { navigate } = useAppRoutes();

  const {
    accumulatedRemainingSpendLimitCents,
    availableBalanceCents,
    cardsWithRemainingSpendLimit,
    staleCards,
  } = accountOverview ?? {};

  const isDataLoading = isBalanceLoading || isMonthOverviewLoading;

  const isPageLoading = areUserPrivilegesLoading;

  const hasAllocatedMoreThanBalance =
    (accumulatedRemainingSpendLimitCents ?? 0) > (availableBalanceCents ?? 0);

  return (
    <Elements stripe={stripePromise}>
      <Box
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
        `}
      >
        <AsynchronouslyLoadedSection isLoading={isPageLoading}>
          {!!privileges?.cards &&
            !!privileges?.transactions &&
            !!privileges?.balance && (
              <>
                <Card
                  css={css`
                    flex: 1 1 400px;
                  `}
                >
                  <CardContent
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      height: 100%;
                      gap: 24px;
                      justify-content: space-between;
                      padding: 16px 20px;
                    `}
                  >
                    <AsynchronouslyLoadedSection isLoading={isDataLoading}>
                      <Box
                        css={css`
                          display: flex;
                          flex-wrap: wrap;
                          gap: 16px;
                          flex: 1 1 150px;
                        `}
                      >
                        <Box
                          css={css`
                            flex: 1 1 100%;
                          `}
                        >
                          <DataItemDisplay
                            css={css`
                              flex: 1 1 100px;
                            `}
                            size="large"
                            label="Current Balance"
                            value={
                              <FancyCurrencyDisplay
                                size="xl"
                                amountCents={availableBalanceCents}
                                className="highlight-block"
                              />
                            }
                          />
                        </Box>
                        <Box
                          css={css`
                            display: flex;
                            flex-wrap: wrap;
                            flex: 1 1 auto;
                          `}
                        >
                          <DataItemDisplay
                            css={css`
                              flex: 1 1 140px;
                            `}
                            size="medium"
                            label={
                              <>
                                <Box
                                  css={css`
                                    height: 14px;
                                    width: 14px;
                                    border-radius: 50%;
                                    background-color: ${COLOR_PALETTE.chartFilled};
                                  `}
                                />
                                Allocated Funds
                                <InfoPopover
                                  contents="This is the total of every active card's spend limit minus the amount already spent on the card. In other words, this is the amount remaining for every card to hit its spend limit max."
                                  name="Allocated Funds"
                                />
                              </>
                            }
                            value={
                              <FancyCurrencyDisplay
                                size="lg"
                                amountCents={
                                  accumulatedRemainingSpendLimitCents ??
                                  undefined
                                }
                                className="highlight-block"
                              />
                            }
                          />
                        </Box>
                        <Box
                          css={css`
                            display: flex;
                            flex-wrap: wrap;
                            flex: 1 1 auto;
                          `}
                        >
                          <DataItemDisplay
                            css={css`
                              flex: 1 1 100px;
                            `}
                            size="medium"
                            label={
                              <>
                                <Box
                                  css={css`
                                    height: 14px;
                                    width: 14px;
                                    border-radius: 50%;
                                    background-color: ${COLOR_PALETTE.chartBackground};
                                  `}
                                />
                                Non-Allocated Funds
                                <InfoPopover
                                  contents="This is the current balance minus the total unspent amount on all active cards with a spend limit. In other words, this is the amount remaining that has yet to be allocated to a card via spend limit."
                                  name="Non-Allocated Funds"
                                />
                              </>
                            }
                            value={
                              <FancyCurrencyDisplay
                                css={css`
                                  flex: 1 1 100%;
                                  font-size: 24px;
                                  line-height: 1.3;
                                  display: block;
                                `}
                                size="lg"
                                amountCents={
                                  (availableBalanceCents ?? 0) -
                                  (accumulatedRemainingSpendLimitCents ?? 0)
                                }
                                className="highlight-block"
                              />
                            }
                          />
                        </Box>
                      </Box>
                      <CircleChart
                        value={accumulatedRemainingSpendLimitCents ?? 0}
                        max={availableBalanceCents ?? 0}
                        css={css`
                          margin: 0 auto;
                        `}
                      />
                    </AsynchronouslyLoadedSection>
                  </CardContent>
                </Card>
                <Card
                  css={css`
                    flex: 1 1 400px;
                  `}
                >
                  <CardContent
                    css={css`
                      display: flex;
                      flex-wrap: nowrap;
                      gap: 16px;
                      justify-content: space-between;
                      align-items: center;
                      height: 100%;
                      padding: 8px 12px;
                    `}
                  >
                    <Box
                      css={css`
                        display: flex;
                        flex-wrap: wrap;
                        gap: 16px;
                        justify-content: space-between;
                        height: 100%;
                        flex: 1 1 200px;
                      `}
                    >
                      <AsynchronouslyLoadedSection isLoading={isDataLoading}>
                        <DataItemDisplay
                          css={css`
                            width: 100%;
                            cursor: pointer;
                            padding: 8px;
                            &:hover {
                              background-color: ${COLOR_PALETTE.dataItemLinkHover};
                              border-radius: 8px;
                            }
                          `}
                          onClick={() => {
                            navigate('/secure/cards', {
                              queryParams: {
                                filters: JSON.stringify({
                                  hasUnspentLimits: true,
                                }),
                              },
                            });
                          }}
                          label="Cards (Remaining Spend Limits)"
                          value={cardsWithRemainingSpendLimit}
                        />
                        <DataItemDisplay
                          css={css`
                            width: 100%;
                            cursor: pointer;
                            padding: 8px;
                            &:hover {
                              background-color: ${COLOR_PALETTE.dataItemLinkHover};
                              border-radius: 8px;
                            }
                          `}
                          onClick={() => {
                            navigate('/secure/cards', {
                              queryParams: {
                                filters: JSON.stringify({
                                  isStale: true,
                                }),
                              },
                            });
                          }}
                          label={
                            <>
                              Cards (Stale)
                              <InfoPopover
                                contents="Active, single-use cards that have not been used in the last 30 days."
                                name="Stale Cards"
                              />
                            </>
                          }
                          value={staleCards}
                        />
                      </AsynchronouslyLoadedSection>
                    </Box>
                    <Box
                      css={css`
                        flex: 1 1 50px;
                        max-width: 100px;
                      `}
                    >
                      <CardIcon
                        css={css`
                          width: 100%;
                          height: 100%;
                        `}
                      />
                    </Box>
                  </CardContent>
                </Card>
                {hasAllocatedMoreThanBalance && (
                  <Alert severity="error">
                    <Box
                      css={css`
                        display: flex;
                        gap: 40px;
                        align-items: flex-start;
                      `}
                    >
                      You have allocated more funds via your card spend limits
                      than you currently have available in your account balance.
                      Depending on the scheduling of your transactions, this may
                      result in declined transactions.
                      {privileges.fund_balance && (
                        <Button
                          onClick={() =>
                            navigate('/secure/funds-transfers/new-transfer')
                          }
                          variant="outlined"
                          color="error"
                          css={css`
                            flex: 0 0 135px;
                            margin: -4px 0 -4px auto;
                          `}
                        >
                          Fund Balance
                        </Button>
                      )}
                    </Box>
                  </Alert>
                )}
                <Card
                  css={css`
                    flex: 1 1 100%;
                  `}
                >
                  <CardContent
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      gap: 16px;
                      justify-content: space-between;
                    `}
                  >
                    <AsynchronouslyLoadedSection isLoading={isDataLoading}>
                      <DataItemDisplay
                        css={css`
                          flex: 0 1 250px;
                        `}
                        label={
                          <>
                            Total Spend ({monthNames[today.getMonth()]})
                            <InfoPopover
                              contents="This is the total amount spent each month on all cards minus any refunds. This number may vary from final totals provided each month and should be treated as an estimate."
                              name="Total Spend"
                            />
                          </>
                        }
                        value={
                          <FancyCurrencyDisplay
                            size="lg"
                            amountCents={
                              (monthOverview?.accumulatedSpendCents ?? 0) * -1
                            }
                          />
                        }
                        isPrivate
                      />
                      <DataItemDisplay
                        css={css`
                          flex: 0 1 250px;
                        `}
                        label={`Total Transactions (${
                          monthNames[today.getMonth()]
                        })`}
                        value={monthOverview?.transactionCount ?? 0}
                      />
                    </AsynchronouslyLoadedSection>
                  </CardContent>
                </Card>
              </>
            )}

          <TransactionsOverviewCard />
          <FundsTransfersOverviewCard />

          {!Object.values(privileges ?? {}).some(
            (value) => typeof value === 'boolean' && !!value,
          ) && (
            <Card
              css={css`
                flex: 1 1 100%;
              `}
            >
              <CardContent
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 16px;
                `}
              >
                <Typography variant="h6">No Permissions</Typography>
                <Typography>
                  You do not have any permissions set. Please contact your
                  administrator to request access.
                </Typography>
              </CardContent>
            </Card>
          )}
        </AsynchronouslyLoadedSection>
      </Box>
    </Elements>
  );
};
