import { createContext, useContext } from 'react';

import { isProd } from 'src/utils';

import type { TrackContext } from './enums';
import type { trackFunction } from './trackFunction';
import type { TrackData, TrackName, TrackObject } from './types';

export const TrackingContext = createContext<
  | {
      trackingProps: TrackData | Record<string, never>;
      track: (trackingProps: TrackObject) => void; // TODO: fix this type
      inheritedProviders: string[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix type to use InheritedProvidersData;
      inheritedProvidersData: any;
      externalTrackFunction?: typeof trackFunction;
      debug: boolean;
    }
  | Record<string, never>
>({});

export const TrackingContextProvider = ({
  children,
  contextName: providerContextName,
  trackingProps = {},
  trackFunction: externalTrackFunctionFromProps,
  debug: currentDebug = false,
}: {
  children: React.ReactNode;
  contextName: TrackContext;
  trackingProps?: TrackData;
  trackFunction?: typeof trackFunction;
  debug?: boolean;
}) => {
  const {
    trackingProps: inheritedTrackingProps,
    inheritedProviders = [],
    inheritedProvidersData = {},
    externalTrackFunction: externalTrackFunctionFromContext,
    debug: inheritedDebug,
  } = useContext(TrackingContext);

  const debug = currentDebug || inheritedDebug;

  const externalTrackFunction =
    externalTrackFunctionFromContext ?? externalTrackFunctionFromProps;

  const inheritedTrackingProviders = [
    providerContextName,
    ...inheritedProviders,
  ];
  const inheritedTrackingProvidersData = {
    [providerContextName]: Object.keys(trackingProps),
    ...inheritedProvidersData,
  };
  const consolidatedTrackingProps = {
    ...inheritedTrackingProps,
    ...trackingProps,
  };

  if (inheritedTrackingProviders.length > 1 && externalTrackFunctionFromProps) {
    console.warn(
      "TrackingProvider: You are trying to override your 'trackFunction', this should only be set once on the outermost provider",
    );
  }
  const track = (trackingProps: TrackObject) => {
    const provider = {
      current: providerContextName,
      inheritted: inheritedTrackingProviders,
      dataByProvider: {
        _self: Object.keys(trackingProps),
        ...inheritedTrackingProvidersData,
      },
    };
    const finalTrackingProps = {
      ...consolidatedTrackingProps,
      ...trackingProps,
      provider: {
        inheritted: provider.inheritted,
        dataByProvider: provider.dataByProvider,
      },
      label: trackingProps.label,
      type: trackingProps.type,
      context: providerContextName,
    };
    const { label, type, context, ...rest } = finalTrackingProps;
    const trackName: TrackName = `${context}_${type}: ${label}`;
    const trackData: TrackData = {
      label: label,
      type: type,
      context: context,
      ...rest,
    };

    if (externalTrackFunction) {
      externalTrackFunction(trackName, trackData);
    } else {
      console.warn(
        "TrackingProvider: You are trying to call 'track' but no 'trackFunction' has been passed to the provider",
      );
    }

    if (debug) {
      if (isProd) {
        console.warn(
          'TrackingProvider: The `debug` prop is set to true in production. This should be set to false in prod.',
        );
      }
      console.log('Tracking Debug:', trackName, trackData);
    }
  };

  return (
    <TrackingContext.Provider
      value={{
        trackingProps: consolidatedTrackingProps,
        track,
        inheritedProviders: inheritedTrackingProviders,
        inheritedProvidersData: inheritedTrackingProvidersData,
        externalTrackFunction,
        debug,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};
