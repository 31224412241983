import { css } from '@emotion/react';
import { EmailOutlined as EmailOutlinedIcon } from '@mui/icons-material';
import { Box, IconButton, Link, Typography } from '@mui/material';

import { CancelCloseIcon } from 'src/components/Icon';
import { useAppRoutes } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';

const ExternalLink = ({
  href,
  children,
}: {
  href: string;
  children: string;
}) => (
  <Link
    href={href}
    target="_blank"
    rel="noreferrer"
    css={css`
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
      align-content: center;
      font-size: 14px;
      font-weight: 300;
      text-decoration: none;
      svg {
        height: 16px;
        width: 16px;
        color: ${COLOR_PALETTE.lightTextOnLight};
      }
    `}
  >
    {children}
    <EmailOutlinedIcon />
  </Link>
);

export const ContactUsPage = () => {
  const { navigate, shouldShowSideNav } = useAppRoutes();
  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <Box
        css={css`
          display: flex;
          border-bottom: 1px solid ${COLOR_PALETTE.border};
          padding-bottom: 22px;
        `}
      >
        <Typography
          variant="h1"
          css={css`
            & {
              font-size: 20px;
              font-weight: 700;
            }
          `}
        >
          Contact Us
        </Typography>
        {!shouldShowSideNav && (
          <IconButton
            onClick={() => navigate('/')}
            color="secondary"
            css={css`
              background-color: ${COLOR_PALETTE.lightButtonColor};
              height: 46px;
              width: 46px;
              margin-left: auto;
            `}
          >
            <CancelCloseIcon
              css={css`
                height: 18px;
                width: 18px;
              `}
            />
          </IconButton>
        )}
      </Box>
      <Box
        css={css`
          max-width: 690px;
          width: 100%;

          margin: 0 auto;
        `}
      >
        <Box
          css={(theme) => css`
            background-color: ${COLOR_PALETTE.darkBackground};
            padding: 17px 20px;
            border-radius: 10px;
            margin-top: 100px;
            ${theme.breakpoints.down('md')} {
              margin-top: 20px;
            }
          `}
        >
          <Typography
            variant="h2"
            css={css`
              & {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                color: ${COLOR_PALETTE.white};
              }
            `}
          >
            Contact Support
          </Typography>
        </Box>
        <Box
          css={css`
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <ExternalLink href="mailto:support@benjicard.com">
            Support - support@benjicard.com
          </ExternalLink>
          <ExternalLink href="mailto:complaints@benjicard.com">
            Complaints - complaints@benjicard.com
          </ExternalLink>
        </Box>
      </Box>
    </Box>
  );
};
