import { css } from '@emotion/react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CardMenu, DataRow, FormFieldInput } from 'src/components';
import { EditIcon } from 'src/components/Icon';
import { TrackType, useToast, useTrack } from 'src/context';
import { useGetUserDetails, useGetUserPrivileges } from 'src/hooks';
import { COLOR_PALETTE, focusedCardShadow } from 'src/theme';
import { CardTypeLabel } from 'src/types/cards';

import { useUpdateCompanyInfo } from './useUpdateCompanyInfo';

interface FormFields {
  companyName: string;
  companyAddress: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  };
  companySupportEmail: string;
}

const labelWidth = 162;

export const CompanyInfoCard = () => {
  const { track } = useTrack();
  const { data: userData } = useGetUserDetails();
  const { privileges } = useGetUserPrivileges();

  const { setToast } = useToast();
  const [isEditMode, setIsEditMode] = useState(false);

  const defaultValues: FormFields = {
    companyName: userData?.accountDetails.companyName ?? '',
    companyAddress: {
      line1: userData?.accountDetails.companyAddress?.line1 ?? '',
      line2: userData?.accountDetails.companyAddress?.line2 ?? '',
      city: userData?.accountDetails.companyAddress?.city ?? '',
      state: userData?.accountDetails.companyAddress?.state ?? '',
      postal_code: userData?.accountDetails.companyAddress?.postal_code ?? '',
      country: userData?.accountDetails.companyAddress?.country ?? '',
    },
    companySupportEmail: userData?.accountDetails.companySupportEmail ?? '',
  };
  const useFormMethods = useForm<FormFields>({
    defaultValues,
    mode: 'onBlur',
  });
  const { handleSubmit, control, watch, reset, formState } = useFormMethods;
  const { isSubmitting } = formState;
  const formValues = watch();

  const { mutateAsync: updateCompanyInfo } = useUpdateCompanyInfo();
  const onSubmit = async (data: FormFields) => {
    track({
      label: 'Submit update company info',
      type: TrackType.action,
      actionType: 'submit',
      companySupportEmail: data.companySupportEmail,
    });
    await updateCompanyInfo(data, {
      onSuccess: (data) => {
        track({
          label: 'Updated company info',
          type: TrackType.effect,
          isSuccessful: true,
          companySupportEmail: data.companySupportEmail,
        });
        setToast({
          message: 'Updated Company Info',
          severity: 'success',
        });
        setIsEditMode(false);
      },
      onError: () => {
        track({
          label: 'Updated company info',
          type: TrackType.effect,
          isSuccessful: false,
          companySupportEmail: data.companySupportEmail,
        });
        reset(undefined, { keepValues: true });
        setToast({
          message: 'Error Updating Company Info',
          severity: 'error',
        });
      },
    });
  };

  const menuItems = [
    ...(isEditMode
      ? []
      : privileges?.company_details
      ? [
          {
            label: 'Edit Info',
            onClick: () => {
              setIsEditMode(true);
            },
            labelIcon: EditIcon,
          },
        ]
      : []),
  ];

  return (
    <FormProvider {...useFormMethods}>
      <Card
        css={css`
          flex: 1 1 430px;
          position: relative;
          ${isEditMode
            ? `
            border: 1px solid ${COLOR_PALETTE.borderDark};
            ${focusedCardShadow};
          `
            : ''}
        `}
      >
        <CardContent
          css={css`
            flex: 1 1 100%;
            padding: 18px;
            display: flex;
            flex-wrap: wrap;
            gap: 18px;
          `}
        >
          <Box
            css={css`
              display: flex;
              width: 100%;
            `}
          >
            <Box
              css={css`
                display: flex;
                width: 100%;
                padding-right: 30px;
                padding-bottom: 16px;
                border-bottom: 1px dashed ${COLOR_PALETTE.border};
              `}
            >
              <Typography
                component="h2"
                css={css`
                  font-size: 18px;
                  font-weight: 700;
                  line-height: normal;
                `}
              >
                Company Info
              </Typography>
              {!!menuItems.length && (
                <CardMenu
                  cardName="Company Info"
                  menuItems={menuItems}
                  css={css`
                    position: absolute;
                    top: 10px;
                    right: 10px;
                  `}
                />
              )}
            </Box>
          </Box>
          {isEditMode ? (
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit, (errors) => {
                () => {
                  track({
                    label: 'Submit update company info',
                    type: TrackType.action,
                    actionType: 'submit',
                    companySupportEmail: formValues.companySupportEmail,
                    validationErrors: Object.entries(errors).map(
                      ([key, value]) => ({
                        field: key,
                        message: value.message,
                      }),
                    ),
                  });
                };
              })}
              css={css`
                flex: 1 1 400px;
                display: flex;
                flex-direction: column;
                justify-content: center;
              `}
            >
              <DataRow
                label="Company Name"
                value={
                  <FormFieldInput
                    disabled={isSubmitting}
                    control={control}
                    name="companyName"
                    defaultValue={defaultValues.companyName}
                    css={css`
                      flex: 1 1 auto;
                    `}
                    rules={{
                      required: 'Required',
                    }}
                  />
                }
                css={css`
                  align-items: flex-start;
                `}
                labelWidth={labelWidth}
              />
              <DataRow
                label="Billing Address"
                value={
                  <Box
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      gap: 8px;
                      flex: 1 1 auto;
                    `}
                  >
                    <FormFieldInput
                      disabled={isSubmitting}
                      control={control}
                      name="companyAddress.line1"
                      placeholder="Street"
                      defaultValue={defaultValues.companyAddress.line1}
                      css={css`
                        flex: 1 1 100%;
                      `}
                      rules={{
                        required: 'Required',
                      }}
                    />
                    <FormFieldInput
                      disabled={isSubmitting}
                      control={control}
                      name="companyAddress.line2"
                      placeholder="Street2"
                      defaultValue={defaultValues.companyAddress.line2}
                      css={css`
                        flex: 1 1 100%;
                      `}
                    />
                    <FormFieldInput
                      disabled={isSubmitting}
                      control={control}
                      name="companyAddress.city"
                      placeholder="City"
                      defaultValue={defaultValues.companyAddress.city}
                      css={css`
                        flex: 4 1 100px;
                      `}
                      rules={{
                        required: 'Required',
                      }}
                    />
                    <FormFieldInput
                      disabled={isSubmitting}
                      control={control}
                      name="companyAddress.state"
                      placeholder="State"
                      defaultValue={defaultValues.companyAddress.state}
                      css={css`
                        flex: 1 1 75px;
                      `}
                      rules={{
                        required: 'Required',
                      }}
                    />
                    <FormFieldInput
                      disabled={isSubmitting}
                      control={control}
                      name="companyAddress.postal_code"
                      placeholder="Zip"
                      defaultValue={defaultValues.companyAddress.postal_code}
                      css={css`
                        flex: 4 1 100px;
                      `}
                      rules={{
                        required: 'Required',
                      }}
                    />
                    <Box
                      css={css`
                        flex: 1 1 75px;
                      `}
                    />
                  </Box>
                }
                css={css`
                  align-items: flex-start;
                `}
                isPrivate
                labelWidth={labelWidth}
              />

              <DataRow
                label="Company Email"
                value={
                  <FormFieldInput
                    disabled={isSubmitting}
                    control={control}
                    name="companySupportEmail"
                    defaultValue={defaultValues.companySupportEmail}
                    css={css`
                      flex: 1 1 auto;
                    `}
                    rules={{
                      required: 'Required',
                    }}
                    type="email"
                  />
                }
                css={css`
                  align-items: flex-start;
                `}
                labelWidth={labelWidth}
              />
              <DataRow
                label="Enabled Card Types"
                value={userData?.accountDetails.companyCardTypes
                  .map((value) => CardTypeLabel[value] ?? value)
                  .join(', ')}
                labelWidth={labelWidth}
              />
              <Box
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  gap: 16px;
                  justify-content: flex-end;
                  padding-top: 16px;
                `}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setIsEditMode(false);
                    reset(defaultValues);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              css={css`
                flex: 1 1 400px;
                display: flex;
                flex-direction: column;
                justify-content: center;
              `}
            >
              <DataRow
                label="Company Name"
                value={userData?.accountDetails.companyName}
                labelWidth={labelWidth}
              />
              <DataRow
                label="Billing Address"
                value={
                  <Box
                    css={css`
                      width: 100%;
                    `}
                  >
                    <Typography>
                      {userData?.accountDetails.companyAddress?.line1}
                    </Typography>
                    {!!userData?.accountDetails.companyAddress?.line2 && (
                      <Typography>
                        {userData?.accountDetails.companyAddress?.line2}
                      </Typography>
                    )}
                    <Typography>
                      {userData?.accountDetails.companyAddress?.city},{' '}
                      {userData?.accountDetails.companyAddress?.state}{' '}
                      {userData?.accountDetails.companyAddress?.postal_code}
                    </Typography>
                  </Box>
                }
                isPrivate
                labelWidth={labelWidth}
              />
              <DataRow
                label="Company Email"
                value={userData?.accountDetails.companySupportEmail}
                labelWidth={labelWidth}
              />
              <DataRow
                label="Enabled Card Types"
                value={userData?.accountDetails.companyCardTypes
                  .map((value) => CardTypeLabel[value] ?? value)
                  .join(', ')}
                labelWidth={labelWidth}
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </FormProvider>
  );
};
