import { SvgIcon } from '@mui/material';

export const GoogleWalletIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
      >
        <g clipPath="url(#clip0_563_4994)">
          <path
            d="M22.7113 13.5914L22.699 13.5791L22.7113 13.5914Z"
            fill="#607D8B"
          />
          <path
            d="M22.7026 14.1748C22.5508 14.1747 22.4053 14.1137 22.2988 14.0056L22.2871 13.9939C22.0632 13.7621 22.0696 13.3927 22.3014 13.1687C22.5276 12.9503 22.8861 12.9503 23.1123 13.1687C23.345 13.3917 23.3529 13.7611 23.1299 13.9938C23.0184 14.1102 22.8637 14.1752 22.7026 14.1736L22.7026 14.1748Z"
            fill="black"
          />
          <path
            d="M23.2825 13.5677C23.2815 13.4572 23.2504 13.3491 23.1926 13.2549C20.9032 9.7692 18.049 6.68938 14.7472 4.14189C14.4931 3.94373 14.1263 3.9892 13.9282 4.24343C13.7841 4.42841 13.7646 4.68176 13.8789 4.88654C15.4138 7.74528 16.2411 10.9302 16.2914 14.1746C16.2914 14.3614 16.2914 14.5388 16.2809 14.7255C16.274 14.8865 16.3329 15.0432 16.4443 15.1597C16.4619 15.1778 16.4807 15.195 16.5003 15.211C17.4258 16.1868 20.5678 21.1553 19.955 24.3346C19.9012 24.6162 20.06 24.8953 20.3297 24.9928C20.3933 25.0161 20.4604 25.028 20.5281 25.0278C20.7409 25.0278 20.9367 24.912 21.0393 24.7256C22.5516 21.4152 23.3212 17.814 23.2942 14.1747C23.2942 13.9645 23.2942 13.7556 23.2825 13.5677Z"
            fill="#FFC107"
          />
          <path
            d="M12.7467 13.9552C12.0826 12.3189 8.96286 9.01582 5.78708 6.58819C4.51897 5.59379 2.68489 5.81569 1.69049 7.08381C0.696087 8.35192 0.917989 10.186 2.1861 11.1804C2.23337 11.2174 2.28173 11.253 2.33114 11.2871C6.96465 14.1244 9.67244 20.6592 9.45065 22.0142C9.40059 22.3165 9.59273 22.6059 9.89068 22.6772C9.93537 22.6882 9.98122 22.6937 10.0272 22.6935C10.2824 22.6934 10.5079 22.5274 10.584 22.2839C10.6417 22.1411 10.7178 22.0065 10.8104 21.8835C12.1607 19.5424 12.8452 16.8766 12.7898 14.1746C12.7895 14.0994 12.7748 14.0249 12.7467 13.9552Z"
            fill="#2196F3"
          />
          <path
            d="M14.9363 4.40216C14.2994 2.92189 12.5831 2.23813 11.1029 2.875C9.73426 3.46378 9.02911 4.98908 9.46711 6.41312C9.53812 6.62938 9.64295 6.83301 9.77759 7.01651C11.0423 9.18618 11.6809 11.6639 11.6228 14.1745C11.6831 16.6635 11.0567 19.1211 9.8126 21.2777C9.67025 21.4692 9.55738 21.6808 9.47761 21.9056C8.91498 23.4157 9.6831 25.096 11.1931 25.6586C11.9179 25.9285 12.7201 25.8998 13.4237 25.5786C14.1017 25.2782 14.6323 24.7206 14.8989 24.0287C16.4241 21.177 17.2941 18.0211 17.4456 14.7908C17.4573 14.5807 17.4585 14.3835 17.4585 14.1745C17.41 10.7625 16.5452 7.41157 14.9363 4.40216Z"
            fill="#F44336"
          />
          <path
            d="M25.4067 2.41795C24.84 0.909389 23.1577 0.145863 21.6492 0.712544C20.9309 0.982317 20.3479 1.52416 20.0262 2.22072C19.6937 2.95339 19.6785 3.79088 19.9842 4.53515C21.3316 7.49745 22.0471 10.708 22.085 13.9621C22.0955 14.15 22.0955 14.3367 22.0955 14.5235C22.125 17.9951 21.3928 21.4309 19.9503 24.5889C19.3877 26.099 20.1558 27.7792 21.6659 28.3418C22.3906 28.6118 23.1929 28.583 23.8964 28.2618C24.5744 27.9614 25.1051 27.4038 25.3717 26.7119C26.7897 24.164 27.9312 17.9583 27.9312 14.5235C27.9312 11.0886 26.7875 4.88294 25.4067 2.41795Z"
            fill="#4CAF50"
          />
        </g>
        <defs>
          <clipPath id="clip0_563_4994">
            <rect
              width="28"
              height="28"
              fill="white"
              transform="translate(0.5 0.525391)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
