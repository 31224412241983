import { css } from '@emotion/react';
import {
  CloseOutlined as CloseOutlinedIcon,
  SearchOutlined as SearchOutlinedIcon,
} from '@mui/icons-material';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useRef, useState } from 'react';

import { COLOR_PALETTE } from 'src/theme';

export const useSearchBar = (props?: {
  defaultInputValue?: string;
  defaultDebounceMS?: number;
}) => {
  const { defaultInputValue = '', defaultDebounceMS = 500 } = props ?? {};
  const [searchValue, setSearchValue] = useState(defaultInputValue);
  const debouncer = useRef(
    debounce((value) => {
      setSearchValue(value);
    }, defaultDebounceMS),
  );
  const handleSetSearchValue = useCallback(
    (value: string) => debouncer.current(value),
    [],
  );

  return {
    searchValue,
    setSearchValue: (value: string) => handleSetSearchValue(value),
    onClear: () => setSearchValue(''),
    defaultValue: defaultInputValue,
  };
};

export const SearchBar = ({
  setSearchValue,
  onClear,
  isLoading,
  disabled,
  className,
  label = '',
  defaultValue = '',
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  onClear: () => void;
  isLoading: boolean;
  disabled?: boolean;
  className?: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);

  return (
    <TextField
      disabled={disabled}
      className={className}
      onChange={(e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        setSearchValue(newValue);
      }}
      value={inputValue}
      label={label}
      variant="outlined"
      placeholder="Search"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start">
            {isLoading && inputValue.length ? (
              <CircularProgress size={24} color="inherit" />
            ) : inputValue.length ? (
              <CloseOutlinedIcon
                onClick={() => {
                  setInputValue('');
                  onClear();
                }}
                sx={{ cursor: 'pointer' }}
              />
            ) : (
              <CloseOutlinedIcon
                sx={{ cursor: 'initial', opacity: 0, pointerEvents: 'none' }}
              />
            )}
          </InputAdornment>
        ),
      }}
      css={css`
        flex: 1 0 230px;
        width: 230px;
        .MuiInputBase-root {
          border-radius: 100px;
          background-color: ${COLOR_PALETTE.grayInputBackground};
          color: ${COLOR_PALETTE.lightTextOnDark};
          min-height: 46px;
        }

        fieldset {
          border: none;
        }
        .Mui-focused input {
          color: inherit;
        }
        .MuiSvgIcon-root {
          color: ${COLOR_PALETTE.lightTextOnDark};
        }
      `}
    />
  );
};
