import { css } from '@emotion/react';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { FormFieldCheckbox } from 'src/components';
import { EmailInputIcon, PasswordInputIcon } from 'src/components/Icon';
import { TrackType, useTrack } from 'src/context';
import { COLOR_PALETTE } from 'src/theme';

import { LogoSection } from './LogoSection';
import { useRegisterNewAccount } from './useRegisterNewAccount';

interface FormFields {
  name: {
    first: string;
    last: string;
  };
  email: string;
  password: string;
  code: string;
  hasAgreedToTerms: boolean;
}

export const RegisterPage = () => {
  const { track } = useTrack();
  const { mutateAsync: registerNewAccount } = useRegisterNewAccount();
  const [searchParams] = useSearchParams();
  const defaultEmail = searchParams.get('email');
  const defaultFirstName = searchParams.get('firstName');
  const defaultLastName = searchParams.get('lastName');
  const defaultCode = searchParams.get('code');

  const [showPassword, setShowPassword] = useState(false);

  const [serverErrorMessage, setServerErrorMessage] = useState<string>('');

  const useFormMethods = useForm<FormFields>({
    defaultValues: {
      name: {
        first: defaultFirstName || '',
        last: defaultLastName || '',
      },
      email: defaultEmail || '',
      password: '',
      code: defaultCode || '',
      hasAgreedToTerms: false,
    },
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    getValues,
    reset,
  } = useFormMethods;

  const onSubmit = async ({ name, email, password }: FormFields) => {
    track({
      label: 'Submit create account',
      email: getValues('email'),
      actionType: 'submit',
      type: TrackType.action,
    });
    setServerErrorMessage('');
    return await registerNewAccount(
      {
        email: email,
        password,
        firstName: name.first,
        lastName: name.last,
      },
      {
        onSuccess: async (user) => {
          track({
            label: 'Created account',
            type: TrackType.effect,
            isSuccessful: true,
            email,
          });
          window.location.href = user.url;
        },
        onError: () => {
          track({
            label: 'Created account',
            type: TrackType.effect,
            isSuccessful: false,
            email,
          });
          reset(undefined, { keepValues: true });
          setServerErrorMessage(
            'Unable to create account. Please try again or contact support.',
          );
        },
      },
    );
  };

  return (
    <>
      <LogoSection />

      <FormProvider {...useFormMethods}>
        <Box
          css={css`
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            width: 100%;
            max-width: 450px;
            padding: 0 32px 60px;
          `}
          component="form"
          onSubmit={handleSubmit(onSubmit, (errors) => {
            track({
              label: 'Submit create account',
              email: getValues('email'),
              actionType: 'submit',
              type: TrackType.action,
              validationErrors: Object.entries(errors).map(([key, value]) => ({
                field: key,
                message: value.message,
              })),
            });
          })}
        >
          <Box
            css={css`
              width: 100%;
              text-align: left;
            `}
          >
            <Typography
              component="h1"
              css={css`
                font-size: 18px;
                font-weight: 700;
                padding-bottom: 4px;
              `}
            >
              Sign Up
            </Typography>
          </Box>
          <Box
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
            `}
          >
            <Controller
              control={control}
              name="name.first"
              rules={{
                required: 'Required',
              }}
              render={({ field }) => {
                return (
                  <TextField
                    className="highlight-block"
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value || ''}
                    error={!!errors.name?.first}
                    type="text"
                    variant="outlined"
                    placeholder="First Name"
                    disabled={isSubmitting}
                    helperText={
                      errors.name?.first
                        ? (errors.name?.first.message as string)
                        : ''
                    }
                    css={css`
                      flex: 1 1 150px;

                      .MuiInputBase-root {
                        height: 48px;
                      }
                      fieldset {
                        border-radius: 10px;
                      }
                      .Mui-focused fieldset {
                        box-shadow: 0px 10px 15px 0px rgba(242, 190, 87, 0.19);
                      }
                    `}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="name.last"
              rules={{
                required: 'Required',
              }}
              render={({ field }) => {
                return (
                  <TextField
                    className="highlight-block"
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value || ''}
                    error={!!errors.name?.last}
                    type="text"
                    variant="outlined"
                    placeholder="Last Name"
                    disabled={isSubmitting}
                    helperText={
                      errors.name?.last
                        ? (errors.name?.last.message as string)
                        : ''
                    }
                    css={css`
                      flex: 1 1 150px;

                      .MuiInputBase-root {
                        height: 48px;
                      }
                      fieldset {
                        border-radius: 10px;
                      }
                      .Mui-focused fieldset {
                        box-shadow: 0px 10px 15px 0px rgba(242, 190, 87, 0.19);
                      }
                    `}
                  />
                );
              }}
            />
          </Box>
          <Controller
            control={control}
            name="email"
            rules={{
              required: 'Required',
            }}
            render={({ field }) => {
              return (
                <TextField
                  fullWidth
                  className="highlight-block"
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value || ''}
                  error={!!errors.email}
                  type="text"
                  variant="outlined"
                  placeholder="Email Address"
                  helperText={
                    errors.email ? (errors.email.message as string) : ''
                  }
                  disabled={isSubmitting}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailInputIcon
                          css={css`
                            height: 20px;
                            width: 20px;
                          `}
                        />
                      </InputAdornment>
                    ),
                  }}
                  css={css`
                    .MuiInputBase-root {
                      height: 48px;
                    }
                    fieldset {
                      border-radius: 10px;
                    }
                    .Mui-focused fieldset {
                      box-shadow: 0px 10px 15px 0px rgba(242, 190, 87, 0.19);
                    }
                  `}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="password"
            rules={{
              required: 'Required',
            }}
            render={({ field }) => {
              return (
                <TextField
                  className="highlight-block"
                  fullWidth
                  placeholder="Password"
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value || ''}
                  error={!!errors.password}
                  variant="outlined"
                  disabled={isSubmitting}
                  helperText={
                    errors.password ? (errors.password.message as string) : ''
                  }
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordInputIcon
                          css={css`
                            height: 20px;
                            width: 20px;
                          `}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            track({
                              label: 'Toggle view password',
                              type: TrackType.action,
                              actionType: 'toggle',
                              isVisible: !showPassword,
                            });
                            setShowPassword(!showPassword);
                          }}
                          edge="end"
                          css={css`
                            height: 36px;
                            width: 36px;
                            margin-right: -8px;
                          `}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon
                              css={css`
                                color: ${COLOR_PALETTE.lightInputIcon};
                              `}
                            />
                          ) : (
                            <VisibilityIcon
                              css={css`
                                color: ${COLOR_PALETTE.lightInputIcon};
                              `}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  css={css`
                    .MuiInputBase-root {
                      height: 48px;
                    }
                    fieldset {
                      border-radius: 10px;
                    }
                    .Mui-focused fieldset {
                      box-shadow: 0px 10px 15px 0px rgba(242, 190, 87, 0.19);
                    }
                  `}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="code"
            rules={{
              required: 'Required',
              validate: (value) =>
                value === '4756' || 'Please enter a valid code.',
            }}
            render={({ field }) => {
              return (
                <TextField
                  className="highlight-block"
                  fullWidth
                  placeholder="Registration Code"
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value || ''}
                  error={!!errors.code}
                  variant="outlined"
                  disabled={isSubmitting}
                  helperText={
                    errors.code ? (errors.code.message as string) : ''
                  }
                  css={css`
                    .MuiInputBase-root {
                      height: 48px;
                    }
                    fieldset {
                      border-radius: 10px;
                    }
                    .Mui-focused fieldset {
                      box-shadow: 0px 10px 15px 0px rgba(242, 190, 87, 0.19);
                    }
                  `}
                />
              );
            }}
          />
          <FormFieldCheckbox
            name="hasAgreedToTerms"
            rules={{
              required: 'Required',
            }}
            label={
              <Typography>
                I agree to the Benji Card{' '}
                <Link href="/legal" target="_blank">
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link href="/legal" target="_blank">
                  Privacy Policy
                </Link>
              </Typography>
            }
            defaultValue={false}
            control={control}
            css={css`
              flex: initial;
            `}
          />
          {serverErrorMessage && (
            <Typography color="error">{serverErrorMessage}</Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitting}
            css={css`
              width: 100%;
            `}
            data-testid="submit"
          >
            Sign up
          </Button>
        </Box>
      </FormProvider>
      <Dialog open={isSubmitting}>
        <DialogContent>
          <Typography variant="h6">
            Creating your account. This may take a minute.
          </Typography>
          <Box
            css={css`
              display: flex;
              padding: 40px;
              justify-content: center;
            `}
          >
            <CircularProgress size={60} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
