import mixpanel from 'mixpanel-browser';
import { useContext } from 'react';

import { TrackingContext } from './TrackingContextProvider';

export const useTrack = () => {
  const { track } = useContext(TrackingContext);

  return {
    track,
    incrementMixpanelProfileData: (value: string) =>
      mixpanel.people.increment(value),
  };
};
