import { css } from '@emotion/react';

import { FormFieldSwitch } from 'src/components';
import { useToast } from 'src/context';
import { COLOR_PALETTE } from 'src/theme';

import { useSetUserStatus } from './useSetUserStatus';

interface UserIsActiveFormFields {
  isUserActive: boolean;
}

export const SubUserIsActiveForm = ({
  isUserActive,
  userId,
  className,
  disabled,
}: {
  isUserActive?: boolean;
  userId: string;
  className?: string;
  disabled?: boolean;
}) => {
  const { setToast } = useToast();
  const { mutateAsync: setUserStatus, isLoading } = useSetUserStatus();

  const onSubmit = async ({ isUserActive }: UserIsActiveFormFields) => {
    await setUserStatus(
      {
        userId,
        isActive: isUserActive,
      },
      {
        onSuccess: () => {
          setToast({
            message: `User Successfully ${
              isUserActive ? 'Activated' : 'Deactivated'
            }`,
            severity: 'success',
          });
        },
        onError: () => {
          setToast({
            message: `Error ${
              isUserActive ? 'Activating' : 'Deactivating'
            } User`,
            severity: 'error',
          });
        },
      },
    );
  };

  return (
    <FormFieldSwitch<{
      userIsActive: boolean;
    }>
      name="userIsActive"
      label={isUserActive ? 'Active' : 'Inactive'}
      className={className}
      disabled={isLoading || disabled}
      checked={!!isUserActive}
      defaultValue={!!isUserActive}
      onChange={(checked) => {
        onSubmit({ isUserActive: checked });
      }}
      css={css`
        .MuiFormControlLabel-label {
          color: ${COLOR_PALETTE.inputLabel}!important;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 300;
        }
      `}
    />
  );
};
