import { css } from '@emotion/react';
import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { isValid } from 'date-fns';
import { debounce, get } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UsaStates } from 'usa-states';

import {
  FormFieldCheckbox,
  FormFieldInput,
  FormFieldSelect,
} from 'src/components';
import { CardTypeRadioCards } from 'src/components';
import {
  AddressInputIcon,
  DateInputIcon,
  EmailInputIcon,
  NameInputIcon,
  PhoneInputIcon,
} from 'src/components/Icon';
import { useCardholderDetailsModal } from 'src/components/modals/CardholderDetailsModal';
import { TrackType, useToast, useTrack } from 'src/context';
import {
  useAppRoutes,
  useGetFeatures,
  useGetPaginatedData,
  useGetUserDetails,
} from 'src/hooks';
import { cleanGroups } from 'src/pages/Groups/utils';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { GroupStatus, type GroupCleaned } from 'src/types/groups';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import {
  ConfirmPossibleDuplicateCardholderModal,
  useConfirmPossibleDuplicateCardholderModal,
} from './ConfirmPossibleDuplicate';

import {
  CreateCardholderErrorCode,
  useCreateCardholder,
} from '../useCreateCardholder';

import type { CardType } from 'src/types/cards';

export const inputCss = css`
  .MuiInputBase-root {
    height: 48px;
  }
  fieldset {
    border-radius: 10px;
  }
  .Mui-focused fieldset {
    box-shadow: 0px 10px 15px 0px rgba(242, 190, 87, 0.19);
  }
`;

export const FormSection = ({
  label,
  children,
  className,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
}) => (
  <Box
    css={css`
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px 30px;
      padding: 15px 0;
      border-bottom: 1px solid ${COLOR_PALETTE.border};
    `}
    className={className}
  >
    <Typography
      css={css`
        flex: 0 0 165px;
        padding-top: 15px;
        font-size: 15px;
        font-weight: 500;
      `}
    >
      {label}
    </Typography>
    <Box
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        flex: 1 1 400px;
      `}
    >
      {children}
    </Box>
  </Box>
);

const usStates = new UsaStates();
const stateOptions = usStates.states.map((state) => ({
  label: state.abbreviation,
  value: state.abbreviation,
}));

const noSpecialCharactersOrNumbersRegex = /^[A-Za-z ]+$/;

type UnsavedChanges = Record<
  string,
  { hasUnsavedChanges: boolean; label: string }
>;
export interface FormFields {
  name: {
    first: string;
    last: string;
  };
  address: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  agreedToTerms: boolean;
  phone?: string;
  email?: string;
  dob?: Date | null;
  cardType?: CardType | '';
  groupId?: string;
}

export const CreateCardholderForm = ({
  onSuccess,
  children,
  getFormFunctions,
  isFromCreateCardFlow,
}: {
  onSuccess?: (props: { id: string; cardType?: CardType }) => void;
  children: (formState: {
    isSubmitting: boolean;
    disabled: boolean;
  }) => React.ReactNode;
  getFormFunctions?: (functions: {
    onClose: (props?: { closeModal: () => void }) => void;
    reset: () => void;
    unsavedChanges: string[];
  }) => void;
  isFromCreateCardFlow?: boolean;
}) => {
  const { data: { accountDetails } = {}, isLoading: isLoadingUser } =
    useGetUserDetails();
  const { features } = useGetFeatures();
  const orgCardTypes = accountDetails?.companyCardTypes;

  const { track, incrementMixpanelProfileData } = useTrack();
  const { openModal: openCardholderModal } = useCardholderDetailsModal();
  const { generatePath } = useAppRoutes();

  const { setToast } = useToast();

  const [unsavedChanges, setUnsavedChanges] = useState<UnsavedChanges>({});
  const hasUnsavedChanges = Object.values(unsavedChanges).some(
    ({ hasUnsavedChanges }) => hasUnsavedChanges,
  );
  const defaultValues: FormFields = useMemo(
    () => ({
      name: {
        first: '',
        last: '',
      },
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'US',
      },
      agreedToTerms: false,
      phone: '',
      email: '',
      dob: null,
      // if org has more than one card type
      cardType: orgCardTypes?.length === 1 ? orgCardTypes[0] ?? '' : '',
      groupId: '',
    }),
    [orgCardTypes],
  );
  const useFormMethods = useForm<FormFields>({
    defaultValues,
    mode: 'onBlur',
    resetOptions: {
      keepDirtyValues: true,
    },
  });
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState,
    setValue,
    register,
    trigger,
  } = useFormMethods;
  const { isSubmitting, errors, dirtyFields } = formState;
  useEffect(() => {
    if (features?.groups) {
      register('groupId', {
        required: 'Required',
      });
    }
  }, [register, features?.groups]);

  const handleReset = useCallback(() => {
    reset(defaultValues);
    setUnsavedChanges({});
  }, [defaultValues, reset]);

  const formValues = watch();

  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] =
    useState(false);
  useEffect(() => {
    setShowUnsavedChangesWarning(false);
    setUnsavedChanges({
      nameFirst: {
        hasUnsavedChanges: formValues.name.first !== defaultValues.name.first,
        label: 'First Name',
      },
      nameLast: {
        hasUnsavedChanges: formValues.name.last !== defaultValues.name.last,
        label: 'Last Name',
      },
      addressLineOne: {
        hasUnsavedChanges:
          formValues.address.line1 !== defaultValues.address.line1,
        label: 'Street 1',
      },
      addressLineTwo: {
        hasUnsavedChanges:
          formValues.address.line2 !== defaultValues.address.line2,
        label: 'Street 2',
      },
      addressCity: {
        hasUnsavedChanges:
          formValues.address.city !== defaultValues.address.city,
        label: 'City',
      },
      addressState: {
        hasUnsavedChanges:
          formValues.address.state !== defaultValues.address.state,
        label: 'State',
      },
      addressPostalCode: {
        hasUnsavedChanges:
          formValues.address.postalCode !== defaultValues.address.postalCode,
        label: 'Postal Code',
      },
      addressCountry: {
        hasUnsavedChanges:
          formValues.address.country !== defaultValues.address.country,
        label: 'Country',
      },
      agreedToTerms: {
        hasUnsavedChanges:
          formValues.agreedToTerms !== defaultValues.agreedToTerms,
        label: 'Agreed to Terms',
      },
      phone: {
        hasUnsavedChanges: formValues.phone !== defaultValues.phone,
        label: 'Phone',
      },
      email: {
        hasUnsavedChanges: formValues.email !== defaultValues.email,
        label: 'Email',
      },
      dob: {
        hasUnsavedChanges:
          (formValues.dob &&
            isValid(formValues.dob) &&
            formValues.dob.toISOString()) !==
          (defaultValues.dob &&
            isValid(defaultValues.dob) &&
            defaultValues.dob.toISOString()),
        label: 'Date of Birth',
      },
    });
  }, [
    defaultValues.address.city,
    defaultValues.address.country,
    defaultValues.address.line1,
    defaultValues.address.line2,
    defaultValues.address.postalCode,
    defaultValues.address.state,
    defaultValues.agreedToTerms,
    defaultValues.dob,
    defaultValues.email,
    defaultValues.name.first,
    defaultValues.name.last,
    defaultValues.phone,
    formValues.address.city,
    formValues.address.country,
    formValues.address.line1,
    formValues.address.line2,
    formValues.address.postalCode,
    formValues.address.state,
    formValues.agreedToTerms,
    formValues.dob,
    formValues.email,
    formValues.name.first,
    formValues.name.last,
    formValues.phone,
  ]);

  const handleCloseModal = useCallback(
    (props?: { closeModal: () => void }) => {
      if (hasUnsavedChanges) {
        if (showUnsavedChangesWarning) {
          setUnsavedChanges({});
          setShowUnsavedChangesWarning(false);
          props?.closeModal();
        } else {
          setShowUnsavedChangesWarning(true);
        }
      } else {
        setUnsavedChanges({});
        setShowUnsavedChangesWarning(false);
        props?.closeModal();
      }
    },
    [hasUnsavedChanges, showUnsavedChangesWarning],
  );

  const unsavedChangesArray = Object.entries(unsavedChanges)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- not using the key to filter
    .filter(([_key, { hasUnsavedChanges }]) => hasUnsavedChanges)
    .map(([label]) => label);

  useEffect(() => {
    if (getFormFunctions) {
      getFormFunctions({
        onClose: handleCloseModal,
        reset: handleReset,
        unsavedChanges: unsavedChangesArray,
      });
    }
  }, [getFormFunctions, handleCloseModal, handleReset, unsavedChangesArray]);

  const { mutateAsync: createCardholder, isLoading } = useCreateCardholder();
  const [errorData, setErrorData] = useState<{
    duplicateCardholder: {
      dob: string;
      groupId: string;
      name: string;
    } | null;
  }>();

  const {
    openModal: openConfirmPossibleDuplicateModal,
    closeModal: closeConfirmPossibleDuplicateModal,
  } = useConfirmPossibleDuplicateCardholderModal();

  const [selectedGroup, setSelectedGroup] = useState<GroupCleaned | null>(null);
  const [groupSearchInputValue, setGroupSearchInputValue] = useState('');
  const [groupSearchValue, setGroupSearchValue] = useState('');
  const debouncer = useRef(
    debounce((value) => {
      setGroupSearchValue(value);
    }, 1000),
  );
  const handleSetCardholderSearchValue = useCallback(
    (value: string) => debouncer.current(value),
    [],
  );
  const { data: groups, isLoading: isLoadingGroups } = useGetPaginatedData({
    endpointUrl: SERVICE_BENJI_CARD_URLS.GROUPS_GET,
    queryKeyBase: queryKeys.groups._baseKey,
    filters: {
      search: groupSearchValue,
      status: GroupStatus.ACTIVE,
    },
    dataFormatter: cleanGroups,
    requiredPrivileges: [Privilege.cards],
    autoFetchNextPage: false,
  });
  useEffect(() => {
    if (dirtyFields.groupId) {
      trigger('groupId');
    }
  }, [selectedGroup?.id, trigger, dirtyFields.groupId]);

  const onSubmit = async (
    values: FormFields & { skipExistingCardHolder?: boolean },
  ) => {
    const { cardType, ...rest } = values;
    const dob = values.dob
      ? new Date(values.dob).toISOString().split('T')[0]
      : undefined;
    track({
      label: 'Submit create cardholder',
      type: TrackType.action,
      actionType: 'submit',
      cardholderEmail: values.email,
      cardholderFirstName: values.name.first,
      cardholderLastName: values.name.last,
      cardholderStatus: 'active',
      cardholderCreatedAt: new Date().toISOString(),
      isFromCreateCardFlow,
    });
    await createCardholder(
      { ...rest, dob },
      {
        onSuccess: (data) => {
          closeConfirmPossibleDuplicateModal();
          track({
            label: 'Created cardholder',
            type: TrackType.effect,
            isSuccessful: true,
            cardholderEmail: values.email,
            cardholderFirstName: values.name.first,
            cardholderLastName: values.name.last,
            cardholderStatus: 'active',
            cardholderCreatedAt: data.created,
            isFromCreateCardFlow,
          });
          incrementMixpanelProfileData('createCardholderCount');
          setUnsavedChanges({});
          setShowUnsavedChangesWarning(false);
          setToast({
            message: `Cardholder ${
              isFromCreateCardFlow ? 'and Card' : ''
            } Created Successfully`,
            severity: 'success',
          });
          if (!isFromCreateCardFlow) {
            openCardholderModal(data.stripe_cardholder_id);
          }
          onSuccess?.({
            id: data.stripe_cardholder_id,
            cardType: cardType ? cardType : undefined,
          });
        },
        onError: (error) => {
          const errorCode = get(error, 'response.data.code');
          const errorData = get(error, 'response.data.data');

          track({
            label: 'Created cardholder',
            type: TrackType.effect,
            isSuccessful: false,
            cardholderEmail: values.email,
            cardholderFirstName: values.name.first,
            cardholderLastName: values.name.last,
            cardholderStatus: 'active',
            isFromCreateCardFlow,
            errorCode,
          });
          reset(undefined, { keepValues: true });
          if (
            !!errorCode &&
            Object.values(CreateCardholderErrorCode).includes(errorCode)
          ) {
            setErrorData(errorData);

            openConfirmPossibleDuplicateModal();
          } else {
            setToast({
              message: 'Error Creating Cardholder',
              severity: 'error',
            });
          }
        },
      },
    );
  };

  return (
    <FormProvider {...useFormMethods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit, (errors) => {
          () => {
            track({
              label: 'Submit create cardholder',
              type: TrackType.action,
              actionType: 'submit',
              cardholderEmail: formValues.email,
              cardholderFirstName: formValues.name.first,
              cardholderLastName: formValues.name.last,
              cardholderStatus: 'active',
              cardholderCreatedAt: new Date().toISOString(),
              validationErrors: Object.entries(errors).map(([key, value]) => ({
                field: key,
                message: value.message,
              })),
              isFromCreateCardFlow,
            });
          };
        })}
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 683px;
          margin: 0 auto;
        `}
      >
        {features?.groups && (
          <FormSection label="Group">
            <Autocomplete
              id="group-search"
              disablePortal
              options={groups?.results ?? []}
              getOptionKey={({ id }) => id}
              getOptionLabel={(option) =>
                option ? option.name : groupSearchInputValue
              }
              onBlur={() => {
                trigger('groupId');
              }}
              onChange={(_e, value) => {
                setSelectedGroup(value);
                setValue('groupId', value?.id);
              }}
              noOptionsText={
                <>
                  No options.{' '}
                  <Link href={generatePath('/secure/groups/new-group')}>
                    Create New Group
                  </Link>
                </>
              }
              value={selectedGroup}
              onInputChange={(_e, value) => {
                setGroupSearchInputValue(value);
                handleSetCardholderSearchValue(value);
              }}
              inputValue={groupSearchInputValue}
              loading={!!groupSearchValue && isLoadingGroups}
              slotProps={{
                paper: {
                  sx: {
                    maxHeight: '300px',
                    '& *': {
                      maxHeight: '300px',
                    },
                  },
                },
              }}
              css={css`
                width: 100%;
              `}
              // overriding filter options to allow for filtering to happen on BE.
              filterOptions={(x) => x}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Typography variant="body1">{option.name}</Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Group"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {!!groupSearchValue && isLoadingGroups ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  error={errors.groupId ? true : false}
                  helperText={errors.groupId ? errors.groupId.message : null}
                  css={css`
                    fieldset {
                      border-radius: 10px;
                    }
                    .Mui-focused fieldset {
                      box-shadow: 0px 10px 15px 0px rgba(242, 190, 87, 0.19);
                    }
                  `}
                />
              )}
            />
          </FormSection>
        )}
        {isFromCreateCardFlow && (orgCardTypes?.length ?? 0) > 1 && (
          <FormSection label="Card Type">
            <CardTypeRadioCards
              disabled={isSubmitting}
              control={control}
              name="cardType"
              label=" "
              defaultValue={defaultValues.cardType}
              css={css`
                flex: 1 1 100%;
                .MuiFormGroup-root {
                  padding-top: 0;
                }
              `}
            />
          </FormSection>
        )}
        <FormSection label="Name">
          <FormFieldInput<FormFields>
            disabled={isSubmitting}
            control={control}
            name="name.first"
            placeholder="First Name"
            defaultValue={defaultValues.name.first}
            icon={<NameInputIcon />}
            css={[
              inputCss,
              css`
                flex: 1 1 150px;
              `,
            ]}
            rules={{
              required: 'Required',
              pattern: {
                value: noSpecialCharactersOrNumbersRegex,
                message: "Name can't contain numbers or special characters",
              },
            }}
          />
          <FormFieldInput<FormFields>
            disabled={isSubmitting}
            control={control}
            name="name.last"
            placeholder="Last Name"
            icon={<NameInputIcon />}
            defaultValue={defaultValues.name.last}
            css={[
              inputCss,
              css`
                flex: 1 1 150px;
              `,
            ]}
            rules={{
              required: 'Required',
              pattern: {
                value: noSpecialCharactersOrNumbersRegex,
                message: "Name can't contain numbers or special characters",
              },
            }}
          />
        </FormSection>
        <FormSection label="Address">
          <FormFieldInput<FormFields>
            disabled={isSubmitting}
            control={control}
            name="address.line1"
            placeholder="Street"
            defaultValue={defaultValues.address.line1}
            icon={<AddressInputIcon />}
            css={[
              inputCss,
              css`
                flex: 1 1 100%;
              `,
            ]}
            rules={{
              required: 'Required',
            }}
          />
          <FormFieldInput<FormFields>
            disabled={isSubmitting}
            control={control}
            name="address.line2"
            placeholder="Street 2"
            defaultValue={defaultValues.address.line2 ?? ''}
            icon={<AddressInputIcon />}
            css={[
              inputCss,
              css`
                flex: 1 1 100%;
              `,
            ]}
          />

          <FormFieldInput<FormFields>
            disabled={isSubmitting}
            control={control}
            name="address.city"
            placeholder="City"
            defaultValue={defaultValues.address.city}
            css={[
              inputCss,
              css`
                flex: 1 1 200px;
              `,
            ]}
            rules={{
              required: 'Required',
            }}
          />
          <FormFieldSelect<FormFields>
            disabled={isSubmitting}
            control={control}
            name="address.state"
            placeholder="State"
            defaultValue={defaultValues.address.state}
            css={[
              inputCss,
              css`
                flex: 1 1 200px;
              `,
            ]}
            className="highlight-block"
            options={stateOptions}
            rules={{
              required: 'Required',
            }}
          />
          <FormFieldInput<FormFields>
            disabled={isSubmitting}
            control={control}
            name="address.postalCode"
            placeholder="Postal Code"
            defaultValue={defaultValues.address.postalCode}
            css={[
              inputCss,
              css`
                flex: 1 1 200px;
              `,
            ]}
            rules={{
              // add masking for validation for zip code
              required: 'Required',
            }}
          />
          <FormFieldInput<FormFields>
            disabled
            control={control}
            name="address.country"
            placeholder="Country"
            defaultValue={defaultValues.address.country}
            css={[
              inputCss,
              css`
                flex: 1 1 200px;
                fieldset {
                  border-color: ${COLOR_PALETTE.border}!important;
                }
              `,
            ]}
            rules={{
              required: 'Required',
            }}
          />
        </FormSection>
        <FormSection
          label="Member Info"
          css={css`
            border-bottom: initial;
          `}
        >
          <FormFieldInput<FormFields>
            disabled={isSubmitting}
            control={control}
            name="email"
            type="email"
            placeholder="Email"
            defaultValue={defaultValues.email ?? ''}
            icon={<EmailInputIcon />}
            css={[
              inputCss,
              css`
                flex: 1 1 200px;
              `,
            ]}
            rules={{
              // add validation for email

              required: 'Required',
            }}
          />
          <FormFieldInput<FormFields>
            // TODO: add masking for phone number
            disabled={isSubmitting}
            control={control}
            name="phone"
            type="tel"
            placeholder="Phone Number"
            defaultValue={defaultValues.phone ?? ''}
            icon={<PhoneInputIcon />}
            css={[
              inputCss,
              css`
                flex: 1 1 200px;
              `,
            ]}
            rules={{
              // add validation for phone number
              required: 'Required',
            }}
          />
          <FormFieldInput<FormFields>
            disabled={isSubmitting}
            control={control}
            name="dob"
            type="date"
            placeholder="Date of Birth"
            defaultValue={defaultValues.dob ?? null}
            icon={<DateInputIcon />}
            css={[
              inputCss,
              css`
                flex: 1 1 200px;
              `,
            ]}
            rules={{
              // TODO: is there any min age validation?
              required: 'Required',
            }}
          />
          <Box
            css={css`
              flex: 1 1 200px;
            `}
          />
        </FormSection>

        {hasUnsavedChanges && showUnsavedChangesWarning && (
          <Alert
            severity="warning"
            css={css`
              flex: 1 1 100%;
            `}
          >
            You have unsaved changes:{' '}
            {Object.values(unsavedChanges)
              .filter(({ hasUnsavedChanges }) => hasUnsavedChanges)
              .map(({ label }) => label)
              .join(', ')}
            . Are you sure you want to leave? Click again to leave without
            saving.
          </Alert>
        )}
        <FormFieldCheckbox<FormFields>
          name="agreedToTerms"
          rules={{
            required: 'Required',
          }}
          label={
            <Typography
              css={css`
                a {
                  color: ${COLOR_PALETTE.blueLink};
                  text-decoration: none;
                }
              `}
            >
              I agree to the terms and conditions outlined in the{' '}
              <Link
                href="https://stripe.com/legal/e-sign-disclosure"
                target="_blank"
              >
                Stripe E-SIGN Disclosure
              </Link>{' '}
              and{' '}
              <Link
                href="https://stripe.com/legal/issuing/celtic-authorized-user-terms"
                target="_blank"
              >
                Stripe Issuing: Authorized User Terms - Celtic Bank
              </Link>{' '}
              documents.
            </Typography>
          }
          defaultValue={defaultValues.agreedToTerms}
          control={control}
          css={css`
            margin-top: 20px;
          `}
        />
        {children({ isSubmitting, disabled: isLoadingUser })}
      </Box>
      <ConfirmPossibleDuplicateCardholderModal
        errorData={errorData}
        onSubmit={() =>
          onSubmit({ ...formValues, skipExistingCardHolder: true })
        }
        isSubmitting={isSubmitting || isLoading}
      />
    </FormProvider>
  );
};
