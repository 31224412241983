import { css } from '@emotion/react';
import { Global } from '@emotion/react';
import { Alert, Box, Button, Link, Typography } from '@mui/material';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ScaleInnerComponentToFitWidth } from 'src/components/ScaleInnerComponentToFitWidth';
import {
  TrackContext,
  TrackType,
  TrackingContextProvider,
  useTrack,
} from 'src/context';
import { COLOR_PALETTE } from 'src/theme';

import { CreditCardPreview } from './CreditCardPreview';
import { useGetCardEphemeralKey } from './useGetCardEphemeralKey';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_API_KEY ?? '',
).then((stripe) => {
  return stripe;
});

const IFrameViewCardTrackingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const { cardId } = useParams();
  return (
    <TrackingContextProvider
      trackingProps={{
        cardId,
      }}
      contextName={TrackContext['iframe-card']}
      {...props}
    />
  );
};

const useStripeNonce = ({ cardId }: { cardId?: string }) => {
  const stripe = useStripe();
  const [nonce, setNonce] = useState<string>();
  useEffect(() => {
    if (stripe && cardId) {
      stripe
        .createEphemeralKeyNonce({
          issuingCard: cardId,
        })
        .then((result) => {
          setNonce(result?.nonce);
        });
    }
  }, [cardId, stripe]);
  return { nonce };
};

const ViewIFrameCardBase = () => {
  const { track } = useTrack();
  const { token, cardId } = useParams();
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState<undefined | true>();
  const { nonce } = useStripeNonce({ cardId: cardId });
  const { data, isLoading } = useGetCardEphemeralKey({
    token,
    cardId,
    hasAgreedToTerms,
    nonce,
  });

  if (isLoading) {
    return <CreditCardPreview isLoading />;
  }

  if (data && 'needsToAgree' in data && data.needsToAgree) {
    return (
      <Box
        css={css`
          display: flex;
          align-items: center;
          flex: 1 1 auto;
          padding: 16px;
          background-color: #0e0e0e;
        `}
      >
        <Box
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 8px;
            background-color: #fff;
            padding: 16px;
            border-radius: 8px;
          `}
        >
          <Typography>
            By viewing this card, I agree to use this card as outlined in the{' '}
            <Link
              href="https://stripe.com/legal/e-sign-disclosure"
              target="_blank"
            >
              Stripe E-SIGN Disclosure
            </Link>{' '}
            and{' '}
            <Link
              href="https://stripe.com/legal/issuing/celtic-authorized-user-terms"
              target="_blank"
            >
              Stripe Issuing: Authorized User Terms - Celtic Bank
            </Link>{' '}
            documents.
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              track({
                label: 'Clicked view card',
                type: TrackType.action,
                actionType: 'click',
              });
              setHasAgreedToTerms(true);
            }}
          >
            View Card
          </Button>
        </Box>
      </Box>
    );
  }

  if (data && 'ephemeralKeySecret' in data && data.ephemeralKeySecret) {
    return (
      <CreditCardPreview
        cardholder={data.cardholderName}
        cardId={cardId}
        ephemeralKeySecret={data.ephemeralKeySecret}
        nonce={nonce}
      />
    );
  }

  return (
    <Box
      css={css`
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        padding: 16px;
      `}
    >
      <Alert severity="warning">
        Unable to load card. Please refresh the page and try again.
      </Alert>
    </Box>
  );
};

export const ViewIFrameCard = () => {
  return (
    <Elements stripe={stripePromise}>
      <IFrameViewCardTrackingContextProvider>
        <Box
          css={css`
            max-width: 100vw;
          `}
        >
          <ScaleInnerComponentToFitWidth minWidthBeforeScaling={320}>
            <Box
              css={css`
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                max-width: 320px;
                width: 100%;
                height: 264px;
                margin: 0 auto;
                border-radius: 12px;
                background-color: #ffffff;
              `}
            >
              <ViewIFrameCardBase />
              <Typography
                variant="caption"
                css={css`
                  position: sticky;
                  left: 0;
                  bottom: 0;
                  display: block;
                  width: 100%;
                  font-weight: 300;
                  font-size: 10px;
                  line-height: 10px;
                  background-color: #ffffff;
                  border-radius: 0 0 12px 12px;
                  padding: 4px 6px;
                  color: #505050;
                  a {
                    color: #000000;
                    font-weight: 300;
                    text-decoration: none;
                  }
                `}
              >
                By using this virtual credit card, I acknowledge that the card
                is solely to pay for the healthcare expenses it is designated
                for up to the spend limit amount. I agree to use this card as
                intended and as outlined in the{' '}
                <Link
                  href="https://stripe.com/legal/e-sign-disclosure"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stripe E-SIGN Disclosure
                </Link>{' '}
                and{' '}
                <Link
                  href="https://stripe.com/legal/issuing/celtic-authorized-user-terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stripe Issuing: Authorized User Terms - Celtic Bank
                </Link>{' '}
                documents.
                <Box
                  css={css`
                    width: 100%;
                    border-top: 1px solid ${COLOR_PALETTE.border};
                    margin: 4px 0 2px;
                  `}
                />
                Benji Card Visa® Commercial Credit cards are powered by Stripe
                and issued by Celtic Bank.
              </Typography>
            </Box>
          </ScaleInnerComponentToFitWidth>
        </Box>
        <Global
          styles={css`
            body {
              background-color: transparent;
            }
            [aria-label='Open React Query Devtools'] {
              display: none !important;
            }
          `}
        />
      </IFrameViewCardTrackingContextProvider>
    </Elements>
  );
};
