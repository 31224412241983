import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';

import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import { useAxios } from './useAxios';
import { useGetUserPrivileges } from './useGetUserPrivileges';

interface AccountOverview {
  accumulatedRemainingSpendLimitCents: number;
  availableBalanceCents: number;
  cardsWithRemainingSpendLimit: number;
  staleCards: number;
}

export const useGetAccountOverview = () => {
  const { axios, generateSecureHeaders } = useAxios();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getAccountOverview = async () => {
    const { data } = await axios.get<AccountOverview>(
      SERVICE_BENJI_CARD_URLS.ACCOUNT_GET_OVERVIEW,
      {
        headers: await generateSecureHeaders(),
      },
    );

    return data;
  };

  return useQuery({
    queryKey: queryKeys.balance.current(),
    queryFn: () => getAccountOverview(),
    enabled: !!isSignedIn && !!privileges?.balance,
  });
};
