import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { generatePath } from 'react-router-dom';

import { SERVICE_BENJI_CARD_URLS, formatDateToMST, queryKeys } from 'src/utils';

import { useAxios } from './useAxios';
import { useGetUserPrivileges } from './useGetUserPrivileges';

import type {
  AuthorizationDetailsCleaned,
  AuthorizationDetailsRaw,
  TransactionDetailsCleaned,
  TransactionDetailsRaw,
  TransactionAuthCombinedRaw,
  TransactionRequestReasonEnum,
} from 'src/types';

const transactionRequestReasonMessage: Record<
  TransactionRequestReasonEnum,
  string
> = {
  account_disabled:
    'The authorization request was declined because your account is disabled.',
  spending_controls:
    'The authorization was declined because of your spending controls.',
  authorization_controls:
    'The authorization was declined because of your spending controls.',
  card_active: 'The authorization was approved.',
  card_inactive:
    'The authorization request was declined because the card was inactive.',
  cardholder_inactive:
    'The authorization request was declined because the cardholder was inactive.',
  insufficient_funds:
    'The authorization request was declined because your account had insufficient funds.',
  cardholder_verification_required:
    'The authorization was not approved because the cardholder still required verification.',
  not_allowed:
    'The charge is not allowed, possibly because it is an ATM withdrawal or cash advance.',
  webhook_approved: 'The authorization was approved.',
  webhook_declined: 'The authorization request was declined.',
  webhook_timeout: 'The authorization request timed out.',
  webhook_error: 'The authorization request failed due to an error.',
  verification_failed: 'The authorization failed required verification checks.',
};
export const useGetAuthorizationOrTransaction = ({
  id,
  type,
}: {
  id: string;
  type: TransactionAuthCombinedRaw['entity_type'];
}) => {
  const { axios, generateSecureHeaders } = useAxios();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getAuthorizationOrTransaction = async ({
    id,
    type,
  }: {
    id: string;
    type: TransactionAuthCombinedRaw['entity_type'];
  }) => {
    if (type === 'authorization') {
      const { data: authorization } = await axios.get<AuthorizationDetailsRaw>(
        `${generatePath(SERVICE_BENJI_CARD_URLS.AUTHORIZATION_GET_DETAILS, {
          authorizationId: id,
        })}`,
        {
          headers: await generateSecureHeaders(),
        },
      );
      const mostRecentRequestHistory = (
        authorization.request_history ?? []
      ).sort((a, b) => b.created - a.created)[0];
      const prettyDisplayDate = formatDateToMST(
        new Date(authorization.created ?? ''),
      );
      return {
        id: authorization.id,
        stripeId: authorization.stripe_authorization_id,
        cardId: authorization.card_id,
        cardholderId: authorization.cardholder_id,
        authorizationDate: authorization.created,
        prettyDisplayDate,
        type: 'authorization',
        merchantName: authorization.merchant_data.name,
        amountCents: authorization.amount,
        status: authorization.status,
        paymentMethod: `****${authorization.card_last4} ${authorization.cardholderName}`,
        cardLabel: `****${authorization.card_last4}`,
        cardholderFullName: authorization.cardholderName,
        requestHistoryStatus: mostRecentRequestHistory?.reason,
        requestHistoryMessage: get(
          transactionRequestReasonMessage,
          mostRecentRequestHistory?.reason ?? '',
          'The authorization request failed.',
        ),
        createdAt: authorization.created,
      } as AuthorizationDetailsCleaned;
    } else {
      const { data: transaction } = await axios.get<TransactionDetailsRaw>(
        `${generatePath(SERVICE_BENJI_CARD_URLS.TRANSACTION_GET_DETAILS, {
          transactionId: id,
        })}`,
        {
          headers: await generateSecureHeaders(),
        },
      );
      const displayDate =
        transaction.type === 'refund'
          ? transaction.processed_date
          : transaction.authorization_date;
      const prettyDisplayDate = formatDateToMST(new Date(displayDate));
      return {
        id: transaction.id,
        stripeId: transaction.stripe_transaction_id,
        cardId: transaction.card_id,
        cardholderId: transaction.cardholder_id,
        authorizationDate: transaction.authorization_date,
        prettyDisplayDate,
        type: 'transaction',
        merchantName: transaction.merchant_data.name,
        amountCents: transaction.amount,
        status: transaction.status,
        paymentMethod: `****${transaction.card_last4} ${transaction.cardholder_name}`,
        cardLabel: `****${transaction.card_last4}`,
        cardholderFullName: transaction.cardholder_name,
        disputeId: transaction.dispute_stripe_id,
        createdAt: transaction.created,
      } as TransactionDetailsCleaned;
    }
  };

  return useQuery({
    queryKey: queryKeys.transactions.details({
      id,
      type,
    }),
    queryFn: () =>
      getAuthorizationOrTransaction({
        id,
        type,
      }),
    enabled: !!isSignedIn && !!privileges?.transactions && !!id && !!type,
  });
};
