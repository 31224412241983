import { css } from '@emotion/react';
import { Box, Button } from '@mui/material';

import { Logo } from 'src/components';
import { BackIcon } from 'src/components/Icon';
import { COLOR_PALETTE } from 'src/theme';

import fadedGridBackgroundYellow from './faded-grid-background-yellow.png';
export const LogoSection = ({ onBack }: { onBack?: () => void }) => {
  return (
    <Box
      css={css`
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        align-items: flex-end;
        padding-top: 44px;
        width: 100%;
        background-image: url(${fadedGridBackgroundYellow});
        background-position: top center;
        background-repeat: no-repeat;
        padding-bottom: 124px;
        @media (max-width: 979px) {
          padding-bottom: 24px;
        }
      `}
    >
      {!!onBack && (
        <Box
          css={css`
            display: flex;
            flex: 0 0 88px;
            padding-left: 40px;
            @media (max-width: 360px) {
              padding-top: 16px;
              padding-left: 16px;
              flex: 1 1 100%;
            }
          `}
        >
          <Button
            onClick={onBack}
            css={css`
              height: 48px;
              width: 48px;
              min-width: initial;
              background-color: ${COLOR_PALETTE.backButtonBackground};
              &:hover {
                background-color: ${COLOR_PALETTE.backButtonBackgroundHover};
              }
            `}
          >
            <BackIcon />
          </Button>
        </Box>
      )}
      <Logo
        css={css`
          width: 116px;
        `}
      />
      {!!onBack && (
        <Box
          css={css`
            flex: 0 0 88px;
            @media (max-width: 360px) {
              flex: 1 1 100%;
            }
          `}
        />
      )}
    </Box>
  );
};
