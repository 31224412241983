import { SignUp, useAuth } from '@clerk/clerk-react';
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { COLOR_PALETTE } from 'src/theme';

import { LogoSection } from './LogoSection';

export const NewSubUserSignUpPage = () => {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');

  const { isSignedIn, signOut } = useAuth();

  useEffect(() => {
    if (isSignedIn) {
      signOut();
    }
  }, [isSignedIn, signOut]);

  return (
    <>
      <LogoSection />

      <Box
        css={css`
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          width: 100%;
          max-width: 450px;
          padding: 0 32px 60px;
          .cl-signIn-havingTrouble .cl-button {
            color: ${COLOR_PALETTE.textOnLight};
            min-height: 46px;
            border-radius: 100px;
            background-color: ${COLOR_PALETTE.primary};
            font-size: 15px;
            font-weight: 500;
            padding: 12px 16px;
            border: none;
            box-shadow: rgba(242, 190, 87, 0.26) 0px 6px 10px 4px !important;
            &:hover {
              box-shadow: none !important;
              background-color: rgb(171, 148, 92);
            }
            &:active {
              box-shadow: none !important;
            }
            &:focus {
              box-shadow: none !important;
            }
          }
        `}
      >
        <SignUp
          routing="virtual"
          forceRedirectUrl={redirectUrl}
          appearance={{
            layout: {
              unsafe_disableDevelopmentModeWarnings: true,
            },
            elements: {
              rootBox: {
                width: '100%',
              },
              cardBox: {
                width: '100%',
                boxShadow: 'none',
              },
              card: {
                padding: '8px 8px 24px',
                boxShadow: 'none',
                borderRadius: '0',
              },
              logoBox: {
                display: 'none',
              },
              input: {
                minHeight: '48px',
                borderRadius: '10px',
                border: '1px solid rgb(231, 231, 231)!important',
                '&:hover': {
                  borderColor: 'rgba(0, 0, 0, 0.4)!important',
                },
                '&:focus': {
                  borderColor: `${COLOR_PALETTE.primary}!important`,
                },
                '&:active': {
                  borderColor: `${COLOR_PALETTE.primary}!important`,
                },
              },
              formFieldInputShowPasswordIcon: {
                color: COLOR_PALETTE.textOnLight,
              },
              alternativeMethodsBlockButton: {
                color: COLOR_PALETTE.textOnLight,
                minHeight: '46px',
                borderRadius: '100px',
                backgroundColor: COLOR_PALETTE.primary,
                fontSize: '15px',
                fontWeight: 500,
                padding: '12px 16px',
                border: 'none',
                boxShadow:
                  'rgba(242, 190, 87, 0.26) 0px 6px 10px 4px!important',
                '&:hover': {
                  boxShadow: 'none!important',
                  backgroundColor: 'rgb(171, 148, 92)',
                },
                '&:active': {
                  boxShadow: 'none!important',
                },
                '&:focus': {
                  boxShadow: 'none!important',
                },
              },
              formButtonPrimary: {
                color: COLOR_PALETTE.textOnLight,
                minHeight: '46px',
                borderRadius: '100px',
                backgroundColor: COLOR_PALETTE.primary,
                fontSize: '15px',
                fontWeight: 500,
                padding: '12px 16px',
                border: 'none',
                boxShadow:
                  'rgba(242, 190, 87, 0.26) 0px 6px 10px 4px!important',
                '&:hover': {
                  boxShadow: 'none!important',
                  backgroundColor: 'rgb(171, 148, 92)',
                },
                '&:active': {
                  boxShadow: 'none!important',
                },
                '&:focus': {
                  boxShadow: 'none!important',
                },
              },
              footerActionLink: {
                color: COLOR_PALETTE.textOnLight,
              },
              backLink: {
                color: COLOR_PALETTE.textOnLight,
              },
              otpCodeFieldInput: {
                border: `1px solid ${COLOR_PALETTE.border}!important`,
              },
              footer: {
                background: 'none',
              },
            },
          }}
        />
      </Box>
    </>
  );
};
