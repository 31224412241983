import { SvgIcon } from '@mui/material';

export const CreateUserIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 8">
          <g id="Vector (Stroke)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.80755 4.08294C3.56159 3.32889 4.5843 2.90527 5.65068 2.90527C6.71706 2.90527 7.73977 3.32889 8.49381 4.08294C9.24786 4.83698 9.67148 5.85969 9.67148 6.92607C9.67148 7.99245 9.24786 9.01516 8.49381 9.7692C7.73977 10.5232 6.71706 10.9469 5.65068 10.9469C4.5843 10.9469 3.56159 10.5232 2.80755 9.7692C2.0535 9.01516 1.62988 7.99245 1.62988 6.92607C1.62988 5.85969 2.0535 4.83698 2.80755 4.08294ZM5.65068 4.42835C4.98824 4.42835 4.35294 4.6915 3.88453 5.15992C3.41611 5.62833 3.15296 6.26363 3.15296 6.92607C3.15296 7.58851 3.41611 8.22381 3.88453 8.69223C4.35294 9.16064 4.98824 9.42379 5.65068 9.42379C6.31312 9.42379 6.94842 9.16064 7.41684 8.69223C7.88525 8.22381 8.1484 7.58851 8.1484 6.92607C8.1484 6.26363 7.88525 5.62833 7.41684 5.15992C6.94842 4.6915 6.31312 4.42835 5.65068 4.42835Z"
              fill="#262626"
            />
            <path
              d="M3.88453 5.15992C4.35294 4.6915 4.98824 4.42835 5.65068 4.42835C6.31312 4.42835 6.94842 4.6915 7.41684 5.15992C7.88525 5.62833 8.1484 6.26363 8.1484 6.92607C8.1484 7.58851 7.88525 8.22381 7.41684 8.69223C6.94842 9.16064 6.31312 9.42379 5.65068 9.42379C4.98824 9.42379 4.35294 9.16064 3.88453 8.69223C3.41611 8.22381 3.15296 7.58851 3.15296 6.92607C3.15296 6.26363 3.41611 5.62833 3.88453 5.15992Z"
              fill="#262626"
            />
          </g>
          <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5403 19.0007H0.762515H0.595825L0.599702 18.9832C0.257346 18.9087 0.000976563 18.6039 0.000976563 18.2391L0.000976563 16.7044C0.000976563 15.638 0.424595 14.6153 1.17864 13.8613C1.93269 13.1072 2.95539 12.6836 4.02177 12.6836L7.28103 12.6836C8.34742 12.6836 9.37012 13.1072 10.1242 13.8613C10.8782 14.6153 11.3018 15.638 11.3018 16.7044L11.3018 18.2391C11.3018 18.6411 10.9904 18.9704 10.5956 18.9987L10.5958 19.0007H10.5403Z"
            fill="#262626"
          />
          <path
            id="Vector (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6168 3.58455C10.7211 3.17711 11.1359 2.93138 11.5434 3.0357C12.4083 3.25715 13.1749 3.76015 13.7223 4.46541C14.2697 5.17066 14.5669 6.03806 14.5669 6.93085C14.5669 7.82364 14.2697 8.69103 13.7223 9.39629C13.1749 10.1015 12.4083 10.6045 11.5434 10.826C11.1359 10.9303 10.7211 10.6846 10.6168 10.2771C10.5124 9.8697 10.7582 9.45484 11.1656 9.35052C11.7029 9.21295 12.1791 8.90049 12.5191 8.46238C12.8592 8.02427 13.0438 7.48545 13.0438 6.93085C13.0438 6.37625 12.8592 5.83742 12.5191 5.39931C12.1791 4.96121 11.7029 4.64874 11.1656 4.51118C10.7582 4.40686 10.5124 3.99199 10.6168 3.58455Z"
            fill="#262626"
          />
          <g id="Group 225">
            <path
              id="Vector 59"
              d="M16.001 3.5L21.001 3.5"
              stroke="#262626"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              id="Vector 60"
              d="M18.501 6V1"
              stroke="#262626"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </g>
          <path
            id="Vector (Stroke)_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.248 13.376C12.3531 12.9688 12.7685 12.7239 13.1757 12.8291C14.035 13.0509 14.7967 13.5508 15.3422 14.2508C15.8877 14.9508 16.1862 15.8116 16.1913 16.6991L16.1914 16.7034H16.1913V18.3331C16.1913 18.7537 15.8504 19.0946 15.4298 19.0946C15.0092 19.0946 14.6683 18.7537 14.6683 18.3331V16.7058C14.6646 16.1552 14.4792 15.6213 14.1408 15.187C13.802 14.7521 13.3288 14.4416 12.795 14.3038C12.3878 14.1986 12.1429 13.7833 12.248 13.376Z"
            fill="#262626"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
