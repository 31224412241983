import { css } from '@emotion/react';
import { ArrowDropDownOutlined as ArrowDropDownOutlinedIcon } from '@mui/icons-material';
import { Box, FormLabel, InputAdornment, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, isBefore } from 'date-fns';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { COLOR_PALETTE } from 'src/theme';
import { MSTDateStringToDateObject } from 'src/utils/date-to-mst';

import type { DateView } from '@mui/x-date-pickers';
import type {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

interface FormFieldDateRangePropsBase<FormFields extends FieldValues> {
  name: FieldPath<FormFields>;
  label: React.ReactNode;
  control: Control<FormFields>;
  defaultValue: FieldPathValue<FormFields, FieldPath<FormFields>>;
  className?: string;
  icon?: React.ReactNode;
  rules?: Omit<
    RegisterOptions<FormFields>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  disabled?: boolean;
  onBlur?: () => void;
  views?: DateView[];
  openTo?: DateView;
  tabIndex?: number;
  disableFuture?: boolean;
}

export function FormFieldDateRange<FormFields extends FieldValues>({
  name,
  label,
  control,
  defaultValue,
  className,
  rules,
  disabled,
  onBlur,
  icon,
  views,
  openTo,
  tabIndex,
  disableFuture,
}: FormFieldDateRangePropsBase<FormFields>) {
  const { formState } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{
        validate: (value) => {
          const startDate = value.startDate ? new Date(value.startDate) : null;
          const endDate = value.endDate ? new Date(value.endDate) : null;
          const isEndDateBeforeStartState =
            !!startDate && !!endDate && isBefore(endDate, startDate);
          if (isEndDateBeforeStartState) {
            return 'End date must be after start date';
          }
          return true;
        },
        ...rules,
      }}
      render={({ field }) => {
        const value = field.value || { startDate: null, endDate: null };
        const error = get(formState.errors, field.name);
        return (
          <Box
            css={css`
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              align-content: flex-start;
              gap: 4px 8px;
            `}
            className={className}
          >
            <FormLabel
              css={css`
                font-size: 12px;
                font-weight: 500;
                width: 100%;
              `}
              tabIndex={tabIndex}
            >
              {label}
            </FormLabel>
            <DatePicker
              disabled={formState.isSubmitting || disabled}
              value={
                value.startDate
                  ? MSTDateStringToDateObject(value.startDate)
                  : null
              }
              name={`${field.name}.startDate`}
              onChange={(newValue) => {
                field.onChange({
                  ...value,
                  startDate: newValue ? format(newValue, 'yyyy-MM-dd') : null,
                });
              }}
              views={views}
              openTo={openTo}
              css={css`
                flex: 1 1 90px;
                .Mui-focused input {
                  color: inherit;
                  padding-right: 4px;
                }
                .MuiInputAdornment-root .MuiButtonBase-root {
                  padding: 0;
                  margin-right: -8px;
                }
                .MuiInputAdornment-positionEnd {
                  margin-left: 0;
                }
              `}
              onAccept={() => {
                field.onBlur();
                onBlur?.();
              }}
              slots={{
                openPickerIcon: ArrowDropDownOutlinedIcon,
              }}
              slotProps={{
                actionBar: {
                  actions: ['clear', 'accept'],
                },
                textField: {
                  onBlur: () => {
                    field.onBlur();
                    onBlur?.();
                  },
                  placeholder: 'Start Date',
                  error: !!error,
                  InputProps: {
                    startAdornment: icon ? (
                      <InputAdornment
                        position="start"
                        css={css`
                          svg {
                            height: 18px;
                            width: 18px;
                          }
                        `}
                      >
                        {icon}
                      </InputAdornment>
                    ) : undefined,
                  },
                },
              }}
              disableFuture={disableFuture}
            />
            <Typography
              css={css`
                align-self: center;
              `}
            >
              -
            </Typography>
            <DatePicker
              disabled={formState.isSubmitting || disabled}
              value={
                value.endDate ? MSTDateStringToDateObject(value.endDate) : null
              }
              name={`${field.name}.endDate`}
              onChange={(newValue) => {
                field.onChange({
                  ...value,
                  endDate: newValue ? format(newValue, 'yyyy-MM-dd') : null,
                });
              }}
              views={views}
              openTo={openTo}
              css={css`
                flex: 1 1 90px;
                .Mui-focused input {
                  color: inherit;
                  padding-right: 4px;
                }
                .MuiInputAdornment-root .MuiButtonBase-root {
                  padding: 0;
                  margin-right: -8px;
                }
                .MuiInputAdornment-positionEnd {
                  margin-left: 0;
                }
              `}
              onAccept={() => {
                field.onBlur();
                onBlur?.();
              }}
              slots={{
                openPickerIcon: ArrowDropDownOutlinedIcon,
              }}
              slotProps={{
                actionBar: {
                  actions: ['clear', 'accept'],
                },
                textField: {
                  onBlur: () => {
                    field.onBlur();
                    onBlur?.();
                  },
                  placeholder: 'End Date',
                  error: !!error,
                },
              }}
              disableFuture={disableFuture}
            />
            {error ? (
              <Typography
                css={css`
                  font-size: 12px;
                  color: ${COLOR_PALETTE.error};
                  flex: 1 1 100%;
                `}
              >
                {error.message as string}
              </Typography>
            ) : null}
          </Box>
        );
      }}
    />
  );
}
