import { css } from '@emotion/react';
import { HighlightOffOutlined as HighlightOffOutlinedIcon } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';

import { DataRow } from 'src/components';
import { CheckCircleIcon } from 'src/components/Icon';
import { useGetUserPrivileges } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { useGetUserPrivilegesLabels } from 'src/types/user';

export const UserPermissionsCard = () => {
  const { privileges } = useGetUserPrivileges();
  const { userPrivilegesLabel } = useGetUserPrivilegesLabels();

  return (
    <Card
      css={css`
        flex: 1 1 430px;
        position: relative;
      `}
    >
      <CardContent
        css={css`
          flex: 1 1 100%;
          padding: 18px;
          display: flex;
          flex-wrap: wrap;
          gap: 18px;
        `}
      >
        <Box
          css={css`
            display: flex;
            width: 100%;
          `}
        >
          <Box
            css={css`
              display: flex;
              width: 100%;
              padding-right: 30px;
              padding-bottom: 16px;
              border-bottom: 1px dashed ${COLOR_PALETTE.border};
            `}
          >
            <Typography
              component="h2"
              css={css`
                font-size: 18px;
                font-weight: 700;
                line-height: normal;
              `}
            >
              User Permissions
            </Typography>
          </Box>
        </Box>
        <Box
          css={css`
            flex: 1 1 400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          `}
        >
          {Object.keys(Privilege)
            .filter((privilege) => privileges?.[privilege as Privilege])
            .map((privilege) => (
              <DataRow
                key={privilege}
                label={userPrivilegesLabel[privilege as Privilege]}
                value={
                  privileges?.[privilege as Privilege] ? (
                    <CheckCircleIcon
                      css={css`
                        height: 16px;
                        width: 16px;
                      `}
                    />
                  ) : (
                    <HighlightOffOutlinedIcon
                      color="disabled"
                      css={css`
                        height: 16px;
                        width: 16px;
                      `}
                    />
                  )
                }
                variation="longLabels"
              />
            ))}
        </Box>
      </CardContent>
    </Card>
  );
};
