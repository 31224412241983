import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { lowerCase } from 'lodash';
import { generatePath } from 'react-router-dom';

import { SERVICE_BENJI_CARD_URLS, formatDateToMST, queryKeys } from 'src/utils';

import { useAxios } from './useAxios';
import { useGetUserPrivileges } from './useGetUserPrivileges';

import type {
  FundsTransferDetailsCleaned,
  FundsTransferDetailsRaw,
} from 'src/types/funds-transfers';

export const useGetBalanceTransfer = ({ id }: { id: string }) => {
  const { axios, generateSecureHeaders } = useAxios();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getBalanceTransfer = async ({ id }: { id: string }) => {
    const { data: balanceTransfer } = await axios.get<FundsTransferDetailsRaw>(
      `${generatePath(
        SERVICE_BENJI_CARD_URLS.BALANCE_GET_BALANCE_HISTORY_DETAILS,
        {
          balanceTransferId: id,
        },
      )}`,
      {
        headers: await generateSecureHeaders(),
      },
    );
    const prettyCreatedAtDate = formatDateToMST(
      new Date(balanceTransfer.created),
    );
    const status = lowerCase(balanceTransfer.status);

    return {
      id: balanceTransfer.id,
      stripeId: balanceTransfer.stripe_id,
      createdAt: balanceTransfer.created,
      prettyCreatedAtDate,
      amountCents: balanceTransfer.amount,
      status,
      type:
        balanceTransfer.amount > 0
          ? 'External Transfer'
          : balanceTransfer.stripe_id.charAt(0) == 'p'
          ? 'Payout To Bank'
          : 'Spend',
    } as FundsTransferDetailsCleaned;
  };

  return useQuery({
    queryKey: queryKeys.balance.transferHistoryDetails({
      id,
    }),
    queryFn: () =>
      getBalanceTransfer({
        id,
      }),
    enabled: !!isSignedIn && !!privileges?.transactions && !!id,
  });
};
