import { formatDateToMST } from 'src/utils';

import type { SendCardRecordRaw, SendCardRecordCleaned } from 'src/types';
import type {
  CardSpendLimitHistoryCleaned,
  CardSpendLimitHistoryRaw,
} from 'src/types/cards';

export const cleanSendCardRecord = (rawSendCardRecords: SendCardRecordRaw[]) =>
  rawSendCardRecords.reduce((acc, sendCardRecord) => {
    const prevTransaction = acc[acc.length - 1];
    const prettySentDate = formatDateToMST(new Date(sendCardRecord.created_at));
    return [
      ...acc,
      {
        id: sendCardRecord.id,
        cardId: sendCardRecord.card_id,
        visited: sendCardRecord.visited,
        recepientEmail: sendCardRecord.email,
        prettySentDate,
        sentDate: sendCardRecord.created_at,
        type: sendCardRecord.type,

        member: {
          name: {
            first: sendCardRecord.member_first_name,
            last: sendCardRecord.member_last_name,
            full: `${sendCardRecord.member_first_name} ${sendCardRecord.member_last_name}`,
          },
          dob: sendCardRecord.member_birth_date,
        },
        serviceDate: sendCardRecord.service_date,
        providerName: sendCardRecord.provider_name,

        isSendDateSameAsPrev:
          prevTransaction?.prettySentDate === prettySentDate,
      } as SendCardRecordCleaned,
    ];
  }, [] as SendCardRecordCleaned[]);

export const cleanCardSpendLimitHistory = (
  rawCardSpendLimitHistory: CardSpendLimitHistoryRaw[],
) =>
  rawCardSpendLimitHistory.reduce((acc, spendLimitHistoryItem) => {
    const prettyCreatedAtDate = formatDateToMST(
      new Date(spendLimitHistoryItem.created),
    );

    return [
      ...acc,
      {
        id: spendLimitHistoryItem.id,
        cardId: spendLimitHistoryItem.card_id,
        spendLimitCents: spendLimitHistoryItem.spend_limit_cents,
        changedAmountCents: spendLimitHistoryItem.change_amount_cents,
        createdAt: spendLimitHistoryItem.created,
        createdAtPrettyDate: prettyCreatedAtDate,
        createdByInternalId: spendLimitHistoryItem.created_by_internal,
        createdByExternalId: spendLimitHistoryItem.created_by_external,
        createdByName: spendLimitHistoryItem.created_by_internal
          ? [
              spendLimitHistoryItem.user?.first_name,
              spendLimitHistoryItem.user?.last_name,
            ]
              .filter(Boolean)
              .join(' ')
          : `${spendLimitHistoryItem.created_by_external} (via API)`,
      } as CardSpendLimitHistoryCleaned,
    ];
  }, [] as CardSpendLimitHistoryCleaned[]);
