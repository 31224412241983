import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { generatePath } from 'react-router-dom';

import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import { useAxios } from './useAxios';
import { useGetUserPrivileges } from './useGetUserPrivileges';

interface AccountMonthOverview {
  month: number;
  year: number;
  accumulatedSpendCents: number;
  transactionCount: number;
}

export const useGetAccountMonthOverview = ({
  year,
  month,
}: {
  year: number;
  month: number;
}) => {
  const { axios, generateSecureHeaders } = useAxios();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getAccountMonthOverview = async ({
    year,
    month,
  }: {
    year: number;
    month: number;
  }) => {
    const { data } = await axios.get<AccountMonthOverview>(
      generatePath(SERVICE_BENJI_CARD_URLS.ACCOUNT_GET_MONTH_OVERVIEW, {
        year,
        month,
      }),
      {
        headers: await generateSecureHeaders(),
      },
    );

    return data;
  };

  return useQuery({
    queryKey: queryKeys.balance.month({
      year,
      month,
    }),
    queryFn: () =>
      getAccountMonthOverview({
        year,
        month,
      }),
    enabled: !!isSignedIn && !!privileges?.balance && !!year && !!month,
  });
};
