import { useMutation } from '@tanstack/react-query';
import { get } from 'lodash';
import qs from 'qs';

import { SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { useAxios } from './useAxios';

export enum DownloadEntity {
  CARDHOLDERS = 'cardholders',
  CARDS = 'cards',
  TRANSACTIONS = 'transactions',
  GROUPS = 'groups',
  // DISPUTES = 'disputes',
  // FUND_TRANSFERS = 'fund_transfers',
}

const entityDownloadUrl: Record<DownloadEntity, string> = {
  [DownloadEntity.CARDHOLDERS]:
    SERVICE_BENJI_CARD_URLS.CARDHOLDERS_DOWNLOAD_CSV,
  [DownloadEntity.CARDS]: SERVICE_BENJI_CARD_URLS.CARDS_DOWNLOAD_CSV,
  [DownloadEntity.TRANSACTIONS]:
    SERVICE_BENJI_CARD_URLS.TRANSACTIONS_DOWNLOAD_CSV,
  [DownloadEntity.GROUPS]: SERVICE_BENJI_CARD_URLS.GROUPS_DOWNLOAD_CSV,
  // [DownloadEntity.DISPUTES]: 'SERVICE_BENJI_CARD_URLS',
  // [DownloadEntity.FUND_TRANSFERS]: 'SERVICE_BENJI_CARD_URLS',
};

export const useDownloadTableCsv = () => {
  const { axios, generateSecureHeaders } = useAxios();

  const getCsvDownload = async ({
    downloadEntity,
    filters = {},
  }: {
    downloadEntity: DownloadEntity;
    filters?: Record<string, unknown>;
  }) => {
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => value !== null),
    ) as Record<string, string>;
    const queryParamsString = qs.stringify(filteredFilters);
    try {
      const response = await axios.get(
        `${entityDownloadUrl[downloadEntity]}?${queryParamsString}`,
        {
          headers: await generateSecureHeaders(),
        },
      );

      if (response.data?.errors?.length) {
        throw new Error(
          response.data.errors
            .map((data: unknown) => get(data, 'message', ''))
            .join(', '),
        );
      }

      const contentDisposition = response.headers['content-disposition'];
      let filename = `${downloadEntity}.csv`;
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch.length > 1) {
          filename = filenameMatch[1];
        }
      }

      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename);

      document.body.appendChild(link);

      link.click();

      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
      return true;
    } catch (error) {
      const message =
        get(error, 'response.data.errors[0].message') ??
        get(error, 'response.data.message');
      throw new Error(
        message ??
          'An error occurred while downloading the CSV. Try different filters or try again later.',
      );
    }
  };

  return useMutation({
    mutationFn: ({
      downloadEntity,
      filters,
    }: {
      downloadEntity: DownloadEntity;
      filters?: Record<string, unknown>;
    }) =>
      getCsvDownload({
        downloadEntity,
        filters,
      }),
  });
};
