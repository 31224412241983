import { css } from '@emotion/react';
import { Box, Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TrackType, useTrack } from 'src/context';
import { useAppRoutes } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { searchParamsToObject } from 'src/utils';

import type { ComponentProps } from 'react';
import type { RoutePath } from 'src/AppRoutes';

const TabPanel = ({
  children,
  value,
  index,
  label,
  tabsKey,
  tabOverflow = 'auto',
  isAnchor = false,
  ...rest
}: {
  children?: React.ReactNode;
  index: number;
  label: string;
  value: number;
  tabsKey: string;
  tabOverflow?: 'auto' | 'hidden';
  isAnchor?: boolean;
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`${tabsKey}-modal-tabpanel-${label}`}
      aria-labelledby={`${tabsKey}-modal-tab-${label}`}
      css={css`
        flex-grow: 1;
        overflow: ${tabOverflow};
        ${isAnchor ? 'position: relative;' : ''}
      `}
      {...rest}
    >
      {value === index && children}
    </Box>
  );
};

export const ModalTabs = ({
  tabs,
  tabsKey,
}: {
  tabs: {
    label: string;
    content: React.ReactNode;
    tabOverflow?: ComponentProps<typeof TabPanel>['tabOverflow'];
    isAnchor?: ComponentProps<typeof TabPanel>['isAnchor'];
  }[];
  tabsKey: string;
}) => {
  const { navigate, pathname } = useAppRoutes();
  const { track } = useTrack();

  const queryParamName = `${tabsKey}-tab`;
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);
  const defaultTab = Number(searchParams.get(queryParamName));

  const [currentTab, setCurrentTab] = useState(
    isNaN(defaultTab) ? 0 : defaultTab,
  );
  const handleSetTab = (_event: React.SyntheticEvent, tabIndex: number) => {
    track({
      label: 'Switched tab',
      type: TrackType.action,
      actionType: 'click',
      tabsKey,
      tabIndex: tabIndex,
      tabLabel: tabs[tabIndex]?.label ?? '',
    });
    setCurrentTab(tabIndex);
    navigate(pathname as RoutePath, {
      queryParams: {
        ...currentSearchParams,
        [queryParamName]: `${tabIndex}`,
      },
      replace: true,
      preventScrollReset: true,
    });
  };

  return (
    <Box
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <Box>
        <Tabs
          value={currentTab}
          onChange={handleSetTab}
          aria-label={`${tabsKey} modal tabs`}
          variant="fullWidth"
          scrollButtons="auto"
          css={css`
            border-bottom: 1px solid ${COLOR_PALETTE.tabsBorder};
            background-color: ${COLOR_PALETTE.modalHeaderBackground};
            &,
            button {
              min-height: 38px;
            }
            button {
              font-size: 14px;
              font-weight: 700;
              padding: 10px;
              color: ${COLOR_PALETTE.lightTextOnDark};
              &.Mui-selected {
                color: ${COLOR_PALETTE.modalHeaderText};
              }
              &:hover {
                background-color: ${COLOR_PALETTE.tabsHover};
              }
            }
            .MuiTabs-indicator {
              background-color: ${COLOR_PALETTE.tabsIndicator};
            }
          `}
        >
          {tabs.map(({ label }, index) => (
            <Tab
              label={label}
              key={index}
              id={`${tabsKey}-modal-tab-${label}`}
              aria-controls={`${tabsKey}-modal-tabpanel-${label}`}
              css={css`
                flex-basis: auto;
                min-width: initial;
              `}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map(({ content, label, tabOverflow, isAnchor }, index) => (
        <TabPanel
          key={index}
          value={currentTab}
          index={index}
          label={label}
          tabsKey={tabsKey}
          tabOverflow={tabOverflow}
          isAnchor={isAnchor}
        >
          {content}
        </TabPanel>
      ))}
    </Box>
  );
};
