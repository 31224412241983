import { isProd, isStaging } from './env-checks';

const useLocalServer = true;

const QA_SERVER_PREVIEW_DEPLOYMENT_URL =
  'benjicard-server-benjicard-server-pr-220.up.railway.app';
const useQAPreviewDeployment =
  true && !window.location.hostname.startsWith('qa.');

export const BASE_PAGE_URL = `https://${isProd ? 'app' : 'qa'}.benjicard.com`;
export const BASE_API_URL =
  !isProd && !isStaging && useLocalServer
    ? 'http://localhost:8080/api'
    : `https://${
        isProd
          ? 'api.benjicard.com'
          : useQAPreviewDeployment
          ? QA_SERVER_PREVIEW_DEPLOYMENT_URL
          : 'qa-api.benjicard.com'
      }/api`;

export const SERVICE_BENJI_CARD_URLS = {
  get CARD_UPDATE_NICKNAME() {
    return `${BASE_API_URL}/accounts/updateCardNickname`;
  },
  get CARD_CLEAR_SPEND_CONTROL() {
    return `${BASE_API_URL}/accounts/clearCardSpendControl`;
  },
  get CARD_UPDATE_SPEND_CONTROL() {
    return `${BASE_API_URL}/accounts/updateCardSpendControl`;
  },
  get CARD_UPDATE_STATUS() {
    return `${BASE_API_URL}/accounts/updateCardStatus`;
  },
  get CARD_SPEND_LIMIT_HISTORY() {
    return `${BASE_API_URL}/cards/spend-limit-history`;
  },
  get CARD_SEND() {
    return `${BASE_API_URL}/sendCard`;
  },
  get CARD_SEND_BASIC_DETAILS() {
    return `${BASE_API_URL}/sendCard/:token`;
  },
  get CARD_SEND_DETAILS() {
    return `${BASE_API_URL}/sendCard/validate/:token`;
  },
  get CARD_GET_AUTHED_EPHEMERAL_KEY() {
    return `${BASE_API_URL}/accounts/ephemeralKey`;
  },
  get CARD_GET_SEND_CARD_EPHEMERAL_KEY() {
    return `${BASE_API_URL}/sendCard/ephemeralKey/:token`;
  },
  get CARD_GET_TOKEN_EPHEMERAL_KEY() {
    return `${BASE_API_URL}/sendCard/iframe/card`;
  },
  get CARD_CREATE() {
    return `${BASE_API_URL}/accounts/issueCard`;
  },
  get CARDS_GET() {
    return `${BASE_API_URL}/accounts/cards`;
  },
  get CARDS_DOWNLOAD_CSV() {
    return `${BASE_API_URL}/accounts/cards/export`;
  },
  get CARD_GET_DETAILS() {
    return `${BASE_API_URL}/accounts/card/:cardStripeId`;
  },
  get CARD_GET_SEND_HISTORY() {
    return `${BASE_API_URL}/accounts/sendCardRecords/:stripeCardId`;
  },
  get CARDHOLDERS_GET() {
    return `${BASE_API_URL}/accounts/cardholders`;
  },
  get CARDHOLDERS_DOWNLOAD_CSV() {
    return `${BASE_API_URL}/accounts/cardholders/export`;
  },
  get CARDHOLDER_GET_DETAILS() {
    return `${BASE_API_URL}/accounts/cardholder/:stripeCardholderId`;
  },
  get CARDHOLDER_CREATE() {
    return `${BASE_API_URL}/accounts/createCardholder`;
  },
  get CARDHOLDER_UPDATE_STATUS() {
    return `${BASE_API_URL}/accounts/updateCardHolderStatus`;
  },
  get GROUPS_GET() {
    return `${BASE_API_URL}/groups`;
  },
  get GROUPS_DOWNLOAD_CSV() {
    return `${BASE_API_URL}/groups/export`;
  },
  get GROUP_GET_DETAILS() {
    return `${BASE_API_URL}/groups/:groupId`;
  },
  get GROUP_CREATE() {
    return `${BASE_API_URL}/groups/create`;
  },
  get GROUP_UPDATE() {
    return `${BASE_API_URL}/groups/update/:groupId`;
  },
  get USER_REGISTER_NEW_ACCOUNT() {
    return `${BASE_API_URL}/signup/admin`;
  },
  get USER_GENERATE_CONTINUE_STRIP_REGISTRATION_LINK() {
    return `${BASE_API_URL}/createCustomAccount/acquireAccountOnboardingLink`;
  },
  get USER_GET_PRIVILEGES() {
    return `${BASE_API_URL}/users/userPrivileges`;
  },
  get USER_GET_DETAILS() {
    return `${BASE_API_URL}/users/profile`;
  },
  get USER_UPDATE_DETAILS() {
    return `${BASE_API_URL}/users/profile/update/:userId`;
  },
  get USERS_GET_SUB_USERS() {
    return `${BASE_API_URL}/users/subUsers`;
  },
  get USERS_GET_SUB_USER_DETAILS() {
    return `${BASE_API_URL}/users/subUsers/:subUserId`;
  },
  get USERS_CREATE_SUB_USER() {
    return `${BASE_API_URL}/users/createSubUser`;
  },
  get USERS_UPDATE_SUB_USER() {
    return `${BASE_API_URL}/users/updateSubUser`;
  },
  get USERS_ENABLE_SUB_USER() {
    return `${BASE_API_URL}/users/activateSubuser`;
  },
  get USERS_DISABLE_SUB_USER() {
    return `${BASE_API_URL}/users/disableSubUser`;
  },
  get USERS_DELETE_SUB_USER() {
    return `${BASE_API_URL}/users/deleteSubUser`;
  },
  get ACCOUNTS_GET_FUNDING_INSTRUCTIONS() {
    return `${BASE_API_URL}/accounts/fundingInstructions`;
  },
  get TRANSACTIONS_GET_ALL() {
    return `${BASE_API_URL}/accounts/authorizationsUnionTransactions`;
  },
  get TRANSACTIONS_DOWNLOAD_CSV() {
    return `${BASE_API_URL}/accounts/authorizationsUnionTransactions/export`;
  },
  get TRANSACTIONS_GET_COMPLETED_ALL() {
    return `${BASE_API_URL}/accounts/transactions`;
  },
  get TRANSACTION_GET_DETAILS() {
    return `${BASE_API_URL}/accounts/transaction/:transactionId`;
  },
  get AUTHORIZATION_GET_DETAILS() {
    return `${BASE_API_URL}/accounts/authorization/:authorizationId`;
  },
  get DISPUTES_GET_ALL() {
    return `${BASE_API_URL}/disputes`;
  },
  get DISPUTES_GET_DETAILS() {
    return `${BASE_API_URL}/disputes/:stripeDisputeId`;
  },
  get DISPUTES_CREATE() {
    return `${BASE_API_URL}/disputes`;
  },
  get ACCOUNT_GET_OVERVIEW() {
    return `${BASE_API_URL}/accounts/overview`;
  },
  get ACCOUNT_GET_MONTH_OVERVIEW() {
    return `${BASE_API_URL}/accounts/monthOverview/:year/:month`;
  },
  get BALANCE_GET_BALANCE_HISTORY() {
    return `${BASE_API_URL}/accounts/balanceHistory`;
  },
  get BALANCE_GET_BALANCE_HISTORY_DETAILS() {
    return `${BASE_API_URL}/accounts/balanceHistoryEntry/:balanceTransferId`;
  },
  get COMPANY_INFO_UPDATE() {
    return `${BASE_API_URL}/accounts/updateCompanyInformation`;
  },
};
