import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { Outlet } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { PageFooter } from 'src/components';
import { COLOR_PALETTE } from 'src/theme';

import fadedGridBackgroundWhite from './faded-grid-background-white.png';
import img_cardPreview from './img_card-preview.png';
import img_lock from './img_lock.svg';
import img_phone from './img_phone.png';

import type {
  AnimationHandler,
  AnimationHandlerResponse,
} from 'react-responsive-carousel/lib/ts/components/Carousel/types';

const bannerItems = [
  {
    img: img_cardPreview,
    title: (
      <Typography
        component="h3"
        css={css`
          font-family: 'Abhaya Libre SemiBold';
          font-size: 46px;
          font-weight: 600;
          color: ${COLOR_PALETTE.white};
        `}
      >
        Simple{' '}
        <Box
          component="span"
          css={css`
            color: ${COLOR_PALETTE.primary};
          `}
        >
          Approach
        </Box>
      </Typography>
    ),
    content:
      'Simplify your healthcare spend with unique virtual credit cards tailored for each payment.',
  },
  {
    img: img_lock,
    title: (
      <Typography
        component="h3"
        css={css`
          font-family: 'Abhaya Libre SemiBold';
          font-size: 46px;
          font-weight: 600;
          color: ${COLOR_PALETTE.white};
        `}
      >
        Secure{' '}
        <Box
          component="span"
          css={css`
            color: ${COLOR_PALETTE.primary};
          `}
        >
          Payments
        </Box>
      </Typography>
    ),
    content:
      'We prioritize your security by partnering with Stripe, the industry leader in secure payments. With stringent certifications and secure connections, every Benji Card transaction ensures your data remains confidential.',
  },
  {
    img: img_phone,
    title: (
      <Typography
        component="h3"
        css={css`
          font-family: 'Abhaya Libre SemiBold';
          font-size: 46px;
          font-weight: 600;
          color: ${COLOR_PALETTE.white};
        `}
      >
        Seamless{' '}
        <Box
          component="span"
          css={css`
            color: ${COLOR_PALETTE.primary};
          `}
        >
          Experience
        </Box>
      </Typography>
    ),
    content:
      'Leave behind the payment experience of the past and have confidence in the digital healthcare payment experience of the future.',
  },
];

const fadeAnimationHandler: AnimationHandler = (
  props,
  state,
): AnimationHandlerResponse => {
  const transitionTime = props.transitionTime + 'ms';
  const transitionTimingFunction = 'ease-in-out';

  let slideStyle: React.CSSProperties = {
    position: 'absolute',
    display: 'block',
    zIndex: 0,
    minHeight: '100%',
    opacity: 0,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transitionTimingFunction: transitionTimingFunction,
    msTransitionTimingFunction: transitionTimingFunction,
    MozTransitionTimingFunction: transitionTimingFunction,
    WebkitTransitionTimingFunction: transitionTimingFunction,
    OTransitionTimingFunction: transitionTimingFunction,
  };

  if (!state.swiping) {
    slideStyle = {
      ...slideStyle,
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    };
  }

  return {
    slideStyle,
    selectedStyle: {
      ...slideStyle,
      opacity: 1,
      position: 'relative',
      zIndex: 2,
    },
    prevStyle: { ...slideStyle },
  };
};

export const SignInPageWrapper = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Box
      css={css`
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        min-height: 100vh;
        text-align: center;
        @media (max-width: 979px) {
          flex-direction: column;
        }
      `}
    >
      <Box
        css={css`
          flex: 1 0 490px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 100%;
        `}
      >
        {children ? children : <Outlet />}
        <PageFooter
          css={css`
            width: 100%;
            padding-top: 0;
            background-color: ${COLOR_PALETTE.white};
            & > p {
              padding-top: 12px;
              border-top: 1px solid ${COLOR_PALETTE.border};
            }
            @media (max-width: 979px) {
              display: none;
            }
          `}
        />
      </Box>
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1 0 490px;
          padding: 40px;
          background-color: ${COLOR_PALETTE.darkBackground};
          background-image: url(${fadedGridBackgroundWhite});
          background-position: top center;
          background-repeat: no-repeat;
          max-width: 100%;
        `}
      >
        <Box
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 36px;
            max-width: 600px;
            @media (max-width: 600px) {
              max-width: 100%;
            }

            .carousel .control-dots {
              z-index: 5;
              display: flex;
              justify-content: flex-start;
              gap: 12px;
            }
            .carousel-root {
              width: 100%;
            }
          `}
        >
          <Carousel
            autoPlay
            infiniteLoop
            interval={7000}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            swipeable={false}
            emulateTouch={false}
            animationHandler={fadeAnimationHandler}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              return (
                <Box
                  component="li"
                  css={css`
                    height: 8px;
                    width: 8px;
                    background-color: ${isSelected
                      ? COLOR_PALETTE.primary
                      : '#454545'};
                    border-radius: 50%;
                    list-style-type: none;
                  `}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
                />
              );
            }}
          >
            {bannerItems.map((item, index) => (
              <Box
                key={index}
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <Box
                  css={css`
                    height: 346px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                  `}
                >
                  <Box
                    component="img"
                    src={item.img}
                    css={css`
                      width: 100%;
                      max-width: 400px;
                      height: auto;
                      max-height: 100%;
                      position: relative;
                      z-index: 1;
                    `}
                  />
                  <Box
                    css={css`
                      position: absolute;
                      bottom: 0;
                      left: 50%;
                      transform: translateX(-50%);
                      width: 313px;
                      max-width: 100%;
                      height: 133px;
                      border-radius: 313px;
                      background: rgba(0, 0, 0, 0.6);
                      filter: blur(26px);
                    `}
                  />
                </Box>
                <Typography
                  color="white"
                  variant="h4"
                  css={css`
                    width: 100%;
                    text-align: left;
                    line-height: 52px;
                    padding-top: 60px;
                    padding-bottom: 8px;
                  `}
                >
                  {item.title}
                </Typography>
                <Typography
                  color="white"
                  variant="body1"
                  fontSize="20px"
                  css={css`
                    min-height: 85px;
                    padding-bottom: 40px;
                    text-align: left;
                    font-size: 15px;
                    font-weight: 300;
                  `}
                >
                  {item.content}
                </Typography>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Box>
      <PageFooter
        css={css`
          width: 100%;
          @media (min-width: 980px) {
            display: none;
          }
        `}
      />
    </Box>
  );
};
