import { SvgIcon } from '@mui/material';

export const ProfilePicPlaceholderIcon = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="50" cy="50" r="50" fill="white" />
        <circle cx="50.2632" cy="50.2632" r="45.2632" fill="#E7E7E7" />
        <path
          d="M50.6879 53.9717C58.2478 53.9717 64.4277 47.7919 64.4277 40.232C64.4277 32.672 58.2478 26.4922 50.6879 26.4922C43.128 26.4922 36.9482 32.672 36.9482 40.232C36.9482 47.7919 43.1281 53.9717 50.6879 53.9717Z"
          fill="white"
        />
        <path
          d="M74.2677 64.95C73.9077 64.05 73.4278 63.2101 72.8878 62.4301C70.1278 58.3501 65.868 55.6502 61.068 54.9902C60.4681 54.9302 59.8081 55.0501 59.3281 55.4101C56.8081 57.2701 53.8082 58.2301 50.6882 58.2301C47.5682 58.2301 44.5683 57.2701 42.0483 55.4101C41.5683 55.0501 40.9083 54.8701 40.3083 54.9902C35.5084 55.6502 31.1885 58.3501 28.4886 62.4301C27.9486 63.2101 27.4686 64.1101 27.1087 64.95C26.9287 65.3101 26.9887 65.73 27.1686 66.0901C27.6487 66.93 28.2486 67.7701 28.7886 68.49C29.6285 69.6301 30.5285 70.65 31.5486 71.6099C32.3885 72.4499 33.3485 73.2299 34.3086 74.0099C39.0484 77.5499 44.7484 79.4097 50.6282 79.4097C56.5081 79.4097 62.2081 77.5498 66.9479 74.0099C67.9079 73.29 68.8679 72.4499 69.7079 71.6099C70.6679 70.65 71.6279 69.6299 72.4679 68.49C73.0679 67.71 73.608 66.93 74.0879 66.0901C74.3877 65.73 74.4477 65.3099 74.2677 64.95Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
