import { SvgIcon } from '@mui/material';

export const ApplePayIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="22"
        height="27"
        viewBox="0 0 22 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M14.7703 4.39519C15.6876 3.23576 16.3099 1.67914 16.1459 0.0883789C14.8032 0.155884 13.1646 0.983631 12.216 2.14383C11.3642 3.1375 10.6103 4.75932 10.8069 6.28334C12.314 6.41543 13.82 5.52202 14.7703 4.39519Z"
              fill="black"
            />
            <path
              id="Vector_2"
              d="M16.1287 6.58012C13.9397 6.44834 12.0785 7.8355 11.0331 7.8355C9.98728 7.8355 8.38646 6.64655 6.65508 6.67853C4.40161 6.71205 2.31066 7.99957 1.16711 10.0473C-1.18497 14.1439 0.546411 20.2207 2.83366 23.557C3.94434 25.2076 5.28313 27.0252 7.04692 26.9598C8.71347 26.8937 9.36674 25.8693 11.3924 25.8693C13.4167 25.8693 14.0052 26.9598 15.7692 26.9268C17.5988 26.8937 18.7425 25.2753 19.8532 23.623C21.1274 21.7413 21.6491 19.9245 21.6818 19.8247C21.6489 19.7916 18.154 18.4368 18.1216 14.3743C18.0886 10.9727 20.8655 9.3546 20.9963 9.25434C19.4283 6.91088 16.9781 6.64655 16.1287 6.58012Z"
              fill="black"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
