import { css } from '@emotion/react';
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Link,
  Typography,
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { startCase } from 'lodash';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  CreditCardPreview,
  DataRow,
  FancyCurrencyDisplay,
  FormFieldInput,
  PageFooter,
} from 'src/components';
import { DataItemDisplay } from 'src/components/DataItemDisplay';
import { ApplePayIcon, GoogleWalletIcon } from 'src/components/Icon';
import {
  TrackContext,
  TrackType,
  TrackingContextProvider,
  useToast,
  useTrack,
} from 'src/context';
import { COLOR_PALETTE } from 'src/theme';
import { CardType } from 'src/types/cards';
import { formatISODate } from 'src/utils';

import { useGetCardBasicDetails } from './useGetCardBasicDetails';
import { useRequestCardDetails } from './useRequestCardDetails';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? '');

const dataItemStyles = css`
  flex: 1 1 200px;
  margin-bottom: 30px;
  .data-item-display-label {
    font-size: 16px;
    font-weight: 500;
  }
  .data-item-display-value {
    font-size: 22px;
    font-weight: 600;
  }
  padding-right: 30px;
  &:nth-child(odd) {
    border-right: 1px solid ${COLOR_PALETTE.border};
  }
  &:nth-child(even) {
    padding-left: 60px;
  }
  @media only screen and (max-width: 450px) {
    padding: 0 8px !important ;
    border-right: initial !important;
  }
`;

interface FormFields {
  memberBirthDate: Date | null;
}

enum ViewCardType {
  MEMBER = 'member',
  PROVIDER = 'provider',
}

const ViewCardTrackingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const { token, type } = useParams();
  const sendCardType = type as ViewCardType;

  const { data: cardBasicDetails } = useGetCardBasicDetails({
    token,
  });
  return (
    <TrackingContextProvider
      trackingProps={{
        payerCompanyName: cardBasicDetails?.details?.payerCompanyName,
        sendCardType,
      }}
      contextName={TrackContext['sent-card']}
      {...props}
    />
  );
};
const ViewCardBase = () => {
  const { track } = useTrack();
  const { setToast } = useToast();
  const { token, type } = useParams();
  const sendCardType = type as ViewCardType;

  const { data: cardBasicDetails, isLoading } = useGetCardBasicDetails({
    token,
  });
  const { mutate: validateBirthday, data: cardDetails } =
    useRequestCardDetails();

  const defaultValues: FormFields = {
    memberBirthDate: null,
  };
  const useFormMethods = useForm<FormFields>({
    defaultValues,
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useFormMethods;
  const onSubmit = async (values: FormFields) => {
    track({
      label: 'Submit patient birthday validation',
      type: TrackType.action,
      actionType: 'submit',
    });
    await validateBirthday(
      {
        ...values,
        memberBirthDate:
          values.memberBirthDate?.toISOString().split('T')[0] ?? '',
        token: token ?? '',
      },
      {
        onSuccess: () => {
          setShowCard(true);
          track({
            label: 'Validated patient birthday',
            type: TrackType.effect,
            isSuccessful: true,
          });
        },
        onError: () => {
          reset(undefined, { keepValues: true });
          setToast({
            message: `Unable to validate birthday.`,
            severity: 'error',
          });
          track({
            label: 'Validated patient birthday',
            type: TrackType.effect,
            isSuccessful: false,
          });
        },
      },
    );
  };

  const [showCard, setShowCard] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);

  return (
    <Box
      css={css`
        padding: 20px 8px;
      `}
    >
      <Card
        css={css`
          max-width: 1000px;
          margin: 0 auto;
          min-height: calc(100vh - 80px);
          display: flex;
          flex-direction: column;
          padding: 0;
        `}
      >
        {isLoading ? (
          <Box
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 24px;
              flex: 1 1 auto;
            `}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                padding: 18px;
                background-color: ${COLOR_PALETTE.darkBackground};
                text-align: center;
              `}
            >
              <Typography
                component="h2"
                css={css`
                  font-size: 24px;
                  font-weight: 500;
                  color: ${COLOR_PALETTE.white};
                `}
              >
                {cardBasicDetails?.details?.payerCompanyName}
              </Typography>
              <Typography
                component="h1"
                css={css`
                  font-size: 32px;
                  font-weight: 700;
                  color: ${COLOR_PALETTE.white};
                `}
              >
                Virtual Credit Card
              </Typography>
              <Typography
                css={css`
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  letter-spacing: 4px;
                  text-transform: uppercase;
                  color: ${COLOR_PALETTE.lightTextOnDark};
                `}
              >
                Powered by Benji Card
              </Typography>
            </Box>
            <Box
              css={css`
                padding: 0 16px;
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
              `}
            >
              <Box
                css={css`
                  width: 100%;
                  max-width: 700px;
                  margin: 0 auto;
                  padding-top: 24px;
                `}
              >
                {cardBasicDetails?.hasCard ? (
                  cardBasicDetails.details?.isActive ? (
                    <>
                      {showCard ? (
                        <>
                          {cardDetails ? (
                            <>
                              <Box
                                css={css`
                                  width: 100%;
                                  max-width: 540px;
                                  margin: 0 auto;
                                  display: flex;
                                  flex-wrap: wrap;
                                `}
                              >
                                <DataItemDisplay
                                  css={dataItemStyles}
                                  label="To:"
                                  value={
                                    <span className="highlight-block">
                                      {startCase(
                                        sendCardType === ViewCardType.PROVIDER
                                          ? cardDetails.details?.providerName
                                          : cardDetails.details?.memberName
                                              .full,
                                      )}
                                    </span>
                                  }
                                />

                                {cardBasicDetails.details.cardType ===
                                  CardType.singleUse && (
                                  <>
                                    {sendCardType === ViewCardType.PROVIDER ? (
                                      <>
                                        <DataItemDisplay
                                          css={dataItemStyles}
                                          label="Patient:"
                                          value={
                                            <span className="highlight-block">
                                              {
                                                cardDetails.details?.memberName
                                                  ?.full
                                              }
                                            </span>
                                          }
                                        />
                                        <DataItemDisplay
                                          css={dataItemStyles}
                                          label="Patient DOB:"
                                          value={
                                            <span className="highlight-block">
                                              {formatISODate(
                                                cardDetails.details
                                                  ?.memberBirthDate ?? '',
                                              )}
                                            </span>
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <DataItemDisplay
                                          css={dataItemStyles}
                                          label="Provider:"
                                          value={
                                            <span className="highlight-block">
                                              {startCase(
                                                cardDetails.details
                                                  ?.providerName,
                                              )}
                                            </span>
                                          }
                                        />
                                      </>
                                    )}
                                    <DataItemDisplay
                                      css={dataItemStyles}
                                      label="Date of Service:"
                                      value={
                                        <span className="highlight-block">
                                          {formatISODate(
                                            cardDetails.details?.serviceDate ??
                                              '',
                                          )}
                                        </span>
                                      }
                                    />
                                  </>
                                )}
                                <DataItemDisplay
                                  css={dataItemStyles}
                                  label="Remaining Card Balance:"
                                  value={
                                    <FancyCurrencyDisplay
                                      amountCents={
                                        cardDetails.details
                                          ?.remainingSpendLimitCents
                                      }
                                      css={css`
                                        display: inline-block;
                                      `}
                                      isPlain
                                      className="highlight-block"
                                    />
                                  }
                                />
                                <Box
                                  css={css`
                                    flex: 1 1 200px;
                                  `}
                                >
                                  {/* Spacer */}
                                </Box>
                              </Box>
                              {cardDetails.details?.remainingSpendLimitCents ===
                                0 && (
                                <Alert
                                  severity="warning"
                                  css={css`
                                    flex: 1 1 100%;
                                    margin-top: 24px;
                                  `}
                                >
                                  <Typography>
                                    Remaining card balance is{' '}
                                    <FancyCurrencyDisplay
                                      amountCents={0}
                                      css={css`
                                        display: inline-block;
                                      `}
                                      className="highlight-block"
                                    />
                                    . Please contact{' '}
                                    {
                                      cardBasicDetails?.details
                                        ?.payerCompanyName
                                    }{' '}
                                    if you need additional funds.
                                  </Typography>
                                </Alert>
                              )}
                              <CreditCardPreview
                                cardholder={cardDetails.details?.cardholderName}
                                cardId={cardDetails.details?.cardId}
                                showByDefault
                                token={token}
                                css={css`
                                  margin: 0 auto 32px;
                                `}
                              />
                              <DataRow
                                label={
                                  <Typography
                                    css={css`
                                      font-size: 15px;
                                      font-weight: 700;
                                    `}
                                  >
                                    Billing Address
                                  </Typography>
                                }
                                value={
                                  <Box
                                    css={css`
                                      width: 100%;
                                    `}
                                  >
                                    <Typography>
                                      {
                                        cardDetails.details
                                          ?.cardholderBillingAddress?.line1
                                      }{' '}
                                      {!!cardDetails.details
                                        ?.cardholderBillingAddress?.line2 && (
                                        <>
                                          {
                                            cardDetails.details
                                              ?.cardholderBillingAddress?.line2
                                          }{' '}
                                        </>
                                      )}
                                      {
                                        cardDetails.details
                                          ?.cardholderBillingAddress?.city
                                      }
                                      ,{' '}
                                      {
                                        cardDetails.details
                                          ?.cardholderBillingAddress?.state
                                      }{' '}
                                      {
                                        cardDetails.details
                                          ?.cardholderBillingAddress
                                          ?.postal_code
                                      }
                                    </Typography>
                                  </Box>
                                }
                                isPrivate
                                labelWidth={150}
                                css={css`
                                  border-bottom-style: dashed;
                                  padding-bottom: 24px;
                                `}
                              />

                              {sendCardType === ViewCardType.MEMBER && (
                                <>
                                  <DataRow
                                    label={
                                      <Typography
                                        css={css`
                                          font-size: 15px;
                                          font-weight: 700;
                                        `}
                                      >
                                        Digital Wallets
                                      </Typography>
                                    }
                                    value={
                                      <Box
                                        css={css`
                                          width: 100%;
                                        `}
                                      >
                                        <Typography>
                                          Your card has been enabled to be used
                                          with your digital wallet for extra
                                          convenience and security.
                                        </Typography>
                                      </Box>
                                    }
                                    isPrivate
                                    labelWidth={150}
                                    css={css`
                                      padding-top: 24px;
                                      padding-bottom: 0;
                                      border-bottom: initial;
                                    `}
                                  />
                                  <Box
                                    css={css`
                                      display: flex;
                                      flex-wrap: wrap;
                                      justify-content: center;
                                      gap: 10px 20px;
                                      padding-top: 36px;
                                      padding-bottom: 24px;
                                      border-bottom: 1px dashed
                                        ${COLOR_PALETTE.border};
                                    `}
                                  >
                                    <Typography
                                      css={css`
                                        width: 100%;
                                        text-align: center;
                                        font-size: 15px;
                                        font-weight: 600;
                                      `}
                                    >
                                      How to Add
                                    </Typography>
                                    <Button
                                      variant="outlined"
                                      target="_blank"
                                      href="https://support.google.com/wallet/answer/12058983?hl=en#zippy=%2Cwith-the-google-wallet-app"
                                      rel="noreferrer"
                                      startIcon={<GoogleWalletIcon />}
                                      css={css`
                                        flex: 0 1 200px;
                                        padding: 16px;
                                        border-radius: 12px;
                                        box-shadow: 0px 6px 10px 4px
                                          rgba(242, 190, 87, 0.26);
                                      `}
                                    >
                                      Google Wallet
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      target="_blank"
                                      href="https://www.apple.com/apple-pay/#setup"
                                      rel="noreferrer"
                                      startIcon={<ApplePayIcon />}
                                      css={css`
                                        flex: 0 1 200px;
                                        padding: 16px;
                                        border-radius: 12px;
                                        box-shadow: 0px 6px 10px 4px
                                          rgba(242, 190, 87, 0.26);
                                      `}
                                    >
                                      Apple Pay
                                    </Button>
                                  </Box>
                                </>
                              )}
                            </>
                          ) : (
                            'unable to load card details'
                          )}
                        </>
                      ) : (
                        <FormProvider {...useFormMethods}>
                          <Box
                            onSubmit={handleSubmit(onSubmit, (errors) => {
                              track({
                                label: 'Submit patient birthday validation',
                                type: TrackType.action,
                                actionType: 'submit',
                                validationErrors: Object.entries(errors).map(
                                  ([key, value]) => ({
                                    field: key,
                                    message: value.message,
                                  }),
                                ),
                              });
                            })}
                            component="form"
                            css={css`
                              display: flex;
                              flex-direction: column;
                              gap: 30px;
                              align-items: center;
                            `}
                          >
                            <FormFieldInput
                              control={control}
                              label="Please validate the patient's birthday to view
                              the card."
                              placeholder={`${cardBasicDetails?.details?.memberName?.full}'s Birthday`}
                              name="memberBirthDate"
                              type="date"
                              views={['year', 'month', 'day']}
                              openTo="year"
                              defaultValue={defaultValues.memberBirthDate}
                              css={css`
                                width: 100%;
                                max-width: 400px;
                                label {
                                  font-size: 14px;
                                  font-weight: 600;
                                  color: ${COLOR_PALETTE.textOnLight};
                                }
                                .MuiFormControl-root {
                                  width: 100%;
                                }
                              `}
                              rules={{
                                required:
                                  "Please enter the patient's birthday.",
                              }}
                            />
                            {type === 'member' && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={hasAgreedToTerms}
                                    onChange={(_e, checked) =>
                                      setHasAgreedToTerms(checked)
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    css={css`
                                      font-size: 14px;
                                      font-weight: 400;
                                    `}
                                  >
                                    By checking this box, I acknowledge that the
                                    virtual credit card provided is designated
                                    solely to pay for the healthcare expenses
                                    specified on this page up to the spend limit
                                    amount. I understand that this card is not
                                    to be used for personal expenses or any
                                    other personal use. I agree to use this card
                                    as intended above and as outlined in the{' '}
                                    <Link
                                      href="https://stripe.com/legal/e-sign-disclosure"
                                      target="_blank"
                                      css={css`
                                        color: ${COLOR_PALETTE.blueLink};
                                        font-weight: 500;
                                      `}
                                    >
                                      Stripe E-SIGN Disclosure
                                    </Link>{' '}
                                    and{' '}
                                    <Link
                                      href="https://stripe.com/legal/issuing/celtic-authorized-user-terms"
                                      target="_blank"
                                      css={css`
                                        color: ${COLOR_PALETTE.blueLink};
                                        font-weight: 500;
                                      `}
                                    >
                                      Stripe Issuing: Authorized User Terms -
                                      Celtic Bank
                                    </Link>{' '}
                                    documents.
                                  </Typography>
                                }
                                css={css`
                                  align-items: flex-start;
                                  .MuiCheckbox-root {
                                    padding: 0 20px 0 0;
                                  }
                                `}
                              />
                            )}
                            <Button
                              variant="contained"
                              type="submit"
                              disabled={
                                (type === 'member' && !hasAgreedToTerms) ||
                                isSubmitting
                              }
                              css={css`
                                min-width: 160px;
                              `}
                            >
                              View Card
                            </Button>
                          </Box>
                        </FormProvider>
                      )}
                    </>
                  ) : (
                    <Alert severity="warning">
                      Card or cardholder is no longer active.
                    </Alert>
                  )
                ) : (
                  <Alert severity="warning">
                    Either this card does not exist or it has already been
                    viewed and this link has expired. Please check your email
                    for an updated link.
                  </Alert>
                )}
              </Box>
              <Box
                css={css`
                  max-width: 700px;
                  margin: 30px auto 0;
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                  align-items: center;
                `}
              >
                {cardBasicDetails?.details?.supportEmail && (
                  <Typography
                    css={css`
                      font-size: 14px;
                      font-weight: 400;
                      max-width: 605px;
                      text-align: center;
                    `}
                  >
                    If you have any issues accessing the card information,
                    please don
                    {`'`}t hesitate to reach out to the{' '}
                    {cardBasicDetails?.details?.payerCompanyName} support team
                    at{' '}
                    <a
                      href={`mailto:${cardBasicDetails?.details?.supportEmail}`}
                    >
                      {cardBasicDetails?.details?.supportEmail}
                    </a>
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  css={css`
                    color: ${COLOR_PALETTE.lightTextOnLight};
                    font-size: 14px;
                    font-weight: 300;
                    text-align: center;
                    padding-bottom: 20px;
                  `}
                >
                  <b>Disclaimer</b>: This card is intended solely for the
                  recipient named above. If you have received this link in
                  error, please disregard it and notify the sender immediately.
                  Any unauthorized use or dissemination of the information
                  contained herein is prohibited.
                </Typography>
              </Box>
              <PageFooter
                css={css`
                  border-top: 1px solid ${COLOR_PALETTE.border};
                  background-color: initial;
                  max-width: 944px;
                  margin: auto auto 0;
                `}
              />
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};

const Wrappers = () => {
  return (
    <ViewCardTrackingContextProvider>
      <Elements stripe={stripePromise}>
        <ViewCardBase />
      </Elements>
    </ViewCardTrackingContextProvider>
  );
};

export const ViewCard = Wrappers;

export default Wrappers;
