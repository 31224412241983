import { SvgIcon } from '@mui/material';

export const BackIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M5.14355 12.0039H18.8578"
          stroke="#1B254B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.00098 12.0039L8.88892 15.8919"
          stroke="#1B254B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.00098 12.0012L8.88892 8.11328"
          stroke="#1B254B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
