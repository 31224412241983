import { SvgIcon } from '@mui/material';

export const PasswordInputIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1220_7326)">
          <path
            d="M10 14.166V17.4993"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.33301 16.6667L11.6663 15"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.33301 15L11.6663 16.6667"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.16699 14.166V17.4993"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 16.6667L5.83333 15"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 15L5.83333 16.6667"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.833 14.166V17.4993"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.167 16.6667L17.5003 15"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.167 15L17.5003 16.6667"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.5 7C6.5 6.73478 6.60536 6.48043 6.79289 6.29289C6.98043 6.10536 7.23478 6 7.5 6H12.5C12.7652 6 13.0196 6.10536 13.2071 6.29289C13.3946 6.48043 13.5 6.73478 13.5 7V10C13.5 10.2652 13.3946 10.5196 13.2071 10.7071C13.0196 10.8946 12.7652 11 12.5 11H7.5C7.23478 11 6.98043 10.8946 6.79289 10.7071C6.60536 10.5196 6.5 10.2652 6.5 10V7Z"
            stroke="#262626"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5 8.5C9.5 8.63261 9.55268 8.75979 9.64645 8.85355C9.74021 8.94732 9.86739 9 10 9C10.1326 9 10.2598 8.94732 10.3536 8.85355C10.4473 8.75979 10.5 8.63261 10.5 8.5C10.5 8.36739 10.4473 8.24021 10.3536 8.14645C10.2598 8.05268 10.1326 8 10 8C9.86739 8 9.74021 8.05268 9.64645 8.14645C9.55268 8.24021 9.5 8.36739 9.5 8.5Z"
            stroke="#262626"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2C10.5304 2 11.0391 2.21071 11.4142 2.58579C11.7893 2.96086 12 3.46957 12 4V6"
            stroke="#262626"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 14.166V14.1743"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1220_7326">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
