import { SvgIcon } from '@mui/material';

export const TransactionsIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 9" clipPath="url(#clip0_712_630)">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.60674 3.63636C5.34451 3.63636 5.09301 3.74172 4.90758 3.92926C4.72215 4.11679 4.61798 4.37115 4.61798 4.63636V17.653L6.05687 16.6829C6.32861 16.4996 6.68263 16.4996 6.95436 16.6829L9.09917 18.129L10.428 16.7851C10.7439 16.4656 11.2561 16.4656 11.572 16.7851L12.9008 18.129L15.0456 16.6829C15.3174 16.4996 15.6714 16.4996 15.9431 16.6829L17.382 17.653V4.63636C17.382 4.37115 17.2778 4.11679 17.0924 3.92926C16.907 3.74172 16.6555 3.63636 16.3933 3.63636H5.60674ZM3.7635 2.77217C4.25236 2.27776 4.91539 2 5.60674 2H16.3933C17.0846 2 17.7476 2.27776 18.2365 2.77217C18.7254 3.26659 19 3.93715 19 4.63636V19.1818C19 19.4835 18.8358 19.7608 18.5727 19.9032C18.3097 20.0456 17.9905 20.0299 17.7423 19.8626L15.4944 18.347L13.2465 19.8626C12.9256 20.0789 12.4984 20.0361 12.2257 19.7603L11 18.5207L9.77429 19.7603C9.50161 20.0361 9.07436 20.0789 8.7535 19.8626L6.50562 18.347L4.25774 19.8626C4.00949 20.0299 3.69031 20.0456 3.42726 19.9032C3.16421 19.7608 3 19.4835 3 19.1818V4.63636C3 3.93716 3.27464 3.26659 3.7635 2.77217Z"
            fill="inherit"
          />
          <path
            id="Vector (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0001 5.19727C11.4207 5.19727 11.7617 5.53822 11.7617 5.9588V6.57227H12.8335C13.2541 6.57227 13.595 6.91322 13.595 7.3338C13.595 7.75439 13.2541 8.09534 12.8335 8.09534H10.5418C10.3791 8.09534 10.2231 8.15998 10.108 8.27502C9.99298 8.39007 9.92835 8.5461 9.92835 8.7088C9.92835 8.8715 9.99298 9.02754 10.108 9.14259C10.2231 9.25763 10.3791 9.32227 10.5418 9.32227H11.4585C12.0251 9.32227 12.5686 9.54736 12.9692 9.94804C13.3699 10.3487 13.595 10.8922 13.595 11.4588C13.595 12.0254 13.3699 12.5689 12.9692 12.9696C12.6407 13.2981 12.2162 13.5086 11.7617 13.5737V14.2088C11.7617 14.6294 11.4207 14.9703 11.0001 14.9703C10.5796 14.9703 10.2386 14.6294 10.2386 14.2088V13.5953H9.16681C8.74623 13.5953 8.40527 13.2544 8.40527 12.8338C8.40527 12.4132 8.74623 12.0723 9.16681 12.0723H11.4585C11.6212 12.0723 11.7772 12.0076 11.8923 11.8926C12.0073 11.7775 12.0719 11.6215 12.0719 11.4588C12.0719 11.2961 12.0073 11.1401 11.8923 11.025C11.7772 10.91 11.6212 10.8453 11.4585 10.8453H10.5418C9.97517 10.8453 9.43173 10.6202 9.03105 10.2196C8.63037 9.81889 8.40527 9.27545 8.40527 8.7088C8.40527 8.14216 8.63037 7.59872 9.03105 7.19804C9.35957 6.86953 9.78405 6.65904 10.2386 6.59388V5.9588C10.2386 5.53822 10.5796 5.19727 11.0001 5.19727Z"
            fill="inherit"
          />
        </g>
        <defs>
          <clipPath id="clip0_712_630">
            <rect width="22" height="22" fill="inherit" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
