import { css } from '@emotion/react';
import { Box } from '@mui/material';

export const PageContainer = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => (
  <Box
    className={className}
    css={css`
      display: flex;
      flex-direction: column;
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      gap: 16px;
      margin: 0 auto;
      height: 100%;
      width: 100%;
    `}
  >
    {children}
  </Box>
);
