import { SvgIcon } from '@mui/material';

export const ExternalLinkIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="share (1) 1">
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.502 6.66732C14.8702 6.66732 15.1687 6.36884 15.1687 6.00065V2.00065C15.1687 1.63246 14.8702 1.33398 14.502 1.33398H10.4996C10.1314 1.33398 9.83294 1.63246 9.83294 2.00065C9.83294 2.36884 10.1314 2.66732 10.4996 2.66732H12.8927L7.15489 8.40505C6.89455 8.66538 6.89455 9.08752 7.15489 9.34785C7.41527 9.60825 7.83734 9.60825 8.09767 9.34785L13.8353 3.61023V6.00065C13.8353 6.36884 14.1338 6.66732 14.502 6.66732ZM3.83301 3.33398C2.72844 3.33398 1.83301 4.22942 1.83301 5.33398V12.6673C1.83301 13.7719 2.72844 14.6673 3.83301 14.6673H11.1663C12.2709 14.6673 13.1663 13.7719 13.1663 12.6673V8.66732C13.1663 8.29912 12.8679 8.00065 12.4997 8.00065C12.1315 8.00065 11.833 8.29912 11.833 8.66732V12.6673C11.833 13.0355 11.5345 13.334 11.1663 13.334H3.83301C3.46482 13.334 3.16634 13.0355 3.16634 12.6673V5.33398C3.16634 4.9658 3.46482 4.66732 3.83301 4.66732H7.83301C8.20121 4.66732 8.49967 4.36884 8.49967 4.00065C8.49967 3.63246 8.20121 3.33398 7.83301 3.33398H3.83301Z"
            fill="#888888"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
