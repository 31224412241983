import { SvgIcon } from '@mui/material';

export const DisputesIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 9" clipPath="url(#clip0_712_4726)">
          <path
            id="Ellipse 1 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.30042 10.9996C3.30042 15.2522 6.74783 18.6996 11.0004 18.6996C15.253 18.6996 18.7004 15.2522 18.7004 10.9996C18.7004 6.74699 15.253 3.29958 11.0004 3.29958C6.74783 3.29958 3.30042 6.74699 3.30042 10.9996ZM11.0004 20.2227C5.90666 20.2227 1.77734 16.0933 1.77734 10.9996C1.77734 5.90582 5.90665 1.7765 11.0004 1.7765C16.0942 1.7765 20.2235 5.90581 20.2235 10.9996C20.2235 16.0933 16.0942 20.2227 11.0004 20.2227Z"
            fill="inherit"
          />
          <path
            id="Ellipse 2 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.59994 10.9999C6.59994 8.56989 8.56989 6.59994 10.9999 6.59994C13.43 6.59994 15.3999 8.56989 15.3999 10.9999C15.3999 13.43 13.43 15.3999 10.9999 15.3999C8.56989 15.3999 6.59994 13.43 6.59994 10.9999ZM10.9999 5.24609C7.82218 5.24609 5.24609 7.82218 5.24609 10.9999C5.24609 14.1777 7.82218 16.7538 10.9999 16.7538C14.1777 16.7538 16.7538 14.1777 16.7538 10.9999C16.7538 7.82218 14.1777 5.24609 10.9999 5.24609Z"
            fill="inherit"
          />
          <path
            id="Vector 1 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.13674 14.8633C6.87239 14.5989 6.87239 14.1703 7.13674 13.9059L13.906 7.13671C14.1703 6.87236 14.5989 6.87236 14.8633 7.13671C15.1276 7.40107 15.1276 7.82967 14.8633 8.09403L8.09406 14.8633C7.8297 15.1276 7.4011 15.1276 7.13674 14.8633Z"
            fill="inherit"
          />
          <path
            id="Vector 2 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.13674 7.13674C6.87239 7.4011 6.87239 7.8297 7.13674 8.09406L13.906 14.8633C14.1703 15.1276 14.5989 15.1276 14.8633 14.8633C15.1276 14.5989 15.1276 14.1703 14.8633 13.906L8.09406 7.13674C7.8297 6.87239 7.4011 6.87239 7.13674 7.13674Z"
            fill="inherit"
          />
        </g>
        <defs>
          <clipPath id="clip0_712_4726">
            <rect width="22" height="22" fill="inherit" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
