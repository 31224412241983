import { useMutation, useQueryClient } from '@tanstack/react-query';
import { generatePath } from 'react-router-dom';

import { useAxios } from 'src/hooks/useAxios';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

export const useUpdateUserInfo = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();

  const updateUserInfo = async ({
    userId,
    name,
  }: {
    userId: string;
    name: {
      first: string;
      last: string;
    };
  }) => {
    const { data } = await axios.put<{
      id: string;
      first_name: string;
      last_name: string;
      email: string;
    }>(
      generatePath(SERVICE_BENJI_CARD_URLS.USER_UPDATE_DETAILS, {
        userId,
      }),
      {
        name,
      },
      {
        headers: await generateSecureHeaders(),
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: {
      userId: string;
      name: {
        first: string;
        last: string;
      };
    }) => updateUserInfo(data),
    onSuccess: () => {
      const userDetailsQueryKey = queryKeys.account.details();
      queryClient.invalidateQueries(userDetailsQueryKey);
    },
  });
};
