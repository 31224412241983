import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect } from 'react';

import { useGetUserDetails } from './useGetUserDetails';

import type { UserDetails } from './useGetUserDetails';

export const useGetFeatures = () => {
  const [cachedFeatures, setCachedFeatures] = useLocalStorage<
    null | UserDetails['accountDetails']['features']
  >('features', null);

  const { data: userDetails, isLoading, dataUpdatedAt } = useGetUserDetails();

  useEffect(() => {
    if (dataUpdatedAt) {
      setCachedFeatures(userDetails?.accountDetails.features ?? null);
    }
  }, [dataUpdatedAt, setCachedFeatures, userDetails?.accountDetails.features]);

  const features = userDetails?.accountDetails.features;

  return {
    features: features ? features : cachedFeatures,
    isLoading: !cachedFeatures && isLoading,
  };
};
