import { UserProfile } from '@clerk/clerk-react';
import { css } from '@emotion/react';
import { Box, Card, CardContent } from '@mui/material';

import { AsynchronouslyLoadedSection, PageContainer } from 'src/components';
import { useGetUserDetails, useGetUserPrivileges } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';

import { CompanyInfoCard } from './CompanyInfoCard';
import { UserInfoCard } from './UserInfoCard';
import { UserPermissionsCard } from './UserPermissionsCard';

const Profile = () => {
  const { isLoading: isLoadingUser } = useGetUserDetails();
  const { isLoading: isLoadingPrivileges } = useGetUserPrivileges();

  const isPageLoading = isLoadingUser || isLoadingPrivileges;

  return (
    <PageContainer>
      <AsynchronouslyLoadedSection isLoading={isPageLoading}>
        <Box
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: 18px;
          `}
        >
          <UserInfoCard />
          <CompanyInfoCard />
          <UserPermissionsCard />
        </Box>
      </AsynchronouslyLoadedSection>
      <Card>
        <CardContent
          css={css`
            .cl-header + .cl-profileSection {
              border-top: 1px dashed #e7e7e7;
            }
          `}
        >
          <UserProfile
            routing="path"
            path="/secure/profile"
            appearance={{
              elements: {
                navbar: {
                  display: 'none',
                },
                headerTitle: {
                  fontSize: '18px',
                },
                profileSectionTitleText: {
                  color: COLOR_PALETTE.lightTextOnLight,
                  fontWeight: 300,
                  fontSize: '12px',
                },
                cardBox: {
                  border: 'none',
                  boxShadow: 'none',
                  height: 'auto',
                },
                menuButton: {
                  color: COLOR_PALETTE.textOnLight,
                },
                pageScrollBox: {
                  padding: '0 8px 24px',
                },
                profileSection__danger: {
                  display: 'none',
                },
              },
            }}
          />
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default Profile;
