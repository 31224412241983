import { css } from '@emotion/react';
import { Alert, Box, Button, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { isValid } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  AsynchronouslyLoadedSection,
  FormFieldInput,
  FormFieldSelect,
} from 'src/components';
import { TrackType, useToast, useTrack } from 'src/context';
import { useGetUserDetails, usePrevious } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { CardType } from 'src/types/cards';

import { useSendCard } from './useSendCard';

import type { UnsavedChanges } from './';
import type { CardholderType } from 'src/types/cardholders';

export interface FormFields {
  cardSendType: 'provider' | 'member' | '';
  email: string;
  emailConfirmation: string;
  memberName: {
    first: string;
    last: string;
  };
  memberDob: Date | null;
  providerName: string;
  dateOfService: Date | null;
}

export const CardSendForm = ({
  cardId,
  cardType,
  memberEmail,
  memberName,
  memberDob,
  cardholderType,
  hasSpendControlSet,
  isCardActive,
  isCardholderActive,
  setUnsavedChanges,
  onSuccess,
  isResend = false,
  closeForm,
  unsavedChanges,
  showUnsavedChangesWarning,
}: {
  cardId: string;
  cardType: CardType;
  memberEmail?: string;
  memberName?: {
    first: string;
    last: string;
  };
  memberDob?: string;
  cardholderType: CardholderType;
  hasSpendControlSet: boolean;
  isCardActive: boolean;
  isCardholderActive: boolean;
  setUnsavedChanges: (props: UnsavedChanges) => void;
  onSuccess?: () => void;
  isResend?: boolean;
  closeForm: () => void;
  unsavedChanges: UnsavedChanges;
  showUnsavedChangesWarning?: boolean;
}) => {
  const { track, incrementMixpanelProfileData } = useTrack();
  const { setToast } = useToast();
  const { mutateAsync: sendCard } = useSendCard();
  const { data: userData, isLoading: isLoadingUser } = useGetUserDetails();
  const isLoading = isLoadingUser;
  const defaultValues: FormFields = useMemo(
    () => ({
      cardSendType: '',
      email: memberEmail ?? '',
      emailConfirmation: memberEmail ?? '',
      memberName: {
        first: memberName?.first ?? '',
        last: memberName?.last ?? '',
      },
      memberDob:
        memberDob && isValid(new Date(memberDob + 'T00:00:00.000'))
          ? new Date(memberDob + 'T00:00:00.000')
          : null,
      providerName: '',
      dateOfService: null,
    }),
    [memberDob, memberName?.first, memberName?.last, memberEmail],
  );
  const useFormMethods = useForm<FormFields>({
    defaultValues,
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
    reset,
    setValue,
  } = useFormMethods;

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const handleSetSuccessMessage = (message: string) => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };

  const formValues = watch();
  useEffect(() => {
    setUnsavedChanges({
      email: {
        hasUnsavedChanges: formValues.email !== defaultValues.email,
        label: 'Email to send card to',
      },
      memberNameFirst: {
        hasUnsavedChanges:
          formValues.memberName.first !== defaultValues.memberName.first,
        label: 'Member first name',
      },
      memberNameLast: {
        hasUnsavedChanges:
          formValues.memberName.last !== defaultValues.memberName.last,
        label: 'Member last name',
      },
      providerName: {
        hasUnsavedChanges:
          formValues.providerName !== defaultValues.providerName,
        label: 'Provider name',
      },
      dateOfService: {
        hasUnsavedChanges:
          (formValues.dateOfService &&
            isValid(formValues.dateOfService) &&
            formValues.dateOfService.toISOString()) !==
          (defaultValues.dateOfService &&
            isValid(defaultValues.dateOfService) &&
            defaultValues.dateOfService.toISOString()),
        label: 'Date of service',
      },
      memberDob: {
        hasUnsavedChanges:
          (formValues.memberDob &&
            isValid(formValues.memberDob) &&
            formValues.memberDob.toISOString()) !==
          (defaultValues.memberDob &&
            isValid(defaultValues.memberDob) &&
            defaultValues.memberDob.toISOString()),
        label: 'Member Birthday',
      },
    });
  }, [
    defaultValues.cardSendType,
    defaultValues.dateOfService,
    defaultValues.email,
    defaultValues.memberDob,
    defaultValues.memberName.first,
    defaultValues.memberName.last,
    defaultValues.providerName,
    formValues.cardSendType,
    formValues.dateOfService,
    formValues.email,
    formValues.memberDob,
    formValues.memberName.first,
    formValues.memberName.last,
    formValues.providerName,
    setUnsavedChanges,
  ]);

  const previousCardSendType = usePrevious(formValues.cardSendType);
  useEffect(() => {
    if (
      formValues.cardSendType === 'provider' &&
      previousCardSendType === 'member' &&
      formValues.email
    ) {
      setValue('email', '');
    }
    if (
      formValues.cardSendType === 'member' &&
      previousCardSendType === 'provider'
    ) {
      if (!formValues.email && memberEmail) {
        setValue('email', memberEmail);
      }
    }
  }, [
    formValues.cardSendType,
    formValues.email,
    memberEmail,
    previousCardSendType,
    setValue,
  ]);

  const onSubmit = async (values: FormFields) => {
    track({
      label: 'Submit send card',
      type: TrackType.action,
      actionType: 'submit',
      isResend: false,
      sendCardType: values.cardSendType,
      recepientEmail: values.email,
    });
    await sendCard(
      {
        ...values,
        cardType,
        memberDob: values.memberDob?.toISOString().split('T')[0] ?? '',
        dateOfService: values.dateOfService?.toISOString().split('T')[0] ?? '',
        cardId,
      },
      {
        onSuccess: () => {
          handleSetSuccessMessage('Sent');
          reset();
          setUnsavedChanges({
            email: {
              hasUnsavedChanges: false,
              label: 'Share Card Email',
            },
          });
          setToast({
            message: (
              <span>
                Card Sent Successfully to{' '}
                <span className="highlight-block">{values.email}</span>
              </span>
            ),
            severity: 'success',
          });
          track({
            label: 'Sent card',
            type: TrackType.effect,
            isSuccessful: true,
            isResend: false,
            sendCardType: values.cardSendType,
            recepientEmail: values.email,
          });
          incrementMixpanelProfileData('sendCardCount');
          onSuccess?.();
        },
        onError: () => {
          reset(undefined, { keepValues: true });
          setToast({
            message: (
              <span>
                Unable to send card to{' '}
                <span className="highlight-block">{values.email}</span>. Try
                agian later.
              </span>
            ),
            severity: 'error',
          });
          track({
            label: 'Sent card',
            type: TrackType.effect,
            isSuccessful: false,
            isResend: false,
            sendCardType: values.cardSendType,
            recepientEmail: values.email,
          });
        },
      },
    );
  };

  return (
    <Box
      css={css`
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        background-color: ${COLOR_PALETTE.white};
      `}
    >
      <IconButton
        aria-label="close"
        onClick={closeForm}
        css={css`
          color: ${COLOR_PALETTE.secondaryButtonColor};
          position: absolute;
          right: 8px;
          top: 4px;
          padding: 10px;
          z-index: 1;
        `}
      >
        <GridCloseIcon
          css={css`
            height: 20px;
            width: 20px;
          `}
        />
      </IconButton>
      <FormProvider {...useFormMethods}>
        <AsynchronouslyLoadedSection isLoading={isLoading}>
          <Box
            onSubmit={handleSubmit(onSubmit, (errors) => {
              track({
                label: 'Submit send card',
                type: TrackType.action,
                actionType: 'submit',
                isResend: false,
                sendCardType: formValues.cardSendType,
                recepientEmail: formValues.email,
                validationErrors: Object.entries(errors).map(
                  ([key, value]) => ({
                    field: key,
                    message: value.message,
                  }),
                ),
              });
            })}
            css={css`
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              align-content: flex-start;
              gap: 8px;
              overflow-y: auto;
              width: 100%;
              height: 100%;
              padding: 20px 14px;
            `}
            component="form"
          >
            <Typography
              css={css`
                font-size: 14px;
                font-weight: 700;
                flex: 1 1 100%;
                padding-bottom: 8px;
              `}
            >
              Send Card
            </Typography>
            {hasSpendControlSet &&
              isCardActive &&
              isCardholderActive &&
              userData?.accountDetails.companySupportEmail && (
                <>
                  {isResend && (
                    <Alert
                      severity="warning"
                      css={css`
                        width: 100%;
                        margin-bottom: 16px;
                      `}
                    >
                      Each email this card is sent to will have access to this
                      card. If you sent it to the wrong email please deactive
                      the card and create a new one.
                    </Alert>
                  )}
                  <FormFieldSelect<FormFields>
                    disabled={isSubmitting}
                    control={control}
                    name="cardSendType"
                    label="To"
                    defaultValue={defaultValues.cardSendType}
                    rules={{
                      required: 'Select who to send the card to',
                    }}
                    css={css`
                      flex: 1 1 100%;
                    `}
                    shrinkLabel={true}
                    options={[
                      {
                        label: (
                          <Typography
                            css={css`
                              opacity: 0;
                            `}
                          >
                            -
                          </Typography>
                        ),
                        value: '',
                      },
                      {
                        label: `Provider ${
                          cardType === CardType.ongoingUse
                            ? ` (Can't send ongoing use cards to providers)`
                            : ''
                        }`,
                        value: 'provider',
                        disabled: cardType === CardType.ongoingUse,
                      },
                      {
                        label: `Member ${
                          cardholderType === 'internal'
                            ? ` (Can${`'`}t send internal cards to members)`
                            : ''
                        }`,
                        value: 'member',
                        disabled: cardholderType === 'internal',
                      },
                    ]}
                    helperText={
                      <Box
                        css={css`
                          &,
                          & > * {
                            display: block;
                          }
                        `}
                      >
                        {formValues.cardSendType === 'provider' && (
                          <Typography
                            css={css`
                              flex: 1 1 100%;
                              font-size: 12px;
                              color: ${COLOR_PALETTE.lightTextOnLight};
                            `}
                          >
                            <b>Provider</b>: The healthcare facility where the
                            member received services. Cards for both internal
                            and member cardholders can be sent to a healthcare
                            provider
                          </Typography>
                        )}
                        {formValues.cardSendType === 'member' && (
                          <Typography
                            css={css`
                              flex: 1 1 100%;
                              font-size: 12px;
                              color: ${COLOR_PALETTE.lightTextOnLight};
                            `}
                          >
                            <b>Member</b>: A member of your organization. Only
                            cards associated with the member cardholder can be
                            sent to that specific member. Internal cardholders
                            cannot have cards sent to members.
                          </Typography>
                        )}
                      </Box>
                    }
                  />

                  <Box
                    css={css`
                      width: 100%;
                      margin: 8px 0%;
                      border-bottom: 1px dashed ${COLOR_PALETTE.borderMid};
                    `}
                  />

                  <FormFieldInput<FormFields>
                    disabled={isSubmitting}
                    control={control}
                    name="email"
                    label={
                      formValues.cardSendType === 'provider'
                        ? 'Provider Email'
                        : 'Member Email'
                    }
                    defaultValue={defaultValues.email}
                    css={css`
                      flex: 1 1 200px;
                    `}
                    rules={{
                      required: 'Email',
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'Entered value does not match email format',
                      },
                    }}
                  />
                  <FormFieldInput<FormFields>
                    disabled={isSubmitting}
                    control={control}
                    name="emailConfirmation"
                    label="Email Confirmation"
                    defaultValue={defaultValues.emailConfirmation}
                    css={css`
                      flex: 1 1 200px;
                    `}
                    rules={{
                      required: 'Confirm the email address',
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'Entered value does not match email format',
                      },
                      validate: (value, { email }) => {
                        if (value === email) {
                          return true;
                        } else {
                          return 'Emails do not match';
                        }
                      },
                    }}
                  />
                  <FormFieldInput<FormFields>
                    disabled={isSubmitting}
                    control={control}
                    name="memberName.first"
                    label="Member First Name"
                    defaultValue={defaultValues.memberName.first}
                    css={css`
                      flex: 1 1 200px;
                    `}
                    rules={{
                      required: 'Required',
                    }}
                  />
                  <FormFieldInput<FormFields>
                    disabled={isSubmitting}
                    control={control}
                    name="memberName.last"
                    label="Member Last Name"
                    defaultValue={defaultValues.memberName.last}
                    css={css`
                      flex: 1 1 200px;
                    `}
                    rules={{
                      required: 'Required',
                    }}
                  />
                  <FormFieldInput<FormFields>
                    disabled={isSubmitting}
                    control={control}
                    name="memberDob"
                    label="Member Date of Birth"
                    defaultValue={defaultValues.memberDob}
                    type="date"
                    css={css`
                      flex: 1 1 350px;
                    `}
                    rules={{
                      required: 'Required',
                    }}
                  />
                  {cardType === CardType.singleUse && (
                    <Box
                      css={css`
                        display: flex;
                        flex-wrap: wrap;
                        gap: 8px;
                      `}
                    >
                      <FormFieldInput<FormFields>
                        disabled={isSubmitting}
                        control={control}
                        name="providerName"
                        label="Provider Name"
                        defaultValue={defaultValues.providerName}
                        css={css`
                          flex: 1 1 200px;
                        `}
                        rules={{
                          required: 'Required',
                        }}
                      />
                      <FormFieldInput<FormFields>
                        disabled={isSubmitting}
                        control={control}
                        name="dateOfService"
                        label="Date of Service"
                        type="date"
                        defaultValue={defaultValues.dateOfService}
                        css={css`
                          flex: 1 1 200px;
                        `}
                        rules={{
                          required: 'Required',
                        }}
                      />
                    </Box>
                  )}
                  {showUnsavedChangesWarning && (
                    <Alert severity="warning">
                      You have unsaved changes:{' '}
                      {Object.values(unsavedChanges)
                        .filter(({ hasUnsavedChanges }) => hasUnsavedChanges)
                        .map(({ label }) => label)
                        .join(', ')}
                      . Are you sure you want to leave? Click again to leave
                      without saving.
                    </Alert>
                  )}
                  <Box
                    css={css`
                      display: flex;
                      justify-content: flex-end;
                      gap: 8px;
                      width: 100%;
                    `}
                  >
                    <Button
                      onClick={closeForm}
                      variant="contained"
                      color="secondary"
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color={successMessage ? 'success' : 'primary'}
                      disabled={
                        isSubmitting || !hasSpendControlSet || !!successMessage
                      }
                    >
                      {successMessage ?? 'Send Card'}
                    </Button>
                  </Box>
                </>
              )}
          </Box>
        </AsynchronouslyLoadedSection>
      </FormProvider>
    </Box>
  );
};
