import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { lowerCase } from 'lodash';
import { generatePath } from 'react-router-dom';

import { SERVICE_BENJI_CARD_URLS, formatDateToMST, queryKeys } from 'src/utils';

import { useAxios } from './useAxios';
import { useGetUserPrivileges } from './useGetUserPrivileges';

import type {
  DisputeDetailsCleaned,
  DisputeDetailsRaw,
} from 'src/types/dispute';

export const useGetDispute = ({ id }: { id: string }) => {
  const { axios, generateSecureHeaders } = useAxios();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getDispute = async ({ id }: { id: string }) => {
    const { data } = await axios.get<DisputeDetailsRaw>(
      `${generatePath(SERVICE_BENJI_CARD_URLS.DISPUTES_GET_DETAILS, {
        stripeDisputeId: id,
      })}`,
      {
        headers: await generateSecureHeaders(),
      },
    );
    const prettyCreatedAtDate = formatDateToMST(new Date(data.created));
    const status = lowerCase(data.status);
    return {
      id: data.id,
      stripeId: data.stripe_dispute_id,
      createdAt: data.created,
      prettyCreatedAtDate,
      amountCents: data.amount,
      merchantName: data.transaction.merchant,
      status,
      reason: data.evidence?.reason,
      explanation:
        data.evidence?.fraudulent?.explanation ??
        data.evidence?.canceled?.explanation ??
        data.evidence?.duplicate?.explanation ??
        data.evidence?.merchandise_not_as_described?.explanation ??
        data.evidence?.not_received?.explanation ??
        data.evidence?.service_not_as_described?.explanation ??
        data.evidence?.other?.explanation,
      transactionId: data.transaction_id,
      transactionStripeId: data.stripe_transaction_id,
      card: {
        stripeId: data.stripe_card_id,
        lastFour: data.card_last4,
        cardLabel: `****${data.card_last4}`,
      },
      cardholderFullName: data.cardholder_name,
      cardholderStripeId: data.stripe_cardholder_id,
      group: data.group,
    } as DisputeDetailsCleaned;
  };

  return useQuery({
    queryKey: queryKeys.disputes.details({
      id,
    }),
    queryFn: () =>
      getDispute({
        id,
      }),
    enabled: !!isSignedIn && !!privileges?.dispute && !!id,
  });
};
