import { RedirectToSignIn, useUser } from '@clerk/clerk-react';
import { useEffect } from 'react';
import {
  Outlet,
  useRoutes,
  Navigate,
  useParams,
  generatePath as routerGeneratePath,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  AppTrackingContextProvider,
  RouteTrackingContextProvider,
  TrackContext,
  TrackingContextProvider,
} from 'src/context';

import {
  AsynchronouslyLoadedSection,
  PageContainer,
  PermissionsWrapper,
} from './components';
import { useGetUserPrivileges } from './hooks';
import { useGenerateContinueStripeRegistrationLink } from './hooks/useGenerateContinueStripeRegistrationLink';
import { useGetUserDetails } from './hooks/useGetUserDetails';
import Card from './pages/Card';
import { CardholdersPage } from './pages/Cardholders';
import { NewCardholderPage } from './pages/Cardholders/NewCardholder';
import { CardsPage } from './pages/Cards';
import { NewCardPage } from './pages/Cards/NewCard';
import { ContactUsPage } from './pages/ContactUs';
import { DashboardPage } from './pages/Dashboard';
import Disputes from './pages/Disputes';
import { FundsTransfersPage } from './pages/FundsTransfers';
import { NewFundsTransfersPage } from './pages/FundsTransfers/NewFundsTransfer';
import { GroupsPage } from './pages/Groups';
import { NewGroupPage } from './pages/Groups/NewGroup';
import { ViewIFrameCard } from './pages/IFrameCard';
import { LegalPage } from './pages/Legal';
import Profile from './pages/Profile';
import {
  SignInPage,
  SignInPageWrapper,
  // ForgotPasswordPage,
  // ResetPasswordPage,
  RegisterPage,
} from './pages/SignIn';
import { NewSubUserSignUpPage } from './pages/SignIn/NewSubUserSignUpPage';
import Transactions from './pages/Transactions';
import { UsersPage } from './pages/Users';
import { NewUserPage } from './pages/Users/NewUser';
import { Privilege, type UserPrivileges } from './types';

import type { UserDetails } from './hooks/useGetUserDetails';
import type { Route, RouteObject } from 'react-router-dom';

interface Route {
  path?: string;
  index?: boolean;
  element?: React.ReactNode;
  exact?: boolean;
  children?: Route[];
  isNotNavigable?: boolean; // Used purely to clean up the results of routesAsArray
}

const NavigateWithParams = ({
  to,
  ...props
}: {
  to: string;
  replace?: boolean;
  exact?: boolean;
}) => {
  const params = useParams();
  return <Navigate to={routerGeneratePath(to, params)} {...props} />;
};

const AuthRedirect = ({ children }: { children?: React.ReactElement }) => {
  const { isSignedIn, isLoaded } = useUser();
  const { data: user } = useGetUserDetails();

  // TODO: clean up this onboarding experience
  const { mutate: generateContinueStripRegistrationLink } =
    useGenerateContinueStripeRegistrationLink();
  useEffect(() => {
    if (user?.userDetails.status === 'onboarding_incomplete') {
      generateContinueStripRegistrationLink(undefined, {
        onSuccess: (data) => {
          window.location.href = data.url;
        },
      });
    }
  }, [generateContinueStripRegistrationLink, user?.userDetails.status]);

  const location = useLocation();
  const isAuthenticatedRoute = location.pathname.includes('/secure');

  if (isLoaded && isAuthenticatedRoute && !isSignedIn) {
    return <RedirectToSignIn />;
  }
  return children ? children : <Outlet />;
};

const RootRedirect = () => {
  const navigate = useNavigate();
  const { isSignedIn } = useUser();

  useEffect(() => {
    if (isSignedIn) {
      navigate('/secure/dashboard');
    } else {
      navigate('/login');
    }
  }, [isSignedIn, navigate]);
  return <AsynchronouslyLoadedSection isLoading />;
};

interface PagePermissionsWrapperProps
  extends Omit<React.ComponentProps<typeof PermissionsWrapper>, 'hasAccess'> {
  privilegesKey?: keyof UserPrivileges;
  featuresKey?: keyof UserDetails['accountDetails']['features'];
}
const PagePermissionsWrapper = (
  props:
    | PagePermissionsWrapperProps
    | React.ComponentProps<typeof PermissionsWrapper>,
) => {
  const { privileges, isLoading: isLoadingPrivileges } = useGetUserPrivileges();
  const { data: userDetails, isLoading: isLoadingUserDetails } =
    useGetUserDetails();
  const isLoading = isLoadingPrivileges || isLoadingUserDetails;

  const hasPrivilege =
    'privilegesKey' in props && props.privilegesKey
      ? !!privileges?.[props.privilegesKey]
      : true;
  const hasFeature =
    'featuresKey' in props && props.featuresKey
      ? !!userDetails?.accountDetails.features[props.featuresKey]
      : true;
  const hasAccess =
    'hasAccess' in props && props.hasAccess
      ? props.hasAccess
      : hasPrivilege && hasFeature;

  return (
    <PageContainer>
      <PermissionsWrapper
        {...props}
        hasAccess={hasAccess}
        isLoading={props.isLoading || isLoading}
      />
    </PageContainer>
  );
};

const IsUserActiveCheck = ({ children }: { children?: React.ReactElement }) => {
  const { data: user } = useGetUserDetails();
  if (user?.userDetails.status !== 'active') {
    return (
      <PagePermissionsWrapper
        hasAccess={false}
        message="Your user is not active. Please contact your account administrator for assistance."
      />
    );
  } else {
    return children ? children : <Outlet />;
  }
};

const ROUTES: Route[] = [
  {
    path: '/',
    element: (
      // This is required for each major route so generating the basePath works
      <RouteTrackingContextProvider>
        <RootRedirect />
      </RouteTrackingContextProvider>
    ),
  },
  {
    path: 'login',
    element: (
      // This is required for each major route so generating the basePath works
      <RouteTrackingContextProvider>
        <TrackingContextProvider contextName={TrackContext.auth}>
          <SignInPageWrapper />
        </TrackingContextProvider>
      </RouteTrackingContextProvider>
    ),
    children: [
      {
        index: true,
        element: <SignInPage />,
      },
      {
        path: 'welcome-new-subuser',
        element: <NewSubUserSignUpPage />,
      },
      // {
      //   path: 'forgot-password',
      //   element: <ForgotPasswordPage />,
      // },
      // {
      //   path: 'create-password/:token',
      //   element: <ResetPasswordPage />,
      // },
      // {
      //   path: 'reset-password/:token',
      //   element: <ResetPasswordPage />,
      // },
    ],
  },
  {
    path: 'new-org-registration',
    element: (
      // This is required for each major route so generating the basePath works
      <RouteTrackingContextProvider>
        <TrackingContextProvider contextName={TrackContext.auth}>
          <SignInPageWrapper>
            <RegisterPage />
          </SignInPageWrapper>
        </TrackingContextProvider>
      </RouteTrackingContextProvider>
    ),
  },
  {
    path: 'contact-us',
    element: (
      // This is required for each major route so generating the basePath works
      <RouteTrackingContextProvider>
        <ContactUsPage />
      </RouteTrackingContextProvider>
    ),
  },
  {
    path: 'legal',
    element: (
      // This is required for each major route so generating the basePath works
      <RouteTrackingContextProvider>
        <LegalPage />
      </RouteTrackingContextProvider>
    ),
  },
  {
    path: 'view-card/:type/:token',
    element: (
      // This is required for each major route so generating the basePath works
      <RouteTrackingContextProvider>
        <Card />
      </RouteTrackingContextProvider>
    ),
  },
  {
    path: 'iframe-card/:cardId/:token',
    element: (
      // This is required for each major route so generating the basePath works
      <RouteTrackingContextProvider>
        <ViewIFrameCard />
      </RouteTrackingContextProvider>
    ),
  },
  {
    path: 'secure',
    isNotNavigable: true,
    element: (
      <AuthRedirect>
        {/* This is required for each major route so generating the basePath works */}
        <RouteTrackingContextProvider>
          <AppTrackingContextProvider>
            <IsUserActiveCheck />
          </AppTrackingContextProvider>
        </RouteTrackingContextProvider>
      </AuthRedirect>
    ),
    children: [
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'groups',
        children: [
          {
            index: true,
            element: (
              <PagePermissionsWrapper
                privilegesKey={Privilege.cards}
                featuresKey="groups"
              >
                <GroupsPage />
              </PagePermissionsWrapper>
            ),
          },
          {
            path: 'new-group',
            element: (
              <PagePermissionsWrapper
                privilegesKey={Privilege.create_edit_groups}
                featuresKey="groups"
              >
                <NewGroupPage />
              </PagePermissionsWrapper>
            ),
          },
        ],
      },
      {
        path: 'cardholders',
        children: [
          {
            index: true,
            element: (
              <PagePermissionsWrapper privilegesKey={Privilege.cards}>
                <CardholdersPage />
              </PagePermissionsWrapper>
            ),
          },
          {
            path: 'new-cardholder',
            element: (
              <PagePermissionsWrapper privilegesKey={Privilege.cards}>
                <NewCardholderPage />
              </PagePermissionsWrapper>
            ),
          },
        ],
      },
      {
        path: 'cards',
        children: [
          {
            index: true,
            element: (
              <PagePermissionsWrapper privilegesKey={Privilege.cards}>
                <CardsPage />
              </PagePermissionsWrapper>
            ),
          },
          {
            path: 'new-card',
            element: (
              <PagePermissionsWrapper privilegesKey={Privilege.cards}>
                <NewCardPage />
              </PagePermissionsWrapper>
            ),
          },
        ],
      },
      {
        path: 'transactions',
        element: (
          <PagePermissionsWrapper privilegesKey={Privilege.transactions}>
            <Transactions />
          </PagePermissionsWrapper>
        ),
      },
      {
        path: 'funds-transfers',

        children: [
          {
            index: true,
            element: (
              <PagePermissionsWrapper privilegesKey={Privilege.balance}>
                <FundsTransfersPage />
              </PagePermissionsWrapper>
            ),
          },
          {
            path: 'new-transfer',
            element: (
              <PagePermissionsWrapper privilegesKey={Privilege.fund_balance}>
                <NewFundsTransfersPage />
              </PagePermissionsWrapper>
            ),
          },
        ],
      },
      {
        path: 'disputes',
        element: (
          <PagePermissionsWrapper privilegesKey={Privilege.dispute}>
            <Disputes />
          </PagePermissionsWrapper>
        ),
      },
      {
        path: 'users',
        children: [
          {
            index: true,
            element: (
              <PagePermissionsWrapper privilegesKey={Privilege.subuser_admin}>
                <UsersPage />
              </PagePermissionsWrapper>
            ),
          },
          {
            path: 'new-user',
            element: (
              <PagePermissionsWrapper privilegesKey={Privilege.subuser_admin}>
                <NewUserPage />
              </PagePermissionsWrapper>
            ),
          },
        ],
      },
      {
        path: 'profile/security',
        element: <Profile />,
      },
    ],
  },

  {
    path: '*',
    // TODO: get designs for a 404 page, until then redirect to root and the the roor redirect handle it.
    element: <Navigate to="/" replace />,
    // element: (
    //   <div>
    //     <h1>404</h1>
    //   </div>
    // ),
    isNotNavigable: true,
  },

  // Redirect for old Routes
  {
    path: 'resetPassword/:token',
    element: <NavigateWithParams to="/login/reset-password/:token" replace />,
    isNotNavigable: true,
  },
  {
    path: 'password/reset/:token',
    element: <NavigateWithParams to="/login/reset-password/:token" replace />,
    isNotNavigable: true,
  },
  {
    path: 'sentCard/:token',
    element: <NavigateWithParams to="/view-card/:token" replace />,
    isNotNavigable: true,
  },
  {
    path: 'dashboard',
    element: <Navigate to="/secure/dashboard" replace />,
    isNotNavigable: true,
  },
  {
    path: 'cards',
    element: <Navigate to="/secure/cards" replace />,
    isNotNavigable: true,
  },
  {
    path: 'transactions',
    element: <Navigate to="/secure/transactions" replace />,
    isNotNavigable: true,
  },
  {
    path: 'balance',
    element: <Navigate to="/secure/funds-transfers" replace />,
    isNotNavigable: true,
  },
  {
    path: 'secure/balance',
    element: <Navigate to="/secure/funds-transfers" replace />,
    isNotNavigable: true,
  },
  {
    path: 'disputes',
    element: <Navigate to="/secure/disputes" replace />,
    isNotNavigable: true,
  },
  {
    path: 'profile',
    element: <Navigate to="/secure/profile" replace />,
    isNotNavigable: true,
  },
  {
    path: 'contactUs',
    element: <Navigate to="/contact-us" replace />,
    isNotNavigable: true,
  },
  {
    path: 'forgot-password',
    element: <Navigate to="/login" replace />,
    isNotNavigable: true,
  },
  {
    path: 'create-password/:token',
    element: <Navigate to="/login" replace />,
    isNotNavigable: true,
  },
  {
    path: 'reset-password/:token',
    element: <Navigate to="/login" replace />,
    isNotNavigable: true,
  },
  {
    path: 'secure/profile',
    element: <Navigate to="/secure/profile/security" replace />,
    isNotNavigable: true,
  },
];

/**
 * routesAsArray is used to help generate the RoutePath type
 */
// const routesAsArray = ({
//   children,
// }: {
//   children: Route[];
//   accumultingPath?: string;
// }) => {
//   let routes: unknown[] = [];
//   const flattenRoutes = (children: Route[], accumultingPath: string) =>
//     children.forEach(({ path, children, isNotNavigable }) => {
//       const currentPath = [accumultingPath, path].filter(Boolean).join('/');
//       if (path && !isNotNavigable) {
//         routes = [
//           ...routes,
//           currentPath.startsWith('/') ? currentPath : `/${currentPath}`,
//         ];
//       }
//       if (children) {
//         flattenRoutes(children, currentPath);
//       }
//     });
//   flattenRoutes(children, '');
//   return routes;
// };
// console.log(routesAsArray({ children: ROUTES }));

// This is from the result of routesAsArray({ children: ROUTES })
// This is used purely to generate the RoutePath type.
// TODO: figure out a better way to auto generate this type from routesAsArray
const routesArray = [
  '/',
  '/login',
  '/login/forgot-password',
  '/login/create-password/:token',
  '/login/reset-password/:token',
  '/new-org-registration',
  '/contact-us',
  '/legal',
  '/view-card/:token',
  '/secure/dashboard',
  '/secure/groups',
  '/secure/groups/new-group',
  '/secure/cardholders',
  '/secure/cardholders/new-cardholder',
  '/secure/cards',
  '/secure/cards/new-card',
  '/secure/transactions',
  '/secure/funds-transfers',
  '/secure/funds-transfers/new-transfer',
  '/secure/disputes',
  '/secure/users',
  '/secure/users/new-user',
  '/secure/profile',
] as const;

export type RoutePath = (typeof routesArray)[number];

export const AppRoutes = () => useRoutes(ROUTES as RouteObject[]);
