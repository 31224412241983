import { css } from '@emotion/react';
import { Box, Card, Link, Typography } from '@mui/material';
import { isNumber } from 'lodash';

import {
  PaginatedTableWrapper,
  StatusChip,
  Table,
  TableError,
  TableLoader,
  TableNoResults,
} from 'src/components';
import { useTransactionDetailsModal } from 'src/components/modals/TransactionDetailsModal';
import { TrackType, useTrack } from 'src/context';
import { useAppRoutes, useGetUserPrivileges } from 'src/hooks';
import { Privilege } from 'src/types';
import {
  SERVICE_BENJI_CARD_URLS,
  USDFormatterNoCents,
  queryKeys,
} from 'src/utils';

import { useOverviewCardTableTheme } from './useOverviewCardTableTheme';

import { cleanTransactionsAuthsCombined } from '../Transactions/utils';

import type { TableColumn } from 'src/components/Table';
import type {
  TransactionAuthCombinedCleaned,
  TransactionAuthCombinedRaw,
} from 'src/types';

export const TransactionsOverviewCard = () => {
  const { track } = useTrack();
  const { navigate } = useAppRoutes();
  const { privileges } = useGetUserPrivileges();

  const { openModal: openTransactionModal } = useTransactionDetailsModal();

  const { tableTheme } = useOverviewCardTableTheme();

  if (!privileges?.transactions) {
    return null;
  }

  const columns: ({
    width: string;
  } & TableColumn<TransactionAuthCombinedCleaned>)[] = [
    {
      label: 'Date',
      renderCell: ({ prettyAuthDate }) => {
        return prettyAuthDate;
      },
      width: '95px',
    },
    {
      label: 'Payment Method',
      renderCell: ({ paymentMethod }) => {
        return <span className="highlight-block">{paymentMethod}</span>;
      },
      width: 'minmax(140px, 2fr)',
    },
    {
      label: 'Merchant',
      renderCell: ({ merchantName }) => {
        return merchantName;
      },
      width: 'minmax(100px, 1fr)',
    },
    {
      label: 'Amount',
      renderCell: ({ amountCents }) => {
        return isNumber(amountCents)
          ? USDFormatterNoCents.format(parseInt(`${(amountCents ?? 0) / 100}`))
          : '$---';
      },
      width: '90px',
    },
    {
      label: 'Status',
      renderCell: ({ status }) => {
        return (
          <Box
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <StatusChip status={status} type="transaction" />
          </Box>
        );
      },
      width: '130px',
      headerCss: css`
        text-align: center;
      `,
    },
  ];

  return (
    <Card
      css={css`
        flex: 1 1 533px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 12px;
        min-height: 260px;
      `}
    >
      <Box
        css={css`
          display: flex;
          justify-content: space-between;
          padding: 12px 14px 0;
        `}
      >
        <Typography variant="h3">Recent Transactions</Typography>
        <Link
          onClick={() => {
            track({
              label: 'Clicked view all link',
              type: TrackType.action,
              actionType: 'click',
              viewAllName: 'Transactions',
            });
            navigate('/secure/transactions');
          }}
          css={css`
            font-weight: 500;
            line-height: normal;
          `}
        >
          View all
        </Link>
      </Box>
      <PaginatedTableWrapper<
        TransactionAuthCombinedRaw,
        TransactionAuthCombinedCleaned
      >
        endpointUrl={SERVICE_BENJI_CARD_URLS.TRANSACTIONS_GET_ALL}
        queryKeyBase={queryKeys.transactions._baseKey}
        dataFormatter={cleanTransactionsAuthsCombined}
        requiredPrivileges={[Privilege.transactions]}
        shouldUseQueryParamsSearchState={false}
        pageSize={6}
      >
        {({
          queryResult: { isLoading, isError },
          pagination,
          paginationData,
          resultCount,
        }) => (
          <>
            <Table<TransactionAuthCombinedCleaned>
              data={paginationData}
              pagination={pagination}
              columns={columns}
              isLoading={isLoading}
              onRowClick={({ stripeId, type }) => {
                openTransactionModal(stripeId, type);
              }}
              columnWidths={columns.map(({ width }) => width).join(' ')}
              theme={tableTheme}
            />
            <TableLoader isLoading={isLoading} />
            <TableError isLoading={isLoading} isError={isError} />
            <TableNoResults
              isLoading={isLoading}
              resultCount={resultCount}
              noResultMessage="No Transactions Yet!"
            />
          </>
        )}
      </PaginatedTableWrapper>
    </Card>
  );
};
