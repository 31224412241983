import { css } from '@emotion/react';
import {
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  Popover,
  Link,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DataRow, SlideLeftTransition, StatusChip } from 'src/components';
import {
  TrackContext,
  TrackType,
  TrackingContextProvider,
  useModal,
  useTrack,
} from 'src/context';
import { useAppRoutes, useGetUserPrivileges } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { GroupStatus } from 'src/types/groups';
import { searchParamsToObject } from 'src/utils';

import { GroupIsActiveForm } from './GroupIsActiveForm';
import { GroupNameForm } from './GroupNameForm';
import { useGetGroupDetails } from './useGetGroupDetails';

export type UnsavedChanges = Record<
  string,
  { hasUnsavedChanges: boolean; label: string }
>;

const GROUP_DETAILS_MODAL_ID = 'group-details-modal';

export const useGroupDetailsModal = (
  props?: Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>,
) => {
  const { track } = useTrack();
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);

  const modal = useModal({
    modalId: GROUP_DETAILS_MODAL_ID,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      navigate('/secure/groups', {
        queryParams: omit(currentSearchParams, 'selectedGroupId'),
        preventScrollReset: true,
      });
    },
    onOpen: () => {
      track({
        label: 'Clicked open modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Group Details',
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
  return {
    ...modal,
    openModal: (id: string) => {
      const isSamePage = pathname === getRoutePathName('/secure/groups');
      navigate('/secure/groups', {
        queryParams: {
          ...(isSamePage && currentSearchParams),
          selectedGroupId: id,
        },
        replace: true,
        preventScrollReset: isSamePage,
      });
      modal.openModal();
    },
  };
};

const GroupTrackingContextProvider = (props: { children: React.ReactNode }) => {
  const [searchParams] = useSearchParams();
  const selectedGroupId = searchParams.get('selectedGroupId');
  const { data: selectedGroup } = useGetGroupDetails({
    id: selectedGroupId ?? '',
  });

  return (
    <TrackingContextProvider
      trackingProps={{
        groupId: selectedGroup?.id,
        groupName: selectedGroup?.name,
      }}
      contextName={TrackContext['funds-transfer']}
      {...props}
    />
  );
};
const GroupDetailsModalBase = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  const { track } = useTrack();
  const { isOpen, openModal, closeModal } = useGroupDetailsModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Funds Transfer Details',
      });
    },
  });
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const { privileges } = useGetUserPrivileges();
  const [searchParams] = useSearchParams();
  const selectedGroupId = searchParams.get('selectedGroupId');
  const { data: selectedGroup, isLoading } = useGetGroupDetails({
    id: selectedGroupId ?? '',
  });
  useEffect(() => {
    if (!isOpen && selectedGroupId) {
      openModal(selectedGroupId);
    }
  }, [isOpen, openModal, selectedGroupId]);

  const isSamePage = pathname === getRoutePathName('/secure/groups');
  useEffect(() => {
    if (isOpen && !selectedGroupId && isSamePage) {
      closeModal();
    }
  }, [closeModal, isOpen, selectedGroupId, isSamePage]);

  const [unsavedChanges, setUnsavedChanges] = useState<UnsavedChanges>({});
  const hasUnsavedChanges = Object.values(unsavedChanges).some(
    ({ hasUnsavedChanges }) => hasUnsavedChanges,
  );
  const handleSetUnsavedChanges = useMemo(
    () => (newUnsavedChanges: UnsavedChanges) => {
      setUnsavedChanges((unsavedChanges) => ({
        ...unsavedChanges,
        ...newUnsavedChanges,
      }));
    },
    [],
  );
  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] =
    useState(false);

  const handleCloseModal = () => {
    if (hasUnsavedChanges) {
      if (showUnsavedChangesWarning) {
        setUnsavedChanges({});
        setShowUnsavedChangesWarning(false);
        closeModal();
      } else {
        setShowUnsavedChangesWarning(true);
      }
    } else {
      setUnsavedChanges({});
      setShowUnsavedChangesWarning(false);
      closeModal();
    }
  };

  const { name, status, id } = selectedGroup ?? {};

  return (
    <Popover
      open={isOpen}
      onClose={handleCloseModal}
      anchorEl={anchorEl}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      css={(theme) => css`
        ${theme.breakpoints.down('sm')} {
          top: 14px;
        }

        z-index: 1300;

        .MuiPopover-paper {
          display: flex;
          flex-direction: column;
          max-height: 680px;
          height: 100%;
          padding: 0;
          margin: 0;
          max-width: 500px;
          width: 100%;
          border-radius: 14px;
          border: 1px solid ${COLOR_PALETTE.modalBorder};
          box-shadow: 0px 7px 21.8px 15px ${COLOR_PALETTE.modalShadow};
          @media (max-width: 524px) {
            max-width: calc(100vw - 24px);
          }
          @media (max-height: 710px) {
            max-height: calc(100vh - 70px);
            ${theme.breakpoints.down('sm')} {
              max-height: calc(100vh - 80px);
            }
          }
        }
      `}
      TransitionComponent={SlideLeftTransition}
    >
      <DialogTitle
        component="div"
        css={css`
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          gap: 0 8px;
          align-items: center;
          padding: 18px 16px;
          min-height: 58px;
          padding-left: 44px;
          background-color: ${COLOR_PALETTE.modalHeaderBackground};
        `}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            color: ${COLOR_PALETTE.modalHeaderText};
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <GridCloseIcon />
        </IconButton>
        <Typography
          variant="h3"
          css={css`
            flex: 1 1 200px;
            color: ${COLOR_PALETTE.modalHeaderText};
            font-weight: 700;
            line-height: normal;
          `}
        >
          Group Details
        </Typography>
        <Box
          css={css`
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 auto;
            justify-content: flex-end;
          `}
        >
          {!isLoading && (
            <GroupIsActiveForm
              isGroupActive={status === GroupStatus.ACTIVE}
              groupId={selectedGroupId ?? ''}
              disabled={
                !selectedGroupId || isLoading || !privileges.create_edit_groups
              }
            />
          )}
        </Box>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0;
        `}
      >
        {isLoading ? (
          <Box
            css={css`
              flex: 1 1 100%;
              display: flex;
              min-height: 200px;
              justify-content: center;
              align-items: center;
              padding: 24px;
            `}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              css={css`
                padding: 10px 8px;
              `}
            >
              <DataRow
                label="Name"
                value={
                  privileges.create_edit_groups ? (
                    <GroupNameForm
                      name={name ?? ''}
                      groupId={id ?? ''}
                      setUnsavedChanges={handleSetUnsavedChanges}
                      disabled={isLoading}
                    />
                  ) : (
                    name
                  )
                }
              />
              <DataRow
                label="Status"
                value={<StatusChip status={status ?? ''} type="group" />}
              />
            </Box>
            {privileges.cards && (
              <Link
                onClick={() => {
                  navigate('/secure/cardholders', {
                    queryParams: {
                      filters: JSON.stringify({
                        groupId: selectedGroupId,
                        ...(status === GroupStatus.INACTIVE && {
                          status: '',
                        }),
                      }),
                    },
                  });
                }}
                css={css`
                  font-weight: 500;
                  line-height: normal;
                  padding: 4px 8px;
                `}
              >
                View All Cardholders
              </Link>
            )}
            {privileges.cards && (
              <Link
                onClick={() => {
                  navigate('/secure/cards', {
                    queryParams: {
                      filters: JSON.stringify({
                        groupId: selectedGroupId,
                        ...(status === GroupStatus.INACTIVE && {
                          status: '',
                        }),
                      }),
                    },
                  });
                }}
                css={css`
                  font-weight: 500;
                  line-height: normal;
                  padding: 4px 8px;
                `}
              >
                View All Cards
              </Link>
            )}
            {privileges.transactions && (
              <Link
                onClick={() => {
                  navigate('/secure/transactions', {
                    queryParams: {
                      filters: JSON.stringify({
                        groupId: selectedGroupId,
                      }),
                    },
                  });
                }}
                css={css`
                  font-weight: 500;
                  line-height: normal;
                  padding: 4px 8px;
                `}
              >
                View All Transactions
              </Link>
            )}
            {privileges.dispute && (
              <Link
                onClick={() => {
                  navigate('/secure/disputes', {
                    queryParams: {
                      filters: JSON.stringify({
                        groupId: selectedGroupId,
                      }),
                    },
                  });
                }}
                css={css`
                  font-weight: 500;
                  line-height: normal;
                  padding: 4px 8px;
                `}
              >
                View All Disputes
              </Link>
            )}
          </>
        )}
      </DialogContent>
    </Popover>
  );
};

export const GroupDetailsModal = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  return (
    <GroupTrackingContextProvider>
      <GroupDetailsModalBase anchorEl={anchorEl} />
    </GroupTrackingContextProvider>
  );
};
