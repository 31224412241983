import { css } from '@emotion/react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from '@mui/material';

import { TrackType, useModal, useTrack } from 'src/context';

export const CONFIRM_POSSIBLE_DUPLICATE_CARDHOLDER_MODAL =
  'confirm-possible-duplicate-cardholder-modal';
export const useConfirmPossibleDuplicateCardholderModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>>,
) => {
  const { track } = useTrack();

  return useModal({
    modalId: CONFIRM_POSSIBLE_DUPLICATE_CARDHOLDER_MODAL,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
    },
    onOpen: () => {
      track({
        label: 'Modal Opened',
        type: TrackType.effect,
        modalName: 'Duplicate Cardholder',
        isSuccessful: null,
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
};

export const ConfirmPossibleDuplicateCardholderModal = ({
  errorData,
  onSubmit,
  isSubmitting,
}: {
  errorData?: {
    duplicateCardholder: {
      dob: string;
      groupId: string;
      name: string;
    } | null;
  };
  onSubmit: () => void;
  isSubmitting?: boolean;
}) => {
  const { track } = useTrack();
  const { isOpen, closeModal } = useConfirmPossibleDuplicateCardholderModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Duplicate Cardholder',
      });
    },
  });

  const handleCloseModal = () => {
    closeModal();
  };

  const duplicateIsFromSameGroup = !!errorData?.duplicateCardholder?.groupId;

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="xs"
      onClose={isSubmitting ? handleCloseModal : undefined}
    >
      <DialogTitle>
        Duplicate Cardholder
        <IconButton
          disabled={isSubmitting}
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          The name and birthdate you entered is already being used for another
          cardholder{duplicateIsFromSameGroup ? ' for this group' : ''}. Do you
          still want to create this cardholder?
        </Typography>
      </DialogContent>
      <DialogActions
        css={css`
          /* TODO: get color from theme */
          border-top: 1px solid #e0e0e0;
        `}
      >
        <Button
          onClick={handleCloseModal}
          variant="text"
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={isSubmitting} variant="contained">
          Create Cardholder
        </Button>
      </DialogActions>
    </Dialog>
  );
};
