import { lowerCase } from 'lodash';

import { formatDateToMST } from 'src/utils';

import type {
  FundsTransferCleaned,
  FundsTransferRaw,
} from 'src/types/funds-transfers';

export const cleanBalanceTransfers = (
  rawBalanceTransfers: FundsTransferRaw[],
) =>
  rawBalanceTransfers.reduce((acc, balanceTransfer) => {
    const prevBalanceTransfer = acc[acc.length - 1];
    const prettyCreatedAtDate = formatDateToMST(
      new Date(balanceTransfer.created),
    );
    const status = lowerCase(balanceTransfer.status);
    return [
      ...acc,
      {
        id: balanceTransfer.id,
        stripeId: balanceTransfer.stripe_id,
        createdAt: balanceTransfer.created,
        prettyCreatedAtDate,
        isCreatedAtDateSameAsPrev:
          prevBalanceTransfer?.prettyCreatedAtDate === prettyCreatedAtDate,
        amountCents: balanceTransfer.amount,
        status,
        type:
          balanceTransfer.amount > 0
            ? 'External Transfer'
            : balanceTransfer.stripe_id.charAt(0) == 'p'
            ? 'Payout To Bank'
            : 'Spend',
      } as FundsTransferCleaned,
    ];
  }, [] as FundsTransferCleaned[]);
