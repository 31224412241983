import { SignIn, useAuth } from '@clerk/clerk-react';
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { COLOR_PALETTE } from 'src/theme';

import { LogoSection } from './LogoSection';
// import { useTrack } from 'src/context';

export const SignInPage = () => {
  // const { track, incrementMixpanelProfileData } = useTrack();
  // On Success
  // track({
  //   label: 'Signed in',
  //   type: TrackType.effect,
  //   isSuccessful: true,
  //   email,
  //   status: user.status,
  // });
  // incrementMixpanelProfileData('signInCount');
  // On Error
  // track({
  //   label: 'Signed in',
  //   type: TrackType.effect,
  //   isSuccessful: false,
  //   email,
  // });
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');
  const defaultEmail = searchParams.get('email');

  const { isSignedIn, signOut } = useAuth();

  useEffect(() => {
    if (isSignedIn) {
      signOut();
    }
  }, [isSignedIn, signOut]);

  return (
    <>
      <LogoSection />

      <Box
        css={css`
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          width: 100%;
          max-width: 450px;
          padding: 0 32px 60px;
          .cl-signIn-havingTrouble .cl-button {
            color: ${COLOR_PALETTE.textOnLight};
            min-height: 46px;
            border-radius: 100px;
            background-color: ${COLOR_PALETTE.primary};
            font-size: 15px;
            font-weight: 500;
            padding: 12px 16px;
            border: none;
            box-shadow: rgba(242, 190, 87, 0.26) 0px 6px 10px 4px !important;
            &:hover {
              box-shadow: none !important;
              background-color: rgb(171, 148, 92);
            }
            &:active {
              box-shadow: none !important;
            }
            &:focus {
              box-shadow: none !important;
            }
          }
        `}
      >
        <SignIn
          routing="virtual"
          initialValues={{
            emailAddress: defaultEmail ?? '',
          }}
          forceRedirectUrl={redirectUrl}
          appearance={{
            layout: {
              unsafe_disableDevelopmentModeWarnings: true,
            },
            elements: {
              rootBox: {
                width: '100%',
              },
              cardBox: {
                width: '100%',
                boxShadow: 'none',
              },
              card: {
                padding: '8px 8px 24px',
                boxShadow: 'none',
                borderRadius: '0',
              },
              logoBox: {
                display: 'none',
              },
              input: {
                minHeight: '48px',
                borderRadius: '10px',
                border: '1px solid rgb(231, 231, 231)!important',
                '&:hover': {
                  borderColor: 'rgba(0, 0, 0, 0.4)!important',
                },
                '&:focus': {
                  borderColor: `${COLOR_PALETTE.primary}!important`,
                },
                '&:active': {
                  borderColor: `${COLOR_PALETTE.primary}!important`,
                },
              },
              formFieldInputShowPasswordIcon: {
                color: COLOR_PALETTE.textOnLight,
              },
              alternativeMethodsBlockButton: {
                color: COLOR_PALETTE.textOnLight,
                minHeight: '46px',
                borderRadius: '100px',
                backgroundColor: COLOR_PALETTE.primary,
                fontSize: '15px',
                fontWeight: 500,
                padding: '12px 16px',
                border: 'none',
                boxShadow:
                  'rgba(242, 190, 87, 0.26) 0px 6px 10px 4px!important',
                '&:hover': {
                  boxShadow: 'none!important',
                  backgroundColor: 'rgb(171, 148, 92)',
                },
                '&:active': {
                  boxShadow: 'none!important',
                },
                '&:focus': {
                  boxShadow: 'none!important',
                },
              },
              formButtonPrimary: {
                color: COLOR_PALETTE.textOnLight,
                minHeight: '46px',
                borderRadius: '100px',
                backgroundColor: COLOR_PALETTE.primary,
                fontSize: '15px',
                fontWeight: 500,
                padding: '12px 16px',
                border: 'none',
                boxShadow:
                  'rgba(242, 190, 87, 0.26) 0px 6px 10px 4px!important',
                '&:hover': {
                  boxShadow: 'none!important',
                  backgroundColor: 'rgb(171, 148, 92)',
                },
                '&:active': {
                  boxShadow: 'none!important',
                },
                '&:focus': {
                  boxShadow: 'none!important',
                },
              },
              footerActionLink: {
                color: COLOR_PALETTE.textOnLight,
              },
              backLink: {
                color: COLOR_PALETTE.textOnLight,
              },
              otpCodeFieldInput: {
                border: `1px solid ${COLOR_PALETTE.border}!important`,
              },
              footer: {
                background: 'none',
              },
            },
          }}
        />
        {/* <Link
          underline="none"
          onClick={() => {
            track({
              label: 'Click forgot password link',
              type: TrackType.action,
              actionType: 'click',
              email: getValues('email'),
            });
            navigate('/login/forgot-password', {
              queryParams: { email: getValues('email') },
            });
          }}
          css={css`
            cursor: pointer;
            margin-left: auto;
          `}
        >
          Forgot Password
        </Link> */}
        {/* Hiding for now until we revamp the flow */}
        {/* <Box
          css={css`
            width: 100%;
            padding: 60px 0;
            @media (max-width: 600px) {
              padding: 16px 0;
            }
          `}
        >
          <Divider
            css={css`
              border-color: rgba(0, 0, 0, 0.9);
            `}
          />
        </Box>
        <Typography variant="h4">Create an Account</Typography>
        <Typography variant="body1">
          Create a new account and start saving money in no time
        </Typography>
        <Button variant="outlined" size="large" color="secondary">
          Create Account
        </Button> */}
      </Box>
    </>
  );
};
