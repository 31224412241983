import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAxios } from 'src/hooks/useAxios';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import type { CardCleaned } from 'src/types';

export const useUpdateCardNickname = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();

  const updateNickname = async ({
    nickname,
    cardId,
  }: {
    nickname: string;
    cardId: string;
  }) => {
    const { data } = await axios.post(
      SERVICE_BENJI_CARD_URLS.CARD_UPDATE_NICKNAME,
      {
        card_id: cardId,
        card_nickname: nickname,
      },
      {
        headers: await generateSecureHeaders(),
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: { nickname: string; cardId: string }) =>
      updateNickname(data),
    onSuccess: (_, { nickname, cardId }) => {
      queryClient.invalidateQueries({
        queryKey: [
          ...queryKeys.paginatedData._baseKey,
          ...queryKeys.cards._baseKey,
        ],
      });
      const cardQueryKey = queryKeys.cards.details({
        id: cardId,
      });
      queryClient.setQueryData(cardQueryKey, (previous?: CardCleaned) => {
        if (!previous) return;
        return {
          ...previous,
          nickname,
        };
      });
    },
  });
};
