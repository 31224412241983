import { css } from '@emotion/react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { get } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import { FormFieldInput } from 'src/components';
import { useGroupDetailsModal } from 'src/components/modals/GroupDetailsModal';
import { TrackType, useToast, useTrack } from 'src/context';
import { useAppRoutes } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { GroupStatus } from 'src/types/groups';

import { useCreateGroup } from '../useCreateGroup';

export const inputCss = css`
  .MuiInputBase-root {
    height: 48px;
  }
  fieldset {
    border-radius: 10px;
  }
  .Mui-focused fieldset {
    box-shadow: 0px 10px 15px 0px rgba(242, 190, 87, 0.19);
  }
`;

export const FormSection = ({
  label,
  children,
  className,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
}) => (
  <Box
    css={css`
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px 30px;
      padding: 15px 0;
      border-bottom: 1px solid ${COLOR_PALETTE.border};
    `}
    className={className}
  >
    <Typography
      css={css`
        flex: 0 0 165px;
        padding-top: 15px;
        font-size: 15px;
        font-weight: 500;
      `}
    >
      {label}
    </Typography>
    <Box
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        flex: 1 1 400px;
      `}
    >
      {children}
    </Box>
  </Box>
);

export interface FormFields {
  name: string;
  status?: GroupStatus;
}

export const NewGroupPage = () => {
  const { navigate } = useAppRoutes();
  const { setToast } = useToast();
  const { track, incrementMixpanelProfileData } = useTrack();
  const { openModal: openGroupDetailsModal } = useGroupDetailsModal();

  const defaultValues: FormFields = {
    name: '',
    status: GroupStatus.ACTIVE,
  };

  const useFormMethods = useForm<FormFields>({
    defaultValues,
    mode: 'onBlur',
    resetOptions: {
      keepDirtyValues: true,
    },
  });
  const { handleSubmit, control, watch, reset, formState } = useFormMethods;
  const { isSubmitting } = formState;
  const formValues = watch();

  const { mutateAsync: createGroup } = useCreateGroup();

  const onSubmit = async (values: FormFields) => {
    track({
      label: 'Submit create group',
      type: TrackType.action,
      actionType: 'submit',
      groupName: values.name,
      groupStatus: values.status,
    });
    await createGroup(values, {
      onSuccess: (data) => {
        track({
          label: 'Created group',
          type: TrackType.effect,
          isSuccessful: true,
          groupName: values.name,
          groupStatus: values.status,
        });
        incrementMixpanelProfileData('createGroupCount');
        setToast({
          message: 'Group Created Successfully',
          severity: 'success',
        });
        openGroupDetailsModal(data.id);
      },
      onError: (error) => {
        const errorMessage = get(error, 'response.data.message');
        track({
          label: 'Created group',
          type: TrackType.effect,
          isSuccessful: false,
          groupName: values.name,
          groupStatus: values.status,
          errorMessage,
        });
        reset(undefined, { keepValues: true });
        setToast({
          message: errorMessage ?? 'Error Creating Group',
          severity: 'error',
        });
      },
    });
  };

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        gap: 20px;
      `}
    >
      <Box
        css={css`
          display: flex;
          justify-content: space-between;
          gap: 20px;
          flex-wrap: wrap-reverse;
          padding-bottom: 20px;
          border-bottom: 1px solid ${COLOR_PALETTE.border};
        `}
      >
        <Typography
          component="h1"
          css={css`
            font-size: 20px;
            font-weight: 700;
          `}
        >
          Create Group
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => navigate('/secure/groups')}
          css={css`
            background-color: ${COLOR_PALETTE.lightButtonColor};
          `}
        >
          <GridCloseIcon
            css={css`
              height: 20px;
              width: 20px;
            `}
          />
        </IconButton>
      </Box>
      <FormProvider {...useFormMethods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit, (errors) => {
            () => {
              track({
                label: 'Submit create group',
                type: TrackType.action,
                actionType: 'submit',
                groupName: formValues.name,
                groupStatus: formValues.status,
                validationErrors: Object.entries(errors).map(
                  ([key, value]) => ({
                    field: key,
                    message: value.message,
                  }),
                ),
              });
            };
          })}
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 683px;
            margin: 0 auto;
          `}
        >
          <FormSection label="Name">
            <FormFieldInput<FormFields>
              disabled={isSubmitting}
              control={control}
              name="name"
              defaultValue={defaultValues.name}
              css={[
                inputCss,
                css`
                  flex: 1 1 150px;
                `,
              ]}
              rules={{
                required: 'Required',
              }}
            />
          </FormSection>
          <Box
            css={css`
              display: flex;
              justify-content: flex-end;
              gap: 14px;
              padding-top: 40px;
            `}
          >
            <Button
              onClick={() => navigate('/secure/groups')}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              Create Group
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};
