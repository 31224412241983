import { css } from '@emotion/react';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Popover, Typography, IconButton } from '@mui/material';
import { useState } from 'react';

import { TrackType, useTrack } from 'src/context';
export const InfoPopover = ({
  contents,
  name,
  className,
}: {
  contents: string;
  name: string;
  className?: string;
}) => {
  const { track } = useTrack();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    track({
      label: 'Clicked info icon',
      type: TrackType.action,
      actionType: 'click',
      infoIconName: name,
    });
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        css={css`
          font-size: 12px;
          height: 16px;
          width: 16px;
          padding: 0;
          margin-bottom: 4px;
        `}
        className={className}
      >
        <InfoOutlinedIcon
          css={css`
            height: 100%;
            width: 100%;
          `}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          css={css`
            padding: 8px;
            max-width: 400px;
          `}
          variant="body2"
        >
          {contents}
        </Typography>
      </Popover>
    </>
  );
};
