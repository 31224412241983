import { SvgIcon } from '@mui/material';

export const BalanceTransferIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="22"
        height="14"
        viewBox="0 0 22 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 18">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.73828 6.0838C1.73828 6.50439 2.07923 6.84534 2.49982 6.84534L4.65368 6.84534C5.07427 6.84534 5.41522 6.50439 5.41522 6.08381C5.41522 5.66322 5.07427 5.32227 4.65368 5.32227L2.49982 5.32227C2.07923 5.32227 1.73828 5.66322 1.73828 6.0838ZM16.1614 6.0838C16.1614 6.50439 16.5023 6.84534 16.9229 6.84534L21.0832 6.84534C21.3912 6.84534 21.6689 6.6598 21.7867 6.37523C21.9046 6.09067 21.8395 5.76312 21.6217 5.54532L18.8717 2.79531C18.5743 2.49792 18.0921 2.49791 17.7947 2.79531C17.4973 3.09271 17.4973 3.57489 17.7947 3.87229L19.2447 5.32227L16.9229 5.32227C16.5023 5.32227 16.1614 5.66322 16.1614 6.0838Z"
            fill="inherit"
          />
          <path
            id="Vector (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.213264 8.54238C0.0953927 8.82694 0.160547 9.15449 0.378345 9.37229L3.12835 12.1223C3.42574 12.4197 3.90792 12.4197 4.20532 12.1223C4.50272 11.8249 4.50272 11.3427 4.20532 11.0453L2.75535 9.59534L4.65403 9.59534C5.07462 9.59534 5.41557 9.25439 5.41557 8.8338C5.41557 8.41322 5.07462 8.07227 4.65403 8.07227L0.916834 8.07227C0.608821 8.07227 0.331136 8.25781 0.213264 8.54238ZM16.1617 8.8338C16.1617 9.25439 16.5027 9.59534 16.9233 9.59534L19.5001 9.59534C19.9207 9.59534 20.2617 9.25439 20.2617 8.8338C20.2617 8.41322 19.9207 8.07227 19.5001 8.07227L16.9233 8.07227C16.5027 8.07227 16.1617 8.41322 16.1617 8.8338Z"
            fill="inherit"
          />
          <path
            id="Ellipse 1 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 1.83851C8.14941 1.83851 5.83851 4.14941 5.83851 7.00005C5.83851 9.85068 8.14941 12.1616 11 12.1616C13.8507 12.1616 16.1616 9.85068 16.1616 7.00005C16.1616 4.14941 13.8507 1.83851 11 1.83851ZM4.31543 7.00005C4.31543 3.30823 7.30823 0.31543 11 0.31543C14.6919 0.31543 17.6847 3.30823 17.6847 7.00005C17.6847 10.6919 14.6919 13.6847 11 13.6847C7.30823 13.6847 4.31543 10.6919 4.31543 7.00005Z"
            fill="inherit"
          />
          <g id="Group 1">
            <path
              id="Vector (Stroke)_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.18968 4.42503C9.54686 4.06785 10.0313 3.86719 10.5364 3.86719H12.5826C12.9564 3.86719 13.2595 4.17026 13.2595 4.54411C13.2595 4.91796 12.9564 5.22103 12.5826 5.22103H10.5364C10.3904 5.22103 10.2503 5.27906 10.147 5.38235C10.0437 5.48563 9.98568 5.62572 9.98568 5.77179C9.98568 5.91786 10.0437 6.05794 10.147 6.16123C10.2503 6.26452 10.3904 6.32254 10.5364 6.32254H11.3549C11.86 6.32254 12.3445 6.52321 12.7016 6.88039C13.0588 7.23757 13.2595 7.72201 13.2595 8.22715C13.2595 8.73228 13.0588 9.21672 12.7016 9.5739C12.3445 9.93108 11.86 10.1317 11.3549 10.1317H9.30876C8.9349 10.1317 8.63184 9.82868 8.63184 9.45483C8.63184 9.08097 8.9349 8.7779 9.30876 8.7779H11.3549C11.501 8.7779 11.641 8.71988 11.7443 8.61659C11.8476 8.5133 11.9056 8.37321 11.9056 8.22715C11.9056 8.08108 11.8476 7.94099 11.7443 7.8377C11.641 7.73442 11.501 7.67639 11.3549 7.67639H10.5364C10.0313 7.67639 9.54686 7.47573 9.18968 7.11855C8.8325 6.76136 8.63184 6.27692 8.63184 5.77179C8.63184 5.26666 8.8325 4.78221 9.18968 4.42503Z"
              fill="inherit"
            />
            <path
              id="Vector (Stroke)_4"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.9455 3.04883C11.3193 3.04883 11.6224 3.3519 11.6224 3.72575L11.6224 4.5442C11.6224 4.91806 11.3193 5.22113 10.9455 5.22113C10.5716 5.22113 10.2686 4.91806 10.2686 4.5442L10.2686 3.72575C10.2686 3.3519 10.5716 3.04883 10.9455 3.04883ZM10.9455 8.778C11.3193 8.778 11.6224 9.08106 11.6224 9.45492L11.6224 10.2734C11.6224 10.6472 11.3193 10.9503 10.9455 10.9503C10.5716 10.9503 10.2686 10.6472 10.2686 10.2734L10.2686 9.45492C10.2686 9.08106 10.5716 8.778 10.9455 8.778Z"
              fill="inherit"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
