import { SvgIcon } from '@mui/material';

export const MoveFundsIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="22"
        height="14"
        viewBox="0 0 22 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 18">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2627 7.91668C20.2627 7.4961 19.9217 7.15514 19.5012 7.15514L17.3473 7.15514C16.9267 7.15514 16.5858 7.4961 16.5858 7.91668C16.5858 8.33727 16.9267 8.67822 17.3473 8.67822L19.5012 8.67822C19.9217 8.67822 20.2627 8.33727 20.2627 7.91668ZM5.83961 7.91668C5.83961 7.4961 5.49865 7.15514 5.07807 7.15514L0.917803 7.15514C0.609791 7.15514 0.332106 7.34069 0.214233 7.62525C0.0963612 7.90982 0.161516 8.23737 0.379314 8.45517L3.12931 11.2052C3.42671 11.5026 3.90889 11.5026 4.20629 11.2052C4.50369 10.9078 4.50369 10.4256 4.20629 10.1282L2.75632 8.67822L5.07807 8.67822C5.49865 8.67822 5.83961 8.33727 5.83961 7.91668Z"
            fill="#262626"
          />
          <path
            id="Vector (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.7877 5.45811C21.9056 5.17355 21.8404 4.84599 21.6226 4.6282L18.8726 1.87819C18.5752 1.5808 18.0931 1.5808 17.7957 1.87819C17.4983 2.17559 17.4983 2.65777 17.7957 2.95517L19.2456 4.40515L17.3469 4.40515C16.9264 4.40515 16.5854 4.7461 16.5854 5.16668C16.5854 5.58727 16.9264 5.92822 17.3469 5.92822L21.0841 5.92822C21.3922 5.92822 21.6698 5.74268 21.7877 5.45811ZM5.83925 5.16668C5.83925 4.7461 5.4983 4.40515 5.07772 4.40515L2.50083 4.40514C2.08024 4.40514 1.73929 4.7461 1.73929 5.16668C1.73929 5.58727 2.08024 5.92822 2.50083 5.92822L5.07772 5.92822C5.4983 5.92822 5.83925 5.58727 5.83925 5.16668Z"
            fill="#262626"
          />
          <path
            id="Subtract"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.001 13.6846C14.6928 13.6846 17.6856 10.6918 17.6856 6.99995C17.6856 3.30814 14.6928 0.31534 11.001 0.315341C7.30921 0.31534 4.31641 3.30814 4.31641 6.99995C4.31641 10.6918 7.30921 13.6846 11.001 13.6846ZM11.0555 10.9507C10.6816 10.9507 10.3785 10.6476 10.3785 10.2738L10.3785 10.1323L9.41862 10.1323C9.04477 10.1323 8.7417 9.82919 8.7417 9.45533C8.7417 9.08148 9.04477 8.77841 9.41862 8.77841L11.0506 8.77841L11.0555 8.77839L11.0603 8.77841L11.4648 8.77841C11.6108 8.77841 11.7509 8.72038 11.8542 8.6171C11.9575 8.51381 12.0155 8.37372 12.0155 8.22765C12.0155 8.08159 11.9575 7.9415 11.8542 7.83821C11.7509 7.73492 11.6108 7.6769 11.4648 7.6769L10.6463 7.6769C10.1412 7.6769 9.65672 7.47623 9.29954 7.11905C8.94236 6.76187 8.7417 6.27743 8.7417 5.7723C8.7417 5.26716 8.94236 4.78272 9.29954 4.42554C9.59301 4.13208 9.97237 3.94427 10.3785 3.8866L10.3785 3.72615C10.3785 3.35229 10.6816 3.04923 11.0555 3.04923C11.4293 3.04923 11.7324 3.35229 11.7324 3.72615L11.7324 3.86769L12.6924 3.86769C13.0663 3.86769 13.3694 4.17076 13.3694 4.54462C13.3694 4.91847 13.0663 5.22154 12.6924 5.22154L10.6463 5.22154C10.5002 5.22154 10.3601 5.27957 10.2569 5.38285C10.1536 5.48614 10.0955 5.62623 10.0955 5.7723C10.0955 5.91836 10.1536 6.05845 10.2569 6.16174C10.3601 6.26503 10.5002 6.32305 10.6463 6.32305L11.4648 6.32305C11.9699 6.32305 12.4543 6.52372 12.8115 6.8809C13.1687 7.23808 13.3694 7.72252 13.3694 8.22765C13.3694 8.73279 13.1687 9.21723 12.8115 9.57441C12.518 9.8679 12.1386 10.0557 11.7324 10.1134L11.7324 10.2738C11.7324 10.6476 11.4293 10.9507 11.0555 10.9507Z"
            fill="#262626"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
