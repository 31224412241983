import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { isNumber, padStart } from 'lodash';

import { USDFormatter, USDFormatterNoCents } from 'src/utils';

const sizes = {
  md: {
    dollarFontSize: '16px',
    centFontSize: '12px',
    fontWeight: 400,
  },
  lg: {
    dollarFontSize: '26px',
    centFontSize: '16px',
    fontWeight: 400,
  },
  xl: {
    dollarFontSize: '32px',
    centFontSize: '22px',
    fontWeight: 700,
  },
};

export const FancyCurrencyDisplay = ({
  amountCents,
  size = 'md',
  className,
  hasError,
  isPlain = false,
}: {
  amountCents?: number;
  size?: 'md' | 'lg' | 'xl';
  className?: string;
  hasError?: boolean;
  isPlain?: boolean;
}) => {
  if (isPlain) {
    return (
      <Box component="span" className={className}>
        {isNumber(amountCents)
          ? USDFormatter.format(amountCents / 100)
          : '$---'}
      </Box>
    );
  }
  return (
    <Typography
      component="span"
      css={(theme) => css`
        font-size: ${sizes[size].dollarFontSize};
        font-weight: ${sizes[size].fontWeight};
        line-height: 1.1;
        color: ${hasError ? theme.palette.error.main : 'inherit'};
      `}
      className={className}
    >
      {isNumber(amountCents)
        ? USDFormatterNoCents.format(parseInt(`${(amountCents ?? 0) / 100}`))
        : '$---'}
      <Typography
        component="span"
        css={(theme) => css`
          font-size: ${sizes[size].centFontSize};
          font-weight: ${sizes[size].fontWeight};
          color: ${hasError ? theme.palette.error.main : 'inherit'};

          padding-left: 2px;
        `}
      >
        {isNumber(amountCents)
          ? padStart(`${Math.abs((amountCents ?? 0) % 100)}`, 2, '0')
          : '--'}
      </Typography>
    </Typography>
  );
};
