import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAxios } from 'src/hooks/useAxios';
import { CardStatus } from 'src/types/cards';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import type { CardCleaned } from 'src/types';

export const useUpdateCardStatus = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();

  const updateCardStatus = async ({
    isCardActive,
    cardId,
  }: {
    isCardActive: boolean;
    cardId: string;
  }) => {
    const { data } = await axios.post(
      SERVICE_BENJI_CARD_URLS.CARD_UPDATE_STATUS,
      {
        card_id: cardId,
        card_status: isCardActive ? 'active' : 'inactive',
      },
      {
        headers: await generateSecureHeaders(),
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: { isCardActive: boolean; cardId: string }) =>
      updateCardStatus(data),
    onSuccess: (_, { isCardActive, cardId }) => {
      queryClient.invalidateQueries({
        queryKey: [
          ...queryKeys.paginatedData._baseKey,
          ...queryKeys.cards._baseKey,
        ],
      });
      const cardQueryKey = queryKeys.cards.details({
        id: cardId,
      });
      queryClient.setQueryData(cardQueryKey, (previous?: CardCleaned) => {
        if (!previous) return;
        return {
          ...previous,
          isActive: isCardActive,
          status: isCardActive ? CardStatus.ACTIVE : CardStatus.INACTIVE,
        };
      });
    },
  });
};
