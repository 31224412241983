import { css } from '@emotion/react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRef } from 'react';

import { useAppRoutes } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';

import { CreateCardholderForm } from './CreateCardholderForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? '');

export const NewCardholderPage = () => {
  const { navigate } = useAppRoutes();
  const formFunctions = useRef<{
    onClose: (props?: { closeModal: () => void }) => void;
    reset: () => void;
    unsavedChanges: string[];
  }>();

  return (
    <Elements stripe={stripePromise}>
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
        `}
      >
        <Box
          css={css`
            display: flex;
            justify-content: space-between;
            gap: 20px;
            flex-wrap: wrap-reverse;
            padding-bottom: 20px;
            border-bottom: 1px solid ${COLOR_PALETTE.border};
          `}
        >
          <Typography
            component="h1"
            css={css`
              font-size: 20px;
              font-weight: 700;
            `}
          >
            Create Cardholder
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => navigate('/secure/cardholders')}
            css={css`
              background-color: ${COLOR_PALETTE.lightButtonColor};
            `}
          >
            <GridCloseIcon
              css={css`
                height: 20px;
                width: 20px;
              `}
            />
          </IconButton>
        </Box>
        <CreateCardholderForm
          getFormFunctions={(functions) => {
            formFunctions.current = functions;
          }}
        >
          {({ isSubmitting, disabled }) => (
            <Box
              css={css`
                display: flex;
                justify-content: flex-end;
                gap: 14px;
                padding-top: 40px;
              `}
            >
              <Button
                onClick={() => navigate('/secure/cardholders')}
                variant="contained"
                color="secondary"
              >
                Close
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting || disabled}
              >
                Create Cardholder
              </Button>
            </Box>
          )}
        </CreateCardholderForm>
      </Box>
    </Elements>
  );
};
