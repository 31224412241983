import { SvgIcon } from '@mui/material';

export const HomeIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4816 5.81365L10.1402 0.472294C9.51016 -0.157749 8.48942 -0.156921 7.85935 0.471717L0.725595 7.60627C0.445613 7.88628 0.445638 8.34025 0.725652 8.62023C1.00567 8.90021 1.45963 8.90019 1.73961 8.62018L2.30807 8.05165V14.3868C2.30807 15.2775 3.03059 16 3.92128 16H14.0785C14.9692 16 15.6917 15.2775 15.6917 14.3868V8.05172L16.2602 8.6202C16.5402 8.9002 16.9941 8.9002 17.2741 8.6202C17.5541 8.34021 17.5541 7.88624 17.2741 7.60624L15.4816 5.81365ZM10.0754 14.566H7.92441V11.3994C7.92441 11.3006 8.00492 11.2201 8.10366 11.2201H9.89611C9.99485 11.2201 10.0754 11.3006 10.0754 11.3994V14.566Z"
          fill="inherit"
        />
      </svg>
    </SvgIcon>
  );
};
