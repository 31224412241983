import { css } from '@emotion/react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { debounce } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  AsynchronouslyLoadedSection,
  CardTypeRadioCards,
  FancyCurrencyDisplay,
  StatusChip,
} from 'src/components';
import { DataItemDisplay } from 'src/components/DataItemDisplay';
import { useCardDetailsModal } from 'src/components/modals/CardDetailsModal';
import { TrackType, useToast, useTrack } from 'src/context';
import {
  useAppRoutes,
  useGetPaginatedData,
  useGetUserDetails,
} from 'src/hooks';
import { useCreateCard } from 'src/hooks/useCreateCard';
import {
  CreateCardholderForm,
  FormSection,
} from 'src/pages/Cardholders/NewCardholder/CreateCardholderForm';
import { cleanCardholders } from 'src/pages/Cardholders/utils';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { formatDateToMST, queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import type { CardholderCleaned } from 'src/types/cardholders';
import type { CardType } from 'src/types/cards';

const TabPanel = ({
  children,
  value,
  index,
}: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && children}
    </Box>
  );
};

export interface FormFields {
  cardType?: CardType | '';
}

export const NewCardPage = () => {
  const { navigate } = useAppRoutes();
  const { track, incrementMixpanelProfileData } = useTrack();
  const [selectedCardholder, setSelectedCardholder] =
    useState<CardholderCleaned | null>(null);
  const formFunctions = useRef<{
    onClose: (props?: { closeModal: () => void }) => void;
    reset: () => void;
    unsavedChanges: string[];
  }>();
  const [tab, setTab] = useState(0);

  const { mutate: createCard, isLoading: isCreatingCard } = useCreateCard();
  const { openModal: openCardDetailsModal } = useCardDetailsModal();

  const [cardholderSearchInputValue, setCardholderSearchInputValue] =
    useState('');
  const [cardholderSearchValue, setCardholderSearchValue] = useState('');
  const debouncer = useRef(
    debounce((value) => {
      setCardholderSearchValue(value);
    }, 1000),
  );
  const handleSetCardholderSearchValue = useCallback(
    (value: string) => debouncer.current(value),
    [],
  );
  const { data: cardholders, isLoading: isLoadingCardholders } =
    useGetPaginatedData({
      endpointUrl: SERVICE_BENJI_CARD_URLS.CARDHOLDERS_GET,
      queryKeyBase: queryKeys.cardholders._baseKey,
      filters: {
        search: cardholderSearchValue,
      },
      dataFormatter: cleanCardholders,
      requiredPrivileges: [Privilege.cards],
      autoFetchNextPage: false,
    });

  const { data: { accountDetails } = {}, isLoading: isLoadingUser } =
    useGetUserDetails();
  const orgCardTypes = accountDetails?.companyCardTypes;
  const { setToast } = useToast();
  const defaultValues: FormFields = useMemo(
    () => ({
      // if org has more than one card type
      cardType: orgCardTypes?.length === 1 ? orgCardTypes[0] ?? '' : '',
    }),
    [orgCardTypes],
  );
  console.log('defaultValues', defaultValues);
  const useFormMethods = useForm<FormFields>({
    defaultValues,
    mode: 'onBlur',
    resetOptions: {
      keepDirtyValues: true,
    },
  });
  const { handleSubmit, control, formState } = useFormMethods;
  const { isSubmitting } = formState;

  const onSubmit = async (values: FormFields) => {
    await createCard(
      {
        cardholderId: selectedCardholder?.stripeId ?? '',
        cardType: (orgCardTypes?.length === 1
          ? orgCardTypes[0] ?? ''
          : values.cardType) as CardType,
      },
      {
        onSuccess: (data) => {
          track({
            label: 'Created card',
            type: TrackType.effect,
            isSuccessful: true,
            isFromExistingCardholder: true,
            cardId: data.id,
            cardExpMonth: data.exp_month,
            cardExpYear: data.exp_year,
            cardSpendControlAmountCents: null,
            cardStatus: data.status,
            cardholderId: selectedCardholder?.id,
            cardholderStripeId: selectedCardholder?.stripeId,
            cardCreatedAt: new Date(data.created).toISOString(),
          });
          incrementMixpanelProfileData('createCardCount');
          setToast({
            message: `Card Created Successfully`,
            severity: 'success',
          });
          openCardDetailsModal(data.cardStripeId);
        },
        onError: () => {
          track({
            label: 'Created card',
            type: TrackType.effect,
            isSuccessful: false,
            isFromExistingCardholder: true,
            cardholderId: selectedCardholder?.stripeId,
          });
          setToast({
            message: 'Error Creating Cardholder',
            severity: 'error',
          });
        },
      },
    );
  };

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        gap: 20px;
      `}
    >
      <Box
        css={css`
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          border-bottom: 1px solid ${COLOR_PALETTE.border};
        `}
      >
        <Box
          css={css`
            flex: 1 1 100%;
            display: flex;
            justify-content: space-between;
            gap: 10px 20px;
            flex-wrap: wrap-reverse;
          `}
        >
          <Typography
            component="h1"
            css={css`
              font-size: 20px;
              font-weight: 700;
            `}
          >
            Create Card
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => navigate('/secure/cards')}
            css={css`
              background-color: ${COLOR_PALETTE.lightButtonColor};
            `}
          >
            <GridCloseIcon
              css={css`
                height: 20px;
                width: 20px;
              `}
            />
          </IconButton>
        </Box>
        <Box
          css={css`
            flex: 1 1 100%;
          `}
        >
          <Tabs
            value={tab}
            onChange={(_e, tab) => setTab(tab)}
            centered
            css={css`
              display: flex;
              position: relative;
              bottom: -1px;
              min-height: 0;
              .MuiTabs-flexContainer {
                justify-content: flex-start;
              }
              button {
                min-height: 0;
                padding: 10px;
                &.Mui-selected p {
                  font-weight: 700;
                }
              }
            `}
          >
            <Tab
              id="create-card-modal-tab-0"
              aria-controls="create-card-modal-tabpanel-0"
              css={css`
                width: 162px;
              `}
              label={
                <Typography
                  css={css`
                    font-size: 14px;
                  `}
                >
                  Existing Cardholder
                </Typography>
              }
            />
            <Tab
              id="create-card-modal-tab-1"
              aria-controls="create-card-modal-tabpanel-1"
              css={css`
                width: 136px;
              `}
              label={
                <Typography
                  css={css`
                    font-size: 14px;
                  `}
                >
                  New Cardholder
                </Typography>
              }
            />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={tab} index={0}>
        <Box
          css={css`
            display: flex;
            flex-direction: column;
            max-width: 683px;
            margin: 0 auto;
          `}
        >
          <Typography
            component="label"
            htmlFor="cardholder-search"
            css={css`
              color: ${COLOR_PALETTE.lightTextOnLight};
              font-size: 12px;
              font-weight: 600;
              padding-bottom: 4px;
            `}
          >
            Cardholder
          </Typography>
          <Autocomplete
            id="cardholder-search"
            disablePortal
            options={cardholders?.results ?? []}
            getOptionKey={({ id }) => id}
            getOptionLabel={(option) =>
              option ? option.name.full : cardholderSearchInputValue
            }
            onChange={(_e, value) => {
              setSelectedCardholder(value);
            }}
            value={selectedCardholder}
            onInputChange={(_e, value) => {
              setCardholderSearchInputValue(value);
              handleSetCardholderSearchValue(value);
            }}
            inputValue={cardholderSearchInputValue}
            loading={!!cardholderSearchValue && isLoadingCardholders}
            slotProps={{
              paper: {
                sx: {
                  maxHeight: '300px',
                  '& *': {
                    maxHeight: '300px',
                  },
                },
              },
            }}
            // overriding filter options to allow for filtering to happen on BE.
            filterOptions={(x) => x}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Typography variant="body1">
                  {option.name.full} -{' '}
                  <Box
                    component="span"
                    css={css`
                      opacity: 0.6;
                    `}
                    className="highlight-block"
                  >
                    {option.billingAddress?.line1}
                    {option.billingAddress?.line2
                      ? ` ${option.billingAddress?.line2}`
                      : ''}
                    , {option.billingAddress?.city},{' '}
                    {option.billingAddress?.state}{' '}
                    {option.billingAddress?.postal_code}
                  </Box>
                </Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="highlight-block"
                placeholder="Select Cardholder"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {!!cardholderSearchValue && isLoadingCardholders ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                css={css`
                  fieldset {
                    border-radius: 10px;
                  }
                  .Mui-focused fieldset {
                    box-shadow: 0px 10px 15px 0px rgba(242, 190, 87, 0.19);
                  }
                `}
              />
            )}
          />
          <Box
            css={css`
              padding-top: 24px;
              border-bottom: 1px dashed ${COLOR_PALETTE.borderMid};
            `}
          />
          {selectedCardholder ? (
            <>
              <AsynchronouslyLoadedSection isLoading={isLoadingUser}>
                <Box
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0 30px;
                    width: 100%;
                    padding-top: 24px;
                  `}
                >
                  <DataItemDisplay
                    label="Name"
                    size="small"
                    value={selectedCardholder.name?.full}
                    css={css`
                      flex: 1 1 120px;
                      margin-bottom: 16px;
                      .data-item-display-label {
                        text-transform: uppercase;
                      }
                    `}
                  />
                  <DataItemDisplay
                    label="Created On"
                    size="small"
                    value={
                      !!selectedCardholder.createdAt &&
                      formatDateToMST(new Date(selectedCardholder.createdAt))
                    }
                    css={css`
                      flex: 1 1 120px;
                      margin-bottom: 16px;
                      .data-item-display-label {
                        text-transform: uppercase;
                      }
                    `}
                  />
                  <DataItemDisplay
                    label="Status"
                    size="small"
                    value={
                      <Box
                        css={css`
                          display: flex;
                        `}
                      >
                        <StatusChip
                          status={selectedCardholder.status}
                          type="cardholder"
                        />
                      </Box>
                    }
                    css={css`
                      flex: 1 1 120px;
                      margin-bottom: 16px;
                      .data-item-display-label {
                        text-transform: uppercase;
                      }
                    `}
                  />
                  <Box
                    css={css`
                      flex: 1 1 120px;
                    `}
                  >
                    {/* Spacer */}
                  </Box>
                  <DataItemDisplay
                    label="Billing Address"
                    size="small"
                    value={`${selectedCardholder.billingAddress?.line1}${
                      selectedCardholder.billingAddress?.line2
                        ? ` ${selectedCardholder.billingAddress?.line2}`
                        : ''
                    },${' '}
              ${selectedCardholder.billingAddress?.city}, ${selectedCardholder
                .billingAddress?.state}${' '}
              ${selectedCardholder.billingAddress?.postal_code}`}
                    css={css`
                      flex: 1 1 120px;
                      margin-bottom: 16px;
                      .data-item-display-label {
                        text-transform: uppercase;
                      }
                    `}
                    isPrivate
                  />

                  <DataItemDisplay
                    label="Total Spent"
                    css={css`
                      flex: 1 1 120px;
                      margin-bottom: 16px;
                      .data-item-display-label {
                        text-transform: uppercase;
                      }
                    `}
                    size="small"
                    value={
                      <FancyCurrencyDisplay
                        isPlain
                        amountCents={
                          selectedCardholder.totalSpendCents
                            ? selectedCardholder.totalSpendCents * -1
                            : 0
                        }
                      />
                    }
                  />

                  <DataItemDisplay
                    label="Transactions"
                    css={css`
                      flex: 1 1 120px;
                      margin-bottom: 16px;
                      .data-item-display-label {
                        text-transform: uppercase;
                      }
                    `}
                    size="small"
                    value={selectedCardholder.transactionsCount ?? 0}
                  />

                  <DataItemDisplay
                    label="Cards"
                    size="small"
                    css={css`
                      flex: 1 1 120px;

                      margin-bottom: 16px;
                      .data-item-display-label {
                        text-transform: uppercase;
                      }
                    `}
                    value={selectedCardholder.cardsCount ?? 0}
                  />
                </Box>
              </AsynchronouslyLoadedSection>
              <FormProvider {...useFormMethods}>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit, (errors) => {
                    () => {
                      track({
                        label: 'Submit create cardholder',
                        type: TrackType.action,
                        actionType: 'submit',
                        cardholderEmail: selectedCardholder.email,
                        cardholderFirstName: selectedCardholder.name.first,
                        cardholderLastName: selectedCardholder.name.last,
                        cardholderStatus: 'active',
                        cardholderCreatedAt: new Date().toISOString(),
                        validationErrors: Object.entries(errors).map(
                          ([key, value]) => ({
                            field: key,
                            message: value.message,
                          }),
                        ),
                      });
                    };
                  })}
                  css={css`
                    width: 100%;
                  `}
                >
                  {(orgCardTypes?.length ?? 0) > 1 && (
                    <FormSection
                      label="Card Type"
                      css={css`
                        border-bottom: initial;
                        border-top: 1px solid ${COLOR_PALETTE.border};
                      `}
                    >
                      <CardTypeRadioCards
                        disabled={isSubmitting}
                        control={control}
                        name="cardType"
                        label=""
                        defaultValue={defaultValues.cardType}
                        css={css`
                          flex: 1 1 100%;
                          .MuiFormGroup-root {
                            padding-top: 0;
                          }
                        `}
                      />
                    </FormSection>
                  )}
                  <Box
                    css={css`
                      display: flex;
                      justify-content: flex-end;
                      gap: 14px;
                      padding-top: 40px;
                    `}
                  >
                    <Button
                      onClick={() => navigate('/secure/cards')}
                      variant="contained"
                      color="secondary"
                    >
                      Close
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        track({
                          label: 'Submit create card',
                          type: TrackType.action,
                          actionType: 'submit',
                          isFromExistingCardholder: true,
                          cardholderId: selectedCardholder?.id,
                          cardholderStripeId: selectedCardholder?.stripeId,
                        });
                      }}
                      disabled={!selectedCardholder || isCreatingCard}
                    >
                      Create Card
                    </Button>
                  </Box>
                </Box>
              </FormProvider>
            </>
          ) : (
            <Box
              css={css`
                display: flex;
                justify-content: flex-end;
                gap: 14px;
                padding-top: 40px;
              `}
            >
              <Button
                onClick={() => navigate('/secure/cards')}
                variant="contained"
                color="secondary"
              >
                Close
              </Button>
            </Box>
          )}

          {/* TODO: when cardholder is selected if there are multiple allowed card types add a card type form input */}
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <CreateCardholderForm
          isFromCreateCardFlow
          onSuccess={({ id, cardType }) => {
            createCard(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- isFromCreateCardFlow guarantees this is will be returned
              { cardholderId: id, cardType: cardType! },
              {
                onSuccess: (data) => {
                  openCardDetailsModal(data.cardStripeId);
                  track({
                    label: 'Created card',
                    type: TrackType.effect,
                    isSuccessful: true,
                    isFromExistingCardholder: false,
                    cardId: data.id,
                    cardExpMonth: data.exp_month,
                    cardExpYear: data.exp_year,
                    cardSpendControlAmountCents: null,
                    cardStatus: data.status,
                    cardholderId: data.cardholder.id,
                    cardholderStripeId: id,
                    cardCreatedAt: new Date(data.created).toISOString(),
                  });
                  incrementMixpanelProfileData('createCardCount');
                },
                onError: () => {
                  track({
                    label: 'Created card',
                    type: TrackType.effect,
                    isSuccessful: false,
                    isFromExistingCardholder: false,
                    cardholderId: id,
                  });
                },
              },
            );
          }}
          getFormFunctions={(functions) => {
            formFunctions.current = functions;
          }}
        >
          {({ isSubmitting, disabled }) => (
            <Box
              css={css`
                display: flex;
                justify-content: flex-end;
                gap: 14px;
                padding-top: 40px;
              `}
            >
              <Button
                onClick={() => navigate('/secure/cards')}
                variant="contained"
                color="secondary"
              >
                Close
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting || isCreatingCard || disabled}
                onClick={() => {
                  track({
                    label: 'Submit create card',
                    type: TrackType.action,
                    actionType: 'submit',
                    isFromExistingCardholder: false,
                  });
                }}
              >
                Create Cardholder and Card
              </Button>
            </Box>
          )}
        </CreateCardholderForm>
      </TabPanel>
    </Box>
  );
};
