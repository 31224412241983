import { SvgIcon } from '@mui/material';

export const DateInputIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
      >
        <g clipPath="url(#clip0_500_14413)">
          <path
            d="M3.5 5.25781C3.5 4.85999 3.65804 4.47846 3.93934 4.19715C4.22064 3.91585 4.60218 3.75781 5 3.75781H14C14.3978 3.75781 14.7794 3.91585 15.0607 4.19715C15.342 4.47846 15.5 4.85999 15.5 5.25781V14.2578C15.5 14.6556 15.342 15.0372 15.0607 15.3185C14.7794 15.5998 14.3978 15.7578 14 15.7578H5C4.60218 15.7578 4.22064 15.5998 3.93934 15.3185C3.65804 15.0372 3.5 14.6556 3.5 14.2578V5.25781Z"
            stroke="#141E27"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 2.25781V5.25781"
            stroke="#141E27"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.5 2.25781V5.25781"
            stroke="#141E27"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.5 8.25781H15.5"
            stroke="#141E27"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.5 11.2578H8V12.7578H6.5V11.2578Z"
            stroke="#141E27"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_500_14413">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0.5 0.0078125)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
