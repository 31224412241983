import { css } from '@emotion/react';
import {
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Link,
  CircularProgress,
  IconButton,
  Popover,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { omit, startCase } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DataRow,
  FancyCurrencyDisplay,
  SlideLeftTransition,
  StatusChip,
} from 'src/components';
import {
  TrackContext,
  TrackType,
  TrackingContextProvider,
  useModal,
  useTrack,
} from 'src/context';
import { useAppRoutes, useGetFeatures, useGetUserPrivileges } from 'src/hooks';
import { useGetDispute } from 'src/hooks/useGetDispute';
import { COLOR_PALETTE } from 'src/theme';
import { formatDateToMST, searchParamsToObject } from 'src/utils';

export const DISPUTE_DETAILS_MODAL_ID = 'dispute-details-modal';

const dataRowLabelWidth = 120;

export const useDisputeDetailsModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>>,
) => {
  const { track } = useTrack();
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);

  const modal = useModal({
    modalId: DISPUTE_DETAILS_MODAL_ID,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      navigate('/secure/disputes', {
        queryParams: omit(currentSearchParams, 'selectedDisputeId'),
        preventScrollReset: true,
      });
    },
    onOpen: () => {
      track({
        label: 'Clicked open modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Dispute Details',
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
  return {
    ...modal,
    openModal: (id: string) => {
      const isSamePage = pathname === getRoutePathName('/secure/disputes');
      navigate('/secure/disputes', {
        queryParams: {
          ...(isSamePage && currentSearchParams),
          selectedDisputeId: id,
        },
        preventScrollReset: isSamePage,
      });
      modal.openModal();
    },
  };
};

const DisputeTrackingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const selectedDisputeId = searchParams.get('selectedDisputeId');
  const { data: selectedDispute } = useGetDispute({
    id: selectedDisputeId ?? '',
  });

  return (
    <TrackingContextProvider
      trackingProps={{
        disputeId: selectedDispute?.id,
        disputeAmountCents: selectedDispute?.amountCents,
        disputeStatus: selectedDispute?.status,
        disputeCreatedAt: selectedDispute?.createdAt
          ? new Date(selectedDispute.createdAt).toISOString()
          : null,
        transactionId: selectedDispute?.transactionId,
      }}
      contextName={TrackContext.dispute}
      {...props}
    />
  );
};
const DisputeDetailsModalBase = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  const { track } = useTrack();
  const { isOpen, openModal, closeModal } = useDisputeDetailsModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Dispute Details',
      });
    },
  });
  const { privileges } = useGetUserPrivileges();
  const { pathname, getRoutePathName, generatePath } = useAppRoutes();
  const { features } = useGetFeatures();
  const [searchParams] = useSearchParams();
  const selectedDisputeId = searchParams.get('selectedDisputeId');
  const { data: dispute, isLoading } = useGetDispute({
    id: selectedDisputeId ?? '',
  });

  useEffect(() => {
    if (!isOpen && selectedDisputeId) {
      openModal(selectedDisputeId);
    }
  }, [isOpen, openModal, selectedDisputeId]);

  const isSamePage = pathname === getRoutePathName('/secure/disputes');
  useEffect(() => {
    if (isOpen && !selectedDisputeId && isSamePage) {
      closeModal();
    }
  }, [closeModal, isOpen, selectedDisputeId, isSamePage]);

  const handleCloseModal = () => {
    closeModal();
  };

  const {
    id,
    createdAt,
    merchantName,
    amountCents,
    status,
    reason,
    explanation,
    transactionStripeId,
    card,
  } = dispute ?? {};

  const cardholderLink = generatePath('/secure/cardholders', undefined, {
    selectedCardholderId: dispute?.cardholderStripeId ?? '',
  });
  const cardLink = generatePath('/secure/cards', undefined, {
    selectedCardId: card?.stripeId ?? '',
  });
  const transactionLink = generatePath('/secure/transactions', undefined, {
    selectedTransactionId: transactionStripeId ?? '',
    type: 'transaction',
  });

  const createdAtObj = !!createdAt && new Date(createdAt);

  return (
    <Popover
      open={isOpen}
      onClose={handleCloseModal}
      anchorEl={anchorEl}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      css={(theme) => css`
        ${theme.breakpoints.down('sm')} {
          top: 14px;
        }

        z-index: 1300;

        .MuiPopover-paper {
          display: flex;
          flex-direction: column;
          max-height: 680px;
          height: 100%;
          padding: 0;
          margin: 0;
          max-width: 500px;
          width: 100%;
          border-radius: 14px;
          border: 1px solid ${COLOR_PALETTE.modalBorder};
          box-shadow: 0px 7px 21.8px 15px ${COLOR_PALETTE.modalShadow};
          @media (max-width: 524px) {
            max-width: calc(100vw - 24px);
          }
          @media (max-height: 710px) {
            max-height: calc(100vh - 70px);
            ${theme.breakpoints.down('sm')} {
              max-height: calc(100vh - 80px);
            }
          }
        }
      `}
      TransitionComponent={SlideLeftTransition}
    >
      <DialogTitle
        component="div"
        css={css`
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          gap: 0 8px;
          align-items: center;
          padding: 18px 16px;
          min-height: 58px;
          padding-left: 44px;
          background-color: ${COLOR_PALETTE.modalHeaderBackground};
        `}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            color: ${COLOR_PALETTE.modalHeaderText};
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <GridCloseIcon />
        </IconButton>
        <Typography
          variant="h3"
          css={css`
            flex: 1 1 200px;
            color: ${COLOR_PALETTE.modalHeaderText};
            font-weight: 700;
            line-height: normal;
          `}
        >
          Dispute Details
        </Typography>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0;
        `}
      >
        {isLoading ? (
          <Box
            css={css`
              flex: 1 1 100%;
              display: flex;
              min-height: 200px;
              justify-content: center;
              align-items: center;
              padding: 24px;
            `}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            css={css`
              padding: 10px 8px;
            `}
          >
            <DataRow
              label="Status"
              value={<StatusChip status={status ?? ''} type="dispute" />}
              labelWidth={dataRowLabelWidth}
            />
            <DataRow
              label="Date"
              value={!!createdAtObj && formatDateToMST(createdAtObj)}
              labelWidth={dataRowLabelWidth}
            />
            <DataRow
              label="Amount"
              value={
                privileges.transactions ? (
                  <Typography
                    component="a"
                    href={transactionLink}
                    css={css`
                      color: inherit;
                    `}
                  >
                    <FancyCurrencyDisplay amountCents={amountCents} />
                  </Typography>
                ) : (
                  <FancyCurrencyDisplay amountCents={amountCents} />
                )
              }
              labelWidth={dataRowLabelWidth}
            />
            <DataRow
              label="Merchant"
              value={merchantName}
              labelWidth={dataRowLabelWidth}
            />
            <DataRow
              label="Reason"
              value={startCase(reason)}
              labelWidth={dataRowLabelWidth}
            />
            {!!explanation && (
              <DataRow
                label="Explanation"
                value={explanation}
                labelWidth={dataRowLabelWidth}
              />
            )}
            <DataRow
              label="Card"
              value={
                privileges.cards ? (
                  <Typography
                    component="a"
                    href={cardLink}
                    css={css`
                      color: inherit;
                    `}
                  >
                    {card?.cardLabel ?? ''}
                  </Typography>
                ) : (
                  card?.cardLabel
                )
              }
              labelWidth={dataRowLabelWidth}
            />
            <DataRow
              label="Cardholder"
              value={
                privileges.cards ? (
                  <Typography
                    component="a"
                    href={cardholderLink}
                    css={css`
                      color: inherit;
                    `}
                  >
                    {dispute?.cardholderFullName ?? ''}
                  </Typography>
                ) : (
                  dispute?.cardholderFullName
                )
              }
              labelWidth={dataRowLabelWidth}
            />
            {features?.groups && (
              <DataRow
                label="Group"
                value={
                  privileges.cards ? (
                    <Typography
                      component="a"
                      href={generatePath('/secure/groups', undefined, {
                        selectedGroupId: dispute?.group?.id ?? '',
                      })}
                      css={css`
                        color: inherit;
                      `}
                    >
                      {dispute?.group?.name}
                    </Typography>
                  ) : (
                    dispute?.group?.name
                  )
                }
                labelWidth={dataRowLabelWidth}
              />
            )}
          </Box>
        )}

        <Typography
          variant="body1"
          css={css`
            margin-top: auto;
            width: 100%;
            padding: 16px;
            text-align: center;
            font-size: 12px;
            font-weight: 300;
            line-height: 166.66%;
            color: ${COLOR_PALETTE.lightTextOnLight};
          `}
        >
          If you have any issues with this dispute, please contact us at{' '}
          <Link
            href={`mailto:support@benjicard.com?subject=Issue with dispute (${id})`}
            css={css`
              color: ${COLOR_PALETTE.blueLink};
            `}
          >
            support@benjicard.com
          </Link>
          .
        </Typography>
      </DialogContent>
    </Popover>
  );
};

export const DisputeDetailsModal = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  return (
    <DisputeTrackingContextProvider>
      <DisputeDetailsModalBase anchorEl={anchorEl} />
    </DisputeTrackingContextProvider>
  );
};
