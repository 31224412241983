import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';

export const useAxios = () => {
  const { getToken } = useAuth();

  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    async (config) => {
      return config;
    },
    (error) => Promise.reject(error),
  );

  const generateSecureHeaders = async () => {
    const token = await getToken();
    return {
      Authorization: `Bearer ${token}`,
    };
  };

  return { axios: axiosInstance, generateSecureHeaders };
};
