import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import type { CardType } from 'src/types/cards';

interface NoCard {
  card: false;
  message: string;
}
interface CardBasicDetailsRaw {
  card: {
    member_first_name: string;
    member_last_name: string;
    agree_to_terms_required: boolean;
    payer_company_name: string;
    supportEmail: string;
    type: CardType;
    isActive: boolean;
  };
}
interface CardDetails {
  hasCard: boolean;
  details?: {
    memberName: {
      first: CardBasicDetailsRaw['card']['member_first_name'];
      last: CardBasicDetailsRaw['card']['member_last_name'];
      full: string;
    };
    payerCompanyName: CardBasicDetailsRaw['card']['payer_company_name'];
    supportEmail: CardBasicDetailsRaw['card']['supportEmail'];
    cardType?: CardBasicDetailsRaw['card']['type'];
    isActive: CardBasicDetailsRaw['card']['isActive'];
  };
}

const getCardBasicDetails = async ({ token }: { token: string }) => {
  const { data } = await axios.get<CardBasicDetailsRaw | NoCard>(
    generatePath(SERVICE_BENJI_CARD_URLS.CARD_SEND_BASIC_DETAILS, {
      token,
    }),
  );

  const cleanedData: CardDetails = {
    hasCard: data.card ? true : false,
    details: data.card
      ? {
          memberName: {
            first: data.card.member_first_name,
            last: data.card.member_last_name,
            full: `${data.card.member_first_name} ${data.card.member_last_name}`,
          },
          payerCompanyName: data.card.payer_company_name,
          supportEmail: data.card.supportEmail,
          cardType: data.card.type,
          isActive: data.card.isActive,
        }
      : undefined,
  };

  return cleanedData;
};

export const useGetCardBasicDetails = ({ token }: { token?: string }) => {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps -- TODO: fix this
    queryKey: queryKeys.viewCard.details({
      token: token ?? '',
    }),
    queryFn: () =>
      getCardBasicDetails({
        token: token ?? '',
      }),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!token,
  });
};
