import { SvgIcon } from '@mui/material';

export const PhoneInputIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
      >
        <g clipPath="url(#clip0_500_14390)">
          <path
            d="M4.25 3.00781H7.25L8.75 6.75781L6.875 7.88281C7.67822 9.51146 8.99635 10.8296 10.625 11.6328L11.75 9.75781L15.5 11.2578V14.2578C15.5 14.6556 15.342 15.0372 15.0607 15.3185C14.7794 15.5998 14.3978 15.7578 14 15.7578C11.0744 15.58 8.31512 14.3377 6.24262 12.2652C4.17013 10.1927 2.92779 7.43336 2.75 4.50781C2.75 4.10999 2.90804 3.72846 3.18934 3.44715C3.47064 3.16585 3.85218 3.00781 4.25 3.00781Z"
            stroke="#141E27"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_500_14390">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0.5 0.0078125)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
