import { SvgIcon } from '@mui/material';

export const CreateCardIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 9" clipPath="url(#clip0_416_10876)">
          <path
            id="Vector"
            d="M10.5417 17.4163H5.5C4.77065 17.4163 4.07118 17.1266 3.55546 16.6109C3.03973 16.0952 2.75 15.3957 2.75 14.6663V7.33301C2.75 6.60366 3.03973 5.90419 3.55546 5.38846C4.07118 4.87274 4.77065 4.58301 5.5 4.58301H16.5C17.2293 4.58301 17.9288 4.87274 18.4445 5.38846C18.9603 5.90419 19.25 6.60366 19.25 7.33301V10.9997"
            stroke="#141E27"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M2.75 9.16699H19.25"
            stroke="#141E27"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M17.417 15.583L17.417 19.2497"
            stroke="#141E27"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M15.583 17.417H19.2497"
            stroke="#141E27"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_5"
            d="M6.4209 13.75H6.42548"
            stroke="#141E27"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_6"
            d="M10.083 13.75H11.9163"
            stroke="#141E27"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_416_10876">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
