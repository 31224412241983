import { css } from '@emotion/react';
import { Card, Box, Button, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { get } from 'lodash';
import { useRef, useState } from 'react';

import {
  FancyCurrencyDisplay,
  InfoPopover,
  PaginatedTableWrapper,
  StatusChip,
  Table,
  TableError,
  TableLoader,
  TableMenu,
  TableNoResults,
  TablePagination,
} from 'src/components';
import { CreateCardIcon } from 'src/components/Icon';
import {
  CardDetailsModal,
  useCardDetailsModal,
} from 'src/components/modals/CardDetailsModal';
import { CreateDisputeModal } from 'src/components/modals/CreateDisputeModal';
import { SearchBar } from 'src/components/PaginatedTableWrapper/SearchBar';
import { FilterType, TableFilters } from 'src/components/TableFilters';
import { TrackType, useToast, useTrack } from 'src/context';
import {
  useAppRoutes,
  useGetFeatures,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import {
  DownloadEntity,
  useDownloadTableCsv,
} from 'src/hooks/useDownloadTableCsv';
import { Privilege } from 'src/types';
import { CardStatus, CardType, CardTypeLabel } from 'src/types/cards';
import { formatDateToMST, queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { cleanCards } from './utils';

import type { Column } from '@table-library/react-table-library/types/compact';
import type { PaginatedTableFilter } from 'src/components/PaginatedTableWrapper';
import type { TableFilter } from 'src/components/TableFilters';
import type { CardCleaned, CardRaw } from 'src/types';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? '');

export const CardsPage = () => {
  const { track } = useTrack();
  const { navigate } = useAppRoutes();
  const { setToast } = useToast();
  const cardRef = useRef<HTMLDivElement>(null);

  const { data: { accountDetails } = {}, isLoading: isLoadingUser } =
    useGetUserDetails();
  const orgCardTypes = accountDetails?.companyCardTypes;
  const { features } = useGetFeatures();

  const { openModal: openCardDetailsModal } = useCardDetailsModal();

  const { isLoading: areUserPrivilegesLoading, privileges } =
    useGetUserPrivileges();

  const [filters, setFilters] = useState<PaginatedTableFilter>({});

  const columns: ({ width: string } & Column<CardCleaned>)[] = [
    {
      label: 'Date',
      renderCell: () => {
        return <span />;
      },
      width: '130px',
    },
    {
      label: 'Cardholder',
      renderCell: ({ cardholderName }) => cardholderName.full,
      width: 'minmax(150px, 250px)',
    },
    {
      label: 'Nickname',
      renderCell: ({ nickname }) => (
        <span className="highlight-block">{nickname}</span>
      ),
      width: 'minmax(150px, 250px)',
    },
    {
      label: 'Card No',
      renderCell: ({ lastFour }) => (
        <span className="highlight-block">**{lastFour}</span>
      ),
      width: '90px',
    },
    {
      label: 'Status',
      renderCell: ({ status }) => {
        return (
          <Box
            css={css`
              display: flex;
            `}
          >
            <StatusChip status={status} type="card" />
          </Box>
        );
      },
      width: '100px',
    },
    {
      label: 'Spent',
      renderCell: ({ totalSpendCents }) => {
        return (
          <FancyCurrencyDisplay
            amountCents={totalSpendCents ? totalSpendCents * -1 : 0}
            isPlain
          />
        );
      },
      width: '140px',
    },
    {
      label: 'Remaining Limit',
      renderCell: ({ spendingControls, isActive }) => {
        const { remainingSpendLimitCents, totalSpendingLimitCents } =
          spendingControls;
        if (totalSpendingLimitCents === null || !isActive) {
          return null;
        } else {
          return (
            <FancyCurrencyDisplay
              amountCents={remainingSpendLimitCents}
              isPlain
            />
          );
        }
      },
      width: '145px',
    },
    {
      label: 'Spend Limit',
      renderCell: ({ spendingControls, isActive }) => {
        const { totalSpendingLimitCents } = spendingControls;
        if (totalSpendingLimitCents === null || !isActive) {
          return null;
        } else {
          return (
            <FancyCurrencyDisplay
              amountCents={totalSpendingLimitCents}
              isPlain
            />
          );
        }
      },
      width: '140px',
    },
    ...((orgCardTypes?.length ?? 0) > 1
      ? [
          {
            label: 'Card Type',
            renderCell: ({ type }: CardCleaned) => (
              <Box
                css={css`
                  font-weight: 400;
                `}
                component="span"
              >
                {CardTypeLabel[type] ?? type}
              </Box>
            ),
            width: '140px',
          },
        ]
      : []),
    ...(features?.groups
      ? [
          {
            label: 'Group',
            renderCell: ({ group }: CardCleaned) => group?.name,
            width: 'minmax(150px, 250px)',
          },
        ]
      : []),
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];

  const isPageLoading = areUserPrivilegesLoading;

  const { mutate: download, isLoading: isDownloading } = useDownloadTableCsv();

  return (
    <>
      <Box
        css={css`
          max-height: calc(100vh - 96px);
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        `}
      >
        <Card
          component="div"
          css={css`
            display: flex;
            width: 100%;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 1 auto;
          `}
          ref={cardRef}
        >
          <PaginatedTableWrapper<CardRaw, CardCleaned>
            endpointUrl={SERVICE_BENJI_CARD_URLS.CARDS_GET}
            queryKeyBase={queryKeys.cards._baseKey}
            dataFormatter={cleanCards}
            requiredPrivileges={[Privilege.cards]}
            filters={filters}
          >
            {({
              queryResult: {
                isLoading,
                isError,
                data: { invalidFiltersWarningMessages } = {},
              },
              pagination,
              paginationData,
              resultCount,
              pageCount,
              searchProps,
            }) => {
              return (
                <>
                  <Box
                    css={css`
                      width: 100%;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      align-content: center;
                      gap: 8px 16px;
                      padding: 16px 16px 0;
                    `}
                  >
                    <Typography
                      variant="h2"
                      css={css`
                        flex-grow: 999;
                      `}
                    >
                      Cards
                    </Typography>
                    <Box
                      css={css`
                        display: flex;
                        flex-wrap: wrap-reverse;
                        gap: 16px;
                        flex-grow: 1;
                        justify-content: flex-end;
                      `}
                    >
                      <SearchBar {...searchProps} isLoading={!!isLoading} />
                      {!!privileges?.issue_card && (
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => navigate('/secure/cards/new-card')}
                          disabled={isPageLoading}
                          startIcon={<CreateCardIcon />}
                        >
                          Create Card
                        </Button>
                      )}
                      <TableMenu
                        disabled={isPageLoading}
                        menuItems={[
                          {
                            label: 'Download CSV',

                            onClick: () => {
                              track({
                                label: 'Clicked Download Card Table',
                                type: TrackType.action,
                                actionType: 'click',
                              });
                              const today = new Date();
                              const createdStartDate = get(
                                filters,
                                'createdOn.startDate',
                              );
                              const createdEndDate = get(
                                filters,
                                'createdOn.endDate',
                              );
                              const hasOnlyStartDateSet =
                                !!createdStartDate && !createdEndDate;
                              download(
                                {
                                  downloadEntity: DownloadEntity.CARDS,
                                  filters: {
                                    ...filters,
                                    ...(!!hasOnlyStartDateSet && {
                                      createdOn: {
                                        startDate: createdStartDate,
                                        // Default end date to today if only start date is set.
                                        endDate: formatDateToMST(
                                          today,
                                          'yyyy-MM-dd',
                                        ),
                                      },
                                    }),
                                    search: searchProps.searchValue,
                                  },
                                },
                                {
                                  onError: (error) => {
                                    const message = get(
                                      error,
                                      'message',
                                      'An error occurred while downloading the CSV. Try different filters or try again later.',
                                    );
                                    setToast({
                                      message,
                                      severity: 'error',
                                    });
                                  },
                                },
                              );
                            },
                            disabled: isDownloading,
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                  <Box
                    css={css`
                      width: 100%;
                      display: flex;
                      gap: 8px 16px;
                      flex-wrap: nowrap;
                      padding: 0 16px;
                    `}
                  >
                    <TableFilters
                      setFilters={setFilters}
                      priorityFilterCount={1}
                      filters={[
                        {
                          name: 'status',
                          label: 'Status',
                          type: FilterType.SELECT,
                          defaultValue: CardStatus.ACTIVE,
                          widthPx: 110,
                          options: [
                            { value: '', label: 'All' },
                            { value: CardStatus.ACTIVE, label: 'Active' },
                            { value: CardStatus.INACTIVE, label: 'Inactive' },
                            { value: CardStatus.CANCELED, label: 'Canceled' },
                          ],
                        },
                        ...((orgCardTypes?.length ?? 0) > 1
                          ? [
                              {
                                name: 'cardType',
                                label: 'Type',
                                type: FilterType.SELECT,
                                defaultValue: '',
                                widthPx: 115,
                                options: [
                                  { value: '', label: 'All' },
                                  {
                                    value: CardType.ongoingUse,
                                    label: CardTypeLabel[CardType.ongoingUse],
                                  },
                                  {
                                    value: CardType.singleUse,
                                    label: CardTypeLabel[CardType.singleUse],
                                  },
                                ],
                              } as TableFilter,
                            ]
                          : []),

                        {
                          name: 'hasUnspentLimits',
                          label: 'Unspent Limits',
                          type: FilterType.SWITCH,
                          defaultValue: false,
                        },
                        {
                          name: 'isStale',
                          label: 'Is Stale',
                          type: FilterType.SWITCH,
                          defaultValue: false,
                          infoPopover: (
                            <InfoPopover
                              contents="Active, single-use cards that have not been used in the last 30 days."
                              name="Stale Cards"
                              css={css`
                                margin-left: 2px;
                              `}
                            />
                          ),
                        },
                        {
                          name: 'createdOn',
                          label: 'Created On',
                          type: FilterType.DATE_RANGE,
                          defaultValue: {
                            startDate: null,
                            endDate: null,
                          },
                          widthPx: 110,
                        },
                        {
                          name: 'groupId',
                          label: 'Viewing Single Group',
                          defaultValue: '',
                          type: FilterType.CHIP,
                        },
                        {
                          name: 'cardholderId',
                          label: 'Viewing Single Cardholder',
                          defaultValue: '',
                          type: FilterType.CHIP,
                        },
                      ]}
                    />
                  </Box>
                  <Table<CardCleaned>
                    data={paginationData}
                    pagination={pagination}
                    columns={columns}
                    isLoading={isLoading || isPageLoading || isLoadingUser}
                    combinedFirstColumn={{
                      primaryColumnKey: 'prettyCreatedAtDate',
                      columnComparatorKey: 'isCreatedAtDateSameAsPrev',
                    }}
                    onRowClick={({ stripeId }) =>
                      openCardDetailsModal(stripeId)
                    }
                    columnWidths={columns.map(({ width }) => width).join(' ')}
                    css={css`
                      margin-top: 10px;
                      width: 100%;
                    `}
                  />
                  <TableLoader isLoading={isLoading} />
                  <TableError isLoading={isLoading} isError={isError} />
                  <TableNoResults
                    isLoading={isLoading}
                    resultCount={resultCount}
                    searchTerms={searchProps.searchValue}
                    noResultMessage={
                      invalidFiltersWarningMessages?.length
                        ? `${invalidFiltersWarningMessages
                            .map(({ message }) => message)
                            .join(', ')}.`
                        : `Please try a different search term. You can search by the cardholder${`'`}s name, card nickname or the card's last four digits.`
                    }
                  />
                  <TablePagination
                    resultCount={resultCount}
                    pageCount={pageCount}
                    pagination={pagination}
                  />
                </>
              );
            }}
          </PaginatedTableWrapper>
        </Card>
      </Box>
      <Elements stripe={stripePromise}>
        <CreateDisputeModal />
        <CardDetailsModal anchorEl={cardRef.current} />
      </Elements>
    </>
  );
};
