import { SvgIcon } from '@mui/material';

export const CancelCloseIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="cancel">
          <path
            id="Vector 57"
            d="M3 3.5L15 15.5"
            stroke="#888888"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            id="Vector 58"
            d="M15 3.5L3 15.5"
            stroke="#888888"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
