export enum GroupStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface GroupRaw {
  id: string;
  name: string;
  status: GroupStatus;
}

export interface GroupCleaned extends GroupRaw {}
export interface GroupDetailsRaw extends GroupRaw {}
export interface GroupDetailsCleaned extends GroupRaw {}
