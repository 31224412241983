import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { lowerCase } from 'lodash';
import { generatePath } from 'react-router-dom';

import { SERVICE_BENJI_CARD_URLS, formatDateToMST, queryKeys } from 'src/utils';

import { useAxios } from './useAxios';
import { useGetUserPrivileges } from './useGetUserPrivileges';

import type {
  CardholderDetailsCleaned,
  CardholderDetailsRaw,
} from 'src/types/cardholders';

export const useGetCardholder = ({ id }: { id: string }) => {
  const { axios, generateSecureHeaders } = useAxios();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getCardholder = async ({ id }: { id: string }) => {
    const { data } = await axios.get<CardholderDetailsRaw>(
      `${generatePath(SERVICE_BENJI_CARD_URLS.CARDHOLDER_GET_DETAILS, {
        stripeCardholderId: id,
      })}`,
      {
        headers: await generateSecureHeaders(),
      },
    );
    const status = lowerCase(data.status);
    return {
      id: data.id,
      stripeId: data.stripe_cardholder_id,
      billingAddress: data.billing.address,
      createdAt: data.created,
      prettyCreatedAtDate: formatDateToMST(new Date(data.created)),
      name: {
        first: data.individual?.first_name,
        last: data.individual?.last_name,
        full: data.name,
      },
      status: status,
      isActive: status === 'active',
      email: data.email,
      phoneNumber: data.phone_number,
      dob:
        data.individual?.dob?.year &&
        data.individual.dob.month &&
        data.individual.dob.day
          ? `${data.individual?.dob?.year ?? ''}-${`${
              data.individual?.dob?.month ?? ''
            }`.padStart(2, '0')}-${`${
              data.individual?.dob?.day ?? ''
            }`.padStart(2, '0')}`
          : '',
      cardholderType: data.metadata.type,
      totalSpendingLimitCents: data.accumulated_spend_limit_cents ?? 0,
      totalSpendCents: data.accumulated_spend_cents ?? 0,
      remainingSpendLimitCents: data.accumulated_remaining_spend_limit_cents,
      transactionsCount: data.total_transactions ?? 0,
      cardsCount: data.total_cards ?? 0,
      group: data.group,
    } as CardholderDetailsCleaned;
  };

  return useQuery({
    queryKey: queryKeys.cards.details({
      id,
    }),
    queryFn: () =>
      getCardholder({
        id,
      }),
    enabled: !!isSignedIn && !!privileges?.cards && !!id,
  });
};
