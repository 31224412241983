import { SvgIcon } from '@mui/material';

export const CreateCardholderIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 7">
          <g id="Union">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.231445 3.47393C0.231445 1.55533 1.78678 0 3.70537 0H14.3944C16.313 0 17.8683 1.55533 17.8683 3.47393V11.4907C17.8683 13.4093 16.313 14.9646 14.3944 14.9646H3.70537C1.78678 14.9646 0.231445 13.4093 0.231445 11.4907V3.47393ZM3.70537 1.60335C2.67228 1.60335 1.8348 2.44084 1.8348 3.47393V11.4907C1.8348 12.5238 2.67228 13.3613 3.70537 13.3613H14.3944C15.4275 13.3613 16.265 12.5238 16.265 11.4907V3.47393C16.265 2.44084 15.4275 1.60335 14.3944 1.60335H3.70537Z"
              fill="#262626"
            />
            <path
              d="M5.57595 19.5074C5.57595 19.0647 5.93487 18.7058 6.37763 18.7058H11.7221C12.1649 18.7058 12.5238 19.0647 12.5238 19.5074C12.5238 19.9502 12.1649 20.3091 11.7221 20.3091H6.37763C5.93487 20.3091 5.57595 19.9502 5.57595 19.5074Z"
              fill="#262626"
            />
            <path
              d="M3.70537 16.0335C3.26262 16.0335 2.9037 16.3924 2.9037 16.8352C2.9037 17.2779 3.26262 17.6369 3.70537 17.6369L14.3944 17.6369C14.8371 17.6369 15.1961 17.2779 15.1961 16.8352C15.1961 16.3924 14.8371 16.0335 14.3944 16.0335L3.70537 16.0335Z"
              fill="#262626"
            />
          </g>
          <path
            id="Union_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.59733 9.85C8.59733 10.209 8.88835 10.5 9.24733 10.5C9.60632 10.5 9.89733 10.209 9.89733 9.85V7.90042H11.849C12.208 7.90042 12.499 7.60941 12.499 7.25042C12.499 6.89144 12.208 6.60042 11.849 6.60042H9.89733V4.65C9.89733 4.29102 9.60632 4 9.24733 4C8.88835 4 8.59733 4.29102 8.59733 4.65V6.60042H6.64902C6.29004 6.60042 5.99902 6.89144 5.99902 7.25042C5.99902 7.60941 6.29004 7.90042 6.64902 7.90042H8.59733V9.85Z"
            fill="#262626"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
