import { useUser } from '@clerk/clerk-react';
import { H } from 'highlight.run';
import { useEffect } from 'react';

import { useGetUserDetails, useGetUserPrivileges } from 'src/hooks';

H.init(process.env.REACT_APP_HIGHLIGHT_PROJECT_ID, {
  environment: process.env.REACT_APP_ENV,
  serviceName: 'frontend-app',
  tracingOrigins: true,
  disableSessionRecording: window.location.hostname === 'localhost',
  networkRecording: {
    enabled: true,
    // TODO: enable this once we have a way to filter out sensitive data
    // https://www.highlight.io/docs/getting-started/client-sdk/replay-configuration/recording-network-requests-and-responses
    recordHeadersAndBody: false,
    urlBlocklist: [],
  },
});

export const Highlight = () => {
  const { isSignedIn } = useUser();
  const { data: user } = useGetUserDetails();
  const { privileges, isSuccess: hasLoadedPrivileges } = useGetUserPrivileges();
  const { data: userDetails, isSuccess: hasLoadedUserDetails } =
    useGetUserDetails();

  useEffect(() => {
    if (isSignedIn && user && hasLoadedPrivileges && hasLoadedUserDetails) {
      const name = `${userDetails.userDetails.first_name} ${userDetails.userDetails.last_name}`;
      H.identify(user.userDetails.id, {
        email: user.userDetails.email,
        highlightDisplayName: name,
        name,
        company: userDetails.accountDetails.companyName,
        ...(!!privileges && privileges),
      });
    }
  }, [
    hasLoadedPrivileges,
    hasLoadedUserDetails,
    isSignedIn,
    privileges,
    user,
    userDetails?.accountDetails.companyName,
    userDetails?.userDetails.first_name,
    userDetails?.userDetails.last_name,
  ]);

  return null;
};
