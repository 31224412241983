import { css } from '@emotion/react';
import { Box, Button, Card, Typography } from '@mui/material';
import { useRef } from 'react';

import {
  PaginatedTableWrapper,
  StatusChip,
  Table,
  TableError,
  TableLoader,
  TableNoResults,
  TablePagination,
} from 'src/components';
import { CreateUserIcon } from 'src/components/Icon';
import { SearchBar } from 'src/components/PaginatedTableWrapper/SearchBar';
import { useAppRoutes, useGetUserPrivileges } from 'src/hooks';
import { Privilege } from 'src/types/user';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import {
  SubUserDetailsModal,
  useSubUserDetailsModal,
} from './SubUserDetailsModal';
import { cleanSubUsers } from './utils';

import type { Column } from '@table-library/react-table-library/types/compact';
import type { SubUserCleaned, SubUserRaw } from 'src/types/user';

export const UsersPage = () => {
  const { navigate } = useAppRoutes();
  const cardRef = useRef<HTMLDivElement>(null);

  const { privileges, isLoading: areUserPrivilegesLoading } =
    useGetUserPrivileges();
  const { openModal: openSubUserDetailsModal } = useSubUserDetailsModal();

  const columns: ({ width: string } & Column<SubUserCleaned>)[] = [
    {
      label: 'Name',
      renderCell: ({ name }) => name.full,
      width: 'minmax(150px, 250px)',
    },
    {
      label: 'Email',
      renderCell: ({ email }) => (
        <span className="highlight-block">{email}</span>
      ),
      width: 'minmax(150px, 300px)',
    },
    {
      label: 'Status',
      renderCell: ({ status }) => (
        <Box
          css={css`
            display: flex;
          `}
        >
          <StatusChip status={status} type="user" />
        </Box>
      ),
      width: '100px',
    },
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];

  const isPageLoading = areUserPrivilegesLoading;
  return (
    <>
      <Box
        css={css`
          max-height: calc(100vh - 96px);
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        `}
      >
        <Card
          component="div"
          css={css`
            display: flex;
            width: 100%;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 1 auto;
          `}
          ref={cardRef}
        >
          <PaginatedTableWrapper<SubUserRaw, SubUserCleaned>
            endpointUrl={SERVICE_BENJI_CARD_URLS.USERS_GET_SUB_USERS}
            queryKeyBase={queryKeys.subUsers._baseKey}
            dataFormatter={cleanSubUsers}
            requiredPrivileges={[Privilege.subuser_admin]}
          >
            {({
              queryResult: { isLoading, isError },
              pagination,
              paginationData,
              resultCount,
              pageCount,
              searchProps,
            }) => (
              <>
                <Box
                  css={css`
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    gap: 16px;
                    padding: 16px 16px 0;
                  `}
                >
                  <Typography
                    variant="h2"
                    css={css`
                      flex-grow: 999;
                    `}
                  >
                    Users
                  </Typography>
                  <Box
                    css={css`
                      display: flex;
                      flex-wrap: wrap-reverse;
                      gap: 16px;
                      flex-grow: 1;
                      justify-content: flex-end;
                    `}
                  >
                    <SearchBar {...searchProps} isLoading={!!isLoading} />
                    {!!privileges?.subuser_admin && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate('/secure/users/new-user')}
                        disabled={isPageLoading}
                        startIcon={<CreateUserIcon />}
                      >
                        Create User
                      </Button>
                    )}
                  </Box>
                </Box>
                <Table<SubUserCleaned>
                  data={paginationData}
                  pagination={pagination}
                  columns={columns}
                  isLoading={isLoading || isPageLoading}
                  onRowClick={({ id }) => {
                    if (privileges?.subuser_admin) {
                      openSubUserDetailsModal(id);
                    }
                  }}
                  columnWidths={columns.map(({ width }) => width).join(' ')}
                  css={css`
                    margin-top: 10px;
                    width: 100%;
                  `}
                />
                <TableLoader isLoading={isLoading} />
                <TableError isLoading={isLoading} isError={isError} />
                <TableNoResults
                  isLoading={isLoading}
                  resultCount={resultCount}
                  searchTerms={searchProps.searchValue}
                  noResultMessage={`Please try a different search term. You can search by name or email.`}
                />
                <TablePagination
                  resultCount={resultCount}
                  pageCount={pageCount}
                  pagination={pagination}
                />
              </>
            )}
          </PaginatedTableWrapper>
        </Card>
      </Box>
      <SubUserDetailsModal anchorEl={cardRef.current} />
    </>
  );
};
