import { SvgIcon } from '@mui/material';

export const UsersIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 8">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.71282 2.08294C4.46687 1.32889 5.48957 0.905273 6.55595 0.905273C7.62234 0.905273 8.64504 1.32889 9.39909 2.08294C10.1531 2.83698 10.5768 3.85969 10.5768 4.92607C10.5768 5.99245 10.1531 7.01516 9.39909 7.7692C8.64504 8.52325 7.62234 8.94687 6.55595 8.94687C5.48957 8.94687 4.46687 8.52325 3.71282 7.7692C2.95877 7.01516 2.53516 5.99245 2.53516 4.92607C2.53516 3.85969 2.95877 2.83698 3.71282 2.08294ZM6.55595 2.42835C5.89352 2.42835 5.25821 2.6915 4.7898 3.15992C4.32138 3.62833 4.05823 4.26363 4.05823 4.92607C4.05823 5.58851 4.32138 6.22381 4.7898 6.69223C5.25821 7.16064 5.89352 7.42379 6.55595 7.42379C7.21839 7.42379 7.8537 7.16064 8.32211 6.69223C8.79052 6.22381 9.05367 5.58851 9.05367 4.92607C9.05367 4.26363 8.79052 3.62833 8.32211 3.15992C7.8537 2.6915 7.21839 2.42835 6.55595 2.42835Z"
            fill="inherit"
          />
          <path
            id="Vector (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.92607 12.2057C4.26363 12.2057 3.62833 12.4688 3.15992 12.9373C2.6915 13.4057 2.42835 14.041 2.42835 14.7034V16.333C2.42835 16.7536 2.0874 17.0946 1.66681 17.0946C1.24623 17.0946 0.905273 16.7536 0.905273 16.333V14.7034C0.905273 13.637 1.32889 12.6143 2.08294 11.8603C2.83698 11.1062 3.85969 10.6826 4.92607 10.6826H8.18533C9.25171 10.6826 10.2744 11.1062 11.0285 11.8603C11.7825 12.6143 12.2061 13.637 12.2061 14.7034V16.333C12.2061 16.7536 11.8652 17.0946 11.4446 17.0946C11.024 17.0946 10.6831 16.7536 10.6831 16.333V14.7034C10.6831 14.041 10.4199 13.4057 9.95149 12.9373C9.48307 12.4688 8.84777 12.2057 8.18533 12.2057H4.92607Z"
            fill="inherit"
          />
          <path
            id="Vector (Stroke)_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.522 1.58357C11.6264 1.17613 12.0412 0.930402 12.4487 1.03472C13.3135 1.25617 14.0801 1.75917 14.6276 2.46443C15.175 3.16969 15.4721 4.03708 15.4721 4.92987C15.4721 5.82266 15.175 6.69006 14.6276 7.39531C14.0801 8.10057 13.3135 8.60357 12.4487 8.82502C12.0412 8.92934 11.6264 8.68361 11.522 8.27617C11.4177 7.86873 11.6634 7.45386 12.0709 7.34954C12.6081 7.21198 13.0844 6.89951 13.4244 6.4614C13.7645 6.0233 13.9491 5.48447 13.9491 4.92987C13.9491 4.37527 13.7645 3.83644 13.4244 3.39834C13.0844 2.96023 12.6081 2.64777 12.0709 2.5102C11.6634 2.40588 11.4177 1.99102 11.522 1.58357Z"
            fill="inherit"
          />
          <path
            id="Vector (Stroke)_4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1513 11.376C13.2565 10.9688 13.6718 10.7239 14.079 10.8291C14.9383 11.0509 15.7001 11.5508 16.2455 12.2508C16.791 12.9508 17.0895 13.8116 17.0946 14.6991L17.0947 14.7034H17.0947V16.3331C17.0947 16.7537 16.7537 17.0946 16.3331 17.0946C15.9125 17.0946 15.5716 16.7537 15.5716 16.3331V14.7058C15.568 14.1552 15.3825 13.6213 15.0441 13.187C14.7053 12.7521 14.2321 12.4416 13.6983 12.3038C13.2911 12.1986 13.0462 11.7833 13.1513 11.376Z"
            fill="inherit"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
