import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

import { FormFieldSwitch } from 'src/components/forms/FormFieldSwitch';
import { TrackType, useToast, useTrack } from 'src/context';
import { useGetCardholder } from 'src/hooks/useGetCardholder';
import { COLOR_PALETTE } from 'src/theme';
import { GroupStatus } from 'src/types/groups';

import {
  ConfirmDeactivateCardholderModal,
  useConfirmDeactivateCardholderModal,
} from './ConfirmDeactivateCardholderModal';
import { useUpdateCardholderStatus } from './useUpdateCardholderStatus';

interface CardIsActiveFormFields {
  isCardholderActive: boolean;
}

export const CardholderIsActiveForm = ({
  cardholderId,
  className,
  disabled,
}: {
  cardholderId: string;
  className?: string;
  disabled?: boolean;
}) => {
  const { track } = useTrack();
  const { setToast } = useToast();

  const { data: { cardsCount, isActive: isCardholderActive, group } = {} } =
    useGetCardholder({
      id: cardholderId ?? '',
    });
  const isGroupInactive = group?.status === GroupStatus.INACTIVE;

  const { mutateAsync: updateCardholderStatus, isLoading } =
    useUpdateCardholderStatus();
  const [isActive, setIsActive] = useState(isCardholderActive);
  const {
    openModal: openConfirmationModal,
    closeModal: closeConfirmationModal,
  } = useConfirmDeactivateCardholderModal();

  useEffect(() => {
    setIsActive(isCardholderActive);
  }, [isCardholderActive]);

  const onSubmit = async ({ isCardholderActive }: CardIsActiveFormFields) => {
    await updateCardholderStatus(
      {
        cardholderId,
        isCardholderActive,
      },
      {
        onSuccess: () => {
          closeConfirmationModal();
          setToast({
            message: `Cardholder Successfully ${
              isCardholderActive ? 'Activated' : 'Deactivated'
            }`,
            severity: 'success',
          });
          track({
            label: 'Set cardholder isActive',
            type: TrackType.effect,
            isSuccessful: true,
            isActive: isCardholderActive,
          });
        },
        onError: () => {
          setIsActive(!isCardholderActive);
          setToast({
            message: `Error ${
              isCardholderActive ? 'Activating' : 'Deactivating'
            } Cardholder`,
            severity: 'error',
          });
          track({
            label: 'Set cardholder isActive',
            type: TrackType.effect,
            isSuccessful: false,
            isActive: isCardholderActive,
          });
        },
      },
    );
  };

  return (
    <>
      <FormFieldSwitch<{
        cardholderIsActive: boolean;
      }>
        name="cardholderIsActive"
        label={isActive && !isGroupInactive ? 'Active' : 'Inactive'}
        className={className}
        disabled={isLoading || disabled || isGroupInactive}
        checked={!!(isActive && !isGroupInactive)}
        defaultValue={!!(isActive && !isGroupInactive)}
        onChange={(checked) => {
          if (!checked && (cardsCount ?? 0) > 0) {
            openConfirmationModal();
          } else {
            setIsActive(checked);
            onSubmit({ isCardholderActive: checked });
            track({
              label: 'Toggle cardholder isActive',
              type: TrackType.action,
              actionType: 'toggle',
              isActive: checked,
            });
          }
        }}
        css={css`
          .MuiFormControlLabel-label {
            color: ${COLOR_PALETTE.inputLabel}!important;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 300;
          }
        `}
      />
      <ConfirmDeactivateCardholderModal
        isSubmitting={isLoading}
        onSubmit={() => {
          setIsActive(false);
          onSubmit({ isCardholderActive: false });
          track({
            label: 'Toggle cardholder isActive',
            type: TrackType.action,
            actionType: 'toggle',
            isActive: false,
          });
        }}
      />
    </>
  );
};
