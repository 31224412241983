import { SvgIcon } from '@mui/material';

export const EditIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="edit">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.55546 6.30546C4.07118 5.78973 4.77065 5.5 5.5 5.5H6.41667C6.92293 5.5 7.33333 5.91041 7.33333 6.41667C7.33333 6.92293 6.92293 7.33333 6.41667 7.33333H5.5C5.25688 7.33333 5.02373 7.42991 4.85182 7.60182C4.67991 7.77373 4.58333 8.00688 4.58333 8.25V16.5C4.58333 16.7431 4.67991 16.9763 4.85182 17.1482C5.02373 17.3201 5.25689 17.4167 5.5 17.4167H13.75C13.9931 17.4167 14.2263 17.3201 14.3982 17.1482C14.5701 16.9763 14.6667 16.7431 14.6667 16.5V15.5833C14.6667 15.0771 15.0771 14.6667 15.5833 14.6667C16.0896 14.6667 16.5 15.0771 16.5 15.5833V16.5C16.5 17.2293 16.2103 17.9288 15.6945 18.4445C15.1788 18.9603 14.4793 19.25 13.75 19.25H5.5C4.77065 19.25 4.07118 18.9603 3.55546 18.4445C3.03973 17.9288 2.75 17.2293 2.75 16.5V8.25C2.75 7.52065 3.03973 6.82118 3.55546 6.30546Z"
              fill="#3D3D3D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.55546 6.30546C4.07118 5.78973 4.77065 5.5 5.5 5.5H6.41667C6.92293 5.5 7.33333 5.91041 7.33333 6.41667C7.33333 6.92293 6.92293 7.33333 6.41667 7.33333H5.5C5.25688 7.33333 5.02373 7.42991 4.85182 7.60182C4.67991 7.77373 4.58333 8.00688 4.58333 8.25V16.5C4.58333 16.7431 4.67991 16.9763 4.85182 17.1482C5.02373 17.3201 5.25689 17.4167 5.5 17.4167H13.75C13.9931 17.4167 14.2263 17.3201 14.3982 17.1482C14.5701 16.9763 14.6667 16.7431 14.6667 16.5V15.5833C14.6667 15.0771 15.0771 14.6667 15.5833 14.6667C16.0896 14.6667 16.5 15.0771 16.5 15.5833V16.5C16.5 17.2293 16.2103 17.9288 15.6945 18.4445C15.1788 18.9603 14.4793 19.25 13.75 19.25H5.5C4.77065 19.25 4.07118 18.9603 3.55546 18.4445C3.03973 17.9288 2.75 17.2293 2.75 16.5V8.25C2.75 7.52065 3.03973 6.82118 3.55546 6.30546Z"
              fill="black"
              fillOpacity="0.2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.55546 6.30546C4.07118 5.78973 4.77065 5.5 5.5 5.5H6.41667C6.92293 5.5 7.33333 5.91041 7.33333 6.41667C7.33333 6.92293 6.92293 7.33333 6.41667 7.33333H5.5C5.25688 7.33333 5.02373 7.42991 4.85182 7.60182C4.67991 7.77373 4.58333 8.00688 4.58333 8.25V16.5C4.58333 16.7431 4.67991 16.9763 4.85182 17.1482C5.02373 17.3201 5.25689 17.4167 5.5 17.4167H13.75C13.9931 17.4167 14.2263 17.3201 14.3982 17.1482C14.5701 16.9763 14.6667 16.7431 14.6667 16.5V15.5833C14.6667 15.0771 15.0771 14.6667 15.5833 14.6667C16.0896 14.6667 16.5 15.0771 16.5 15.5833V16.5C16.5 17.2293 16.2103 17.9288 15.6945 18.4445C15.1788 18.9603 14.4793 19.25 13.75 19.25H5.5C4.77065 19.25 4.07118 18.9603 3.55546 18.4445C3.03973 17.9288 2.75 17.2293 2.75 16.5V8.25C2.75 7.52065 3.03973 6.82118 3.55546 6.30546Z"
              fill="black"
              fillOpacity="0.2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.55546 6.30546C4.07118 5.78973 4.77065 5.5 5.5 5.5H6.41667C6.92293 5.5 7.33333 5.91041 7.33333 6.41667C7.33333 6.92293 6.92293 7.33333 6.41667 7.33333H5.5C5.25688 7.33333 5.02373 7.42991 4.85182 7.60182C4.67991 7.77373 4.58333 8.00688 4.58333 8.25V16.5C4.58333 16.7431 4.67991 16.9763 4.85182 17.1482C5.02373 17.3201 5.25689 17.4167 5.5 17.4167H13.75C13.9931 17.4167 14.2263 17.3201 14.3982 17.1482C14.5701 16.9763 14.6667 16.7431 14.6667 16.5V15.5833C14.6667 15.0771 15.0771 14.6667 15.5833 14.6667C16.0896 14.6667 16.5 15.0771 16.5 15.5833V16.5C16.5 17.2293 16.2103 17.9288 15.6945 18.4445C15.1788 18.9603 14.4793 19.25 13.75 19.25H5.5C4.77065 19.25 4.07118 18.9603 3.55546 18.4445C3.03973 17.9288 2.75 17.2293 2.75 16.5V8.25C2.75 7.52065 3.03973 6.82118 3.55546 6.30546Z"
              fill="black"
              fillOpacity="0.2"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3159 2.6657C15.8487 2.13315 16.5713 1.83398 17.3247 1.83398C18.0784 1.83398 18.8012 2.13338 19.3341 2.66632C19.867 3.19925 20.1664 3.92207 20.1664 4.67575C20.1664 5.42913 19.8673 6.15166 19.3347 6.68454C19.3345 6.68475 19.335 6.68432 19.3347 6.68454L11.649 14.3978C11.477 14.5704 11.2434 14.6674 10.9997 14.6674H8.24967C7.74341 14.6674 7.33301 14.257 7.33301 13.7507V11.0007C7.33301 10.7571 7.43003 10.5234 7.60265 10.3514L15.3159 2.6657C15.3161 2.6655 15.3157 2.66591 15.3159 2.6657ZM17.3247 3.66732C17.0572 3.66732 16.8007 3.77356 16.6116 3.96268L9.16634 11.3814V12.8341H10.619L18.0377 5.38882C18.2269 5.1997 18.3331 4.9432 18.3331 4.67575C18.3331 4.4083 18.2269 4.1518 18.0377 3.96268C17.8486 3.77356 17.5921 3.66732 17.3247 3.66732Z"
            fill="#141E27"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0185 3.9345C14.3765 3.57652 14.9569 3.57652 15.3148 3.9345L18.0648 6.6845C18.4228 7.04248 18.4228 7.62288 18.0648 7.98086C17.7069 8.33884 17.1265 8.33884 16.7685 7.98086L14.0185 5.23086C13.6605 4.87288 13.6605 4.29248 14.0185 3.9345Z"
            fill="#141E27"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
