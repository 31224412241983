import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAxios } from 'src/hooks/useAxios';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import type { Address } from 'src/types';

export const useUpdateCompanyInfo = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();

  const updateCompanyInfo = async ({
    companyName,
    companyAddress,
    companySupportEmail,
  }: {
    companyName: string;
    companyAddress: Address;
    companySupportEmail: string;
  }) => {
    const { data } = await axios.post(
      SERVICE_BENJI_CARD_URLS.COMPANY_INFO_UPDATE,
      {
        companyName,
        companyAddress,
        supportEmail: companySupportEmail,
      },
      {
        headers: await generateSecureHeaders(),
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: {
      companySupportEmail: string;
      companyName: string;
      companyAddress: Address;
    }) => updateCompanyInfo(data),
    onSuccess: () => {
      const userDetailsQueryKey = queryKeys.account.details();
      queryClient.invalidateQueries(userDetailsQueryKey);
    },
  });
};
