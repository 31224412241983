import { useGetUserDetails } from 'src/hooks';
import { CardTypeLabel } from 'src/types/cards';

import { FormFieldRadioCard } from './forms/FormFieldRadioCard';

import type { ComponentProps, ReactNode } from 'react';
import type { FieldValues } from 'react-hook-form';

export function CardTypeRadioCards<FormFields extends FieldValues>(
  props: Omit<
    ComponentProps<typeof FormFieldRadioCard<FormFields>>,
    'options' | 'label'
  > & { label?: ReactNode },
) {
  const { data: { accountDetails } = {} } = useGetUserDetails();
  const orgCardTypes = accountDetails?.companyCardTypes;

  return (
    <FormFieldRadioCard<FormFields>
      {...props}
      label={props.label ?? 'Card Type'}
      rules={{
        ...props.rules,
        required: 'Card Type is required',
      }}
      options={(orgCardTypes ?? []).map((value) => ({
        label: CardTypeLabel[value] ?? value,
        value,
      }))}
    />
  );
}
