import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

import { FormFieldSwitch } from 'src/components/forms/FormFieldSwitch';
import { TrackType, useToast, useTrack } from 'src/context';
import { COLOR_PALETTE } from 'src/theme';
import { GroupStatus } from 'src/types/groups';

import { useUpdateGroupDetails } from './useUpdateGroupDetails';

interface groupIsActiveFormFields {
  isGroupActive: boolean;
}

export const GroupIsActiveForm = ({
  isGroupActive,
  groupId,
  className,
  disabled,
}: {
  isGroupActive?: boolean;
  groupId: string;
  className?: string;
  disabled?: boolean;
}) => {
  const { track } = useTrack();
  const { setToast } = useToast();
  const { mutateAsync: updateGroup, isLoading } = useUpdateGroupDetails();
  const [isActive, setIsActive] = useState(isGroupActive);

  useEffect(() => {
    setIsActive(isGroupActive);
  }, [isGroupActive]);

  const onSubmit = async ({ isGroupActive }: groupIsActiveFormFields) => {
    await updateGroup(
      {
        groupId,
        status: isGroupActive ? GroupStatus.ACTIVE : GroupStatus.INACTIVE,
      },
      {
        onSuccess: () => {
          setToast({
            message: `Group Successfully ${
              isGroupActive ? 'Activated' : 'Deactivated'
            }`,
            severity: 'success',
          });
          track({
            label: 'Set group isActive',
            type: TrackType.effect,
            isSuccessful: true,
            isActive: isGroupActive,
          });
        },
        onError: () => {
          setIsActive(!isGroupActive);
          setToast({
            message: `Error ${
              isGroupActive ? 'Activating' : 'Deactivating'
            } Group`,
            severity: 'error',
          });
          track({
            label: 'Set group isActive',
            type: TrackType.effect,
            isSuccessful: false,
            isActive: isGroupActive,
          });
        },
      },
    );
  };

  return (
    <FormFieldSwitch<{
      groupIsActive: boolean;
    }>
      name="groupIsActive"
      label={isActive ? 'Active' : 'Inactive'}
      className={className}
      disabled={isLoading || disabled}
      checked={!!isActive}
      onChange={(checked) => {
        setIsActive(checked);
        onSubmit({ isGroupActive: checked });
        track({
          label: 'Toggle group isActive',
          type: TrackType.action,
          actionType: 'toggle',
          isActive: checked,
        });
      }}
      css={css`
        .MuiFormControlLabel-label {
          color: ${COLOR_PALETTE.inputLabel}!important;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 300;
        }
      `}
    />
  );
};
