import { css } from '@emotion/react';
import { Box, Card, Button, Typography } from '@mui/material';
import { useRef } from 'react';

import {
  AsynchronouslyLoadedSection,
  FancyCurrencyDisplay,
  PaginatedTableWrapper,
  StatusChip,
  Table,
  TableError,
  TableLoader,
  TableNoResults,
  TablePagination,
} from 'src/components';
import { MoveFundsIcon } from 'src/components/Icon';
import {
  BalanceTransferDetailsModal,
  useBalanceTransferDetailsModal,
} from 'src/components/modals/BalanceTransferDetailsModal';
import { useAppRoutes, useGetUserPrivileges } from 'src/hooks';
import { Privilege } from 'src/types';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { cleanBalanceTransfers } from './utils';

import type { Column } from '@table-library/react-table-library/types/compact';
import type {
  FundsTransferCleaned,
  FundsTransferRaw,
} from 'src/types/funds-transfers';

export const FundsTransfersPage = () => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { navigate } = useAppRoutes();

  const endpointUrl = SERVICE_BENJI_CARD_URLS.BALANCE_GET_BALANCE_HISTORY;
  const { openModal: openBalanceTransferModal } =
    useBalanceTransferDetailsModal();
  const { isLoading: areUserPrivilegesLoading, privileges } =
    useGetUserPrivileges();

  const columns: ({
    width: string;
  } & Column<FundsTransferCleaned>)[] = [
    {
      label: 'Date',
      renderCell: () => {
        return <span />;
      },
      width: '130px',
    },
    {
      label: 'Type',
      renderCell: ({ type }) => {
        return type;
      },
      width: 'minmax(150px, 200px)',
    },
    {
      label: 'Status',
      renderCell: ({ status }) => {
        return (
          <Box
            css={css`
              display: flex;
            `}
          >
            <StatusChip status={status} type="balanceTransfer" />
          </Box>
        );
      },
      width: '120px',
    },
    {
      label: 'Amount',
      renderCell: ({ amountCents }) => {
        return <FancyCurrencyDisplay amountCents={amountCents ?? 0} isPlain />;
      },
      width: '140px',
    },
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];

  const isPageLoading = areUserPrivilegesLoading;

  return (
    <>
      <Box
        css={css`
          max-height: calc(100vh - 96px);
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        `}
      >
        <Card
          component="div"
          css={css`
            display: flex;
            width: 100%;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 1 auto;
          `}
          ref={cardRef}
        >
          <PaginatedTableWrapper<FundsTransferRaw, FundsTransferCleaned>
            endpointUrl={endpointUrl}
            queryKeyBase={queryKeys.balance._baseKey}
            dataFormatter={cleanBalanceTransfers}
            requiredPrivileges={[Privilege.balance]}
          >
            {({
              queryResult: { isLoading, isError },
              pagination,
              paginationData,
              resultCount,
              pageCount,
            }) => (
              <>
                <Box
                  css={css`
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    gap: 16px;
                    padding: 16px 16px 0;
                  `}
                >
                  <Typography
                    variant="h2"
                    css={css`
                      flex-grow: 999;
                    `}
                  >
                    Funds Transfers
                  </Typography>
                  <Box
                    css={css`
                      display: flex;
                      flex-wrap: wrap-reverse;
                      gap: 16px;
                      flex-grow: 1;
                      justify-content: flex-end;
                    `}
                  >
                    {/* <SearchBar
                      {...searchProps}
                      isLoading={!!isLoading}
                    /> */}
                    {privileges?.fund_balance && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() =>
                          navigate('/secure/funds-transfers/new-transfer')
                        }
                        disabled={isPageLoading}
                        startIcon={<MoveFundsIcon />}
                      >
                        Move Funds
                      </Button>
                    )}
                  </Box>
                </Box>
                <AsynchronouslyLoadedSection isLoading={isPageLoading}>
                  <Table<FundsTransferCleaned>
                    data={paginationData}
                    pagination={pagination}
                    columns={columns}
                    isLoading={isLoading || isPageLoading}
                    onRowClick={({ stripeId }) => {
                      openBalanceTransferModal(stripeId);
                    }}
                    columnWidths={columns.map(({ width }) => width).join(' ')}
                    combinedFirstColumn={{
                      primaryColumnKey: 'prettyCreatedAtDate',
                      columnComparatorKey: 'isCreatedAtDateSameAsPrev',
                    }}
                    css={css`
                      margin-top: 10px;
                      width: 100%;
                    `}
                  />
                  <TableLoader isLoading={isLoading} />
                  <TableError isLoading={isLoading} isError={isError} />
                  <TableNoResults
                    isLoading={isLoading}
                    resultCount={resultCount}
                  />
                  <TablePagination
                    resultCount={resultCount}
                    pageCount={pageCount}
                    pagination={pagination}
                  />
                </AsynchronouslyLoadedSection>
              </>
            )}
          </PaginatedTableWrapper>
        </Card>
      </Box>
      <BalanceTransferDetailsModal anchorEl={cardRef.current} />
    </>
  );
};
