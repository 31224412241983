import { addHours } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
const MST = 'MST';
const MST_OFFSET_HOURS = 7;
export const formatDateToMST = (date: Date, format?: string) =>
  formatInTimeZone(date, MST, format ?? 'MM/dd/yyyy');

export const dateToMST = (date: Date | string) => utcToZonedTime(date, MST);

export const MSTDateStringToDateObject = (
  dateString: string,
  endOrStartOfDate: 'startOfDate' | 'endOfDate' = 'startOfDate',
) => {
  let date = new Date(
    `${dateString}T${
      endOrStartOfDate === 'startOfDate' ? '00:00:00.000Z' : '23:59:59.999Z'
    }`,
  );
  date = addHours(date, MST_OFFSET_HOURS);
  return date;
};
