import { css } from '@emotion/react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CreateCardIcon } from 'src/components/Icon';
import { useToast } from 'src/context';
import { useGetUserDetails } from 'src/hooks';
import { useCreateCard } from 'src/hooks/useCreateCard';
import { COLOR_PALETTE } from 'src/theme';
import { type CardType } from 'src/types/cards';

import { CardTypeRadioCards } from '../../';
import { useCardDetailsModal } from '../CardDetailsModal';

import type { ComponentProps } from 'react';
import type { CreateCardResponseCleaned } from 'src/hooks/useCreateCard';

interface FormFields {
  cardType: CardType | '';
}

const CreateCardButton = ({
  onClick,
  className,
  label = 'Create Card',
  variant = 'contained',
  disabled,
}: {
  onClick: () => void;
  className?: string;
  label?: string;
  variant?: ComponentProps<typeof Button>['variant'];
  disabled?: boolean;
}) => {
  return (
    <Button
      startIcon={<CreateCardIcon />}
      variant={variant}
      className={className}
      onClick={onClick}
      disabled={disabled}
      css={css`
        gap: 0;
      `}
    >
      {label}
    </Button>
  );
};

export const InlineCreateCardForm = ({
  cardholderId,
  onSuccess,
  onError,
  children,
}: {
  cardholderId: string;
  onSuccess?: (data: CreateCardResponseCleaned) => void;
  onError?: () => void;
  children: (data: {
    disabled: boolean;
    onClick: () => void;
    button: typeof CreateCardButton;
  }) => React.ReactNode;
}) => {
  const { setToast } = useToast();
  const { mutate: createCard, isLoading: isCreatingCard } = useCreateCard();

  const [isSelectCardTypeModalOpen, setIsSelectCardTypeModalOpen] =
    useState(false);
  const handleClose = () => {
    reset();
    setIsSelectCardTypeModalOpen(false);
  };
  const { openModal: openCardDetailsModal } = useCardDetailsModal();

  const { data: { accountDetails } = {}, isLoading: isLoadingUser } =
    useGetUserDetails();
  const orgCardTypes = accountDetails?.companyCardTypes;
  const defaultValues: FormFields = {
    cardType: orgCardTypes?.length === 1 ? orgCardTypes[0] ?? '' : '',
  };
  const useFormMethods = useForm<FormFields>({
    defaultValues,
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useFormMethods;

  const handleCreateCard = (values: FormFields) => {
    createCard(
      {
        cardholderId,
        cardType: values.cardType as CardType,
      },
      {
        onSuccess: (data) => {
          onSuccess?.(data);
          handleClose();
          setToast({
            message: `Card Created Successfully`,
            severity: 'success',
          });
          openCardDetailsModal(data.cardStripeId);
        },
        onError: () => {
          onError?.();
          setToast({
            message: 'Error Creating Card',
            severity: 'error',
          });
        },
      },
    );
  };

  const onSubmit = async (values: FormFields) => {
    handleCreateCard(values);
  };

  return (
    <>
      {children({
        disabled: isCreatingCard || isLoadingUser,
        onClick: () => {
          if (defaultValues.cardType) {
            handleCreateCard({ cardType: defaultValues.cardType });
          } else {
            setIsSelectCardTypeModalOpen(true);
          }
        },
        button: CreateCardButton,
      })}
      {isSelectCardTypeModalOpen && (
        <Box
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            background: ${COLOR_PALETTE.white};
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
          `}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            css={css`
              color: ${COLOR_PALETTE.secondaryButtonColor};
              position: absolute;
              right: 8px;
              top: 14px;
              padding: 10px;
              z-index: 1;
            `}
          >
            <GridCloseIcon
              css={css`
                height: 20px;
                width: 20px;
              `}
            />
          </IconButton>
          <FormProvider {...useFormMethods}>
            <Box
              onSubmit={handleSubmit(onSubmit, (errors) => {
                console.log(errors);
              })}
              css={css`
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                width: 100%;
                padding: 24px;
              `}
              component="form"
            >
              <CardTypeRadioCards
                disabled={isSubmitting}
                control={control}
                name="cardType"
                label={
                  <Typography
                    component="span"
                    css={css`
                      font-size: 15px;
                      font-weight: 700;
                    `}
                  >
                    Card Type
                  </Typography>
                }
                defaultValue={defaultValues.cardType}
                css={css`
                  flex: 1 1 100%;
                  margin-bottom: 16px;
                `}
              />

              <Box
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  gap: 8px;
                  width: 100%;
                `}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="secondary"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isCreatingCard}
                >
                  Create Card
                </Button>
              </Box>
            </Box>
          </FormProvider>
        </Box>
      )}
    </>
  );
};
