import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAxios } from 'src/hooks/useAxios';
import { CardType } from 'src/types/cards';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import type { FormFields } from './CardSendForm';

interface SendCardMutationParamsBase
  extends Omit<FormFields, 'memberDob' | 'dateOfService' | 'providerName'> {
  cardId: string;
  memberDob: string;
}

interface SendCardMutationParamsOngoingUse extends SendCardMutationParamsBase {
  cardType: CardType.ongoingUse;
}
interface SendCardMutationParamsSingleUse extends SendCardMutationParamsBase {
  cardType: CardType.singleUse;
  providerName: string;
  dateOfService: string;
}
type SendCardMutationParams =
  | SendCardMutationParamsOngoingUse
  | SendCardMutationParamsSingleUse;

export const useSendCard = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();
  const sendCard = async (props: SendCardMutationParams) => {
    const baseBody = {
      recepientEmail: props.email,
      memberFirstName: props.memberName.first,
      memberLastName: props.memberName.last,
      memberBirthDate: props.memberDob,
      cardId: props.cardId,
      cardType: props.cardType,
      type: props.cardSendType,
    };

    const { data } = await axios.post(
      SERVICE_BENJI_CARD_URLS.CARD_SEND,
      props.cardType === CardType.singleUse
        ? {
            ...baseBody,
            providerName: props.providerName,
            serviceDate: props.dateOfService,
          }
        : {
            ...baseBody,
          },
      {
        headers: await generateSecureHeaders(),
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: SendCardMutationParams) => sendCard(data),
    onSuccess: (_, { cardId }) => {
      queryClient.invalidateQueries({
        queryKey: [
          ...queryKeys.paginatedData._baseKey,
          ...queryKeys.cards._sendCardHistoryBaseKey({ id: cardId }),
        ],
      });
    },
  });
};
