import { css } from '@emotion/react';
import { MoreHorizOutlined as MoreHorizOutlinedIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import { TrackType, useTrack } from 'src/context';

import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';

export const CardMenu = ({
  cardName,
  menuItems,
  className,
}: {
  cardName: string;
  menuItems: {
    label: string;
    labelIcon?: ({ className }: { className?: string }) => EmotionJSX.Element;
    onClick: () => void;
  }[];
  className?: string;
}) => {
  const { track } = useTrack();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    track({
      label: 'Clicked card menu',
      type: TrackType.action,
      actionType: 'click',
      cardName,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        css={css`
          margin-left: auto;
          align-self: flex-start;
        `}
        className={className}
      >
        <MoreHorizOutlinedIcon />
      </IconButton>
      <Menu
        id={`${cardName}-card-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-controls={open ? `${cardName}-card-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        PaperProps={{
          sx: {
            overflow: 'visible',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems.map(({ label, onClick, labelIcon: LabelIcon }, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              handleClose();
              onClick();
              track({
                label: 'Clicked card menu item',
                type: TrackType.action,
                actionType: 'click',
                cardName,
                menuItemLabel: label,
              });
            }}
            css={css`
              min-width: 100px;
            `}
          >
            {LabelIcon ? (
              <LabelIcon
                css={css`
                  height: 22px;
                  width: 22px;
                  margin-right: 10px;
                `}
              />
            ) : null}
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
