import { css } from '@emotion/react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { get, startCase } from 'lodash';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CardMenu, DataRow, FormFieldInput } from 'src/components';
import { EditIcon, ProfilePicPlaceholderIcon } from 'src/components/Icon';
import { TrackType, useToast, useTrack } from 'src/context';
import { useGetUserDetails } from 'src/hooks';
import { COLOR_PALETTE, focusedCardShadow } from 'src/theme';

import { useUpdateUserInfo } from './useUpdateUserInfo';

interface FormFields {
  name: {
    first: string;
    last: string;
  };
}

export const UserInfoCard = () => {
  const { track } = useTrack();
  const { data: userData } = useGetUserDetails();
  const name =
    !!userData &&
    `${startCase(userData.userDetails.first_name)} ${startCase(
      userData.userDetails.last_name,
    )}`;
  const { setToast } = useToast();
  const [isEditMode, setIsEditMode] = useState(false);

  const defaultValues: FormFields = {
    name: {
      first: userData?.userDetails.first_name ?? '',
      last: userData?.userDetails.last_name ?? '',
    },
  };
  const useFormMethods = useForm<FormFields>({
    defaultValues,
    mode: 'onBlur',
  });
  const { handleSubmit, control, watch, reset, formState } = useFormMethods;
  const { isSubmitting } = formState;
  const formValues = watch();

  const { mutateAsync: updateUserInfo } = useUpdateUserInfo();
  const onSubmit = async (data: FormFields) => {
    track({
      label: 'Submit update user info',
      type: TrackType.action,
      actionType: 'submit',
      name_first: data.name.first,
      name_last: data.name.last,
    });
    await updateUserInfo(
      {
        userId: userData?.userDetails.id ?? '',
        name: data.name,
      },
      {
        onSuccess: (data) => {
          track({
            label: 'Updated User info',
            type: TrackType.effect,
            isSuccessful: true,
            name_first: data.first_name,
            name_last: data.last_name,
            email: data.email,
          });
          setToast({
            message: 'Updated User Info',
            severity: 'success',
          });
          setIsEditMode(false);
        },
        onError: (error) => {
          const serverMessage = get(error, 'response.data.message', '');
          track({
            label: 'Updated User info',
            type: TrackType.effect,
            isSuccessful: false,
            name_first: data.name.first,
            name_last: data.name.last,
          });
          reset(undefined, { keepValues: true });
          setToast({
            message:
              typeof serverMessage === 'string'
                ? serverMessage
                : 'Error updating user info',
            severity: 'error',
          });
        },
      },
    );
  };

  const menuItems = [
    ...(isEditMode
      ? []
      : [
          {
            label: 'Edit Info',
            onClick: () => {
              setIsEditMode(true);
            },
            labelIcon: EditIcon,
          },
        ]),
  ];

  return (
    <FormProvider {...useFormMethods}>
      <Card
        css={css`
          flex: 1 1 100%;
          position: relative;
          ${isEditMode
            ? `
            border: 1px solid ${COLOR_PALETTE.borderDark};
            ${focusedCardShadow};
          `
            : ''}
        `}
      >
        <CardContent
          css={css`
            flex: 1 1 100%;
            padding: 18px;
            display: flex;
            flex-wrap: wrap;
            gap: 14px;
          `}
        >
          <Box
            css={css`
              display: flex;
              width: 100%;
              padding-right: 30px;
            `}
          >
            <Typography
              component="h2"
              css={css`
                font-size: 18px;
                font-weight: 700;
                line-height: normal;
              `}
            >
              User Info
            </Typography>
            {!!menuItems.length && (
              <CardMenu
                cardName="User Info"
                menuItems={menuItems}
                css={css`
                  position: absolute;
                  top: 10px;
                  right: 10px;
                `}
              />
            )}
          </Box>
          <Box
            css={css`
              flex: 1 1 100%;
              display: flex;
              gap: 30px;
            `}
          >
            <ProfilePicPlaceholderIcon
              css={css`
                height: 100px;
                width: 100px;
              `}
            />
            {isEditMode ? (
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit, (errors) => {
                  () => {
                    track({
                      label: 'Submit update user info',
                      type: TrackType.action,
                      actionType: 'submit',
                      name_first: formValues.name.first,
                      name_last: formValues.name.last,
                      validationErrors: Object.entries(errors).map(
                        ([key, value]) => ({
                          field: key,
                          message: value.message,
                        }),
                      ),
                    });
                  };
                })}
                css={css`
                  flex: 1 1 auto;
                  align-items: center;
                  display: flex;
                `}
              >
                <Box
                  css={css`
                    display: flex;
                    flex-direction: column;
                    flex: 0 0 auto;
                    justify-content: center;
                  `}
                >
                  <DataRow
                    label="Name"
                    value={
                      <Box
                        css={css`
                          display: flex;
                          gap: 8px;
                        `}
                      >
                        <FormFieldInput
                          disabled={isSubmitting}
                          control={control}
                          name="name.first"
                          placeholder="First Name"
                          defaultValue={defaultValues.name.first}
                          css={css`
                            flex: 1 1 160px;
                            max-width: 420px;
                          `}
                          rules={{
                            required: 'Required',
                          }}
                        />
                        <FormFieldInput
                          disabled={isSubmitting}
                          control={control}
                          name="name.last"
                          placeholder="Last Name"
                          defaultValue={defaultValues.name}
                          css={css`
                            flex: 1 1 160px;
                            max-width: 420px;
                          `}
                          rules={{
                            required: 'Required',
                          }}
                        />
                      </Box>
                    }
                    isPrivate
                    css={css`
                      padding: 0;
                      border: none;
                    `}
                  />
                  <DataRow
                    value={userData?.userDetails.email}
                    label="Email"
                    isPrivate
                  />
                </Box>
                <Box
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;
                    justify-content: flex-end;
                    margin-left: auto;
                    align-self: flex-end;
                  `}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setIsEditMode(false);
                      reset(defaultValues);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                css={css`
                  flex: 1 1 400px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                `}
              >
                <DataRow value={name} label="Name" />
                <DataRow
                  label="Email"
                  value={userData?.userDetails.email}
                  isPrivate
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </FormProvider>
  );
};
