import { css } from '@emotion/react';
import { Box } from '@mui/material';

export const Logo = ({
  className,
  variant = 'dark',
}: {
  className?: string;
  variant?: 'light' | 'dark';
}) => {
  return (
    <Box
      component="img"
      src={
        variant === 'dark' ? '/BenjiCardLogo.png' : '/BenjiCardLogo_light.svg'
      }
      alt="logo"
      className={className}
      css={css`
        max-width: 180px;
        margin: 0 auto;
      `}
    />
  );
};
