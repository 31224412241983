import { css } from '@emotion/react';
import { Alert, Box, Button, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { FormProvider, useForm } from 'react-hook-form';

import { FormFieldInput, FormFieldSwitch } from 'src/components';
import { EmailInputIcon, NameInputIcon } from 'src/components/Icon';
import {
  TrackContext,
  TrackingContextProvider,
  TrackType,
  useToast,
  useTrack,
} from 'src/context';
import { useAppRoutes, useGetFeatures } from 'src/hooks';
import {
  FormSection,
  inputCss,
} from 'src/pages/Cardholders/NewCardholder/CreateCardholderForm';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { useGetUserPrivilegesLabels } from 'src/types/user';
import { splitArray } from 'src/utils/split-array';

import { useSubUserDetailsModal } from '../SubUserDetailsModal';
import { useUpdateOrCreateUser } from '../useUpdateOrCreateUser';

import type { AxiosError } from 'axios';
import type { FieldPath } from 'react-hook-form';

const privilegeBaseCss = css`
  justify-content: space-between;
  max-width: 176px;
`;

interface FormFields {
  userPrivileges: Record<Privilege, boolean>;
  isActive: boolean;
  name: {
    first: string;
    last: string;
  };
  email: string;
}

export const NewUserPage = () => {
  const { navigate } = useAppRoutes();
  const { track, incrementMixpanelProfileData } = useTrack();
  const { setToast } = useToast();
  const { mutateAsync: createOrUpdateUser } = useUpdateOrCreateUser();
  const { openModal: openSubUserDetailsModal } = useSubUserDetailsModal();
  const { userPrivilegesLabel } = useGetUserPrivilegesLabels();

  const defaultValues = {
    name: {
      first: '',
      last: '',
    },
    email: '',
    userPrivileges: Object.fromEntries(
      Object.keys(Privilege).map((key) => [key, false]),
    ),
  };
  const useFormMethods = useForm<FormFields>({
    defaultValues,
  });
  const { handleSubmit, control, watch, formState } = useFormMethods;
  const { isSubmitting } = formState;
  const formValues = watch();

  const { features } = useGetFeatures();

  const onSubmit = async (values: FormFields) => {
    await createOrUpdateUser(
      {
        privileges: values.userPrivileges,
        name: values.name,
        email: values.email,
      },
      {
        onSuccess: (subUserId) => {
          setToast({
            message: 'User Created Successfully',
            severity: 'success',
          });
          openSubUserDetailsModal(subUserId);

          track({
            label: 'Created sub user',
            type: TrackType.effect,
            isSuccessful: true,
            subUserId,
            subUserEmail: values.email,
            subUserFirstName: values.name.first,
            subUserLastName: values.name.last,
            subUserCreatedAt: new Date().toISOString(),
            subUserStatus: 'active',
            privileges: values.userPrivileges,
          });
          incrementMixpanelProfileData('createSubUserCount');
        },
        onError: (error) => {
          const errorObject = error as AxiosError;
          const errorMessage =
            typeof errorObject?.response?.data === 'string' &&
            errorObject?.response?.data;
          setToast({
            message: `Error Creating User${
              errorMessage ? `: ${errorMessage}` : ''
            }`,
            severity: 'error',
          });
          track({
            label: 'Created sub user',
            type: TrackType.effect,
            isSuccessful: false,
            subUserEmail: values.email,
            subUserFirstName: values.name.first,
            subUserLastName: values.name.last,
            subUserStatus: 'active',
            privileges: values.userPrivileges,
          });
        },
      },
    );
  };

  const fields = [
    {
      name: `userPrivileges.${Privilege.transactions}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.transactions],
      defaultValue: defaultValues.userPrivileges[Privilege.transactions],
    },
    {
      name: `userPrivileges.${Privilege.cards}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.cards],
      defaultValue: defaultValues.userPrivileges[Privilege.cards],
    },
    ...(features?.groups
      ? [
          {
            name: `userPrivileges.${Privilege.create_edit_groups}` as FieldPath<
              Omit<FormFields, 'isActive'>
            >,
            label: userPrivilegesLabel[Privilege.create_edit_groups],
            defaultValue:
              defaultValues.userPrivileges[Privilege.create_edit_groups],
          },
        ]
      : []),
    {
      name: `userPrivileges.${Privilege.card_details}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.card_details],
      defaultValue: defaultValues.userPrivileges[Privilege.card_details],
    },
    {
      name: `userPrivileges.${Privilege.issue_card}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.issue_card],
      defaultValue: defaultValues.userPrivileges[Privilege.issue_card],
    },
    ...(features?.sendCardsViaEmail
      ? [
          {
            name: `userPrivileges.${Privilege.send_card}` as FieldPath<
              Omit<FormFields, 'isActive'>
            >,
            label: userPrivilegesLabel[Privilege.send_card],
            defaultValue: defaultValues.userPrivileges[Privilege.send_card],
          },
        ]
      : []),
    {
      name: `userPrivileges.${Privilege.balance}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.balance],
      defaultValue: defaultValues.userPrivileges[Privilege.balance],
    },
    {
      name: `userPrivileges.${Privilege.fund_balance}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.fund_balance],
      defaultValue: defaultValues.userPrivileges[Privilege.fund_balance],
    },
    {
      name: `userPrivileges.${Privilege.dispute}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.dispute],
      defaultValue: defaultValues.userPrivileges[Privilege.dispute],
    },
    {
      name: `userPrivileges.${Privilege.create_dispute}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.create_dispute],
      defaultValue: defaultValues.userPrivileges[Privilege.create_dispute],
    },
    {
      name: `userPrivileges.${Privilege.subuser_admin}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.subuser_admin],
      defaultValue: defaultValues.userPrivileges[Privilege.subuser_admin],
    },
    {
      name: `userPrivileges.${Privilege.company_details}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.company_details],
      defaultValue: defaultValues.userPrivileges[Privilege.company_details],
    },
  ];
  const [firstHalfOfFields, secondHalfOfFields] = splitArray(fields);

  return (
    <TrackingContextProvider contextName={TrackContext['sub-user']}>
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
        `}
      >
        <Box
          css={css`
            display: flex;
            justify-content: space-between;
            gap: 20px;
            flex-wrap: wrap-reverse;
            padding-bottom: 20px;
            border-bottom: 1px solid ${COLOR_PALETTE.border};
          `}
        >
          <Typography
            component="h1"
            css={css`
              font-size: 20px;
              font-weight: 700;
            `}
          >
            Create User
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => navigate('/secure/users')}
            css={css`
              background-color: ${COLOR_PALETTE.lightButtonColor};
            `}
          >
            <GridCloseIcon
              css={css`
                height: 20px;
                width: 20px;
              `}
            />
          </IconButton>
        </Box>

        <FormProvider {...useFormMethods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            css={css`
              display: flex;
              flex-direction: column;
              width: 100%;

              max-width: 683px;
              margin: 0 auto;
            `}
          >
            <FormSection label="Name">
              <FormFieldInput<FormFields>
                disabled={isSubmitting}
                control={control}
                name="name.first"
                placeholder="First Name"
                defaultValue={defaultValues.name.first}
                icon={<NameInputIcon />}
                css={[
                  inputCss,
                  css`
                    flex: 1 1 150px;
                  `,
                ]}
                rules={{
                  required: 'Required',
                }}
              />
              <FormFieldInput<FormFields>
                disabled={isSubmitting}
                control={control}
                name="name.last"
                placeholder="Last Name"
                icon={<NameInputIcon />}
                defaultValue={defaultValues.name.last}
                css={[
                  inputCss,
                  css`
                    flex: 1 1 150px;
                  `,
                ]}
                rules={{
                  required: 'Required',
                }}
              />
            </FormSection>
            <FormSection
              label="Email"
              css={css`
                border-bottom: initial;
              `}
            >
              <FormFieldInput<FormFields>
                disabled={isSubmitting}
                control={control}
                name="email"
                type="email"
                placeholder="Email"
                defaultValue={defaultValues.email ?? ''}
                icon={<EmailInputIcon />}
                css={[
                  inputCss,
                  css`
                    flex: 1 1 200px;
                  `,
                ]}
                rules={{
                  // add validation for email

                  required: 'Required',
                }}
              />

              <Box
                css={css`
                  flex: 1 1 200px;
                `}
              />
            </FormSection>

            <FormSection label="Privileges">
              <Box
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: column;
                  gap: 25px 0;
                  flex-grow: 1;
                  label {
                    max-width: none;
                    padding-right: 8px;
                  }
                `}
              >
                {firstHalfOfFields?.map(({ name, label, defaultValue }) => (
                  <FormFieldSwitch
                    key={name}
                    disabled={isSubmitting}
                    control={control}
                    name={name}
                    label={label}
                    defaultValue={defaultValue}
                    css={privilegeBaseCss}
                  />
                ))}
              </Box>
              <Box
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: column;
                  gap: 25px 0;
                  flex-grow: 1;
                  label {
                    max-width: none;
                    padding-right: 8px;
                  }
                `}
              >
                {secondHalfOfFields?.map(({ name, label, defaultValue }) => (
                  <FormFieldSwitch
                    key={name}
                    disabled={isSubmitting}
                    control={control}
                    name={name}
                    label={label}
                    defaultValue={defaultValue}
                    css={privilegeBaseCss}
                  />
                ))}
              </Box>
              {!!formValues.userPrivileges.create_dispute &&
                !formValues.userPrivileges.dispute && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to{' '}
                    {userPrivilegesLabel[Privilege.create_dispute]}, you must
                    also give them access to{' '}
                    {userPrivilegesLabel[Privilege.dispute]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.fund_balance &&
                !formValues.userPrivileges.balance && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to{' '}
                    {userPrivilegesLabel[Privilege.fund_balance]}, you must also
                    give them access to {userPrivilegesLabel[Privilege.balance]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.card_details &&
                !formValues.userPrivileges.cards && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to{' '}
                    {userPrivilegesLabel[Privilege.card_details]}, you must also
                    give them access to {userPrivilegesLabel[Privilege.cards]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.send_card &&
                !formValues.userPrivileges.card_details && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to {userPrivilegesLabel[Privilege.send_card]}
                    , you must also give them access to{' '}
                    {userPrivilegesLabel[Privilege.card_details]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.issue_card &&
                !formValues.userPrivileges.card_details && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to{' '}
                    {userPrivilegesLabel[Privilege.issue_card]}, you must also
                    give them access to{' '}
                    {userPrivilegesLabel[Privilege.card_details]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.cards &&
                !formValues.userPrivileges.transactions && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to {userPrivilegesLabel[Privilege.cards]}{' '}
                    they must also have access to{' '}
                    {userPrivilegesLabel[Privilege.transactions]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.create_edit_groups &&
                !formValues.userPrivileges.cards && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to{' '}
                    {userPrivilegesLabel[Privilege.create_edit_groups]} they
                    must also have access to{' '}
                    {userPrivilegesLabel[Privilege.cards]}
                  </Alert>
                )}
            </FormSection>

            <Box
              css={css`
                display: flex;
                justify-content: flex-end;
                gap: 14px;
                padding-top: 40px;
              `}
            >
              <Button
                onClick={() => navigate('/secure/users')}
                variant="contained"
                color="secondary"
              >
                Close
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={
                  isSubmitting ||
                  (!!formValues.userPrivileges.create_dispute &&
                    !formValues.userPrivileges.dispute) ||
                  (!!formValues.userPrivileges.fund_balance &&
                    !formValues.userPrivileges.balance) ||
                  (!!formValues.userPrivileges.card_details &&
                    !formValues.userPrivileges.cards) ||
                  (!!formValues.userPrivileges.send_card &&
                    !formValues.userPrivileges.card_details) ||
                  (!!formValues.userPrivileges.issue_card &&
                    !formValues.userPrivileges.card_details) ||
                  (!!formValues.userPrivileges.cards &&
                    !formValues.userPrivileges.transactions) ||
                  (!!formValues.userPrivileges.create_edit_groups &&
                    !formValues.userPrivileges.cards)
                }
                onClick={() => {
                  track({
                    label: 'Submit create sub user',
                    type: TrackType.action,
                    actionType: 'submit',
                    subUserEmail: formValues.email,
                    subUserFirstName: formValues.name.first,
                    subUserLastName: formValues.name.last,
                    subUserCreatedAt: new Date().toISOString(),
                    subUserStatus: 'active',
                    privileges: formValues.userPrivileges,
                  });
                }}
              >
                Create User
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </TrackingContextProvider>
  );
};
