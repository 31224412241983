import { useUser } from '@clerk/clerk-react';
import {
  useNavigate,
  generatePath as routerGeneratePath,
  useParams,
  useLocation,
} from 'react-router-dom';

import type { RoutePath } from '../AppRoutes';
import type { NavigateOptions, Params, ParamParseKey } from 'react-router-dom';

type ParamsKeys = ParamParseKey<RoutePath>;

export const useAppRoutes = () => {
  const location = useLocation();
  const currentRouteParams = useParams<ParamsKeys>();
  const routerNavigate = useNavigate();

  /**
   * Generates path from set list of app routes.
   * Will auto persist any route params.
   * Adding any route params will overwrite any persisted params
   */
  const generatePath = (
    route: RoutePath,
    params?: Partial<Params<ParamsKeys>>,
    queryParams?: Record<string, string>,
  ) => {
    const queryParamsString = new URLSearchParams(queryParams).toString();
    // @ts-expect-error the typing for generatePath doesn't allow for params to be undefined but they should be optional here
    const routeWithParams = routerGeneratePath<RoutePath>(route, {
      ...currentRouteParams,
      ...params,
    });

    return [
      routeWithParams,
      `${queryParamsString ? `?${queryParamsString}` : ''}`,
    ]
      .filter(Boolean)
      .join('');
  };

  interface Options extends NavigateOptions {
    params?: Params;
    queryParams?: Record<string, string>;
  }
  /**
   * Navigates to either a route delta(number) or a route from the set list of app routes.
   * Will auto persist any route params.
   * Adding any route `params` to the `options` arg will overwrite any persisted params
   */
  const navigate = (
    to: RoutePath | number,
    { params, queryParams, ...options }: Options = {},
  ) => {
    const queryParamsString = new URLSearchParams(queryParams).toString();
    if (typeof to === 'number') {
      routerNavigate(to);
    } else {
      routerNavigate(
        [
          generatePath(to, params),
          `${queryParamsString ? `?${queryParamsString}` : ''}`,
        ]
          .filter(Boolean)
          .join(''),
        options,
      );
    }
  };

  const isAuthenticatedRoute = location.pathname.includes('/secure');

  const { pathname } = useLocation();
  const { isSignedIn } = useUser();
  const routesToShowSideNavWhenSignedIn = [
    generatePath('/contact-us'),
    generatePath('/legal'),
  ];
  const shouldShowSideNav =
    isAuthenticatedRoute ||
    (isSignedIn && routesToShowSideNavWhenSignedIn.includes(pathname));

  const shouldUsePageWrapper = !(
    pathname.includes('/login') ||
    pathname.includes('/register') ||
    pathname.includes('/new-org-registration') ||
    pathname.includes('/view-card') ||
    pathname.includes('/iframe')
  );

  const shouldShowContactUsFooterLink = !pathname.includes('/view-card');

  const getRoutePathName = (route: RoutePath) => route;

  return {
    navigate,
    generatePath,
    pathname,
    params: currentRouteParams,
    isAuthenticatedRoute,
    shouldShowSideNav,
    shouldUsePageWrapper,
    shouldShowContactUsFooterLink,
    getRoutePathName,
  };
};
