import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';

import { COLOR_PALETTE } from 'src/theme';

export const DataColumns = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Box
      css={css`
        display: flex;
        gap: 0 100px;
        flex-wrap: wrap;

        & > * {
          flex: 1 1 510px;
        }
      `}
      className={className}
    >
      {children}
      <div />
      <div />
    </Box>
  );
};

export const DataRow = ({
  value,
  label,
  className,
  isPrivate,
  labelWidth = 80,
  variation = 'default',
}: {
  value: React.ReactNode;
  label: React.ReactNode;
  className?: string;
  isPrivate?: boolean;
  labelWidth?: number;
  variation?: 'default' | 'longLabels';
}) => {
  const isValueString = typeof value === 'string';
  return (
    <Box
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 0 6px;
        width: 100%;
        padding: 10px 0;
        align-self: flex-start;
        border-bottom: 1px solid ${COLOR_PALETTE.border};

        ${variation === 'longLabels'
          ? `
            border-bottom: 1px solid ${COLOR_PALETTE.border};
            &:last-child {
              border-bottom: none;
            }
          `
          : ``}

        & > * {
          min-height: 18px;
          align-items: center;
          display: flex;
        }
      `}
      className={className}
    >
      <Typography
        variant="body1"
        css={css`
          font-size: 12px;
          color: ${COLOR_PALETTE.lightTextOnLight};
          ${variation === 'longLabels'
            ? `
            flex: 1 0 ${labelWidth}px;
          `
            : `
            flex: 0 0 ${labelWidth}px;
          `}
          font-weight: 300;
        `}
      >
        {label}
      </Typography>
      <Typography
        variant="body1"
        component={isValueString ? 'p' : 'div'}
        css={css`
          font-size: 14px;
          ${variation === 'longLabels'
            ? `
            flex: 0 0 auto;
          `
            : `
            flex: 1 0 220px;
          `}
        `}
        className={isPrivate ? 'highlight-block' : undefined}
      >
        {value}
      </Typography>
    </Box>
  );
};
