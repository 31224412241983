import type { Address } from './address';
import type { CardholderType } from './cardholders';
import type { GroupStatus } from './groups';

export enum CardType {
  singleUse = 'single_use',
  ongoingUse = 'ongoing',
}

export enum CardStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CANCELED = 'canceled',
}

export const CardTypeLabel: Record<CardType, string> = {
  ongoing: 'Ongoing Use',
  single_use: 'Single Use',
};

export interface SpendingLimit {
  amount: number;
  categories?: [];
  interval: string;
}

export interface SendCardRecordRaw {
  id: string;
  card_id: string;
  token: string;
  visited: boolean;
  email: string;
  benji_card_account_id: string;
  created_at: string;
  updated_at: string;
  type: string;
  spend_amount_cents: number;
  member_first_name: string;
  member_last_name: string;
  agree_to_terms_required: boolean;
  provider_name: string;
  service_date: string;
  member_birth_date: string;
  payer_company_name: string;
}
export interface SendCardRecordCleaned {
  id: SendCardRecordRaw['id'];
  cardId: SendCardRecordRaw['card_id'];
  visited: SendCardRecordRaw['visited'];
  recepientEmail: SendCardRecordRaw['email'];
  sentDate: SendCardRecordRaw['created_at'];
  prettySentDate: string;
  isSendDateSameAsPrev: boolean;
  type: SendCardRecordRaw['type'];
  member: {
    name: {
      first: SendCardRecordRaw['member_first_name'];
      last: SendCardRecordRaw['member_last_name'];
      full: string;
    };
    dob: SendCardRecordRaw['member_birth_date'];
  };
  serviceDate: SendCardRecordRaw['service_date'];
  providerName: SendCardRecordRaw['provider_name'];
}

export interface CardSpendLimitHistoryRaw {
  id: string;
  card_id: string;
  spend_limit_cents: number;
  change_amount_cents: number;
  created_by_internal: string | null;
  created_by_external: string | null;
  created: string;
  user?: {
    id: string;
    first_name: string;
    last_name: string;
  };
}
export interface CardSpendLimitHistoryCleaned {
  id: CardSpendLimitHistoryRaw['id'];
  cardId: CardSpendLimitHistoryRaw['card_id'];
  spendLimitCents: CardSpendLimitHistoryRaw['spend_limit_cents'];
  changedAmountCents: CardSpendLimitHistoryRaw['change_amount_cents'];
  createdAt: CardSpendLimitHistoryRaw['created'];
  createdAtPrettyDate: string;
  createdByInternalId: CardSpendLimitHistoryRaw['created_by_internal'];
  createdByExternalId: CardSpendLimitHistoryRaw['created_by_external'];
  createdByName: string;
}

export interface CardRaw {
  id: string;
  stripe_card_id: string;
  created: string;
  cardholderName: {
    first: string;
    last: string;
    full: string;
  };
  cardholderEmail: string;
  cardholderPhone: string;
  cardholderDob: string;
  cardholderType: CardholderType;
  cardholderId: string;
  cardNumber: string;
  cardExpMonth: number;
  cardExpYear: number;
  cardBilling: {
    address: Address;
  };
  cardStatus: CardStatus;
  cardNickname: string;
  cardSpendControl: {
    spending_limits: SpendingLimit[];
    allowed_categories: null;
    blocked_categories: null;
    spending_limits_currency: 'usd';
  };
  cardType: CardType;
  remainingSpendLimit: number;
  spend: number;
  group?: {
    id: string;
    name: string;
  };
}

export interface CardCleaned {
  id: CardRaw['id'];
  type: CardRaw['cardType'];
  stripeId: CardRaw['stripe_card_id'];
  createdAt: string;
  prettyCreatedAtDate: string;
  isCreatedAtDateSameAsPrev: boolean;
  cardholderName: CardRaw['cardholderName'];
  cardholderEmail: CardRaw['cardholderEmail'];
  cardholderPhone: CardRaw['cardholderPhone'];
  cardholderDob: CardRaw['cardholderDob'];
  cardholderType: CardRaw['cardholderType'];
  cardholderId: CardRaw['cardholderId'];
  lastFour: CardRaw['cardNumber'];
  expiration: {
    month: CardRaw['cardExpMonth'];
    year: CardRaw['cardExpYear'];
    prettyDateString: string; // '08/26'
  };
  billingAddress: CardRaw['cardBilling']['address'];
  status: CardRaw['cardStatus'];
  isActive: boolean;
  nickname: CardRaw['cardNickname'];
  spendingControls: {
    remainingSpendLimitCents: number;
    totalSpendingLimitCents: number;
    spendingLimits: CardRaw['cardSpendControl']['spending_limits'];
  };
  totalSpendCents: CardRaw['spend'];
  group?: CardRaw['group'];
}

export interface CardDetailsRaw {
  card: {
    id: string;
    stripe_card_id: string;
    brand: 'Visa';
    cancellation_reason: string | null;
    cardholder: {
      id: string;
      stripe_cardholder_id: string;
      name: string;
      type: string;
      email: string;
      object: 'issuing.cardholder';
      status: string;
      billing: {
        address: Address;
      };
      company: string | null;
      created: number;
      metadata: {
        type: CardholderType;
      };
      individual: {
        dob: {
          day: number;
          year: number;
          month: number;
        };
        last_name: string;
        first_name: string;
        card_issuing: {
          user_terms_acceptance: {
            ip: string | null;
            date: number;
            user_agent: string | null;
          };
        };
        verification: {
          document: {
            back: string | null;
            front: string | null;
          };
        };
      };
      phone_number: string;
      requirements: {
        past_due: [];
        disabled_reason: string | null;
      };
      preferred_locales: [];
      spending_controls: {
        spending_limits: SpendingLimit[];
        allowed_categories: [];
        blocked_categories: [];
        spending_limits_currency: null;
      };
      group?: {
        id: string;
        name: string;
        status: GroupStatus;
      };
    };
    created: string;
    createdBy: string;
    created_by_external: string | null;
    created_by_internal: string | null;
    currency: 'usd';
    exp_month: number;
    exp_year: number;
    last4: string;
    nickname: string;
    spending_controls: {
      spending_limits: SpendingLimit[];
      allowed_categories: null;
      blocked_categories: null;
      spending_limits_currency: 'usd';
    };
    status: CardStatus;
    type: CardType;
    spend: number;
    remaining_spend_limit_cents: number | null;
  };
  transactionCount: {
    count: number;
  };
}
export interface CardDetailsCleaned {
  id: CardDetailsRaw['card']['id'];
  type: CardDetailsRaw['card']['type'];
  stripeId: CardDetailsRaw['card']['stripe_card_id'];
  createdAt: CardDetailsRaw['card']['created'];
  createdByName: CardDetailsRaw['card']['createdBy'];
  createdByInternalId: CardDetailsRaw['card']['created_by_internal'];
  createdByExternalId: CardDetailsRaw['card']['created_by_external'];
  lastFour: CardDetailsRaw['card']['last4'];
  expiration: {
    month: CardDetailsRaw['card']['exp_month'];
    year: CardDetailsRaw['card']['exp_year'];
    prettyDateString: string; // '08/26'
  };
  billingAddress: CardDetailsRaw['card']['cardholder']['billing']['address'];
  status: CardDetailsRaw['card']['status'];
  isActive: boolean;
  nickname: CardDetailsRaw['card']['nickname'];
  spendingControls: {
    totalSpendingLimitCents: number;
    spendingLimits: CardDetailsRaw['card']['spending_controls']['spending_limits'];
  };
  totalSpendCents: CardDetailsRaw['card']['spend'];
  remainingSpendLimitCents: CardDetailsRaw['card']['remaining_spend_limit_cents'];
  transactionsCount: CardDetailsRaw['transactionCount']['count'];
  cardholder: {
    id: string; // TODO: get the BE to return the internal id
    stripeId: CardDetailsRaw['card']['cardholder']['id'];
    name: {
      first: CardDetailsRaw['card']['cardholder']['individual']['first_name'];
      last: CardDetailsRaw['card']['cardholder']['individual']['last_name'];
      full: CardDetailsRaw['card']['cardholder']['name'];
    };
    email: CardDetailsRaw['card']['cardholder']['email'];
    phone: CardDetailsRaw['card']['cardholder']['phone_number'];
    dob: string;
    type: CardDetailsRaw['card']['cardholder']['type'];
    status: CardDetailsRaw['card']['cardholder']['status'];
    group?: CardDetailsRaw['card']['cardholder']['group'];
  };
}
