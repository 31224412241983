export const splitArray = <T>(arr: T[]) => {
  // Calculate the middle index, rounding up if the length is odd
  const middleIndex = Math.ceil(arr.length / 2);

  // Split the array into two halves
  const firstHalf = arr.slice(0, middleIndex);
  const secondHalf = arr.slice(middleIndex);

  return [firstHalf, secondHalf];
};
