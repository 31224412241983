import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useGetBasePath, usePrevious } from 'src/hooks';
import { isProd, searchParamsToObject } from 'src/utils';

import { TrackContext } from './enums';
import { TrackingContextProvider } from './TrackingContextProvider';

export const RouteTrackingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const pathParams = useParams();
  const searchParams = searchParamsToObject(
    new URLSearchParams(location.search),
  );
  const { basePath } = useGetBasePath();

  const previousUrl = usePrevious(location.pathname + location.search);
  const currentUrl = location.pathname + location.search;
  useEffect(() => {
    if (previousUrl !== currentUrl) {
      if (!isProd) {
        console.log('Mixpanel pageview', {
          path: location.pathname,
          basePath,
          pathParams,
          searchParams,
        });
      }
      mixpanel.track_pageview({
        path: location.pathname,
        basePath,
        pathParams,
        searchParams,
      });
    }
  }, [
    basePath,
    currentUrl,
    location.pathname,
    pathParams,
    previousUrl,
    searchParams,
  ]);

  return (
    <TrackingContextProvider
      trackingProps={{
        path: location.pathname,
        pathParams: pathParams as Record<string, string>,
        searchParams,
        basePath,
      }}
      contextName={TrackContext.route}
      {...props}
    />
  );
};
