import { useEffect, useRef, useState } from 'react';

import type { ReactNode } from 'react';

export const ScaleInnerComponentToFitWidth = ({
  children,
  minWidthBeforeScaling,
  className,
}: {
  children: ReactNode;
  minWidthBeforeScaling: number;
  className?: string;
}) => {
  const [width, setWidth] = useState(0);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0]) {
        const { width } = entries[0].contentRect;
        setWidth(width);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  return (
    <div style={{ width: '100%', overflow: 'hidden' }} className={className}>
      <div ref={elementRef} style={{ width: '100%' }}>
        <div
          style={{
            minWidth: `${minWidthBeforeScaling}px`,
            transform: `scale(${
              width >= minWidthBeforeScaling ? 1 : width / minWidthBeforeScaling
            })`,
            transformOrigin: 'top left',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
