import { css } from '@emotion/react';
import { Clear as ClearIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { TrackType, useToast, useTrack } from 'src/context';
import { COLOR_PALETTE } from 'src/theme';

import { useUpdateCardNickname } from './useUpdateCardNickname';

import type { UnsavedChanges } from './';

interface CardNicknameFormFields {
  nickname: string;
}

export const CardNicknameForm = ({
  nickname,
  cardId,
  setUnsavedChanges,
}: {
  nickname?: string;
  cardId: string;
  setUnsavedChanges: (props: UnsavedChanges) => void;
}) => {
  const { track } = useTrack();
  const { setToast } = useToast();
  const { mutateAsync: updateCardNickname } = useUpdateCardNickname();
  const useFormMethods = useForm<CardNicknameFormFields>();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useFormMethods;

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const handleSetSuccessMessage = (message: string) => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };

  const nicknameFormValue = watch('nickname');
  useEffect(() => {
    setUnsavedChanges({
      nickname: {
        hasUnsavedChanges: nicknameFormValue !== nickname,
        label: 'Card Nickname',
      },
    });
  }, [nicknameFormValue, nickname, setUnsavedChanges]);

  const onSubmit = async ({ nickname }: CardNicknameFormFields) => {
    track({
      label: 'Submit card nickname',
      type: TrackType.action,
      actionType: 'submit',
    });
    await updateCardNickname(
      {
        cardId,
        nickname: nickname ?? '',
      },
      {
        onSuccess: () => {
          handleSetSuccessMessage('Saved');
          setUnsavedChanges({
            nickname: {
              hasUnsavedChanges: false,
              label: 'Card Nickname',
            },
          });
          setToast({
            message: 'Card Nickname Saved Successfully',
            severity: 'success',
          });
          track({
            label: 'Set card nickname',
            type: TrackType.effect,
            isSuccessful: true,
          });
        },
        onError: () => {
          setToast({
            message: 'Error Saving Card Nickname',
            severity: 'error',
          });
          track({
            label: 'Set card nickname',
            type: TrackType.effect,
            isSuccessful: false,
          });
        },
      },
    );
  };

  return (
    <FormProvider {...useFormMethods}>
      <Box
        onSubmit={handleSubmit(onSubmit, (errors) => {
          track({
            label: 'Submit card nickname',
            type: TrackType.action,
            actionType: 'submit',
            validationErrors: Object.entries(errors).map(([key, value]) => ({
              field: key,
              message: value.message,
            })),
          });
        })}
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0 8px;
          justify-content: flex-end;
          align-items: baseline;
        `}
        component="form"
      >
        <Typography
          css={css`
            flex: 1 1 100%;
            font-size: 12px;
            font-weight: 500;
            padding-bottom: 4px;
          `}
        >
          Card Nickname
        </Typography>
        <Controller
          control={control}
          name="nickname"
          defaultValue={nickname ?? ''}
          render={({ field }) => {
            return (
              <TextField
                className="highlight-block"
                sx={{
                  input: {
                    '&::placeholder': {
                      opacity: 1,
                      color: COLOR_PALETTE.lightTextOnLight,
                    },
                  },
                }}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value || ''}
                error={!!errors.nickname}
                type="text"
                variant="outlined"
                placeholder=""
                helperText={
                  errors.nickname ? (errors.nickname.message as string) : ''
                }
                css={css`
                  flex: 1 1 200px;
                  fieldset {
                    border-radius: 8px;
                    border-color: ${COLOR_PALETTE.border};
                  }
                `}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disabled={!nicknameFormValue || isSubmitting}
                        size="small"
                        css={css`
                          padding: 8px;
                          min-width: 0;
                          color: rgba(0, 0, 0, 0.6);
                        `}
                        onClick={() => {
                          if (field.name) {
                            setValue(field.name, '');
                          } else {
                            console.warn('CurrencyTextField: no name prop');
                          }
                        }}
                      >
                        <ClearIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color={successMessage ? 'success' : 'primary'}
          disabled={isSubmitting || !!successMessage}
          css={css`
            flex: 0 0 80px;
            padding: 10px 16px;
          `}
        >
          {successMessage ?? 'Save'}
        </Button>
      </Box>
    </FormProvider>
  );
};
