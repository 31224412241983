import { usePagination } from '@table-library/react-table-library/pagination';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetPaginatedData, usePrevious } from 'src/hooks';
import { DEFAULT_PAGE_SIZE } from 'src/utils';

import { useSearchBar } from './SearchBar';

import type { Data, TableNode } from '@table-library/react-table-library';
import type { Pagination } from '@table-library/react-table-library/types/pagination';
import type { QueryKey, UseQueryResult } from '@tanstack/react-query';
import type { PaginatedData, Privilege } from 'src/types';

const useScrollToTopOnPageChange = (page: number) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);
};

const useQueryLatestResultAndPageCount = (
  queryResult: UseQueryResult<PaginatedData<unknown>>,
) => {
  const [resultCount, setResultCount] = useState<number>();
  const [pageCount, setPageCount] = useState<number>();

  const updatedAt = queryResult.dataUpdatedAt ?? queryResult.errorUpdatedAt;
  const previousUpdatedAt = usePrevious(updatedAt);
  useEffect(() => {
    if (!!updatedAt && updatedAt !== previousUpdatedAt) {
      setPageCount(queryResult.data?.totalPages ?? 0);
      setResultCount(queryResult.data?.total ?? 0);
    }
  }, [
    previousUpdatedAt,
    queryResult.data?.total,
    queryResult.data?.totalPages,
    updatedAt,
  ]);

  return { resultCount, pageCount };
};

const useUpdateQueryParams = (
  shouldUseQueryParamsSearchState: boolean,
  { page }: { page: number },
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchParams.entries());

  useEffect(() => {
    if (shouldUseQueryParamsSearchState) {
      if (page !== Number(searchParamsObject.page)) {
        setSearchParams(
          { ...searchParamsObject, page: String(page) },
          { replace: true },
        );
      }
    }
  }, [
    page,
    searchParamsObject,
    setSearchParams,
    shouldUseQueryParamsSearchState,
  ]);
};

export type PaginatedTableFilter = Record<
  string,
  string | boolean | number | undefined
>;

export const PaginatedTableWrapper = <
  DataRaw extends TableNode,
  DataCleaned extends TableNode = DataRaw,
>({
  endpointUrl,
  queryKeyBase,
  filters,
  dataFormatter,
  requiredPrivileges,
  shouldUseQueryParamsSearchState = true,
  pageSize = DEFAULT_PAGE_SIZE,
  children,
}: {
  endpointUrl: string;
  queryKeyBase: string | unknown[] | readonly unknown[];
  filters?: PaginatedTableFilter;
  dataFormatter?: (data: DataRaw[]) => DataCleaned[];
  requiredPrivileges?: Privilege[];
  shouldUseQueryParamsSearchState?: boolean;
  pageSize?: number;
  children: (data: {
    queryResult: UseQueryResult<PaginatedData<DataCleaned>> & {
      queryKey: QueryKey;
    };
    pagination: Pagination<DataCleaned>;
    // TODO: figure out a cleaner way so I don't return everything twice. This is needed in this format for the table component
    paginationData: Data<DataCleaned>;
    resultCount?: number;
    pageCount?: number;
    searchProps: ReturnType<typeof useSearchBar>;
  }) => React.ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchParams.entries());

  const [page, setPage] = useState(
    shouldUseQueryParamsSearchState && searchParamsObject.page
      ? Number(searchParamsObject.page)
      : 1,
  );

  const searchProps = useSearchBar();

  const previousSearch = usePrevious(searchProps.searchValue);
  const isSearchValueDifferent = previousSearch !== searchProps.searchValue;
  useEffect(() => {
    if (isSearchValueDifferent) {
      setPage(1);
    }
  }, [isSearchValueDifferent]);

  const queryResult = useGetPaginatedData<DataRaw, DataCleaned>({
    endpointUrl,
    queryKeyBase,
    page: isSearchValueDifferent ? 1 : page,
    pageSize,
    filters: {
      ...(searchProps.searchValue ? { search: searchProps.searchValue } : {}),
      ...filters,
    },
    dataFormatter,
    requiredPrivileges,
  });
  const { resultCount, pageCount } =
    useQueryLatestResultAndPageCount(queryResult);

  useScrollToTopOnPageChange(page);
  useUpdateQueryParams(shouldUseQueryParamsSearchState, { page });

  const paginationData: Data<DataCleaned> = {
    nodes: queryResult.data?.results ?? [],
    pageInfo: {
      totalPages: queryResult.data?.totalPages,
      total: queryResult.data?.total,
    },
  };
  const pagination = usePagination(
    paginationData,
    {
      state: {
        page,
        size: DEFAULT_PAGE_SIZE,
      },
      onChange: (action) => {
        setPage(action.payload.page);
      },
    },
    {
      isServer: true,
    },
  );
  useEffect(() => {
    if (pageCount && page > pageCount) {
      setPage(1);
    }
  }, [page, pageCount]);

  return (
    <>
      {children({
        queryResult,
        pagination,
        paginationData,
        resultCount,
        pageCount,
        searchProps,
      })}
    </>
  );
};
