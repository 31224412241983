import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import { useAxios } from './useAxios';

import type { CardType, SpendingLimit } from 'src/types/cards';

interface CreateCard {
  cardholderId: string;
  cardType: CardType;
  nickname?: string;
  spendLimitCents?: number;
}

interface CreateCardResponse {
  id: string;
  stripe_card_id: string;
  object: 'issuing.card';
  brand: string; // 'Visa';
  cancellation_reason: null;
  cardholder: {
    id: string; // 'ich_1O1ybyQ9Ow3CBOAj8heppya6'
    object: 'issuing.cardholder';
    billing: {
      address: {
        city: string;
        country: string;
        line1: string;
        line2: string | null;
        postal_code: string;
        state: string;
      };
    };
    company: string | null;
    created: number; // 1697492766
    email: string | null;
    individual: {
      card_issuing: {
        user_terms_acceptance: {
          date: number; // 169749276653;
          ip: string | null;
          user_agent: null;
        };
      };
      dob: { day: number; month: number; year: number } | null;
      first_name: string;
      last_name: string;
      verification: {
        document: {
          back: null;
          front: null;
        };
      };
    };
    livemode: boolean;
    metadata: Record<string, unknown>;
    name: string;
    phone_number: string | null;
    preferred_locales: [];
    requirements: {
      disabled_reason: null;
      past_due: [];
    };
    spending_controls: {
      allowed_categories: [];
      blocked_categories: [];
      spending_limits: [];
      spending_limits_currency: null;
    };
    status: string; // 'active'
    type: string; // 'individual';
  };
  created: number; // 1697548159
  currency: string; // 'usd'
  exp_month: number; // 9
  exp_year: number; // 2026
  last4: string; // '0831'
  livemode: boolean;
  pin: null;
  replaced_by: null;
  replacement_for: null;
  replacement_reason: null;
  shipping: null;
  spending_controls: {
    allowed_categories: null;
    blocked_categories: null;
    spending_limits: SpendingLimit[];
    spending_limits_currency: 'usd';
  };
  status: string; // 'active'
  type: 'virtual';
  wallets: Record<string, unknown>;
}

export interface CreateCardResponseCleaned
  extends Omit<CreateCardResponse, 'stripe_card_id'> {
  cardStripeId: CreateCardResponse['stripe_card_id'];
}

export const useCreateCard = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();

  const createCard = async ({
    cardholderId,
    cardType,
    nickname,
    spendLimitCents,
  }: CreateCard) => {
    const { data } = await axios.post<CreateCardResponse>(
      SERVICE_BENJI_CARD_URLS.CARD_CREATE,
      {
        newCardholderId: cardholderId,
        cardType,
        cardNickname: nickname,
        spendLimitCents,
      },
      {
        headers: await generateSecureHeaders(),
      },
    );
    const { stripe_card_id, ...rest } = data;
    const cleanedData: CreateCardResponseCleaned = {
      ...rest,
      cardStripeId: stripe_card_id,
    };
    return cleanedData;
  };

  return useMutation({
    mutationFn: (
      data: Pick<
        CreateCard,
        'cardholderId' | 'cardType' | 'nickname' | 'spendLimitCents'
      >,
    ) => createCard(data),
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.cards._baseKey,
      ]);
    },
  });
};
