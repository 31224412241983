import { css } from '@emotion/react';
import { FormControlLabel, Switch } from '@mui/material';
import { Controller } from 'react-hook-form';

import { COLOR_PALETTE } from 'src/theme';

import type {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

interface FormFieldSwitchBaseProps<FormFields extends FieldValues> {
  name: FieldPath<FormFields>;
  label: React.ReactNode;
  defaultValue?: FieldPathValue<FormFields, FieldPath<FormFields>>;
  tabIndex?: number;
  className?: string;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  onBlur?: (value: boolean) => void;
  rules?: Omit<
    RegisterOptions<FormFields>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

interface FormFieldSwitchControlledProps<FormFields extends FieldValues>
  extends FormFieldSwitchBaseProps<FormFields> {
  control: Control<FormFields>;
}

interface FormFieldSwitchUncontrolledProps<FormFields extends FieldValues>
  extends FormFieldSwitchBaseProps<FormFields> {
  checked: boolean;
}

export function FormFieldSwitch<FormFields extends FieldValues>({
  name,
  label,
  defaultValue,
  className,
  disabled,
  rules,
  tabIndex,
  onChange,
  onBlur,
  ...rest
}:
  | FormFieldSwitchControlledProps<FormFields>
  | FormFieldSwitchUncontrolledProps<FormFields>) {
  if ('control' in rest) {
    return (
      <Controller
        control={rest.control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <FormControlLabel
            disabled={disabled}
            label={label}
            className={className}
            labelPlacement="start"
            tabIndex={tabIndex}
            css={css`
              height: 22px;
              align-content: center;
              margin-left: 0;
              .MuiSwitch-root {
                padding: 8px;
              }
              .MuiFormControlLabel-label {
                color: ${COLOR_PALETTE.textOnLight}!important;
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
              }
              .MuiSwitch-track {
                ${disabled
                  ? `
                    background-color: ${COLOR_PALETTE.switchBackgroundDisabled}!important;
                    `
                  : !field.value
                  ? `background-color: ${COLOR_PALETTE.switchBackgroundOff}!important;`
                  : `
              background-color: ${COLOR_PALETTE.switchBackground}!important;
            `}
                border-radius: 14px;
                opacity: 1 !important;
              }
              .MuiSwitch-switchBase {
                padding: 11px;
              }
              .MuiSwitch-thumb {
                color: ${COLOR_PALETTE.white};
                height: 16px;
                width: 16px;
              }
            `}
            control={
              <Switch
                name={field.name}
                defaultChecked={defaultValue}
                disabled={disabled}
                onChange={(e, checked) => {
                  field.onChange(e);
                  onChange?.(checked);
                }}
                onBlur={(e) => {
                  field.onBlur();
                  onBlur?.(!!e.target.value);
                }}
                checked={field.value}
              />
            }
          />
        )}
      />
    );
  } else {
    return (
      <FormControlLabel
        disabled={disabled}
        label={label}
        className={className}
        labelPlacement="start"
        tabIndex={tabIndex}
        css={css`
          height: 22px;
          align-content: center;
          .MuiSwitch-root {
            padding: 8px;
          }
          .MuiFormControlLabel-label {
            color: ${COLOR_PALETTE.textOnLight}!important;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
          }
          .MuiSwitch-track {
            ${disabled
              ? `
              background-color: ${COLOR_PALETTE.switchBackgroundDisabled}!important;
              `
              : !rest.checked
              ? `background-color: ${COLOR_PALETTE.switchBackgroundOff}!important;`
              : `
              background-color: ${COLOR_PALETTE.switchBackground}!important;
            `}
            border-radius: 14px;
            opacity: 1 !important;
          }
          .MuiSwitch-switchBase {
            padding: 11px;
          }
          .MuiSwitch-thumb {
            color: ${COLOR_PALETTE.white};
            height: 16px;
            width: 16px;
          }
        `}
        control={
          <Switch
            name={name}
            defaultChecked={defaultValue}
            disabled={disabled}
            onChange={(_, checked) => onChange?.(checked)}
            onBlur={(e) => onBlur?.(!!e.target.value)}
            checked={rest.checked}
          />
        }
      />
    );
  }
}
