import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAxios } from 'src/hooks/useAxios';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();

  const deleteUser = async ({ userId }: { userId: string }) => {
    const { data } = await axios.post(
      SERVICE_BENJI_CARD_URLS.USERS_DELETE_SUB_USER,
      {
        userId,
      },
      {
        headers: await generateSecureHeaders(),
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: { userId: string }) => deleteUser(data),
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.subUsers._baseKey,
      ]);
    },
  });
};
