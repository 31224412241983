import { useLocation, useParams } from 'react-router-dom';

export const useGetBasePath = () => {
  const location = useLocation();
  let basePath = location.pathname;
  const params = useParams();

  Object.entries(params).forEach(([paramName, paramValue]) => {
    basePath = basePath.replace(paramValue ?? '', `:${paramName}`);
  });

  return { basePath };
};
