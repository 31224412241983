import { SvgIcon } from '@mui/material';

export const CardsIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 9">
          <g id="Union">
            <path
              d="M4.66972 14.3333C4.66972 13.8599 5.0394 13.4762 5.49541 13.4762H5.50459C5.9606 13.4762 6.33028 13.8599 6.33028 14.3333C6.33028 14.8067 5.9606 15.1905 5.50459 15.1905H5.49541C5.0394 15.1905 4.66972 14.8067 4.66972 14.3333Z"
              fill="inherit"
            />
            <path
              d="M9.47099 13.4762C9.01497 13.4762 8.6453 13.8599 8.6453 14.3333C8.6453 14.8067 9.01497 15.1905 9.47099 15.1905H11.4588C11.9148 15.1905 12.2844 14.8067 12.2844 14.3333C12.2844 13.8599 11.9148 13.4762 11.4588 13.4762H9.47099Z"
              fill="inherit"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.57798 3C2.60192 3 1 4.66294 1 6.71429V15.2857C1 17.3371 2.60192 19 4.57798 19H17.422C19.3981 19 21 17.3371 21 15.2857V6.71429C21 4.66294 19.3981 3 17.422 3H4.57798ZM2.65138 9.47619V15.2857C2.65138 16.3903 3.51395 17.2857 4.57798 17.2857H17.422C18.4861 17.2857 19.3486 16.3903 19.3486 15.2857V9.47619H2.65138ZM19.3486 7.76191H2.65138V6.71429C2.65138 5.60972 3.51395 4.71429 4.57798 4.71429H17.422C18.4861 4.71429 19.3486 5.60972 19.3486 6.71429V7.76191Z"
              fill="inherit"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
