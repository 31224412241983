import { Slide } from '@mui/material';
import { forwardRef } from 'react';

import type { TransitionProps } from '@mui/material/transitions';

export const SlideLeftTransition = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
