import { css } from '@emotion/react';
import {
  FormHelperText,
  MenuItem,
  Select,
  FormControl,
  FormLabel,
} from '@mui/material';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { COLOR_PALETTE } from 'src/theme';

import type { ReactNode } from 'react';
import type {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

export function FormFieldSelect<FormFields extends FieldValues>({
  name,
  label,
  placeholder,
  control,
  defaultValue,
  className,
  disabled,
  options,
  helperText,
  rules,
  isSmall,
  shrinkLabel,
  displayEmpty = shrinkLabel || !!placeholder,
}: {
  name: FieldPath<FormFields>;
  label?: ReactNode;
  placeholder?: string;
  control: Control<FormFields>;
  defaultValue: FieldPathValue<FormFields, FieldPath<FormFields>>;
  options: { label: React.ReactNode; value: string; disabled?: boolean }[];
  className?: string;
  disabled?: boolean;
  helperText?: React.ReactNode;
  rules?: Omit<
    RegisterOptions<FormFields>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  isSmall?: boolean;
  shrinkLabel?: boolean;
  displayEmpty?: boolean;
}) {
  const { formState } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <FormControl
          disabled={formState.isSubmitting || disabled}
          className={className}
          error={!!get(formState.errors, field.name)}
        >
          <FormLabel
            css={css`
              font-size: 12px;
              font-weight: 500;
            `}
          >
            {label}
          </FormLabel>
          <Select
            id={field.name}
            name={field.name}
            placeholder={placeholder}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            size={isSmall ? 'small' : 'medium'}
            displayEmpty={displayEmpty}
            css={css`
              .MuiSelect-select {
                ${!!placeholder && !field.value
                  ? `color: ${COLOR_PALETTE.lightTextOnLight};`
                  : ''}
              }
            `}
          >
            {!!placeholder && (
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
            )}
            {options.map(({ label, value, disabled }) => (
              <MenuItem
                key={value}
                value={value}
                disabled={disabled}
                className="highlight-block"
              >
                {label}
              </MenuItem>
            ))}
          </Select>
          {(helperText || !!get(formState.errors, field.name)) && (
            <FormHelperText error={!!get(formState.errors, field.name)}>
              {get(formState.errors, field.name)
                ? (get(formState.errors, field.name)?.message as string)
                : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
