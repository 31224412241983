import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';

import { AsynchronouslyLoadedSection } from 'src/components/AsynchronouslyLoadedSection';
import { DataItemDisplay } from 'src/components/DataItemDisplay';
import { FancyCurrencyDisplay } from 'src/components/FancyCurrencyDisplay';
import { PaginatedTableWrapper } from 'src/components/PaginatedTableWrapper';
import { TableError, TableLoader, TablePagination } from 'src/components/Table';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { cleanCardSpendLimitHistory } from './utils';

import type {
  CardSpendLimitHistoryCleaned,
  CardSpendLimitHistoryRaw,
} from 'src/types/cards';

const CARD_SPEND_LIMIT_HISTORY_PAGE_SIZE = 5;

export const SpendLimitHistory = ({ cardId }: { cardId: string }) => {
  return (
    <AsynchronouslyLoadedSection isLoading={!cardId}>
      <Box>
        <PaginatedTableWrapper<
          CardSpendLimitHistoryRaw,
          CardSpendLimitHistoryCleaned
        >
          endpointUrl={SERVICE_BENJI_CARD_URLS.CARD_SPEND_LIMIT_HISTORY}
          queryKeyBase={queryKeys.cards._cardSpendLimitHistoryBaseKey()}
          dataFormatter={cleanCardSpendLimitHistory}
          requiredPrivileges={[Privilege.cards]}
          shouldUseQueryParamsSearchState={false}
          pageSize={CARD_SPEND_LIMIT_HISTORY_PAGE_SIZE}
          filters={{ cardId }}
        >
          {({
            queryResult: { isLoading, isError },
            pagination,
            paginationData,
            resultCount,
            pageCount,
          }) => (
            <>
              <Box
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  padding-bottom: 12px;
                `}
              >
                {(resultCount ?? 0) > 0 && (
                  <Box
                    css={css`
                      display: flex;
                      flex: 1 1 100%;
                      align-items: baseline;
                      padding: 8px 14px;
                      position: sticky;
                      z-index: 1;
                      top: -1px;
                      background-color: ${COLOR_PALETTE.white};
                      min-height: 55px;
                      align-items: center;

                      &:after {
                        content: '';
                        position: absolute;
                        left: 8px;
                        right: 8px;
                        bottom: 0;
                        height: 1px;
                        border-bottom: 1px dashed ${COLOR_PALETTE.borderMid};
                      }
                    `}
                  >
                    <Typography
                      css={css`
                        font-size: 14px;
                        font-weight: 700;
                      `}
                    >
                      Card Spend Limit History
                    </Typography>
                  </Box>
                )}
                {paginationData.nodes.map((spendLimitHistoryItem, index) => (
                  <Box
                    key={spendLimitHistoryItem.id}
                    css={css`
                      display: flex;
                      gap: 8px 16px;
                      padding: 14px;
                      border-bottom: 1px solid
                        ${index + 1 === CARD_SPEND_LIMIT_HISTORY_PAGE_SIZE
                          ? 'transparent'
                          : COLOR_PALETTE.border};
                    `}
                  >
                    <Box>
                      <Typography
                        css={css`
                          font-size: 15px;
                          font-weight: 600;
                        `}
                      >
                        {index +
                          1 +
                          ((pagination.state.page ?? 1) - 1) *
                            CARD_SPEND_LIMIT_HISTORY_PAGE_SIZE}
                        .
                      </Typography>
                    </Box>
                    <Box
                      css={css`
                        flex: 1 1 auto;
                        display: flex;
                        gap: 12px 18px;
                        flex-wrap: wrap;
                      `}
                    >
                      <Typography
                        css={css`
                          flex: 1 1 100%;
                          font-weight: 600;
                        `}
                      >
                        {spendLimitHistoryItem.createdAtPrettyDate}
                      </Typography>

                      <DataItemDisplay
                        css={css`
                          flex: 0 0 120px;
                        `}
                        size="small"
                        label="New Spend Limit"
                        value={
                          spendLimitHistoryItem.spendLimitCents ? (
                            <FancyCurrencyDisplay
                              amountCents={
                                spendLimitHistoryItem.spendLimitCents
                              }
                            />
                          ) : (
                            'No Limit'
                          )
                        }
                      />
                      <DataItemDisplay
                        css={css`
                          flex: 1 0 140px;
                        `}
                        size="small"
                        label="Changed By"
                        value={spendLimitHistoryItem.createdByName}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box
                css={css`
                  padding: 0 14px;
                `}
              >
                <TableLoader isLoading={isLoading} />
                <TableError isLoading={isLoading} isError={isError} />
              </Box>
              {(pageCount ?? 0) > 1 && (
                <TablePagination
                  resultCount={resultCount}
                  pageCount={pageCount}
                  pagination={pagination}
                  noSidePadding
                  css={css`
                    width: initial;
                    margin: 0 14px;
                    position: sticky;
                    bottom: 0;
                    background-color: ${COLOR_PALETTE.white};
                  `}
                />
              )}
            </>
          )}
        </PaginatedTableWrapper>
      </Box>
    </AsynchronouslyLoadedSection>
  );
};
